import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	allMarketMatch: {},
};

const SBglobalPersist = createSlice({
	name: 'SBglobalPersist',
	initialState,
	reducers: {
		setMatch: (state, action) => ({
			...state,
			allMarketMatch: action.payload,
		}),
	},
});

export const { setMatch } = SBglobalPersist.actions;

export default SBglobalPersist.reducer;
