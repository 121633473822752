export const SuccessMessage = {
	login: 'loggedInSuccess',
	loginMetamask: 'loggedInMetamaskSuccess',
	logout: 'loggedOutSuccess',
	changePassword: 'profileMessagesChangePassword',
	playerStatusUpdate: 'updatePlayerStatusSuccess',
	createOperator: 'operatorUserCreatedSuccess',
	resetPassword: 'passwordResetSuccess',
	operatorDelete: 'operatorDeletedSuccess',
	operatorStatusUpdate: 'operatorStatusUpdateSuccess',
	signup: 'signupSuccess',
	BetPlacedSuccessfully: 'BetPlacedSuccessfully',
	PlayerEscapedSuccessfully: 'PlayerEscapedSuccessfully',
	tokenVerify: 'tokenVerify',
	metamaskLogin: 'Metamask Login Successful',
	emailVerify: 'emailVerify',
	emailChangedSuccess: 'emailChangedSuccess',
	profileUpdatedSuccess: 'profileUpdatedSuccess',
	otpVerified: 'otpVerified',
	twoFADeactivated: 'twoFADeactivated',
	wagerLimit: 'wagerLimit',
	depositLimit: 'depositLimit',
	lossLimit: 'lossLimit',
	accountDisable: 'accountDisable',
	sessionTimeout: 'sessionTimeout',
	limitUpdated: 'limitUpdated',
	addToFavourite: 'addToFavourite',
	removeFromFavourite: 'removeFromFavourite',
};
