import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPreMatchesService } from 'SportsBook/BetBalancer/Network/Services/preMatch.service';

const getPreMatches = createAsyncThunk(
	'get/preMatches',
	async (payload, thunkApi) => {
		try {
			let res = await getPreMatchesService(payload);
			res = res.data.data;
			return res;
		} catch (err) {
			return thunkApi.rejectWithValue(
				(err && err[0] && err[0]?.description) || err?.message
			);
		}
	}
);

export { getPreMatches };
