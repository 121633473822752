import React from 'react';
import { Grid, Button, Typography, List, ListItem } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { outcomeFormatter } from 'SportsBook/BetBalancer/Helper/formatterFunctions';
import { SB_ROUTE_PATHS } from 'SportsBook/BetBalancer/SportsRoutes/routeConstant';
import downarrow from '../../../../../components/ui-kit/Icons/sports-icon/downarrow.svg';
import useMarkets from './hooks/useMarkets';
import Odds from '../../../Components/Odds';
import { setMatch } from '../../../Store/slice/globalPersist.slice';

const MainMarkets = ({ eventMarkets, isBoxOpen, toggleBoxOpen, match }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { filteredMainMarkets } = useMarkets(eventMarkets);

	const handleNavigate = () => {
		dispatch(setMatch(match));
		navigate(`${SB_ROUTE_PATHS.ALL_MARKETS}?eventId=${match?.fixtureId}`);
	};
	const handleDropdown = (e) => {
		e.stopPropagation();
		if (filteredMainMarkets?.[0]?.outcomes?.length > 1) {
			toggleBoxOpen(match?.fixtureId);
		}
	};

	return (
		<>
			{filteredMainMarkets?.length > 0 ? (
				<Grid>
					<Typography className="match-upper--text">
						{filteredMainMarkets[0]?.market?.name}
					</Typography>
				</Grid>
			) : (
				<Grid>
					<Typography className="match-upper--text">{t('oneXtwo')}</Typography>
				</Grid>
			)}
			<List className="odds-listing">
				{filteredMainMarkets[0]?.outcomes?.length ? (
					filteredMainMarkets[0]?.outcomes?.map((odds) => (
						<ListItem>
							<Typography variant="h6">
								{outcomeFormatter(
									odds,
									match?.eventParticipants[0]?.participant?.name,
									match?.eventParticipants[1]?.participant?.name,
									filteredMainMarkets[0]?.market?.providerId
								)}
							</Typography>
							<Grid className="odds-list">
								<Odds
									odds={odds}
									market={filteredMainMarkets[0]?.market}
									match={match}
								/>
							</Grid>
						</ListItem>
					))
				) : (
					<ListItem onClick={handleNavigate}>
						<Typography variant="h6" sx={{ visibility: 'hidden' }}>
							{t('hiddenName')}
						</Typography>
						<Grid className="odds-list">
							<Typography
								variant="span"
								// className={isAdded ? 'active' : ''}
								// onClick={oddsActiveClass}
							>
								{t('goToAllMarkets')}
							</Typography>
						</Grid>
					</ListItem>
				)}
				<Grid>
					<Typography
						variant="h6"
						style={{
							opacity: 0,
							lineHeight: 'normal',
							minHeight: '19px',
							maxHeight: '19px',
						}}
					>
						N
					</Typography>
					<Button
						className="more-market--btn"
						onClick={(e) => handleDropdown(e)}
					>
						<img src={downarrow} alt="" />
					</Button>
				</Grid>
				{filteredMainMarkets?.length > 1 && (
					<Grid
						className={`inner-odds--section ${
							isBoxOpen === match?.fixtureId ? 'selected' : ''
						}`}
					>
						{filteredMainMarkets.slice(1, 5).map((market) => (
							// <List className="inner-odds-listing">
							//     <Typography className="inner-market--name">
							//         {market.market.name}
							//     </Typography>
							//     {market?.outcomes?.map((odds) => (
							//         <ListItem>

							//             <Typography variant="h6">{outcomeFormatter(odds,
							//                 match?.eventParticipants[0]?.participant?.name,
							//                 match?.eventParticipants[1]?.participant?.name,
							//                 filteredMainMarkets[0]?.market?.providerId
							//             )}</Typography>
							//             <Grid className="odds-list">
							//                 <Odds
							//                     odds={odds}
							//                     market={market?.market}
							//                     match={match}
							//                 />
							//             </Grid>
							//         </ListItem>
							//     ))}
							// </List>
							<>
								<Grid>
									<Typography className="match-upper--text">
										{market.market.name}
									</Typography>
								</Grid>
								<List className="odds-listing">
									{market?.outcomes?.map((odds) => (
										<ListItem>
											<Typography variant="h6">
												{outcomeFormatter(
													odds,
													match?.eventParticipants[0]?.participant?.name,
													match?.eventParticipants[1]?.participant?.name,
													filteredMainMarkets[0]?.market?.providerId
												)}
											</Typography>
											<Grid className="odds-list">
												<Odds
													odds={odds}
													market={filteredMainMarkets[0]?.market}
													match={match}
												/>
											</Grid>
										</ListItem>
									))}
								</List>
							</>
						))}
					</Grid>
				)}
			</List>
		</>
	);
};

export default MainMarkets;

MainMarkets.propTypes = {
	eventMarkets: PropTypes.arrayOf(
		PropTypes.shape({
			status: PropTypes.number,
			marketId: PropTypes.string,
			market: PropTypes.shape({
				providerId: PropTypes.string,
				name: PropTypes.string,
			}),
			outcomes: PropTypes.arrayOf(
				PropTypes.shape({
					providerId: PropTypes.string,
					name: PropTypes.string,
					status: PropTypes.string,
					base_line: PropTypes.string,
					price: PropTypes.string,
				})
			),
		})
	).isRequired,
	isBoxOpen: PropTypes.string.isRequired,
	toggleBoxOpen: PropTypes.func.isRequired,
	match: PropTypes.shape({
		id: PropTypes.string,
		fixtureId: PropTypes.string,
		status: PropTypes.string,
		betStatus: PropTypes.string,
		score: PropTypes.string,
		startDate: PropTypes.string,
		league: PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
			sport: PropTypes.shape({
				id: PropTypes.string,
				name: PropTypes.string,
			}),
		}),
		eventParticipants: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
				type: PropTypes.string,
				participant: PropTypes.shape({
					id: PropTypes.string,
					name: PropTypes.string,
				}),
			})
		),
	}).isRequired,
};
