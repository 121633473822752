/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useMemo, useState } from 'react';
import { ReactSelect } from 'components/molecules/ReactSelect/index';
import { WalletIcon } from 'components/ui-kit/Icons/iconComponents/WalletIcon';
import InputField from 'components/molecules/Input/InputField';
import Button from 'components/molecules/Button/index';
import '../profile.scss';
import { useDispatch, useSelector } from 'react-redux';
import { openErrorToaster } from 'helpers/toaster.helpers';
import {
	getCryptoNetworkFees,
	getCryptoNetworks,
	withdrawAmountRequest,
} from 'redux-thunk/thunk/user.thunk';
import {
	resetpaymentDetails,
	resetPaymentState,
} from 'redux-thunk/redux/slices/payment.slice';
import Loader from 'components/ui-kit/Loader/index';
import { Divider } from '@mui/material';
import { preventSymbolsInNumField } from 'utils/helperFunctions.utils';

const WithdrawAmount = () => {
	const userData = useSelector((state) => state?.user?.user?.user);
	const [selectedCurrency, setSelectedCurrency] = useState(0);
	const [selectedNetwork, setSelectedNetwork] = useState(0);
	const settingData = useSelector((state) => state.general.settingData);
	const [errrorMsg, setErrorMsg] = useState('');
	const [addressError, setAddressError] = useState('');
	const { cryptoNetworks, networkFees, withdrawSuccess, withdrawLoading } =
		useSelector((state) => state.payment);
	const [totalWithDrawAmount, setTotalWithDrawAmount] = useState(0);
	const [withdrawInfo, setWithdrawInfo] = useState({
		address: '',
		amount: '',
	});
	const dispatch = useDispatch();
	const [minAmount, setMinAmount] = useState('');

	useEffect(() => {
		if (withdrawSuccess) {
			setWithdrawInfo({
				address: '',
				amount: '',
			});
			setTotalWithDrawAmount(0);
			setAddressError('');
			setSelectedNetwork(0);
			setSelectedCurrency(0);
			setErrorMsg('');
			dispatch(resetPaymentState());
		}
		return () => {
			dispatch(resetPaymentState());
		};
	}, [withdrawSuccess]);

	useEffect(() => {
		if (networkFees?.amount) {
			if (!withdrawInfo.amount) {
				setTotalWithDrawAmount(0);
			}
			if (withdrawInfo.amount || withdrawInfo.amount === '0') {
				const amount =
					Number(withdrawInfo.amount || 0) - Number(networkFees?.amount);
				if (amount < 0) {
					setTotalWithDrawAmount(0);
				} else {
					setTotalWithDrawAmount(amount.toFixed(2));
				}
			}
		}
	}, [networkFees, withdrawInfo.amount]);

	useEffect(() => {
		if (networkFees?.amount) {
			const amount =
				Number(networkFees?.amount) +
				(settingData?.ccPaymentWithdrawalLimit
					? +JSON.parse(settingData?.ccPaymentWithdrawalLimit)?.[
							selectedCurrency?.value
						]
					: 0);
			setMinAmount(amount);
		}
	}, [networkFees]);

	const onAmountChange = (e) => {
		const value = Number(e?.target?.value || 0);
		let msg = '';
		const curr = userData?.wallets?.find(
			(item) => item.currency.code === selectedCurrency?.value
		);
		if (value === 0) {
			msg = 'Please enter amount';
		}
		if (curr?.amount < value) {
			msg = 'Insufficient Funds';
		}
		if (+minAmount > +value) {
			msg = `Minimum withdraw amount ${minAmount}`;
		}
		setErrorMsg(msg);
		setWithdrawInfo((current) => ({ ...current, amount: e?.target?.value }));
	};

	const handleAddress = (e) => {
		const value = e?.target?.value;
		setWithdrawInfo((current) => ({ ...current, address: value }));
		if (!value) {
			setAddressError('Please enter Address');
		} else {
			setAddressError('');
		}
	};

	const handleSubmit = () => {
		if (errrorMsg) {
			openErrorToaster(errrorMsg);
		}
		if (addressError) {
			openErrorToaster(addressError);
		}
		if (!withdrawInfo.address) {
			setAddressError('Please enter Address');
			return;
		}
		if (+minAmount > +withdrawInfo.amount) {
			setErrorMsg(`Minimum withdraw amount ${minAmount}`);
			return;
		}
		if (!errrorMsg && !addressError && selectedCurrency) {
			dispatch(
				withdrawAmountRequest({
					currencyCode: selectedCurrency?.value,
					chain: selectedNetwork?.value,
					amount: withdrawInfo?.amount,
					address: withdrawInfo?.address,
				})
			);
		}
	};

	const currencyOptions = useMemo(
		() =>
			userData?.wallets?.map(({ currency }) => ({
				label: (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%',
						}}
					>
						<span>
							<span className="me-1">{currency?.symbol}</span>
							<span>{currency.code}</span>
						</span>
						{/* <span>
							<img
								src={goldICon}
								alt="Coin"
								className="me-2 option-coin-icon"
							/>
							{bonusAmount}
						</span> */}
					</div>
				),
				value: currency.code,
			})),
		[userData]
	);

	// const userCurrencyData = userData?.wallets?.find(
	// 	(item) => item?.currency?.isDefault === true
	// );

	const networkOptions = useMemo(
		() =>
			cryptoNetworks?.map((item) => ({
				label: (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%',
						}}
					>
						<span>
							{/* <span className="me-1">{currency?.symbol}</span> */}
							<span>{item.chainFullName}</span>
						</span>
						{/* <span>
							<img
								src={goldICon}
								alt="Coin"
								className="me-2 option-coin-icon"
							/>
							{bonusAmount}
						</span> */}
					</div>
				),
				value: item.chain,
			})),
		[cryptoNetworks]
	);
	const handleCurrencyChange = (item) => {
		dispatch(getCryptoNetworks({ currencyCode: item.value }));
		dispatch(resetpaymentDetails());
		setSelectedCurrency(item);
		setSelectedNetwork(0);
	};
	const handleNetworkSelect = (item) => {
		dispatch(resetpaymentDetails());
		dispatch(
			getCryptoNetworkFees({
				currencyCode: selectedCurrency.value,
				chain: item.value,
			})
		);
		setSelectedNetwork(item);
	};

	return (
		<div className="profile-card-wrap">
			<div className="profile-heading mb-3">
				<h4 className="m-0">
					<WalletIcon /> Withdraw
				</h4>
			</div>
			<div className="d-flex col-lg-12 gap-1 mb-3 form-group">
				<div className="col-lg-12 col-12 w-2 ">
					<label htmlFor="country" className="form-label ">
						Withdraw Currency
					</label>
					<ReactSelect
						selectProvider__control
						my_css
						css-dvyn1m-control
						isSearchable={false}
						// defaultMenuIsOpen
						options={currencyOptions}
						className="selectProvider"
						defaultValue={selectedCurrency}
						classNamePrefix="selectProvider"
						placeholder="Wallets"
						value={selectedCurrency}
						onChange={handleCurrencyChange}
					/>
				</div>
			</div>
			<div className="d-flex col-lg-12 gap-1 mb-3 form-group">
				<div className="col-lg-12 col-12 w-2 ">
					<label htmlFor="country" className="form-label">
						Choose Network
					</label>
					<ReactSelect
						type="text"
						placeholder="Network"
						classNamePrefix="selectProvider"
						options={networkOptions}
						defaultValue={selectedNetwork}
						value={selectedNetwork}
						onChange={handleNetworkSelect}
						isDisabled={!selectedCurrency?.value}
					/>
				</div>
			</div>
			<div className="d-flex col-lg-12 gap-1 mb-3 form-group">
				<div className="col-lg-12 col-12 w-2 ">
					<label htmlFor="country" className="form-label ">
						Withdrawal Address
						{/* Withdrawal Address(Note: Only Bitcoin) */}
					</label>
					<InputField
						disabled={!selectedNetwork}
						type="text"
						placeholder="Address"
						className="form-control shadow-none"
						onChange={handleAddress}
						required
						value={withdrawInfo.address}
					/>
					<span className="amount-error-msg">{addressError}</span>
				</div>
			</div>

			<div className="d-flex col-lg-12 gap-1 mb-3 form-group">
				<div className="col-lg-12 col-12 w-2 ">
					<div className="d-flex justify-content-between">
						<label htmlFor="country" className="form-label">
							Withdrawal Amount
						</label>
						<div className="min-amount-tag">
							{minAmount ? (
								<div
									style={{ color: '#fff' }}
									className=""
								>{`Min: ${minAmount}`}</div>
							) : null}
						</div>
					</div>
					<InputField
						disabled={!selectedNetwork}
						type="number"
						placeholder="Amount"
						className="form-control shadow-none"
						onChange={onAmountChange}
						onKeyDown={preventSymbolsInNumField}
						value={withdrawInfo.amount}
						required
					/>
					<span className="amount-error-msg">{errrorMsg}</span>
					{/* <div className="amt-btn-box">
						<button className="amt-btn">Min</button>
						<button className="amt-btn">25%</button>
						<button className="amt-btn">50%</button>
						<button className="amt-btn">Max</button>
					</div> */}
					{/* <div className="amt-text-box">
						<p className="text">
							Available: <strong>₹0.00</strong>
						</p>
						<p className="text">
							<a href="#">Locked funds:</a> <strong>₹0.00</strong>
						</p>
					</div> */}
				</div>
			</div>
			<Divider style={{ color: '#f8e4e4' }} />
			{networkFees.amount ? (
				// <div
				// 	style={{ color: '#fff' }}
				// 	className=""
				// >{`Fee: ${networkFees.amount}`}</div>
				<div className="total-withdraw-amount">
					<span>Fee: </span>
					<span>{` ${networkFees.amount}`}</span>
				</div>
			) : null}
			<div className="total-withdraw-amount">
				<span>Total Withdraw amount: </span>
				<span>{`${totalWithDrawAmount || 0}`}</span>
			</div>
			<div className="d-flex col-lg-12 gap-1 mb-3 mt-2 form-group">
				<div className="auth-btn-wrap w-100 d-flex justify-content-start align-items-center">
					<Button
						disabled={
							!!errrorMsg ||
							!selectedCurrency ||
							!!addressError ||
							!withdrawInfo.amount ||
							withdrawLoading
						}
						type="submit"
						className="btn btn-primary submit-button-fund"
						style={{
							marginTop: '10px',
							width: '100%',
						}}
						buttonlabel={<span>{withdrawLoading ? <Loader /> : 'Submit'}</span>}
						onClick={handleSubmit}
					/>
				</div>
			</div>
		</div>
	);
};

export default React.memo(WithdrawAmount);
