import styled from 'styled-components';

export const OddsTypeContainer = styled.div`
	.odds-section {
		margin-bottom: 3rem;
		.odds-select {
			background-color: var(--casino-input);
			border-radius: 8px;
			border: 1px solid var(--casino-input);
		}
		label {
			color: #fff;
			opacity: 0.4;
		}
		fieldset {
			border: transparent;
		}
		svg,
		div {
			color: rgba(255, 255, 255, 0.4);
		}
	}
`;
