/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Tabs, Tab, Box, Typography, Grid } from '@mui/material';
// import { NavLink } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { SPORTS_ICON } from '../TopSportsBar/constant';

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const MatchHeading = ({ iconSrc, title, handleSportSelect, selectedSport }) => {
	const { sports } = useSelector((state) => state.SBsports);

	useEffect(
		() =>
			// setting empty id
			() =>
				handleSportSelect(''),
		[]
	);
	return (
		<>
			<Box className="top-heading">
				<Typography variant="h4" display="flex">
					<img src={iconSrc} alt="" />
					{title}
				</Typography>
				{/* <NavLink to="#" className="view-all--btn">
					View All
				</NavLink> */}
			</Box>
			<Box>
				<Box>
					<Tabs
						aria-label="tabs"
						variant="scrollable"
						scrollButtons
						className="sports-filter"
					>
						{sports?.map((sport, index) => (
							<Tab
								key={sport?.name}
								className={sport.providerId === selectedSport ? 'active' : ''}
								icon={
									<ReactSVG
										src={SPORTS_ICON[sport.providerId]}
										className="sports-icon"
										wrapper="span"
									/>
								}
								label={sport.name}
								{...a11yProps(index)}
								onClick={() => handleSportSelect(sport)}
							/>
						))}
						<Grid className="left-effect" />
						<Grid className="right-effect" />
					</Tabs>
				</Box>
			</Box>
		</>
	);
};

export default MatchHeading;

MatchHeading.propTypes = {
	iconSrc: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	setValue: PropTypes.func.isRequired,
	handleSportSelect: PropTypes.func.isRequired,
	selectedSport: PropTypes.string.isRequired,
};
