import { createSlice } from '@reduxjs/toolkit';
import {
	getUserCasinoTransactions,
	getUserSportsTransactions,
	getUserTransactions,
} from 'redux-thunk/thunk/user.thunk';

const initialState = {
	usersCasinoTransactions: null,
	usersSportTransactions: null,
	isUserTransactionLoading: false,
	isCasinoTransactionLoading: false,
	isSportTransactionLoading: false,
};

const { reducer } = createSlice({
	name: 'transactions',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getUserCasinoTransactions.fulfilled, (state, action) => ({
				...state,
				isCasinoTransactionLoading: false,
				usersCasinoTransactions: action.payload,
			}))
			.addCase(getUserCasinoTransactions.pending, (state) => ({
				...state,
				isCasinoTransactionLoading: true,
			}))
			.addCase(getUserSportsTransactions.fulfilled, (state, action) => ({
				...state,
				isSportTransactionLoading: false,
				usersSportTransactions: action.payload,
			}))
			.addCase(getUserSportsTransactions.pending, (state) => ({
				...state,
				isSportTransactionLoading: true,
				usersSportTransactions: null,
			}))
			.addCase(getUserSportsTransactions.rejected, (state) => ({
				...state,
				isSportTransactionLoading: false,
			}))
			.addCase(getUserTransactions.pending, (state) => ({
				...state,
				isUserTransactionLoading: true,
			}))
			.addCase(getUserTransactions.fulfilled, (state) => ({
				...state,
				isUserTransactionLoading: false,
			}));
	},
});

export default reducer;
