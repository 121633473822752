import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	CASINO_LOAD_PAGINATION_LIMIT,
	CASINO_SUB_CATE_GAME_LIMIT,
} from 'constants/index';
import {
	getCasinoCategoriesService,
	getSiteSettingDataService,
} from 'network/services/general.service';
import { getGamesListService } from 'network/services/sportsBook.service';
import {
	getCategoriesDataSuccess,
	getSettingDataSuccess,
	setCategoryGames,
} from 'redux-thunk/redux/slices/general.slice';

export const getCategoryData = createAsyncThunk(
	'/getCategoryData',
	async (data, thunkApi) => {
		try {
			const res = await getCasinoCategoriesService();
			let categoryGames = {};
			const categoryArr = [];
			res?.casinoCategories?.forEach((item) => {
				if (
					(+item.gameCount >= 8 && categoryArr.length < 3) ||
					item.uniqueId === 'originals'
				) {
					categoryArr.push(item);
				}
			});
			// for (const item of categoryArr) {
			// 	const cateGameData = await getGamesListService({
			// 		limit: CASINO_LOAD_PAGINATION_LIMIT,
			// 		categories: `["${item?.id}"]`,
			// 		pageNo: 1,
			// 	});
			// 	categoryGames = {
			// 		id: item?.id,
			// 		categoryInfo: { ...item, games: cateGameData.casinoGames.rows || [] },
			// 	};
			// 	thunkApi.dispatch(setCategoryGames(categoryGames));
			// };
			const promises = categoryArr.map((item) =>
				getGamesListService({
					limit: CASINO_LOAD_PAGINATION_LIMIT,
					categories: `["${item?.id}"]`,
					pageNo: 1,
				})
					.then((cateGameData) => {
						const games = {
							id: item?.id,
							categoryInfo: {
								...item,
								games: cateGameData.casinoGames.rows || [],
							},
						};
						thunkApi.dispatch(setCategoryGames(games));
					})
					.catch((error) => {
						throw new Error(
							`Error fetching games for category ${item?.id}:`,
							error
						);
					})
			);
			Promise.all(promises).catch(() => {});
			const gameData = await getGamesListService({
				limit: CASINO_SUB_CATE_GAME_LIMIT,
				pageNo: 1,
			});
			categoryGames = {
				id: 'all',
				categoryInfo: {
					id: 'all',
					name: { EN: 'All' },
					games: gameData.casinoGames.rows || [],
				},
			};
			thunkApi.dispatch(setCategoryGames(categoryGames));
			thunkApi.dispatch(getCategoriesDataSuccess(res));
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const getSiteSettingData = createAsyncThunk(
	'/getSiteSettingData',
	async (data, thunkApi) => {
		try {
			const res = await getSiteSettingDataService();
			thunkApi.dispatch(getSettingDataSuccess(res));
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);
