/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import { ApproveVerificationIcon } from 'components/ui-kit/Icons/iconComponents/ApproveVerificationIcon';
import { PendingVerificationIcon } from 'components/ui-kit/Icons/iconComponents/PendingVerificationIcon';
import { RejectVerificationIcon } from 'components/ui-kit/Icons/iconComponents/RejectVerificationIcon';

const VerificationLabelInfo = (props) => {
	const { labels, selectedTab, onChangeTabToggler, setImageDoc, documents } =
		props;
	return (
		<ul
			className="nav nav-pills horizontal-theme-tabs verification-labels"
			id="pills-tab"
			role="tablist"
		>
			{selectedTab &&
				labels?.map((item, index) => {
					let documentsFind = null;
					if (item?.name) {
						documentsFind = documents?.find(
							(innerItem) =>
								innerItem.documentName === item?.documentLabel?.name
						);
					}
					return (
						<>
							{item?.required && (
								<li
									className={`nav-item ${
										selectedTab === item.name ? 'active' : ''
									}`}
									role="presentation"
									key={index}
								>
									<button
										onClick={() => {
											onChangeTabToggler(item);
											setImageDoc(null);
										}}
										className={`nav-link ${
											selectedTab === item.name ? 'active' : ''
										}`}
										type="button"
									>
										<span className="icon">
											{documentsFind?.status === 1 && (
												<ApproveVerificationIcon
													className="approve-icon"
													fill="black"
												/>
											)}
											{documentsFind?.status === 2 && (
												<RejectVerificationIcon className="reject-icon" />
											)}
											{(!documentsFind?.status ||
												documentsFind?.status === 0) && (
												<PendingVerificationIcon className="pending-icon" />
											)}
										</span>
										{item?.name}
									</button>
								</li>
							)}
						</>
					);
				})}
		</ul>
	);
};

export default VerificationLabelInfo;
