/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import {
	Grid,
	Typography,
	Box,
	Tabs,
	Tab,
	MenuItem,
	Select,
	CircularProgress,
	Tooltip,
} from '@mui/material';
import ArrowBackwardIcon from '@mui/icons-material/ArrowBack';
import moment from 'moment';
import NoDataFound from 'SportsBook/BetBalancer/Components/NoDataFound/NoDataFound';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';
import DatePickerInput from 'SportsBook/BetBalancer/Components/DatePicker/DatePicker';
import Button from 'SportsBook/BetBalancer/Components/Button/index';
import SBLoader from 'SportsBook/BetBalancer/Assets/Loader/SBLoader';
import AllBetsModal from './components/AllBetsModal/AllBetsModal';
import CashoutModal from './components/CashoutModal';
import { MyBetsContainer } from './style';
import SingleBet from './components/SingleBet';
import {
	BET_SETTLEMENT_STATUS,
	SETTLEMENT_STATUS,
} from '../../Helper/constants';
import useMyBets from './hooks/useMyBets';
import useCashout from './hooks/useCashout';

const MyBets = () => {
	const { t } = useTranslation();
	const {
		handleCloseModal,
		fetchMoreData,
		handleOptionSelect,
		onEndDateChange,
		handleChange,
		onStartDateChange,
		handleModalData,
		modalData,
		isAllBetsModalOpen,
		myBetsLoading,
		mybets,
		settlementStatus,
		selectedValue,
		startDate,
		endDate,
		page,
		totalPages,
		handleBack,
		fetchMyBets,
		handleIdCopy,
		getWinText,
	} = useMyBets();

	const {
		handleCashout,
		cashoutLoading,
		isCashoutModalOpen,
		closeCashoutModal,
		handleAcceptCashout,
	} = useCashout(fetchMyBets);

	const renderMyBets = useMemo(
		() =>
			mybets?.map((bet) => {
				const firstBet = bet?.bets[0];
				const icon =
					bet?.type === 'multiple' ? (
						<Button
							className="bg-transparent border-0 m-0 p-0"
							onClick={() => handleModalData(bet)}
						>
							<RemoveRedEyeIcon />
						</Button>
					) : null;

				return (
					<Grid key={bet.id} className="box-grid">
						<Grid
							display="flex"
							justifyContent="space-between"
							alignItems="center"
						>
							<Grid display="flex" gap={2}>
								<Typography className="text-capitalize" variant="span">
									{bet?.type}
								</Typography>
								<Typography variant="span" className="bet-slip-date">
									{' '}
									{moment(bet.createdAt).format('MM-DD-yyyy, hh:mm a')}
								</Typography>
							</Grid>
							<Grid>
								<Typography className="text-capitalize bet-status pending won loss case-out">
									{bet.settlementStatus}
								</Typography>
							</Grid>
						</Grid>

						<SingleBet bet={firstBet} icon={icon} />

						<Grid
							display="flex"
							justifyContent="space-between"
							sx={{ paddingBottom: '0.8rem', fontSize: '12px' }}
						>
							<Typography sx={{ fontSize: '12px' }}>
								{t('totalOdds')}
							</Typography>

							<Typography sx={{ fontSize: '12px' }}>
								{bet?.multipliedOdds?.toFixed(2)}
							</Typography>
						</Grid>
						<Grid
							display="flex"
							justifyContent="space-between"
							sx={{ paddingBottom: '0.8rem', fontSize: '12px' }}
						>
							<Typography sx={{ fontSize: '12px' }}>{t('stake')}</Typography>

							<Typography sx={{ fontSize: '12px' }}>{bet?.stake}</Typography>
						</Grid>
						<Grid
							display="flex"
							justifyContent="space-between"
							sx={{ paddingBottom: '0.8rem', fontSize: '12px' }}
						>
							<Typography sx={{ fontSize: '12px' }}>
								{getWinText(bet.settlementStatus)}
							</Typography>

							<Typography sx={{ fontSize: '12px' }}>
								{bet?.winningAmount}
							</Typography>
						</Grid>
						<Grid display="flex">
							<Tooltip
								placement="top"
								title={t('copy')}
								onClick={() => handleIdCopy(bet.id)}
							>
								<Typography
									display="flex"
									alignItems="center"
									gap={1.5}
									sx={{
										paddingBottom: '1rem',
										fontSize: '12px',
										cursor: 'pointer',
									}}
								>
									{t('betslipIdSB')} {bet.id}
									<ContentCopyIcon sx={{ fontSize: '12px' }} />
								</Typography>
							</Tooltip>
						</Grid>
						{bet.settlementStatus === SETTLEMENT_STATUS.PENDING && (
							<Grid sx={{ textAlign: 'center' }} className="cash-out---btn">
								<Button
									disabled={cashoutLoading}
									onClick={(e) => handleCashout(e, bet.id)}
								>
									{cashoutLoading ? (
										<CircularProgress
											size="1rem"
											className="mt-2 color-black"
										/>
									) : (
										t('cashout')
									)}
								</Button>
							</Grid>
						)}
					</Grid>
				);
			}),
		[mybets]
	);

	return (
		<>
			<MyBetsContainer>
				<Grid className="mybet-custom--spacer">
					<Grid sx={{ display: 'flex' }}>
						<ArrowBackwardIcon
							onClick={handleBack}
							sx={{
								marginRight: '10px',
								color: 'var(--white)',
								cursor: 'pointer',
							}}
						/>

						<h4>{t('myBets')}</h4>
					</Grid>

					<Grid
						className="mybet-tabs"
						display="flex"
						alignItems="center"
						justifyContent="space-between"
						sx={{ flexDirection: { xs: 'column', md: 'row' } }}
					>
						<Box className="d-flex">
							<Tabs
								value={selectedValue}
								onChange={handleChange}
								textColor="secondary"
								indicatorColor="secondary"
								aria-label="secondary tabs example"
								className="tabs-button"
							>
								{/* <Tab value="all" label="All" /> */}
								<Tab value="sportBook" label={t('sportsbook')} />
								{/* <Tab value="exchange" label="Exchange" /> */}
							</Tabs>
							<Box>
								<Grid
									className="odds-section"
									sx={{ marginBottom: '0 !important' }}
								>
									<Select
										sx={{
											height: 37,
											width: 120,
											background:
												'linear-gradient(to right,rgba(20, 32, 46, 1) 0%,rgba(33, 50, 68, 1) 100%)',
										}}
										labelId="demo-select-small-label"
										id="odds-select"
										className="odds-select text-light"
										value={settlementStatus}
										onChange={handleOptionSelect}
									>
										{BET_SETTLEMENT_STATUS.map(({ name, value, key }) => (
											<MenuItem key={key} value={value}>
												{name}
											</MenuItem>
										))}
									</Select>
								</Grid>
							</Box>
						</Box>

						<Box
							display="flex"
							gap={2}
							justifyContent="center"
							alignItems="center"
							sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
						>
							<Grid display="flex" flexDirection="column">
								{/* <Typography variant="label">Start Date</Typography> */}
								<DatePickerInput
									label="Start Date"
									placeholder="Start Date"
									selected={startDate}
									handleDateChange={onStartDateChange}
									selectsStart
									startDate={startDate}
									endDate={endDate}
									maxDate={moment().toDate()}
								/>
							</Grid>
							<Grid display="flex" flexDirection="column">
								{/* <Typography variant="label">End Date</Typography> */}
								<DatePickerInput
									label="End Date"
									placeholder="End Date"
									selected={endDate}
									handleDateChange={onEndDateChange}
									selectsEnd
									startDate={startDate}
									endDate={endDate}
									minDate={startDate}
									maxDate={moment().toDate()}
								/>
							</Grid>
						</Box>
					</Grid>
					{/*
                        <InfiniteScrollComponent
                            hasMore
                            fetchData={fetchMoreData}
                            length={mybets?.length}
                            limit={limit}
                            totalPages={totalPages}
                            page={page}
                        > */}
					{mybets?.length > 0 ? (
						<>
							<Grid className="grid-section">{renderMyBets}</Grid>
							<Grid> {myBetsLoading && <SBLoader />}</Grid>
						</>
					) : myBetsLoading ? (
						<SBLoader />
					) : (
						<NoDataFound title={t('noBetsFound')} />
					)}

					{page < totalPages && (
						<div className="load-more-wrap text-center my-3">
							<button
								type="button"
								className="btn btn-secondary"
								style={{ minWidth: '110px' }}
								onClick={() => {
									if (page < totalPages) {
										fetchMoreData();
									}
								}}
							>
								{!myBetsLoading ? (
									t('loadMore')
								) : (
									<CircularProgress size="1rem" className="mt-2 color-black" />
								)}
							</button>
						</div>
					)}
					{/* </InfiniteScrollComponent> */}
				</Grid>
			</MyBetsContainer>
			<AllBetsModal
				isOpen={isAllBetsModalOpen}
				data={modalData}
				onCloseModal={handleCloseModal}
			/>
			<CashoutModal
				isOpen={isCashoutModalOpen}
				onCloseModal={closeCashoutModal}
				handleAcceptCashout={handleAcceptCashout}
			/>
		</>
	);
};

export default MyBets;
