import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index';
import axiosInstance from 'network/apis/index';
import { microServices } from 'network/apis/microservice/index';

export const getAllBonusService = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/bonus',
		{},
		{
			server: microServices.SERVICE_URL_1,
			params: data,
			loader: LOADER_HANDLER_TYPES.page,
		}
	);

export const activateWageringService = (data) =>
	axiosInstance(METHOD_TYPES.post, '/bonus/activate-wagering', data, {
		server: microServices.SERVICE_URL_1,
		loader: LOADER_HANDLER_TYPES.submit,
		successMessage: 'Bonus activated successfully',
	});

export const getUserBonusService = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/get-bonus-detail',
		{},
		{
			server: microServices.SERVICE_URL_1,
			params: data,
		}
	);
export const getBonusDetailsService = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/bonus/detail',
		{},
		{
			server: microServices.SERVICE_URL_1,
			params: data,
		}
	);
export const getLoyaltyLevelService = () =>
	axiosInstance(
		METHOD_TYPES.get,
		'/get-loyalty-level',
		{},
		{
			server: microServices.SERVICE_URL_1,
			loader: LOADER_HANDLER_TYPES.submit,
		}
	);

export const availBonusService = (data) =>
	axiosInstance(METHOD_TYPES.post, '/bonus/avail', data, {
		server: microServices.SERVICE_URL_1,
	});

export const getMyBonusesService = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/bonus/user',
		{},
		{
			server: microServices.SERVICE_URL_1,
			params: data,
			loader: LOADER_HANDLER_TYPES.page,
		}
	);

export const fetchDailyBonus = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/bonus/daily/bonus',
		{},
		{
			server: microServices.SERVICE_URL_1,
			params: data,
			loader: LOADER_HANDLER_TYPES.page,
		}
	);

export const fetchWeeklyBonus = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/bonus/weekly/bonus',
		{},
		{
			server: microServices.SERVICE_URL_1,
			params: data,
			loader: LOADER_HANDLER_TYPES.page,
		}
	);


export const fetchMonthlyBonus = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/bonus/monthly/bonus',
		{},
		{
			server: microServices.SERVICE_URL_1,
			params: data,
			loader: LOADER_HANDLER_TYPES.page,
		}
	);

export const claimBonusDailyWeekly = (data) =>
	axiosInstance(METHOD_TYPES.post, '/bonus/daily-weekly/claim', data, {
		server: microServices.SERVICE_URL_1,
	});

export const cancelBonusService = (data) =>
	axiosInstance(METHOD_TYPES.post, '/bonus/cancel', data, {
		server: microServices.SERVICE_URL_1,
	});
export const getReferralCodeService = (data) =>
	axiosInstance(METHOD_TYPES.get, '/user/referred-users', data, {
		server: microServices.SERVICE_URL_1,
		params: data,
		loader: LOADER_HANDLER_TYPES.submit,
	});

export const fetchBonusClaimService = (payload) =>
	axiosInstance(METHOD_TYPES.post, '/bonus/claim', payload, {
		server: microServices.SERVICE_URL_1,
		loader: LOADER_HANDLER_TYPES.page,
		params: payload,
	});
