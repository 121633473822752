import styled from 'styled-components';

export const TopSportsBarContainer = styled.div`
.sports-navigation {
  background-color: var(--topNavigationBg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 2;
  position: relative;
  .sb-sports-listing{
    width: calc(100% - 120px);
    display: flex;
    align-items:center;
  }
  ul{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 0;
    padding: 10px 8px;
    overflow: hidden;
    li {
      padding: 8px;
      text-align: center;
      width: auto;
      &:nth-child(1) {
        a.active {
          span.live-icon {
            svg {
              filter: var(--drop-shadow);
              opacity: 1;
              path {
                fill: var(--text-color);
              }
            }
          }
        }
      }
      a {
        position: relative;
        img,svg {
          width: 32px;
          opacity: 0.8;
          &:hover {
            opacity: 1;
          }
        }
        &.show{
          transform: rotate(180deg);
        }
        .hot-icon {
          position: absolute;
    top: -8px;
    right: -10px;
    background-color: var(--white);
    height: 20px;
    width: 20px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
          svg {
            color: red;
            width: 15px;
            opacity: 1;
          }
        }
      }
      .drop-down-icon {
        height: 32px;
        width: 32px;
        min-width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 18px;
          margin: 0 auto;
        }
      }
    }
  }
  .right-button--group {
    width: 96px;
    margin-right: 8px;
    display: flex;
    justify-content:space-between;
    button {
      background-color: transparent;
      border: none;
      padding: 8px;
    }
  }
}


.extra-sports-icon {
  background-color: var(--extraMarketSportsBg);
  overflow: hidden;
  transform: translateY(-100%);
  // transition: max-height 1s;
  position: absolute;
  transition: 0.3s ease-in-out;
  width: 100%;
  left: 0;
  z-index: 1;
  &.show{
    transform: translateY(0);
    padding: 10px 0;
  }
  ul{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0;
    padding: 10px;
    li {
      padding: 8px;
      width: 90px;
      min-width: 90px;
      justify-content: center;
      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0.7;
        font-size: 12px;
        text-transform: capitalize;
        text-align: center;
        &:hover{
          opacity: 1;
          color: var(--white);
        }
        img {
          width: 32px;
      }
    }
  }
}
`;
