import styled, { css } from 'styled-components';

const OddsUpDownMixin = css`
	position: absolute;
	top: 3px;
	right: 3px;
	height: 5px;
	width: 5px;
	border-radius: 50%;
	display: inline-block;
	padding: 0;
`;
export const MatchesBoxContainer = styled.div`
	.sports-list-box-view {
		padding: 0 1rem 1rem 1rem;
		.box-view {
			display: grid;
			align-items: stretch;
			grid-template-columns: repeat(auto-fill, minmax(24%, 1fr));
			gap: 12px 12px;
			@media screen and (max-width: 1590px) {
				grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
			}
			@media screen and (max-width: 1260px) {
				grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
			}
			// @media screen and (max-width: 1023px) {
			// 	grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
			// }
			@media screen and (max-width: 991px) {
				grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
			}
			@media screen and (max-width: 767px) {
				grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
			}
			@media screen and (max-width: 680px) {
				grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
			}
			.box {
				color: var(--white);
				width: 100%;
				border: 1px solid transparent;
				opacity: 1;
				padding: 8px;
				background-color: var(--boxViewBg);
				box-sizing: border-box;
				min-height: 100%;
				border-color: var(--boxViewBg);
				border-radius: 8px;
				cursor: pointer;
				position: relative;
				&.open {
					box-shadow: 0 0 100px rgba(0, 0, 0, 0.65);
				}
				.inner-box--more---market {
					// position: absolute;
					width: 100%;
				}
				.league-name {
					font-size: 12px;
					opacity: 0.4;
					cursor: pointer;
					width: calc(100% - 70px);
					overflow: hidden;
					white-space: nowrap;
					position: relative;
					top: 2px;
					-webkit-mask-image: var(--textMaskBg);
					&:hover {
						opacity: 1;
					}
					img {
						width: 15px;
						margin-right: 5px;
					}
				}
				.icons {
					font-size: 12px;
					display: flex;
					gap: 8px;
					width: 70px;
					justify-content: flex-end;
					svg {
						width: 15px;
						opacity: 0.4;
						cursor: pointer;
						&:hover {
							opacity: 1;
						}
					}
				}
				.match-time {
					line-height: 0;
					padding-bottom: 10px;
					span {
						font-size: 12px;
						opacity: 0.4;
						margin-top: 5px;
						display: inline-block;
						line-height: normal;
					}
					img {
						margin-left: 8px;
					}
				}
				.live-match-time {
					display: flex;
					justify-content: space-between;
					padding-top: 6px;
					padding-bottom: 8px;
					span {
						color: var(--secondaryRadialBtn);
						font-size: 12px;
					}
					img {
						width: 20px;
						margin-right: 0;
					}
				}
			}
			.team-logo--name {
				gap: 8px;
				padding-bottom: 10px;
				.match-score {
					color: rgb(255, 255, 255);
					border: 1px solid rgba(255, 255, 255, 0.1);
					height: 26px;
					width: 26px;
					padding: 4px;
					font-size: 12px;
					background-color: rgba(255, 255, 255, 0.05);
					box-sizing: border-box;
					align-items: center;
					font-weight: bold;
					line-height: 16px;
					border-radius: 5px;
					justify-content: center;
					display: flex;
				}
			}
			span.team-name {
				font-size: 13px;
				font-weight: bold;
				width: 99%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				-webkit-mask-image: var(--textMaskBg);
			}
			img {
				width: 20px;
				margin-right: 10px;
			}
		}

		.match-upper--text {
			font-size: 13px;
			opacity: 0.4;
			padding-bottom: 5px;
		}

		ul.inner-odds-listing {
			flex-direction: column;
			.inner-market--name {
				width: 100%;
				padding-top: 8px;
				font-size: 12px;
				opacity: 0.4;
			}
		}

		.inner-odds--section {
			height: 0;
			width: calc(100% + 18px);
			margin-left: -9px;
			background: var(--boxViewBg);
			left: 0;
			z-index: 9;
			overflow: hidden;
			position: absolute;
			transition: all 0.2s ease-in-out;
			top: 105%;
		}

		.inner-odds--section.selected {
			overflow-y: scroll;
			height: 250px;
			transition: height 0.2s;
			padding: 8px;
			box-shadow: 0px 33px 38px rgba(0, 0, 0, 0.65);
			border-radius: 0 0 8px 8px;
		}

		ul.odds-listing li .odds-list {
			@media screen and (max-width: 767px) {
				flex-direction: column;
			}
			span {
				@media screen and (max-width: 767px) {
					padding: 6px 0;
					font-size: 12px;
				}
			}
		}

		ul.inner-odds-listing li {
			.odds-list {
				@media screen and (max-width: 767px) {
					flex-direction: row;
				}
				span {
					&:nth-child(1) {
						background-color: var(--sidebar-bg);
					}
				}
			}
		}

		ul.odds-listing,
		ul.inner-odds-listing {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 8px;
			padding: 0;
			.odds-type--one {
				.odds-list-2 {
					background-color: var(--sidebar-bg);
					display: flex;
					justify-content: space-between;
					width: 100%;
					border-radius: 5px;
					padding: 0 2px;
				}
				div p {
					padding: 12px 6px;
					border-radius: 5px;
					font-weight: bold;
					cursor: pointer;
					font-size: 12px;
				}
			}
			li {
				padding: 0;
				flex-direction: column;
				.odds-list {
					padding: 0;
					background-color: var(--oddsParentBg);
					border-radius: 5px;
					gap: 4px;
					display: flex;
					width: 100%;
				}
				h6 {
					font-size: 12px;
					opacity: 0.4;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 85px;
                    text-align: center;
				}
				span {
					color: var(--white);
					width: 100%;
					justify-content: center;
					display: flex;
					padding: 5px 0;
					border-radius: 5px;
					font-weight: bold;
					cursor: pointer;
					font-size: 12px;
					position: relative;
					// width: 100%;
					padding: 10px;
					.odds-up {
						${OddsUpDownMixin}
						background-color: green !important;
					}
					.odds-down {
						background-color: red !important;
						${OddsUpDownMixin}
					}
					&:hover {
						background-color: var(--secondaryRadialBtn) !important;
						color: var(--button-text);
					}
					// &:nth-child(1) {
					// 	background-color: var(--sidebar-bg);
					// }
					&:nth-child(2) {
						background-color: var(--sidebar-bg);
					}
					&:nth-child(3) {
						background-color: var(--sidebar-bg);
					}
					&.active {
						background-color: var(--secondaryRadialBtn);
						color: var(--button-text);
					}
				}
			}
		}
		.favorite-icon.fill-icon {
			animation: zoom-in-zoom-out 1s ease;
			opacity: 1 !important;
			path {
				fill: var(--secondaryRadialBtn);
			}
		}
		.more-market--btn {
			padding: 2px;
			min-width: auto;
			position: relative;
			top: -2px;
            background-color: var(--oddsParentBg);
			img {
				margin-right: 0;
			}
		}

        .no-games-message {
            width: "100%",
            text-align: "center",
            color: var(--white);
            margin: "0.625rem 0",
            "& h1": {
              font-size: "1rem",
              font-weight: "500",
              width: "100%",
              color: "#574264"
            },
            "& .MuiPagination-ul": {
              justify-content: "center",
              flex-wrap: "nowrap"
            }
        }
	}

	@keyframes zoom-in-zoom-out {
		0% {
			transform: scale(1, 1);
		}
		50% {
			transform: scale(1.5, 1.5);
		}
		100% {
			transform: scale(1, 1);
		}
	}

	.bounce2 {
		animation: bounce2 2s ease;
	}
	@keyframes bounce2 {
		30% {
			transform: scale(1);
		}
		40%,
		60% {
			transform: rotate(-20deg) scale(1);
		}
		50% {
			transform: rotate(20deg) scale(1);
		}
		70% {
			transform: rotate(0deg) scale(1);
		}
		100% {
			transform: scale(1);
		}
	}

	.pulse {
		animation: pulse 0.5s infinite ease-in-out alternate;
	}
	@keyframes pulse {
		from {
			transform: scale(0.7);
		}
		to {
			transform: scale(1.2);
		}
	}
`;
