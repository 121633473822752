import axios from 'axios';
import { getAuthToken } from 'SportsBook/BetBalancer/Helper/storage';
import {
	errorHandler,
	responseHandler,
} from './interceptorHandler/responseHandler';
// import { getAccessToken } from './storageUtils';

// const BASE_URL = process.env.REACT_APP_MOCK_SERVER;
const BASE_URL = process.env.REACT_APP_SPORTS_BOOK_SERVER;

const METHODS = {
	get: 'GET',
	post: 'POST',
	put: 'PUT',
	delete: 'DELETE',
};

axios.interceptors.response.use(responseHandler, errorHandler);

const makeRequest = async ({
	url = '/',
	method = METHODS.get,
	data = {},
	config = {},
	params = {},
}) => {
	const authToken = getAuthToken();
	return axios.request({
		headers: {
			...(authToken ? { Authorization: `AccessToken=${authToken}` } : {}),
		},
		url: `${BASE_URL}${url}`,
		method,
		data,
		config,
		params,
		withCredentials: true,
	});
};

const getRequest = (url, params) =>
	makeRequest({ url, method: METHODS.get, params });

const postRequest = (url, data, config) =>
	makeRequest({ url, method: METHODS.post, data, config });

const putRequest = (url, data, config) =>
	makeRequest({ url, method: METHODS.put, data, config });

const deleteRequest = (url, data) =>
	makeRequest({ url, method: METHODS.delete, data });

export { getRequest, postRequest, putRequest, deleteRequest };
