import { createSlice } from '@reduxjs/toolkit';
import { paymentProviderRequest } from 'redux-thunk/thunk/paymentProvider.thunk';

const initialState = {
	paymentProviderList: null,
	paymentProviderLoading: false,
	paymentProviderError: '',
};

const {
	actions: {
		getPaymentProviderSuccess,
		getPaymentProviderStart,
		getPaymentProviderFail,
	},
	reducer,
} = createSlice({
	name: 'paymentProvider',
	initialState,
	reducers: {
		getPaymentProviderSuccess: (state, action) => ({
			...state,
			paymentProviderLoading: false,
			paymentProviderList: action.payload,
		}),

		getPaymentProviderStart: (state) => ({
			...state,
			paymentProviderLoading: true,
		}),
		getPaymentProviderFail: (state) => ({
			...state,
			paymentProviderLoading: false,
			paymentProviderError: true,
		}),
	},
	extraReducers: (builder) => {
		builder
			.addCase(paymentProviderRequest.pending, (state) => ({
				...state,
				paymentProviderLoading: true,
			}))
			.addCase(paymentProviderRequest.fulfilled, (state, action) => ({
				...state,
				paymentProviderLoading: false,
				paymentProviderList: action.payload,
			}))
			.addCase(paymentProviderRequest.rejected, (state, action) => ({
				...state,
				paymentProviderLoading: false,
				paymentProviderError: action.payload,
			}));
	},
});

export default reducer;
export {
	getPaymentProviderSuccess,
	getPaymentProviderStart,
	getPaymentProviderFail,
};
