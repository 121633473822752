const SPORTS_PROVIDER_ID = {
	SOCCER: '1',
	BASKETBALL: '2',
	BASEBALL: '3',
	ICE_HOCKEY: '4',
	TENNIS: '5',
	HANDBALL: '6',
	FLOORBALL: '7',
	GOLF: '9',
	BOXING: '10',
	RUGBY: '12',
	AUSSIERULES: '13',
	BANDY: '15',
	FOOTBALL: '16',
	CYCLING: '17',
	SPECIALS: '18',
	SNOOKER: '19',
	TABLE_TENNIS: '20',
	DARTS: '22',
	VOLLEYBALL: '23',
	FIELD_HOCKEY: '24',
	WATERPOLO: '26',
	CURLING: '28',
	FUTSAL: '29',
	OLYMPICS: '30',
	BADMINTON: '31',
	BOWLS: '32',
	BEACH_VOLLEY: '34',
	ATHLETICS: '36',
	SQUASH: '37',
	LACROSSE: '39',
	FORMULA_1: '40',
	ALPINE_SKIING: '43',
	BIATHLON: '44',
	CROSS_COUNTRY: '46',
	NORDIC_COMBINED: '47',
	SKI_JUMPING: '48',
	SWIMMING: '52',
	SCHWINGEN: '56',
	BEACH_SOCCER: '60',
	PESAPALLO: '61',
	ROWING: '64',
	CANOEING: '72',
	ARCHERY: '75',
	EQUESTRIAN: '76',
	FENCING: '77',
	GYMNASTICS: '78',
	JUDO: '79',
	SAILING: '81',
	TRIATHLON: '84',
	WEIGHTLIFTING: '85',
	MOUNTAIN_BIKE: '88',
	DIVING: '96',
	TRACK_CYCLING: '97',
	RALLY: '101',
	ESPORT_LEAGUE_OF_LEGENDS: '110',
	MMA: '117',
	ESPORT_ROCKET_LEAGUE: '128',
	INDY_RACING: '129',
	SPEEDWAY: '131',
	GAELIC_FOOTBALL: '135',
	GAELIC_HURLING: '136',
	KABADDI: '138',
	FORMULA_E: '142',
	BASKETBALL_3X3: '155',
	TOURING_CAR_RACING: '188',
	MOTORCYCLE_RACING: '190',
	STOCK_CAR_RACING: '191',
	ESPORT_VALORANT: '194',
};

const eventConfig = {
	[SPORTS_PROVIDER_ID.SOCCER]: {
		mainMarkets: ['186'],
	},
};

export { eventConfig, SPORTS_PROVIDER_ID };
