/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, FormControl, Grid } from '@mui/material';
// import { Box } from '@mui/material';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import { getSportInfo } from 'SportsBook/BetBalancer/Store/thunk/sportInfo.thunk';
import { isEmpty } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
// import { SPORTS_PROVIDER_ID } from 'SportsBook/BetBalancer/Helper/sports.config';
// import { selectLeague } from 'SportsBook/BetBalancer/Store/slice/league.slice';
// import { selectLocation } from 'SportsBook/BetBalancer/Store/slice/location.slice';
import { CountryContainer } from './country.style';
import countyIcon1 from '../../Assets/Images/country/county-1.svg';
// import countyIcon2 from '../../Assets/Images/country/county-2.svg';
// import countyIcon3 from '../../Assets/Images/country/county-3.svg';
// import countyIcon4 from '../../Assets/Images/country/county-4.svg';
// import countyIcon5 from '../../Assets/Images/country/county-5.svg';
// import countyIcon6 from '../../Assets/Images/country/county-6.svg';
// import countyIcon7 from '../../Assets/Images/country/county-7.svg';
// import countyIcon8 from '../../Assets/Images/country/county-8.svg';
// import countyIcon9 from '../../Assets/Images/country/county-9.svg';
// import countyIcon10 from '../../Assets/Images/country/county-10.svg';

function a11yProps(leagueId) {
	return {
		id: `league-tab-${leagueId}`,
		'aria-controls': `league-tabpanel-${leagueId}`,
	};
}

const CountryFilter = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const selectedTopSport = params.get('sportId');
	const selectedLeagueId = params.get('leagueId');
	const { leagues } = useSelector((state) => state.SBleague);
	const { locations, selectedLocation } = useSelector(
		(state) => state.SBlocation
	);
	console.log(locations, selectedLocation, 'locarion');
	useEffect(() => {
		if (!isEmpty(selectedTopSport)) {
			dispatch(
				getSportInfo({
					providerId: selectedTopSport,
					liveMatches: false,
				})
			);
		}
	}, [selectedTopSport]);

	const handleSelectLeague = (leagueId, e) => {
		e.preventDefault();
		navigate(
			`/sports/sport-events/?sportId=${
				selectedTopSport || ''
			}&leagueId=${leagueId}`
		);
	};

	// const handleSelectLocation = (e) => {
	// 	e.preventDefault();
	// 	dispatch(selectLocation(e.target.value));
	// };
	return (
		<CountryContainer>
			<Box sx={{ width: '100%' }}>
				<Box className="countyFilterSection d-flex">
					<Grid className="odds-section mr-2">
						<FormControl>
							{/* <Select
								sx={{
									height: 37,
									width: 150,
									color: '#fff',
									background:
										'linear-gradient(to right,rgba(20, 32, 46, 1) 0%,rgba(33, 50, 68, 1) 100%)',
								}}
								labelId="property-label"
								id="property-select"
								value={selectedLocation}
								onChange={(e) => handleSelectLocation(e)}
							>
								{locations?.map((loc) => (
									<MenuItem key={loc.providerId} value={loc.providerId}>
										{loc.name}
									</MenuItem>
								))}
							</Select> */}
						</FormControl>
					</Grid>
					<Tabs
						value={Number(selectedLeagueId)}
						variant="scrollable"
						scrollButtons="auto"
						aria-label="scrollable auto tabs"
					>
						{leagues?.map((league) => (
							<Tab
								label={league?.name}
								value={Number(league.id)}
								icon={
									<ReactSVG
										src={countyIcon1}
										className="country-icon"
										wrapper="span"
									/>
								}
								{...a11yProps(league?.id)}
								onClick={(e) => handleSelectLeague(league?.id, e)}
							/>
						))}
					</Tabs>
				</Box>
			</Box>
		</CountryContainer>
	);
};

export default CountryFilter;
