import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMyBets } from 'SportsBook/BetBalancer/Store/thunk/mybets.thunk';
import { getSportBookExchange } from 'SportsBook/BetBalancer/Store/thunk/sportsBookExchange.thunk';
// import InfiniteScrollComponent from 'SportsBook/BetBalancer/Components/InfiniteScrollComponent';
// import Loader from 'components/ui-kit/Loader/index';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getAuthToken } from 'helpers/cookie.helpers';
import useWebSocket from 'SportsBook/BetBalancer/Socket/hooks/useWebSocket';
import { EVENTS, SOCKET_URL } from 'SportsBook/BetBalancer/Socket/events';
import { setMyBets } from 'SportsBook/BetBalancer/Store/slice/mybets.slice';
import { isEmpty } from 'lodash';
import { copyToClipboard } from 'utils/helperFunctions.utils';
import { openSuccessToaster } from 'SportsBook/BetBalancer/Helper/toaster';
import { SETTLEMENT_STATUS } from 'SportsBook/BetBalancer/Helper/constants';

const limit = 12;

const useMyBets = () => {
	const token = getAuthToken();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const defaultStartDate = moment().subtract(1, 'months').toDate();
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);
	const [modalData, setModalData] = useState([]);
	const [isAllBetsModalOpen, setIsAllBetsModalOpen] = useState(false);
	const {
		mybets,
		totalPages,
		loading: myBetsLoading,
	} = useSelector((state) => state?.SBmyBets);

	const mybetsRef = useRef(mybets);

	const [selectedValue, setSelectedValue] = useState('sportBook');
	const [startDate, setStartDate] = useState(defaultStartDate);
	const [endDate, setEndDate] = useState(moment().toDate());

	const [settlementStatus, setSettlementStatus] = useState('all');

	const handleModalData = (bet) => {
		setIsAllBetsModalOpen(true);
		setModalData(bet?.bets);
	};
	const handleCloseModal = () => {
		setIsAllBetsModalOpen(false);
		setTimeout(() => {
			setModalData([]);
		}, 500);
	};

	const handleBack = () => {
		navigate(-1);
	};

	const getWinText = (status) => {
		switch (status) {
			case SETTLEMENT_STATUS.WON:
			case SETTLEMENT_STATUS.LOST:
				return t('wonAmount');

			case SETTLEMENT_STATUS.REFUND:
				return t('refundAmount');

			case SETTLEMENT_STATUS.CASHOUT:
				return t('cashoutAmount');
			case SETTLEMENT_STATUS.PENDING:
			default:
				return t('possibleWinAmount');
		}
	};

	const handleUpdatedData = (data) => {
		if (!isEmpty(data)) {
			const updatedMyBets = mybetsRef?.current?.map((bet) => {
				let newBet = bet;
				// eslint-disable-next-line eqeqeq
				if (bet.id == data.id) {
					const updateBets = bet?.bets.map((bt, i) => {
						const newbt = bt;
						if (bt.id === data?.bets[i]?.id) {
							newbt.settlementStatus = data?.bets[i].settlementStatus;
						}
						return newbt;
					});
					newBet = {
						...newBet,
						bets: updateBets,
						settlementStatus: data.settlementStatus,
					};
				}
				return newBet;
			});
			dispatch(setMyBets(updatedMyBets));
		}
	};

	const { disconnectSocket } = useWebSocket(
		SOCKET_URL.PRIVATE,
		EVENTS.BET,
		handleUpdatedData,
		token,
		true
	);

	useEffect(() => {
		mybetsRef.current = mybets;
		return () => {
			disconnectSocket();
		};
	}, [mybets]);

	const fetchMyBets = (isFetchMore) => {
		const fromDate = moment(startDate).format('yyyy-MM-DD');
		const toDate = moment(endDate).format('yyyy-MM-DD');

		if (selectedValue === 'sportBook') {
			dispatch(
				getMyBets({
					payload: {
						page,
						perPage: limit,
						settlementStatus:
							settlementStatus === 'all' ? null : settlementStatus,
						startDate: fromDate,
						endDate: toDate,
					},
					isFetchMore,
				})
			);
		} else if (selectedValue === 'exchange') {
			dispatch(
				getSportBookExchange({
					payload: {
						page,
						perPage: limit,
						settlementStatus:
							settlementStatus === 'all' ? null : settlementStatus,
						startDate: fromDate,
						endDate: toDate,
					},
					isFetchMore,
				})
			);
		}
	};

	useEffect(() => {
		fetchMyBets();
	}, [selectedValue, startDate, endDate, limit, settlementStatus]);

	const fetchMoreData = () => {
		if (page < totalPages) {
			setPage((prev) => prev + 1);
			fetchMyBets(true);
		}
	};
	const resetPage = () => setPage(1);

	const handleChange = (event, newValue) => {
		if (newValue !== selectedValue) {
			resetPage();
			setSelectedValue(newValue);
		}
	};

	const onStartDateChange = (date) => {
		if (date) {
			resetPage();
			setStartDate(date);
		}
	};

	const onEndDateChange = (date) => {
		if (date) {
			resetPage();
			setEndDate(date);
		}
	};

	const handleOptionSelect = (event) => {
		resetPage();
		setSettlementStatus(event.target.value);
	};

	const handleIdCopy = (id) => {
		copyToClipboard(id);
		openSuccessToaster({ message: t('copyToClipboard') });
	};

	return {
		handleCloseModal,
		fetchMoreData,
		handleOptionSelect,
		onEndDateChange,
		handleChange,
		onStartDateChange,
		handleModalData,
		modalData,
		isAllBetsModalOpen,
		myBetsLoading,
		mybets,
		settlementStatus,
		selectedValue,
		startDate,
		endDate,
		page,
		totalPages,
		handleBack,
		fetchMyBets,
		handleIdCopy,
		getWinText,
	};
};

export default useMyBets;
