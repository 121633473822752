import styled from 'styled-components';

export const ExtraMarketContainer = styled.div`
  .match-detail {
    margin: 1rem;
    background: var(--boxViewBg);
    padding: 1rem;
    border-radius: 8px;
    .bredcrums {
      opacity: 0.4;
      cursor: pointer;
      font-size:12px
      &:hover {
        opacity: 1;
      }
      span {
        font-size:12px;
      }
      .match-icon {
        width: 1rem;
        svg {
          width: 100% !important;
        }
      }
      .arrow-icon {
        transform: rotate(-90deg);
        width: 0.7rem;
        svg {
          width: 100% !important;
        }
      }
      .icons {
        gap: 0.9rem;
        display: flex;
        align-items: center;
      }
    }
  }
  .match-status {
    padding: 1rem 0;
    img {
      width: 40px;
      margin-bottom: 1rem;
    }
    p{
      font-size:14px;
    }
    .match-day {
     opacity:0.4;
    }
    .match-time {
      font-size:16px;
      font-weight:bold;
    }
    .teamlogo {
      width:calc(100% / 3);
      .extra-market--score {
        color: rgb(255, 255, 255);
        border: 1px solid rgba(255, 255, 255,0.1);
        height: 26px;
        width: 26px;
        padding: 4px;
        font-size: 12px;
        background-color: rgba(255, 255, 255, 0.05);
        box-sizing: border-box;
        align-items: center;
        font-weight: bold;
        line-height: 16px;
        border-radius: 5px;
        justify-content: center;
        display: flex;
      }
    }
  }
  .market-type-tabs {
    padding: 0rem 1rem 1rem 1rem;
    .MuiTabs-indicator {
      display: none;
    }
    button {
      padding: 0.5rem 1rem;
      min-height: auto;
    }
    .market-value{
      &.Mui-selected {
        border-radius: 32px;
        background-color: #212532;
        .market-name,.number-bg  {
          opacity: 1;
        }
      }
      &:hover {
        .market-name,.number-bg  {
          opacity: 1;
        }
      }
    }
    .market-name {
      color: #FFFFFF;
      display: inline-block;
      opacity: 0.5;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      margin-right: 4px;
      letter-spacing: 0.5px;
      margin: 0;
    }
    .number-bg {
      color: rgba(255,255,255,0.7);
      padding: 0 6px;
      font-size: 12px;
      box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.15);
      font-weight: 600;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.1);
      margin-left: 5px;
    }
  }
  .accordion {
    background-color: var(--boxViewBg);
    color: var(--white);
    box-shadow: none;
    margin-bottom:4px;
    border-radius: 8px;
    &.Mui-expanded {
      margin-top: 0;
    }
    &:before{
      background-color:transparent;
      height: 0;
    }
  }

  .accordion-header {
    justify-content:flex-start;
    min-height:auto;
    background-color:var(--boxViewBg);
    padding: 8px;
    border-radius: 8px;
    position: relative;
    .extra-market--name {
      font-size:14px;
    }
    .MuiAccordionSummary-content {
      flex-grow: inherit;
      margin: 0;
    }
    .MuiAccordionSummary-expandIconWrapper{
      svg {
        color: var(--white);
        opacity: 0.4;
      }
    }
    // &:after{
    //   content: "More";
    //   position: absolute;
    //   right: 0;
    //   top: 0;
    // }
  }
  .accordion-detail {
    padding: 0;
    .accordion-detail-ul {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      padding: 8px;
      &.exchange-book-accordion{
        gap: 0;
        .accordion-detail-li  {
          width: 100%;
          border-radius:0;
        }
      }
      .accordion-detail-li {
        display: flex;
        padding: 5px 8px;
        background-color: var(--oddsParentBg);
        border-radius:5px;
        width: calc(100% / 3 - 6px);
        flex-direction: column;
        gap: 10px;
        cursor: pointer;
        &.total-accordion-detail-li {
          width: calc(100% / 2 - 6px);
        }
        &:hover {
          background-color: rgba(43,46,59,0.7);
          // span.odds-1, span.odds-2 {
          //     background-color: var(--secondaryRadialBtn)!important;
          //     color: var(--button-text);
          // }
        }
        p{
          display: flex;
          justify-content: space-between;
          width: 100%;
          gap: 8px;
          font-size: 16px;
          font-weight: bold;
          align-items: center;
          .more-market--team---name {
            flex-grow: 1;
            opacity: 1;
            font-size: 14px;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .odds-1, .odds-2 {
            padding: 10px;
            border-radius: 5px;
            font-size: 12px;
            font-weight: bold;
            cursor : pointer;
            &.back-odds{
              background-color:rgb(143 210 237);
              color:#000;
            }
            &.lay-odds{
              background-color:rgb(251 175 179);
              color:#000;
            }
          }
          .odds-1 {
            span {
              background-color: var(--sidebar-bg);
              border-radius: 5px;
              padding: 10px 20px;
              width: 80px;
              text-align: center;
              display: inline-block;
              &.active {
                background-color: var(--secondaryRadialBtn)!important;
                color: var(--button-text);
              }
            }
          }
        }
      }
    }
  }
  .extra-market-middle-section {
    width: calc(100vw - 20rem);
    @media screen and (max-width: 1199px) {
        width: 100%;
    }
  }

  .extra-market--right---panel {
    @media screen and (max-width: 1199px) {
        display: none;
    }
    width: 310px;
    margin: 1rem 1rem 1rem 0;
    border-radius: 8px;
    .soon {
        display: flex;
        position: relative;
        flex-direction :column;
        height: 280px;
        justify-content: center;
        align-items: center;
        span{
            position: absolute;
            top: 50%;
            font-weight: 500;
            color: var(--secondaryRadialBtn);
        }
    }
    .sports-list-box-view {
      padding: 0;
      .box-view {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        gap: 12px;
      }
    }
    .traker {

      height: 320px;
      width : 100%;
      background-color:var(--boxViewBg);
      border-radius:8px;
      margin-bottom:12px;
      p {
        text-align: center;
        background-color: var(--oddsParentBg);
        padding: 8px;
        font-weight: bold;
      }
    }
  }
`;
