/* eslint-disable no-nested-ternary */
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { Grid, Typography, Stack } from '@mui/material';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
// import StarIcon from '@mui/icons-material/Star';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setMatch } from 'SportsBook/BetBalancer/Store/slice/globalPersist.slice';
// import noMatchFound from 'SportsBook/BetBalancer/Assets/Images/home/no-data.svg'
import { SB_ROUTE_PATHS } from 'SportsBook/BetBalancer/SportsRoutes/routeConstant';
import NoDataFound from 'SportsBook/BetBalancer/Components/NoDataFound/NoDataFound';
import moment from 'moment';
import soccer from '../../../../../components/ui-kit/Icons/sports-icon/soccer.svg';
import { MatchesBoxContainer } from './style';
import MainMarkets from './MainMarkets';
import CardSkeleton from '../SkeltonLoaders/CardSkeleton';

const MatchesBox = ({ matches, loading }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	// const [isClassAdded, setClassAdded] = useState(false);
	const [isBoxOpen, setBoxOpen] = useState('');

	const toggleBoxOpen = (id) => {
		setBoxOpen(id);
		if (id === isBoxOpen) {
			setBoxOpen('');
		} else {
			setBoxOpen(id);
		}
	};

	// const toggleClass = (e) => {
	//     e.stopPropagation();
	//     setClassAdded(!isClassAdded);
	// };

	const handleNavigate = (match) => {
		dispatch(setMatch(match));
		navigate(`${SB_ROUTE_PATHS.ALL_MARKETS}?eventId=${match.fixtureId}`);
	};

	return (
		// eslint-disable-next-line react/jsx-no-undef
		<MatchesBoxContainer className="match-box-container">
			{!loading && !matches?.length ? (
				// <Grid item sx={{ display: 'flex', justifyContent: 'center', color: 'white', margin: '4rem' }}>
				//     <h3>Matches not available!</h3>
				// </Grid>
				<NoDataFound title={t('matchesNotAvailable')} />
			) : (
				<Stack
					direction="row"
					justifyContent="center"
					alignItems="center"
					spacing={2}
					className="sports-list-box-view"
				>
					<Grid container className="box-view">
						{!loading && matches.length ? (
							matches.map((match) => (
								<Grid onClick={() => handleNavigate(match)}>
									<Grid
										className={`box ${isBoxOpen === match.id ? 'open' : ''}`}
									>
										<Grid className="inner-box--more---market">
											<Grid display="flex" justifyContent="space-between">
												<Typography variant="span" className="league-name">
													<img src={soccer} alt="soccer" />
													{match?.league?.name}
												</Typography>

												<Typography variant="span" className="icons">
													<SignalCellularAltIcon />
													{/* <StarIcon
                                                    className={
                                                        isClassAdded
                                                            ? 'favorite-icon fill-icon'
                                                            : 'favorite-icon'
                                                    }
                                                    onClick={(e) => toggleClass(e)}
                                                /> */}
												</Typography>
											</Grid>

											<Grid>
												<Typography className="match-time">
													<span>
														{moment(match?.startDate).format(
															'MM-DD-yyyy, hh:mm a'
														)}
													</span>
												</Typography>
											</Grid>

											<Stack direction="column" className="team-logo--name">
												<Grid
													display="flex"
													justifyContent="space-between"
													alignItems="center"
												>
													<Typography variant="span" className="team-name">
														<img
															src="https://files-immutable-4cbc033nbd3.sptpub.com/flags_by_code/ECU.svg"
															alt="SMU Mustangs"
														/>
														{match?.eventParticipants[0]?.participant?.name}
													</Typography>
												</Grid>

												<Grid
													display="flex"
													justifyContent="space-between"
													alignItems="center"
												>
													<Typography variant="span" className="team-name">
														<img
															src="https://files-immutable-4cbc033nbd3.sptpub.com/flags_by_code/GEO.svg"
															alt="North Texas"
														/>
														{match?.eventParticipants[1]?.participant?.name}
													</Typography>
												</Grid>
											</Stack>
											<MainMarkets
												isBoxOpen={isBoxOpen}
												toggleBoxOpen={toggleBoxOpen}
												match={match}
												eventMarkets={match?.eventMarkets || []}
											/>
										</Grid>
									</Grid>
								</Grid>
							))
						) : (
							<CardSkeleton />
						)}
					</Grid>
				</Stack>
			)}
		</MatchesBoxContainer>
	);
};

MatchesBox.defaultProps = {
	// text: '',
};
MatchesBox.propTypes = {
	matches: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			fixtureId: PropTypes.string,
			status: PropTypes.string,
			betStatus: PropTypes.string,
			score: PropTypes.string,
			startDate: PropTypes.string,
			league: PropTypes.shape({
				id: PropTypes.string,
				name: PropTypes.string,
				sport: PropTypes.shape({
					id: PropTypes.string,
					name: PropTypes.string,
				}),
			}),
			eventParticipants: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.string,
					type: PropTypes.string,
					participant: PropTypes.shape({
						id: PropTypes.string,
						name: PropTypes.string,
					}),
				})
			),
		}).isRequired
	).isRequired,
	loading: PropTypes.bool.isRequired,
};
export default MatchesBox;
