import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getItem } from 'helpers/localstorage.helpers';
import { getAllCmsSiteData } from 'redux-thunk/thunk/gameSetting';

import { SHOW_SIDE_NAV } from 'constants/index';
import './footer.scss';
import { List, ListItem } from '@mui/material';

const Footer = () => {
	const dispatch = useDispatch();
	const showSideNav = getItem(SHOW_SIDE_NAV);
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(getAllCmsSiteData());
	}, []);
	const { cmsSiteData } = useSelector((state) => state.gameSetting);
	const { settingData } = useSelector((state) => state?.general);
	const { showChat } = useSelector((state) => state?.settings);
	// const setting = settingData;

	return (
		<footer className={`mt-3 ${!showSideNav ? 'sidebar-close' : ''} ${!showChat ? 'chat-close' : ''}`}>
			<div className="footer-top">
				<div className="content-wrapper container-lg custom-spacer">
					<div className="row">
						<div className="col-lg-3 col-md-3 col-6">
							<div className="footer-about my-3">
								<div className="footer-logo">
									<img
										height="auto"
										width={145}
										src={
											settingData?.logo
												? settingData.logo
												: '/assets/images/logo/city99.png'
										}
										alt="footer logo"
									/>
								</div>
								<p className="m-0">
									It is a long established fact that a reader will be distracted
									by the readable content of a page when looking at its layout.
								</p>
							</div>
						</div>

						<div className="col-lg-3 col-md-3 col-6">
							<div className="footer-linking d-flex justify-content-between my-3">
								<h4 className="m-0">Help & Advise</h4>
								<List>
									{cmsSiteData &&
										cmsSiteData?.length > 0 &&
										cmsSiteData?.map((item) => {
											if (item?.slug !== 'about' && item?.slug !== 'others') {
												return (
													<ListItem
														key={`listItem ${item.id}`}
														style={{ padding: '0px' }}
													>
														<NavLink to={`/cms/${item.id}`}>
															{item.title.EN || t('faq')}
														</NavLink>
													</ListItem>
												);
											}
											return null;
										})}
								</List>
							</div>
						</div>

						<div className="col-lg-3 col-md-3 col-6">
							<div className="footer-linking d-flex justify-content-between my-3">
								<h4 className="m-0">About us</h4>
								<List>
									{cmsSiteData &&
										cmsSiteData?.length > 0 &&
										cmsSiteData?.map((item) => {
											if (item?.slug === 'about') {
												return (
													<ListItem
														key={`listItem ${item.id}`}
														style={{ padding: '0px' }}
													>
														<NavLink to={`/cms/${item.id}`}>
															{item.title.EN || ''}
														</NavLink>
													</ListItem>
												);
											}
											return null;
										})}
								</List>
							</div>
						</div>

						{/* <div className="col-lg-3 col-md-3 col-6">
							<div className="footer-linking d-flex justify-content-between my-3">
								<h4 className="m-0">Others</h4>
								<List>
									{cmsSiteData &&
										cmsSiteData?.length > 0 &&
										cmsSiteData?.map((item) => {
											if (item.category === 'others') {
												return (
													<ListItem
														key={`listItem ${item.id}`}
														style={{ padding: '0px' }}
													>
														<NavLink to={`/cms/${item.id}`}>
															{item.title.EN || t('faq')}
														</NavLink>
													</ListItem>
												);
											}
											return null;
										})}
								</List>
							</div>
						</div> */}
					</div>
				</div>
			</div>
			<div className="content-wrapper container-lg custom-spacer">
				{/* <div className="footer-bottom d-lg-flex align-items-center justify-content-between">
					<img
            src='/assets/images/homePage/gc.png'
            className='gaming-img'
            alt='GC'
          />
					<p>{t('footerAboutSite')}</p>
				</div> */}
				<div className="language-wrap justify-content-center">
					<div className="copyright-section d-flex align-items-center justify-content-center">
						<p className="m-0"> ©{t('footerRightsReserved')} </p>
					</div>
					{/* <div className='dropdown'>
            <button
              className='btn btn-secondary dropdown-toggle shadow-none'
              type='button'
              id='dropdownMenuButton1'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              <img src='/assets/images/homePage/earth.png' alt='Language' />
              English
            </button>
            <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
              <li>
                <a className='dropdown-item' href='#'>
                  Action
                </a>
              </li>
              <li>
                <a className='dropdown-item' href='#'>
                  Another action
                </a>
              </li>
              <li>
                <a className='dropdown-item' href='#'>
                  Something else here
                </a>
              </li>
            </ul>
          </div> */}
				</div>
			</div>
			{/* <div className="footer-bottom-linking d-md-flex justify-content-center align-items-center">
				<p className="m-0">
					Support
					<NavLink to="#">{setting?.supportEmail}</NavLink>
				</p> */}
			{/* <p className='m-0'>
          Partners
          <NavLink to='#'>partners@ARC.app</NavLink>
        </p>
        <p className='m-0'>
          Legal
          <NavLink to='#'>legal@ARC.app</NavLink>
        </p> */}
			{/* </div> */}
		</footer>
	);
};
export default Footer;
