import { createSlice } from '@reduxjs/toolkit';
import { LEAGUE_ALL } from 'SportsBook/BetBalancer/Helper/eventConstants';
import { getSportInfo } from '../thunk/sportInfo.thunk';

const initialState = {
	leagues: [],
	loading: false,
	error: null,
};
// SB means SportsBook
const SBleague = createSlice({
	name: 'SBleague',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getSportInfo.pending, (state) => ({
				...state,
				loading: true,
			}))
			.addCase(getSportInfo.fulfilled, (state, action) => ({
				...state,
				leagues: [LEAGUE_ALL, ...action.payload.leagues],
				loading: false,
			}))
			.addCase(getSportInfo.rejected, (state, action) => ({
				...state,
				error: action.payload,
				loading: false,
			}));
	},
});

export default SBleague.reducer;
