/* eslint-disable react/forbid-prop-types */
import React from 'react';
import {
	Button,
	DialogTitle,
	DialogContent,
	Grid,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import SingleBet from '../SingleBet';
import { ModalStyle, DialogContentContainer } from './style';

const AllBetsModal = ({ isOpen, data, onCloseModal }) => {
	const { t } = useTranslation();
	return (
		<ModalStyle
			open={isOpen}
			size="lg"
			onClose={onCloseModal}
			scroll="paper"
			className="mybet-modal-1"
			aria-labelledby="contained-modal-title-vcenter"
		>
			<DialogTitle>
				<Grid display="flex" justifyContent="space-between">
					<Typography>{t('allBets')}</Typography>
					<Button className="bg-transparent border-0" onClick={onCloseModal}>
						<CloseIcon />
					</Button>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<DialogContentContainer>
					<div className="box-grid">
						{data?.map((bet) => (
							<SingleBet bet={bet} icon={null} />
						))}
					</div>
				</DialogContentContainer>
			</DialogContent>
		</ModalStyle>
	);
};

AllBetsModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onCloseModal: PropTypes.func.isRequired,
	data: PropTypes.array.isRequired,
};

export default React.memo(AllBetsModal);
