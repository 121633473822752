import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSuccessToaster } from 'helpers/toaster.helpers';
import { getTranslation } from 'helpers/translations.helpers';

import {
	getAllTournamentService,
	getTournamentDetailsService,
	leaderBoardService,
	tournamentEnrollService,
	tournamentTransactionsService,
	getMyTournamentService,
	tournamentReBuyEnrollService,
} from 'network/services/tournament.service';
import {
	getTournamentDetailsFail,
	getTournamentDetailsStart,
} from 'redux-thunk/redux/slices/tournament.slice';

export const getAllTournament = createAsyncThunk(
	'fetch/all-tournament',
	async (data, thunkApi) => {
		try {
			const res = await getAllTournamentService(data);

			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);
export const getMyTournament = createAsyncThunk(
	'fetch/my-tournament',
	async (data, thunkApi) => {
		try {
			const res = await getMyTournamentService(data);

			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const getTournamentDetails = createAsyncThunk(
	'fetch/user-tournament-details',
	async (data, thunkApi) => {
		try {
			const res = await getTournamentDetailsService(data);
			thunkApi.dispatch(getTournamentDetailsStart(res));
			return res;
		} catch (error) {
			thunkApi.dispatch(getTournamentDetailsFail());
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);
export const tournamentEnroll = createAsyncThunk(
	'enroll/tournament',
	async ({ tournamentId, walletId }, thunkApi) => {
		try {
			const res = await tournamentEnrollService({ tournamentId, walletId });
			thunkApi.dispatch(getAllTournament());
			openSuccessToaster({
				message: getTranslation('tournamentEnrollSuccess'),
			});
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);
export const tournamentReBuyEnroll = createAsyncThunk(
	'enroll/tournament',
	async ({ tournamentId, walletId }, thunkApi) => {
		try {
			const res = await tournamentReBuyEnrollService({
				tournamentId,
				walletId,
			});
			thunkApi.dispatch(getAllTournament());
			openSuccessToaster({
				message: getTranslation('tournamentReBuyEnrollSuccess'),
			});
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const getLeaderBoardDetails = createAsyncThunk(
	'tournament/leaderBoard',
	async ({ tournamentId, currencyId, page, perPage }, thunkApi) => {
		try {
			const res = await leaderBoardService({
				tournamentId,
				currencyId,
				page,
				perPage,
			});
			openSuccessToaster({
				// message: getTranslation('tournamentEnrollSuccess'),
			});
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);
export const getTournamentTransactionsDetails = createAsyncThunk(
	'tournament/transactions',
	async ({ tournamentId, currencyId }, thunkApi) => {
		try {
			const res = await tournamentTransactionsService({
				tournamentId,
				currencyId,
			});
			openSuccessToaster({
				// message: getTranslation('tournamentEnrollSuccess'),
			});
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);
