/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { fetchUserInformation } from 'redux-thunk/thunk/user.thunk';
import {
	getLocalLanguage,
	setLocalLanguage,
} from 'helpers/localstorage.helpers';
import { LANGUAGES } from 'constants/index';
import { userMetamaskLogin } from 'redux-thunk/thunk/metamask.thunk';

const defaultMetamaskState = {
	loginMetamaskData: null,
	loginMetamaskLoading: false,
	operatorInformationData: null,
	language: getLocalLanguage() || LANGUAGES[0].languageCode,
};

const metamaskSlice = createSlice({
	name: 'metamaskSlice',
	initialState: defaultMetamaskState,
	reducers: {
		setLoginMetamaskData: (state, action) => ({
			...state,
			loginMetamaskData: action?.payload,
		}),
		handleLanguageChange: (state, action) => {
			setLocalLanguage(action.payload);
			return {
				...state,
				language: action.payload,
			};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(userMetamaskLogin.fulfilled, (state, action) => ({
				...state,
				loginMetamaskData: action.payload,
				language:
					action.payload?.additionalInfo?.languageCode ||
					LANGUAGES[0].languageCode,
				loginMetamaskLoading: false,
			}))
			.addCase(userMetamaskLogin.pending, (state, action) => {
				const { password, userNameOrEmail } = action.meta.arg;
				return {
					...state,
					loginMetamaskData: { password, userNameOrEmail },
					loginMetamaskLoading: true,
				};
			})
			.addCase(userMetamaskLogin.rejected, (state, action) => ({
				...state,
				loginMetamaskLoading: false,
			}));
	},
});

export const { handleLanguageChange, setLoginMetamaskData } =
	metamaskSlice.actions;

export default metamaskSlice.reducer;
