import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loading: false,
	languageKeys: null,
	languageTabs: null,
	languageData: {
		multiLanguageSupportId: 6,
		tenantId: 11,
		language: 'EN',
		casinoBannerDesc: 'Get an a amazing 100% welcome bonus upto $50',
		casinoBannerJoinNow: 'Join now',
		casinoBannerTnc: 'Terms and conditions apply',
		casinoFavorite: 'Favorite',
		casinoNoFavGamesFound: 'No favorite games found',
		casinoNoGamesFound: 'No games found',
		casinoGameViewAllBtn: 'View All',
		casinoSearch: 'Search games',
		casinoMoreGames: 'More Games',
		casinoProviders: 'Provider',
		homeAbout: 'About',
		footerAboutSite:
			'GammaStack is an end-to-end IT solutions provider boasting 8+ years of experience in the industry and backed by an exceptionally talented team of 350+ developers. We cover comprehensive iGaming software development solutions including but not limited to sports betting, online casinos, game development, live dealer casinos, fantasy sports and much more. Building an iGaming platform from scratch or customising it in accordance with your specific requirements, we have got you all covered !! Our team has left no stone unturned to stay updated with the latest technologies, tools and trends of the industry so that we can deliver you the most innovative solution within the quickest possible time. We have successfully delivered various projects to our different clients based in varying geographical locations. Shaking hands with some of the major third-party providers in the industry has strengthened us as a team and these partnerships have resulted in fruitful outcomes through successful launchings of solutions.',
		footerRightsReserved: 'Gammastack. All Rights Reserved',
		footerCategory: 'Category',
		footerSupport: 'Support',
		footerOther: 'Other Links',
		// promBannerDesc: 'Welcome Promotion <br> 100% Welcome bonus <br> Up to $200 + 50 freespins',
		promBannerDesc: 'Freespin promotion get upto 50 freespins',
		promClaimNow: 'CLAIM NOW',
		promReadMore: 'Read More',
		promFreespinGames: 'Freespin Games',
		promTermsAndConditions: 'Terms and condition',
		homeBannerDesc: 'Get an amazing 100% welcome bonus upto $100',
		homeBannerJoinNow: 'Join Now',
		homeBannerTnc: 'Terms and conditions apply',
		homeRealPlayerSec: 'Real player, Real wins<br> everyday',
		homeCurrentWinners: 'Current Winners',
		homeTopWinners: 'Top 5 Winners',
		homeTopGames: 'Top Last Week Games',
		homeAboutContent:
			'This website is operated by Gammix Limited, a company incorporated under the laws of Malta with registration number\r\r C68405 and registered address at 77.',
		homeTestimonial: 'What are players say',
		headerHome: 'Home',
		headerPromotions: 'Promotions',
		headerLoyalty: 'Loyalty',
		headerSearch: 'search',
		headerRealMoney: 'Real Money',
		headerCasinoBonus: 'Casino Bonus',
		headerLevel: 'level',
		headerDeposit: 'Deposit',
		headerAccAndInfo: 'Account Info & Settings',
		headerAccVerify: 'Account Verification',
		headerBonus: 'Bonus',
		headerLimits: 'Limits',
		headerDepositFunds: 'Deposit funds',
		headerWithdrawFunds: 'Withdraw funds',
		headerTransactionHistory: 'Transaction History',
		headerBetHistory: 'Bet History',
		headerCasinoHistory: 'Casino Bet History',
		headerSportsHistory: 'Sports Bet History',
		headerLogout: 'Logout',
		headerSelectYourLang: 'Select your language',
		loginKey: 'LogIn',
		loginToYourAccount: 'To Your Account',
		loginUsername: 'Username',
		loginEmail: 'Email',
		loginEnter: 'Enter',
		loginYour: 'your',
		loginPassword: 'Password',
		loginForget: 'Forget',
		loginDoNotHaveAccount: 'Don’t have an account',
		loginSignUp: 'Sign UP',
		loginLog: 'Log',
		loginIn: 'In',
		signupGetAnAmazing: 'Get an amazing',
		signupBannerDesc: 'Get An Amazing 100% Welcome Bonus Upto $100.',
		signupStartWithEmail: 'Start with email',
		signupLoginDetails: 'Login in Details',
		signupEmailAddress: 'Email Address',
		signupUserName: 'User Name',
		signupNewsLetter:
			'I would like to receive bonuses, promotions, news or other relevant information periodically by News Letter.',
		signupSms:
			'I would like to receive bonuses, promotions, news or other relevant information periodically by SMS.',
		signupPrivacyPolicy: 'I have read and accepted the Privacy Policy',
		signupTermAndConditions:
			'I agree with the processing of my personal data. I am above 18 years of age and have read and accepted all Terms and Conditions.',
		signupConfirm: 'Confirm',
		signupNextStep: 'Next Step',
		signupHaveAccount: 'Already have an account',
		signupSignIn: 'Sign in',
		signupPersonalDetails: 'Personal Details',
		signupFirstName: 'First Name',
		signupDob: 'Date Of Birth',
		signupAddress: 'Address',
		signupPhoneNo: 'Phone Number',
		signupCity: 'City',
		signupPostcode: 'Postcode',
		signupCounty: 'Country',
		signupCurrency: 'Currency',
		signupGender: 'Gender',
		signupMan: 'Man',
		signupWomen: 'Women',
		signupOther: 'Other',
		signupBack: 'Back',
		signupCreateAnAccount: 'Create an Account',
		signupLastName: 'Last Name',
		cashbackBannerBtn: 'Get Your Reward',
		cashbackBannerHeading: 'Gammastack Money Back Mansion',
		cashbackBannerDesc: 'Your loyalty deserves a reward!',
		cashbackHeadingOne:
			'Discover the Gammastack Cashback Advantage: Simple, Rewarding, & Effortless',
		cashbackHeadingOneDesc:
			"At Gammastack, we understand that simplicity and transparency are key to rewarding our players. That's why we've created a hassle-free, straightforward cashback program that puts money back in your pocket without making you jump through hoops.",
		cashbackHeadingTwo: 'The Gammastack Cashback Difference',
		cashbackHeadingTwoDesc:
			"Our cashback offer is designed with real players in mind. No more wading through pages of complex terms and conditions. With Gammastack, you 're automatically enrolled in our cashback program when you deposit and play your favorite games. If you lose your balance, don't worry! You'll receive cashback the next day, based on your loyalty level and engagement. As you accumulate loyalty points and progress through our 10 loyalty levels, your daily cashback percentage increases, allowing you to earn up to 15% cashback daily. Best of all, the cashback is automatically credited to your account, making it a truly seamless experience.",
		cashbackHeadingThree: 'Claiming Your Cashback Has Never Been Easier',
		cashbackHeadingThreeDesc:
			"With Gammastack, you don't need to do anything to claim your cashback. Simply deposit, play, and enjoy your favorite games. Your cashback on any losses will be automatically credited to your account the following morning. You can check your cashback progress in your profile overview at any time.",
		cashbackHeadingFour: 'Cashback Limits and Maximum Cash Out',
		cashbackHeadingFourDesc:
			'The daily cashback program has minimum and maximum cash-out limits based on your loyalty level. Amounts exceeding the maximum cashable limit will be automatically removed from your wallet. The minimum cashback awarded is €0.5, while the maximum daily cashback is €1000.',
		cashbackTestimonialHeading: 'Tailored Cashback for Levels 2-10',
		cashbackTestimonialDesc:
			"Our cashback program is available for players at loyalty level 2 and above, ensuring that every valued Gammastack player enjoys the benefits of cashback rewards. Here's a quick look at the cashback percentages and wagering requirements based on your loyalty level.",
		cashbackTableDesc:
			"Here's a quick look at the cashback percentages and wagering requirements based on your loyalty level. The total cashable amount from cashback is also determined by your loyalty level, as shown in the table below.",
		cashbackTableHeading: 'Cashback and Wagering Requirements by Loyalty Level',
		cashbackFooterDesc:
			'Experience the simplicity and rewards of the Gammastack cashback program today, and let us show you how effortless earning cashback can be.',
		headerCashback: 'Cashback',
		cashbackTableHeaderOne: 'Loyalty Level',
		cashbackTableHeaderTwo: 'Cashback Percentage',
		cashbackTableHeaderThree: 'Wagering Requirement',
		cashbackTableHeaderFour: null,
		loyaltyBannerBtn: 'Check Progress',
		loyaltyBannerHeading: 'You Deserve Our Brand-New Loyalty',
		loyaltyBannerDesc: '100% Welcome Bonus Upto $200',
		loyaltyHeadingOne: 'You are Always Rewarded at GAMMASTACK',
		loyaltyHeadingOneDesc:
			"At Gammastack, we take loyalty to a whole new level of excitement, showering you with rewards at every twist and turn! Our thrilling loyalty program gives you points for each daring bet you place at our casino. These points propel you through our dazzling loyalty tiers, unlocking an array of incredible benefits that rain down upon you daily, weekly, and monthly. Now, let's dive into the exhilarating details of how it all works! Gammastack is thrilled to present our captivating loyalty program, featuring 10 unique levels that offer an array of enticing benefits for our cherished players. Here's a quick guide to help you understand and navigate your way to even greater rewards:",
		loyaltySubHeadOne: 'Earning Loyalty Points',
		loyaltySubHeadOneDesc:
			"For every €10 in cash bets placed at our casino, you'll earn 1 loyalty point. As soon as you earn points by placing bets on any of our exciting games, you'll be assigned to one of our 10 loyalty levels. Each level is designed to reward you with increasingly enticing benefits as you progress.",
		loyaltySubHeadTwo: 'Climbing the Loyalty Ladder',
		loyaltySubHeadTwoDesc:
			"The more points you accumulate, the higher you'll ascend through the loyalty levels, unlocking even more fantastic rewards along the way. Plus, you'll earn daily cashback based on your current level. The higher your loyalty level, the bigger the cashback percentage you'll receive daily.",
		loyaltySubHeadThree: 'Level up, get rewarded ',
		loyaltySubHeadThreeDesc:
			'Elevate your gaming experience by progressing through our enticing reward tiers, where each level up unlocks an exhilarating array of free spins to maximize your chances of winning. As you climb higher, delight in the satisfaction of receiving instant cash rewards, a testament to your dedication and skill. Additionally, relish the benefits of our increasingly generous cashback offers, designed to make your journey even more rewarding, providing a safety net for your bets and an incentive to keep pushing forward.',
		loyaltyTableHeading: 'Levels and Rewards Overview',
		loyaltyTableHeaderOne: 'Loyalty Level',
		loyaltyTableHeaderTwo: 'Loyalty Points',
		loyaltyTableHeaderThree: 'Daily Cashback',
		loyaltyTableHeaderFour: null,
		loyaltyTableHeaderFive: null,
		loyaltyTableHeaderSix: null,
		loyaltyTableHeaderSeven: null,
		loyaltyTableDesc:
			"Embark on this thrilling adventure through Gammastack' loyalty levels and experience the exciting rewards waiting for you at every turn!",
		loyaltyHeadingTwo:
			'Unlock the Ultimate Gaming Experience: Gammastack VIP Program',
		loyaltyHeadingTwoDesc:
			"Once you reach Level 6 in our loyalty program, you'll unlock a world of exclusive privileges as a member of the prestigious Gammastack VIP Program. This elite club is designed to offer a superior gaming experience and pamper you with exceptional perks and benefits, including in examples below.",
		loyaltyHeadingThree: 'You are Always Rewarded at GAMMASTACK',
		loyaltyHeadingThreeDesc:
			'Experience the ultimate gaming luxury as a member of the Gammastack VIP Program. Embrace the thrill of climbing through our rewarding loyalty levels and unlock a world of indulgence, excitement, and unparalleled service that awaits you at the pinnacle of online gaming.',
		loyaltySubHeadFour: 'Higher Deposit and Withdrawal Limits',
		loyaltySubHeadFourDesc:
			'Enjoy the flexibility of increased deposit and withdrawal limits as a VIP member.',
		loyaltySubHeadFive: 'Exclusive Bonuses and Promotions',
		loyaltySubHeadFiveDesc:
			'Gain access to special bonuses and promotions tailor-made for our VIP players.',
		loyaltySubHeadSix: 'Personal Account Manager',
		loyaltySubHeadSixDesc:
			'Receive dedicated support and personalised assistance from your account manager.',
		loyaltySubHeadSeven: 'Faster Withdrawal Times',
		loyaltySubHeadSevenDesc:
			"As a VIP, you'll enjoy expedited withdrawal times, ensuring you can access your winnings even quicker.",
		loyaltySubHeadEight: 'Monthly Cashback Rewards',
		loyaltySubHeadEightDesc:
			'Boost your bankroll with generous monthly cashback rewards exclusively for our VIP members.',
		loyaltySubHeadNine: 'Invitations to Exclusive Events and Tournaments',
		loyaltySubHeadNineDesc:
			'Get your hands on coveted invites to exclusive events and tournaments, where you can mingle with fellow VIPs and compete for incredible prizes.',
		loyaltySubHeadTen: 'Birthday Bonuses',
		loyaltySubHeadTenDesc:
			'Celebrate your special day in style with a delightful birthday bonus just for you!',
		loyaltySubHeadEleven: 'Luxury Gifts',
		loyaltySubHeadElevenDesc:
			'Indulge in an exquisite selection of luxury gifts handpicked to reward our most valued players.',
		loyaltyTestimonialHeadOne: 'AT Gammastack GAMING WE MAKE SURE THAT',
		loyaltyTestimonialHeadTwo: 'Every Spin Counts',
		loyaltyTestimonialDesc:
			"Every spin you take at the slots ain't just for fun, but also gets you closer to some seriously sweet rewards! Rack up those spins, and watch as the prizes and Cashbacks get bigger and better. It's a win-win, making your time at the casino a total blast, while you rake in those perks. So, spin away and let the good times roll!",
		loyaltyHeadingFour: 'Progression System',
		loyaltyPoints: 'Points',
		loyaltyLevel: 'Level',
		loyaltyCashback: 'Cashback',
		sidebarCmsOne: 'About Us',
		sidebarCmsTwo: 'Help',
		completePayment: 'Complete the payment in',
		kycProtocolDetails:
			'In order to follow KYC procedures, we are required to verify your identity before we are able to process any withdrawal requests. Please upload the necessary documents and we will verify your account as soon as possible.',
		bonusForfeited: 'Your bonus is FORFEITED',
		bonusZeroedOut: 'Your bonus is ZEROED-OUT',
		bonusExpired: 'Your bonus is EXPIRED',
		bonusBalanceDone:
			'You have reached the balance bonus limit. Now you can activate applied bonus from your bonuses.',
		completedWagering:
			'Congratulation, you have successfully completed wagering!',
		loginHomeBannerBtn: 'Deposit',
		loginPromBannerBtn: 'Claim Now',
		loginLoyaltyBannerBtn: 'Start Earning',
		loginCashbackBannerBtn: 'Get Your Reward',
		accountsInfoFirstTab: 'Private Data',
		accountsInfoFourthTab: 'Subscriptions',
		createdAt: '2022-12-16T07:57:40.445Z',
		updatedAt: '2023-05-10T06:10:36.486Z',
	},
};

const {
	actions: {
		getLanguageSupportKeysStart,
		getLanguageSupportKeysSuccess,
		getLanguageSupportKeysFailure,
	},
	reducer,
} = createSlice({
	name: 'languages',
	initialState,
	reducers: {
		getLanguageSupportKeysStart: (state) => ({
			...state,
			loading: true,
			languageKeys: null,
		}),
		getLanguageSupportKeysSuccess: (state, { payload }) => ({
			...state,
			loading: false,
			languageKeys: payload.languageData,
			languageTabs: payload.languageKeys,
		}),
		getLanguageSupportKeysFailure: (state) => ({
			...state,
			loading: false,
		}),
	},
});

export default reducer;
export {
	getLanguageSupportKeysStart,
	getLanguageSupportKeysSuccess,
	getLanguageSupportKeysFailure,
};
