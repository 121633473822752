/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Select from 'react-select';

export const ReactSelect = (props) => {
	const colourStyles = {
		control: (styles) => ({
			...styles,
			backgroundColor: '#121C27',
			borderColor: '#223244',
			borderRadius: '8px',
			boxShadow: 'none',
			'&:hover': {
				borderColor: '#223244',
			},
			'&:focus': {
				borderColor: '#ffffc1',
			},
		}),
		option: (styles) => ({
			...styles,
			backgroundColor: '#121C27',
			color: 'white',
			borderColor: '#121C27',
		}),
		singleValue: (styles) => ({ ...styles, color: 'white' }),
		multiValue: (styles) => ({
			...styles,
			backgroundColor: '#0096b0',
		}),
	};
	return <Select {...props} styles={colourStyles} />;
};
