/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Grid, Typography } from '@mui/material';
// import moment from 'moment'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
	getMarketName,
	outcomeFormatter,
} from 'SportsBook/BetBalancer/Helper/formatterFunctions';

const SingleBet = ({ bet, icon }) => {
	const { t } = useTranslation();
	return (
		<Grid className="grid-bg">
			<Grid display="flex" justifyContent="space-between" className="font14">
				<Typography className="font14">
					{bet?.event?.league?.location?.name} - {bet?.event?.league?.name}
				</Typography>
				{icon}
			</Grid>
			<Grid display="flex" gap={1.5}>
				<Typography className="team-ellip font14">
					{bet?.event?.eventParticipants[0]?.participant?.name}
				</Typography>
				<Typography className="font14">{t('vs')}</Typography>
				<Typography className="team-ellip font14">
					{bet?.event?.eventParticipants[1]?.participant?.name}
				</Typography>
			</Grid>
			<Grid
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				gap={2}
			>
				<Typography className="font14 match-type-status">
					{getMarketName(1, 2, bet?.eventMarket)} :{' '}
					{outcomeFormatter(
						bet?.eventMarketOutcome,
						bet?.event?.eventParticipants[0]?.participant?.name,
						bet?.event?.eventParticipants[1]?.participant?.name,
						bet?.eventMarket?.market?.providerId
					)}
				</Typography>
				{/* <Typography className='bet-date-time font14'>
                {moment(bet.createdAt).format('DD/MM/YYYY, hh:mm a')}
            </Typography> */}
				<Typography className="font14">{bet?.odds?.toFixed(2)}</Typography>
			</Grid>
		</Grid>
	);
};
SingleBet.propTypes = {
	bet: PropTypes.object.isRequired,
	icon: PropTypes.element.isRequired,
};

export default SingleBet;
