/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useMemo } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
	LOADER_HANDLER_TYPES,
	ROUTE_PATHS,
	TABLE_CELL_TYPE,
	TRANSACTIONS_LIMIT,
} from 'constants/index';
import './myReferral.scss';
import 'react-datepicker/dist/react-datepicker.css';

import Loader from 'components/ui-kit/Loader/index';

import { getReferralCode } from 'redux-thunk/thunk/bonus.thunk';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
// import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { debounce } from 'lodash';
import moment from 'moment';
import InputField from 'components/molecules/Input/InputField';
import Pagination from 'components/ui-kit/Pagination/index';

const tableColumns = [
	{
		id: uuidv4(),
		label: 'User Name',
		value: 'userName',
		type: TABLE_CELL_TYPE.date,
	},
	// {
	// 	id: uuidv4(),
	// 	label: 'Name',
	// 	value: 'firstName',
	// 	type: TABLE_CELL_TYPE.text,
	// },
	{
		id: uuidv4(),
		label: 'Amount',
		value: 'amount',
		type: TABLE_CELL_TYPE.date,
	},

	{
		id: uuidv4(),
		label: 'Date',
		value: 'create_at',
		type: TABLE_CELL_TYPE.object,
	},
];

const MyReferral = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { referralCodeData, myBonusesLoading } = useSelector(
		(state) => state.bonus
	);
	const { allCurrencies } = useSelector((state) => state?.gameSetting);
	// eslint-disable-next-line no-unused-vars
	const [numberOfRecords, setNumberOfRecords] = useState(10);
	const [page, setPage] = useState(1);
	const newCurrency = allCurrencies?.find(
		(c) => c.id === Number(referralCodeData?.referredUser?.[0]?.currency_id)
	)?.symbol;

	const debouncedOnSearch = useMemo(
		() =>
			debounce((value) => {
				dispatch(
					getReferralCode({
						limit: numberOfRecords,
						searchString: value,
						page,
					})
				);
			}, 1000), // Adjust the debounce delay as needed
		[dispatch, numberOfRecords, page]
	);
	return (
		<div
			className={`tab-pane fade ${
				pathname === ROUTE_PATHS.MY_BONUSES ? 'show active' : ''
			}`}
			id="v-pills-transaction"
			role="tabpanel"
			aria-labelledby="v-pills-transaction-tab"
		>
			<div className="profile-card-wrap">
				<Grid container>
					<Grid item xs={12} md={6} display="flex" alignItems="center">
						<div className="profile-heading ">
							<h4>
								<PersonAddAlt1Icon /> {t('myReferrals')}
							</h4>
						</div>
					</Grid>
					<Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
						<Grid className="wagerSection">
							<Grid
								container
								display="flex"
								spacing={{ xs: '0', sm: '2' }}
								alignItems="center"
								marginBottom="10px"
								className="wagerInputs"
								style={{ width: '70%', marginLeft: 'auto' }}
							>
								<InputField
									name="referral"
									className="referral_input"
									type="text"
									placeholder={t('Search by username')}
									onChange={(e) => debouncedOnSearch(e.target.value)}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid xs={12}>
					<div className="table-responsive">
						<table className="table theme-table">
							<thead>
								<tr>
									{tableColumns.map((column) => (
										<th
											key={column?.id}
											scope="col"
											className=" text-center text-nowrap"
										>
											{column.label}
										</th>
									))}
								</tr>
							</thead>
							{myBonusesLoading ? (
								<tbody>
									<td colSpan={tableColumns?.length || 4}>
										<Loader variant={LOADER_HANDLER_TYPES.content} />
									</td>
								</tbody>
							) : (
								<tbody>
									{referralCodeData?.referredUser?.length > 0 ? (
										referralCodeData?.referredUser?.map((data) => (
											<tr key={data.id}>
												<td className="text-center">
													<div className="text-box">{data?.username} </div>
												</td>
												{/* <td className="text-center td-ellipsis">
													<div className="text-box">
														<span className="icon" />
														<span className="  ">
															{(data?.first_name && data?.last_name ? `${data?.first_name}  ${data?.last_name}` : '-')}
														</span>
													</div>
												</td> */}
												<td className="text-center">
													<div className="text-box justify-content-start">
														<span className="text">
															{newCurrency} {data?.amount}
														</span>
													</div>
												</td>

												<td className="text-center">
													<div className="text-box justify-content-end">
														<span className="  ">
															{moment
																.utc(data?.create_at)
																.local()
																.format('DD/MM/YYYY')}
														</span>
														<span className="icon" />
													</div>
												</td>
											</tr>
										))
									) : (
										<tr>
											<td colSpan={7} className="text-center text-white">
												{/* {loading ? <Loader variant={LOADER_HANDLER_TYPES.table} /> : 'Data Not Found'} */}
												{t('dataNotFound')}
											</td>
										</tr>
									)}
								</tbody>
							)}
						</table>
					</div>
					{referralCodeData?.referredUser?.length !== 10 &&
					page === 1 ? null : (
						<Pagination
							page={page}
							setPage={setPage}
							limit={TRANSACTIONS_LIMIT}
							dataLength={referralCodeData?.referredUser?.length}
							totalPages={referralCodeData?.totalPages}
						/>
					)}
				</Grid>
			</div>
		</div>
	);
};

export default MyReferral;
