const STAKE_AMOUNT = [
	{
		value: 10,
		label: 10,
	},
	{
		value: 100,
		label: 100,
	},
	{
		value: 1000,
		label: 1000,
	},
];

export { STAKE_AMOUNT };
