import { createSlice } from '@reduxjs/toolkit';
import {
	withdrawAmountsRequest,
	withdrawNatellerAmountsRequest,
	withdrawCoinPaymentAmountsRequest,
} from 'redux-thunk/thunk/withdrawAmount.thunk';

const initialState = {
	withdrawLoading: false,
	withdrawError: '',
};
const {
	actions: { setWithdrawData, setShowWalletPopup },
	reducer,
} = createSlice({
	name: 'withdrawPayment',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(withdrawAmountsRequest.pending, (state) => ({
				...state,
				withdrawLoading: true,
			}))
			.addCase(withdrawAmountsRequest.fulfilled, (state) => ({
				...state,
				withdrawLoading: false,
			}))
			.addCase(withdrawAmountsRequest.rejected, (state, action) => ({
				...state,
				withdrawLoading: false,
				withdrawError: action.payload,
			}))
			.addCase(withdrawNatellerAmountsRequest.pending, (state) => ({
				...state,
				withdrawLoading: true,
			}))
			.addCase(withdrawNatellerAmountsRequest.fulfilled, (state) => ({
				...state,
				withdrawLoading: false,
			}))
			.addCase(withdrawNatellerAmountsRequest.rejected, (state, action) => ({
				...state,
				withdrawLoading: false,
				withdrawError: action.payload,
			}))
			.addCase(withdrawCoinPaymentAmountsRequest.pending, (state) => ({
				...state,
				withdrawLoading: true,
			}))
			.addCase(withdrawCoinPaymentAmountsRequest.fulfilled, (state) => ({
				...state,
				withdrawLoading: false,
			}))
			.addCase(withdrawCoinPaymentAmountsRequest.rejected, (state, action) => ({
				...state,
				withdrawLoading: false,
				withdrawError: action.payload,
			}));
	},
});

export default reducer;
export { setWithdrawData, setShowWalletPopup };
