/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Buffer } from 'buffer';
import { useDispatch, useSelector } from 'react-redux';
import { setShowSignupPopup } from 'redux-thunk/redux/slices/gameSetting.slice';
import { Form, Formik } from 'formik';
import { Typography } from '@mui/material';
import {
	// getAllCountriesStart,
	// getAllCurrencyStart,
	getRegistrationFieldsStart,
} from 'redux-thunk/thunk/gameSetting';
import { useTranslation } from 'react-i18next';
import toast from 'helpers/toaster.helpers';
import { formatDateMDY } from 'utils/helper';
import { browserFingerPrint } from 'utils/browserFingerprint';
import { userSignUp } from 'redux-thunk/thunk/auth.thunk';
// import { getItem } from 'helpers/localstorage.helpers';
// import { isValidImage } from 'utils/validate';
import AuthStep from './SignupWizard/AuthStep';
import { userSigninSchema } from './schema';

const Signup = ({ setShowVerifyEmailPopup }) => {
	const formik = useRef();
	// const { languageData } = useSelector(state => state?.language)
	const { regFields } = useSelector((state) => state?.gameSetting);
	// const { settingData } = useSelector((state) => state.general);
	const { t, i18n } = useTranslation();
	// const [isCheckLoading, setIsCheckLoading] = useState(false);
	const [isPassValid, setIsPassValid] = useState(false);
	const [acceptConditions, setAcceptConditions] = useState({
		privacyPolicy: false,
		termsAndConditions: false,
		newsLetter: false,
		sms: false,
	});
	const dispatch = useDispatch();

	// const InitialStepHandler = async (form) => {
	// 	setIsCheckLoading(false);
	// 	if (isFinalStep) {
	// 		setIsFinalStep((val) => !val);
	// 		return;
	// 	}

	// 	if (regFields?.email !== 0 || regFields?.username !== 0) {
	// 		try {
	// 			const res = await checkIsEmailUnique({
	// 				email: form.email,
	// 				username: form.username,
	// 			});
	// 			setIsFinalStep(
	// 				res?.emailExists === true || res?.usernameExists === true
	// 			);
	// 			if (res?.emailExists || res?.usernameExists)
	// 				openErrorToaster({
	// 					message: getTranslation('userNameOrEmailExists'),
	// 				});
	// 		} catch (error) {
	// 			setIsCheckLoading(false);
	// 			return;
	// 		}
	// 	}
	// 	const formikErrors = formik.current.errors;
	// 	const formikValues = formik.current.values;

	// 	if (
	// 		(regFields?.email !== 0 && formikValues.email.length <= 0) ||
	// 		(regFields?.username !== 0 && formikValues.username.length <= 0) ||
	// 		(regFields?.password !== 0 && formikValues.password.length <= 0) ||
	// 		(regFields?.confirmPassword !== 0 &&
	// 			formikValues.confirmPassword.length <= 0)
	// 	) {
	// 		formik?.current?.setFieldTouched('email', true);
	// 		formik?.current?.setFieldTouched('username', true);
	// 		formik?.current?.setFieldTouched('password', true);
	// 		formik?.current?.setFieldTouched('confirmPassword', true);
	// 		return;
	// 	}

	// 	if (
	// 		!isFinalStep &&
	// 		(formikErrors.email ||
	// 			formikErrors.username ||
	// 			formikErrors.password ||
	// 			formikErrors.confirmPassword)
	// 	) {
	// 		if (
	// 			formikValues.password &&
	// 			formikValues?.confirmPassword &&
	// 			formikValues.password !== formikValues.confirmPassword
	// 		) {
	// 			toast(t('matchPassword'), 'error');
	// 		} else toast(t('fillAllFields'), 'error');
	// 		return;
	// 	}
	// 	let toastMessage = `${t('pleaseAccept')}`;

	// 	if (!acceptConditions.privacyPolicy) {
	// 		toastMessage += ` ${t('acceptPrivacyPolicy')}`;
	// 	}
	// 	if (!acceptConditions.termsAndConditions) {
	// 		toastMessage += ` ${t('acceptTerms')}`;
	// 	}

	// 	let modeOfCommShouldValidate = false;

	// 	if (regFields?.sms === 2 || regFields?.newsLetter === 2) {
	// 		modeOfCommShouldValidate = true;
	// 	}

	// 	if (
	// 		modeOfCommShouldValidate &&
	// 		!(acceptConditions?.newsLetter || acceptConditions?.sms)
	// 	) {
	// 		if (
	// 			acceptConditions.termsAndConditions &&
	// 			acceptConditions.privacyPolicy
	// 		) {
	// 			toastMessage += `${t('modeOfComm')}`.replace('and', '');
	// 		} else {
	// 			toastMessage += ` ${t('modeOfComm')}`;
	// 		}
	// 	} else {
	// 		modeOfCommShouldValidate = false;
	// 	}

	// 	if (
	// 		!acceptConditions.privacyPolicy ||
	// 		!acceptConditions.termsAndConditions ||
	// 		modeOfCommShouldValidate
	// 	) {
	// 		toast(`${toastMessage} ${t('beforeNextStep')}`, 'error');
	// 		return;
	// 	}
	// 	setIsFinalStep((val) => !val);
	// 	setIsCheckLoading(false);
	// };

	const arr =
		regFields &&
		Object.keys(regFields)
			.map((keyName) => regFields[keyName] !== false && keyName)
			.filter((ele) => ele !== false && ele);

	const initialState =
		arr &&
		arr.reduce((accumulator, currentValue) => {
			accumulator[currentValue] = '';
			return accumulator;
		}, {});

	const signUpHandler = (intState) => {
		dispatch(
			userSignUp({
				userDetails: intState,
				setShowVerifyEmailPopup,
				setIsSignupLoading: false,
			})
		);
	};
	const handleSubmitValidate = (formValues) => {
		console.log(formValues, 'formValues');

		let toastMessage = `${t('pleaseAccept')}`;

		if (!acceptConditions.privacyPolicy) {
			toastMessage += ` ${t('acceptPrivacyPolicy')}`;
		}
		if (!acceptConditions.termsAndConditions) {
			toastMessage += ` ${t('acceptTerms')}`;
		}
		let modeOfCommShouldValidate = false;

		if (regFields?.sms === 2 || regFields?.newsLetter === 2) {
			modeOfCommShouldValidate = true;
		}

		if (
			modeOfCommShouldValidate &&
			!(acceptConditions?.newsLetter || acceptConditions?.sms)
		) {
			if (
				acceptConditions.termsAndConditions &&
				acceptConditions.privacyPolicy
			) {
				toastMessage += `${t('modeOfComm')}`.replace('and', '');
			} else {
				toastMessage += ` ${t('modeOfComm')}`;
			}
		} else {
			modeOfCommShouldValidate = false;
		}

		if (
			!acceptConditions.privacyPolicy ||
			!acceptConditions.termsAndConditions ||
			modeOfCommShouldValidate
		) {
			toast(`${toastMessage} ${t('beforeNextStep')}`, 'error');
			return;
		}

		// eslint-disable-next-line no-restricted-syntax
		for (const k in initialState) {
			if (k === 'newsLetter') {
				initialState[k] = formValues[k] ? 'true' : 'false';
			} else if (k === 'sms') {
				initialState[k] = formValues[k] ? 'true' : 'false';
			} else {
				initialState[k] = formValues[k];
			}
		}
		if (regFields.phone) {
			initialState.phoneCode = formValues.phoneCode;
		}
		if (regFields.address) {
			initialState.city = formValues.city.trim();
			initialState.zipCode = formValues.zipCode.toString();
		}
		if (regFields.referralCode) {
			initialState.referralCode = formValues.referralCode;
		}
		// eslint-disable-next-line no-restricted-syntax
		for (const k in initialState) {
			if (
				regFields[k] === 0 ||
				initialState[k] === '' ||
				initialState[k] === null
			) {
				delete initialState[k];
			}
		}
		const encryptedPass = Buffer.from(initialState.password).toString('base64');
		initialState.newsLetter = acceptConditions.newsLetter.toString();
		initialState.sms = acceptConditions.sms.toString();
		initialState.password = encryptedPass;
		if (initialState.confirmPassword) {
			initialState.confirmPassword = encryptedPass;
		}
		if (initialState.dateOfBirth) {
			initialState.dateOfBirth = formatDateMDY(initialState.dateOfBirth);
		}
		if (initialState.firstName) {
			initialState.firstName = initialState.firstName.trim();
		}
		if (initialState.lastName) {
			initialState.lastName = initialState.lastName.trim();
		}
		initialState.uniqueKey = browserFingerPrint();
		if (regFields.phone === 2 && !isPassValid) {
			toast(t('enterValidPhone'), 'error');
		} else {
			signUpHandler(initialState);
		}
	};

	// const previousPage = () => {
	// 	setIsFinalStep(false);
	// };

	const modalCloseHandler = () => {
		dispatch(setShowSignupPopup(false));
	};

	useEffect(() => {
		// dispatch(getAllCountriesStart());
		// dispatch(getAllCurrencyStart());
		dispatch(getRegistrationFieldsStart());
	}, []);

	const isRequired = (fieldName) => fieldName === 2;

	return (
		<div
			className="auth-form-container modal fade show d-block text-white"
			style={{ display: 'block' }}
			id="popup_modal"
		>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div
					role="presentation"
					className="modal-content login-modal"
					onClick={(e) => e.stopPropagation()}
				>
					<div className="modal-body p-0">
						<button
							aria-label="close button"
							type="button"
							style={{ float: 'right' }}
							className="btn-close btn-close-white me-2 mt-2"
							onClick={() => modalCloseHandler()}
						/>
						<div className="row px-2">
							<div className="col-lg-12 col-12">
								<div className="auth-form-wrap">
									<div className="auth-logo">
										<img
											className="brand-logo-size"
											src={
												// settingData?.logo
												// 	? settingData.logo
												// 	:
												'/assets/images/logo/city99.png'
											}
											alt=""
										/>
										<Typography
											style={{
												marginBottom: '25px',
											}}
											variant="h5"
											className="modal-title"
										>
											<span
												style={{
													color: 'white',
													fontSize: '18px',
													fontWeight: '800',
												}}
											>
												{t('signinSignup')}
											</span>
										</Typography>
									</div>
									<div className="register-wrap ">
										{/* <Box
											sx={{
												width: '100%',
												marginBottom: '1.5rem',
												color: 'text.primary',
											}}
										>
											<Stepper
												activeStep={isFinalStep ? 1 : 0}
												alternativeLabel
											>
												<Step key={t('signupLoginDetails')}>
													<StepLabel>
														<span style={{ color: 'white', fontWeight: '600' }}>
															{t('signupLoginDetails')}
														</span>
													</StepLabel>
												</Step>
												<Step key={t('signupPersonalDetails')}>
													<StepLabel>
														<span style={{ color: 'white', fontWeight: '600' }}>
															{t('signupPersonalDetails')}
														</span>
													</StepLabel>
												</Step>
											</Stepper>
										</Box> */}

										<Formik
											enableReinitialize={() =>
												i18n.on('languageChanged', () => true)
											}
											innerRef={formik}
											initialValues={{
												email: '',
												password: '',
												username: '',
												referralCode: '',
												// firstName: '',
												// lastName: '',
												// phone: null,
												// phoneCode: '',
												// dateOfBirth: `01/01/${new Date().getFullYear() - 18}`,
												// address: '',
												// confirmPassword: '',
												// countryCode: '',
												// currencyCode: '',
												// gender: '',
												// nationality: '',
												// preferredLanguage: 'EN',
												// newsLetter: '',
												// sms: '',
												// zipCode: '',
												// city: '',
											}}
											validationSchema={userSigninSchema(regFields, t)}
											validateOnMount
											validateOnBlur
											validateOnChange
											onSubmit={(formValues) => {
												handleSubmitValidate(formValues);
											}}
										>
											{({
												values,
												handleBlur,
												handleChange,
												handleSubmit,
												errors,
												touched,
												setFieldValue,
												// setFieldTouched,
											}) => (
												<Form autoComplete="off">
													<AuthStep
														values={values}
														handleBlur={handleBlur}
														handleChange={handleChange}
														handleSubmit={handleSubmit}
														errors={errors}
														touched={touched}
														setFieldValue={setFieldValue}
														isRequired={isRequired}
														acceptConditions={acceptConditions}
														setAcceptConditions={setAcceptConditions}
														// isCheckLoading={isCheckLoading}
													/>
												</Form>
											)}
										</Formik>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
Signup.propTypes = {
	setShowVerifyEmailPopup: PropTypes.func.isRequired,
};
export default Signup;
