// import React from "react";
import { SPORTS_PROVIDER_ID } from 'SportsBook/BetBalancer/Helper/sports.config';
import { SB_ROUTE_PATHS } from 'SportsBook/BetBalancer/SportsRoutes/routeConstant';
import alpineskiing from '../../../Assets/Images/sports-icon/alpineskiing.svg';
// import americanfootball from '../../../Assets/Images/sports-icon/americanfootball.svg';
import aussierules from '../../../Assets/Images/sports-icon/aussierules.svg';
import badminton from '../../../Assets/Images/sports-icon/badminton.svg';
import bandy from '../../../Assets/Images/sports-icon/bandy.svg';
import baseball from '../../../Assets/Images/sports-icon/baseball.svg';
import basketball from '../../../Assets/Images/sports-icon/basketball.svg';
import biathlon from '../../../Assets/Images/sports-icon/biathlon.svg';
// import bikes from '../../../Assets/Images/sports-icon/bikes.svg';
import boxing from '../../../Assets/Images/sports-icon/boxing.svg';
// import cricket from '../../../Assets/Images/sports-icon/cricket.svg';
import cycling from '../../../Assets/Images/sports-icon/cycling.svg';
// import ecricket from '../../../Assets/Images/sports-icon/ecricket.svg';
// import esport from '../../../Assets/Images/sports-icon/esport.svg';
// import favorite from '../../../Assets/Images/sports-icon/favorite.svg';
// import fifab from '../../../Assets/Images/sports-icon/fifa-b.svg';
// import fifapenalty from '../../../Assets/Images/sports-icon/fifa-penalty.svg';
// import fifavolta from '../../../Assets/Images/sports-icon/fifa-volta.svg';
// import fighting from '../../../Assets/Images/sports-icon/fighting.svg';
import floorball from '../../../Assets/Images/sports-icon/floorball.svg';
import formula from '../../../Assets/Images/sports-icon/formula.svg';
import futsal from '../../../Assets/Images/sports-icon/futsal.svg';
import gaelicfootball from '../../../Assets/Images/sports-icon/gaelicfootball.svg';
import gaelichurling from '../../../Assets/Images/sports-icon/gaelichurling.svg';
import golf from '../../../Assets/Images/sports-icon/golf.svg';
import handball from '../../../Assets/Images/sports-icon/handball.svg';
import home from '../../../Assets/Images/sports-icon/home.svg';
import icehockey from '../../../Assets/Images/sports-icon/icehockey.svg';
import kabbadi from '../../../Assets/Images/sports-icon/kabbadi.svg';
// import live from '../../../Assets/Images/sports-icon/live.svg';
import mma from '../../../Assets/Images/sports-icon/mma.svg';
// import nba2k from '../../../Assets/Images/sports-icon/nba2k.svg';
import rocketleague from '../../../Assets/Images/sports-icon/rocket-league.svg';
import rugby from '../../../Assets/Images/sports-icon/rugby.svg';
import skijumping from '../../../Assets/Images/sports-icon/skijumping.svg';
import snooker from '../../../Assets/Images/sports-icon/snooker.svg';
import soccer from '../../../Assets/Images/sports-icon/soccer.svg';
import specials from '../../../Assets/Images/sports-icon/specials.svg';
// import sportsbaseball from '../../../Assets/Images/sports-icon/sports-baseball.svg';
import squash from '../../../Assets/Images/sports-icon/squash.svg';
// import sumo from '../../../Assets/Images/sports-icon/sumo.svg';
import tabletennis from '../../../Assets/Images/sports-icon/tabletennis.svg';
import tennis from '../../../Assets/Images/sports-icon/tennis.svg';
import volleyball from '../../../Assets/Images/sports-icon/volleyball.svg';
import waterpolo from '../../../Assets/Images/sports-icon/waterpolo.svg';
import darts from '../../../Assets/Images/sports-icon/darts.svg';
// import mybet from '../../../Assets/Images/sports-icon/mybet.svg';
// import search from '../../../Assets/Images/sports-icon/search.svg';
// import downarrow from '../../../Assets/Images/sports-icon/downarrow.svg';

export const ITEMS = [
	{ name: 'Home', image: home, text: 'home', link: SB_ROUTE_PATHS.SPORTS },
	// {
	// 	name: 'Favorite',
	// 	image: favorite,
	// 	text: 'favorite',
	// 	link: SB_ROUTE_PATHS.FAVORITE,
	// },
	// { name: 'Live', image: live, text: 'live', link: SB_ROUTE_PATHS.LIVE },
];

export const HOT_BADGE = ['1', '5'];

export const SPORTS_ICON = {
	[SPORTS_PROVIDER_ID.SOCCER]: soccer,
	[SPORTS_PROVIDER_ID.BASKETBALL]: basketball,
	[SPORTS_PROVIDER_ID.BASEBALL]: baseball,
	[SPORTS_PROVIDER_ID.ICE_HOCKEY]: icehockey,
	[SPORTS_PROVIDER_ID.TENNIS]: tennis,
	[SPORTS_PROVIDER_ID.HANDBALL]: handball,
	[SPORTS_PROVIDER_ID.FLOORBALL]: floorball,
	[SPORTS_PROVIDER_ID.GOLF]: golf,
	[SPORTS_PROVIDER_ID.BOXING]: boxing,
	[SPORTS_PROVIDER_ID.RUGBY]: rugby,
	[SPORTS_PROVIDER_ID.AUSSIERULES]: aussierules,
	[SPORTS_PROVIDER_ID.BANDY]: bandy,
	// [SPORTS_PROVIDER_ID.FOOTBALL]: "Football",
	[SPORTS_PROVIDER_ID.CYCLING]: cycling,
	[SPORTS_PROVIDER_ID.SPECIALS]: specials,
	[SPORTS_PROVIDER_ID.SNOOKER]: snooker,
	[SPORTS_PROVIDER_ID.TABLE_TENNIS]: tabletennis,
	[SPORTS_PROVIDER_ID.DARTS]: darts,
	[SPORTS_PROVIDER_ID.VOLLEYBALL]: volleyball,
	// [SPORTS_PROVIDER_ID.FIELD_HOCKEY]: "Field Hockey",
	[SPORTS_PROVIDER_ID.WATERPOLO]: waterpolo,
	// [SPORTS_PROVIDER_ID.CURLING]: "Curling",
	[SPORTS_PROVIDER_ID.FUTSAL]: futsal,
	// [SPORTS_PROVIDER_ID.OLYMPICS]: "Olympics",
	[SPORTS_PROVIDER_ID.BADMINTON]: badminton,
	// [SPORTS_PROVIDER_ID.BOWLS]: "Bowls",
	// [SPORTS_PROVIDER_ID.BEACH_VOLLEY]: "Beach Volley",
	// [SPORTS_PROVIDER_ID.ATHLETICS]: "Athletics",
	[SPORTS_PROVIDER_ID.SQUASH]: squash,
	// [SPORTS_PROVIDER_ID.LACROSSE]: "Lacrosse",
	[SPORTS_PROVIDER_ID.FORMULA_1]: formula, // formula 1
	[SPORTS_PROVIDER_ID.ALPINE_SKIING]: alpineskiing,
	[SPORTS_PROVIDER_ID.BIATHLON]: biathlon,
	// [SPORTS_PROVIDER_ID.CROSS_COUNTRY]: "Cross-Country",
	// [SPORTS_PROVIDER_ID.NORDIC_COMBINED]: "Nordic Combined",
	[SPORTS_PROVIDER_ID.SKI_JUMPING]: skijumping,
	// [SPORTS_PROVIDER_ID.SWIMMING]: "Swimming",
	// [SPORTS_PROVIDER_ID.SCHWINGEN]: "Schwingen",
	// [SPORTS_PROVIDER_ID.BEACH_SOCCER]: "Beach Soccer",
	// [SPORTS_PROVIDER_ID.PESAPALLO]: "Pesapallo",
	// [SPORTS_PROVIDER_ID.ROWING]: "Rowing",
	// [SPORTS_PROVIDER_ID.CANOEING]: "Canoeing",
	// [SPORTS_PROVIDER_ID.ARCHERY]: "Archery",
	// [SPORTS_PROVIDER_ID.EQUESTRIAN]: "Equestrian",
	// [SPORTS_PROVIDER_ID.FENCING]: "Fencing",
	// [SPORTS_PROVIDER_ID.GYMNASTICS]: "Gymnastics",
	// [SPORTS_PROVIDER_ID.JUDO]: "Judo",
	// [SPORTS_PROVIDER_ID.SAILING]: "Sailing",
	// [SPORTS_PROVIDER_ID.TRIATHLON]: "Triathlon",
	// [SPORTS_PROVIDER_ID.WEIGHTLIFTING]: "Weightlifting",
	// [SPORTS_PROVIDER_ID.MOUNTAIN_BIKE]: "Mountain Bike",
	// [SPORTS_PROVIDER_ID.DIVING]: "Diving",
	// [SPORTS_PROVIDER_ID.TRACK_CYCLING]: "Track cycling",
	// [SPORTS_PROVIDER_ID.RALLY]: "Rally",
	// [SPORTS_PROVIDER_ID.ESPORT_LEAGUE_OF_LEGENDS]: 'ESport League of Legends',
	[SPORTS_PROVIDER_ID.MMA]: mma,
	[SPORTS_PROVIDER_ID.ESPORT_ROCKET_LEAGUE]: rocketleague, // "ESport Rocket League",
	// [SPORTS_PROVIDER_ID.INDY_RACING]: "Indy Racing",
	// [SPORTS_PROVIDER_ID.SPEEDWAY]: "Speedway",
	[SPORTS_PROVIDER_ID.GAELIC_FOOTBALL]: gaelicfootball,
	[SPORTS_PROVIDER_ID.GAELIC_HURLING]: gaelichurling,
	[SPORTS_PROVIDER_ID.KABADDI]: kabbadi,
	// [SPORTS_PROVIDER_ID.FORMULA_E]: "Formula E",
	// [SPORTS_PROVIDER_ID.BASKETBALL_3X3]: "Basketball 3x3",
	// [SPORTS_PROVIDER_ID.TOURING_CAR_RACING]: "Touring Car Racing",
	// [SPORTS_PROVIDER_ID.MOTORCYCLE_RACING]: "Motorcycle Racing",
	// [SPORTS_PROVIDER_ID.STOCK_CAR_RACING]: "Stock Car Racing",
	// [SPORTS_PROVIDER_ID.ESPORT_VALORANT]: "ESport Valorant",
};
