import React, { useMemo } from 'react';
import PrivateData from '../PrivateData';
import ChangePassword from '../ChangePassword';
import Address from '../Address';
import Statistics from '../Statistics';

const useFormData = () => {
	const tabsData = useMemo(
		() => [
			{
				name: 'Account Info',
				content: () => <PrivateData />,
			},
			{
				name: 'Change Password',
				content: () => <ChangePassword />,
			},
			{
				name: 'Address',
				content: () => <Address />,
			},
			{
				name: 'Statistics',
				content: () => <Statistics />,
			},
		],
		[]
	);
	return tabsData;
};

export default useFormData;
