import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { ReactSVG } from 'react-svg';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import { useTranslation } from 'react-i18next';
// import StarIcon from '@mui/icons-material/Star';
import moment from 'moment';
import PropTypes from 'prop-types';
import soccer from 'SportsBook/BetBalancer/Assets/Images/sports-icon/soccer.svg';
import leftArrow from 'SportsBook/BetBalancer/Assets/Images/sports-icon/downarrow.svg';
import flag1 from 'SportsBook/BetBalancer/Assets/Images/sports-icon/country-flag-1.svg';
import flag2 from 'SportsBook/BetBalancer/Assets/Images/sports-icon/country-flag-2.svg';
import ArrowBackwardIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

// FIXME: LOCATION AND SPORT NAME
const TopScoreSection = ({ allMarketMatch }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const handleBack = () => {
		navigate(-1);
	};
	return (
		<Grid className="match-detail">
			<Stack
				className="bredcrums"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
			>
				<Grid display="flex" alignItems="center" flexDirection="row" gap={0.5}>
					<ArrowBackwardIcon
						onClick={handleBack}
						sx={{ marginRight: '10px', color: 'var(--white)' }}
					/>

					<ReactSVG src={soccer} className="match-icon" wrapper="span" />
					<Typography variant="span">{t('soccer')}</Typography>
					<ReactSVG src={leftArrow} className="arrow-icon" wrapper="span" />
					<Typography variant="span">{t('international')}</Typography>
					<ReactSVG src={leftArrow} className="arrow-icon" wrapper="span" />
					<Typography variant="span">
						{allMarketMatch?.league?.name || ''}
					</Typography>
				</Grid>

				<Typography variant="span" className="icons">
					<SignalCellularAltIcon sx={{ fontSize: '1.5rem' }} />
					{/* <StarIcon sx={{ fontSize: '1.5rem' }} /> */}
				</Typography>
			</Stack>

			<Stack
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				className="match-status"
			>
				<Grid className="teamlogo">
					<img src={flag1} alt="flag" />
					<Typography className="team-name">
						{allMarketMatch?.eventParticipants?.[0].participant.name}
					</Typography>
				</Grid>

				<Grid className="teamlogo" sx={{ textAlign: 'center' }}>
					<Grid>
						<Typography className="match-day">
							{moment(allMarketMatch.startDate).format('yyyy-MM-DD')}
						</Typography>
						<Typography className="match-time">
							{t('at')} {moment(allMarketMatch.startDate).format('h:mm')}
						</Typography>
					</Grid>
					{/* <Grid
                    display="flex"
                    justifyContent="center"
                    gap={2}
                    mt={1}
                >
                    <Typography
                        display="flex"
                        justifyContent="center"
                        gap={1.5}
                    >
                        <Typography
                            variant="span"
                            className="extra-market--score"
                        >
                            96
                        </Typography>
                        <Typography
                            variant="span"
                            className="extra-market--score"
                        >
                            69
                        </Typography>
                        <Typography
                            variant="span"
                            className="extra-market--score"
                        >
                            66
                        </Typography>
                        <Typography
                            variant="span"
                            className="extra-market--score"
                        >
                            99
                        </Typography>
                    </Typography>

                    <Typography>:</Typography>

                    <Typography
                        display="flex"
                        justifyContent="center"
                        gap={1.5}
                    >
                        <Typography
                            variant="span"
                            className="extra-market--score"
                        >
                            96
                        </Typography>
                        <Typography
                            variant="span"
                            className="extra-market--score"
                        >
                            69
                        </Typography>
                        <Typography
                            variant="span"
                            className="extra-market--score"
                        >
                            66
                        </Typography>
                        <Typography
                            variant="span"
                            className="extra-market--score"
                        >
                            99
                        </Typography>
                    </Typography>
                </Grid> */}
				</Grid>

				<Grid className="teamlogo" sx={{ textAlign: 'right' }}>
					<img src={flag2} alt="flag" />
					<Typography className="team-name">
						{allMarketMatch?.eventParticipants?.[1].participant.name}
					</Typography>
				</Grid>
			</Stack>
		</Grid>
	);
};

export default TopScoreSection;

TopScoreSection.propTypes = {
	allMarketMatch: PropTypes.shape({
		id: PropTypes.string,
		fixtureId: PropTypes.string,
		status: PropTypes.string,
		betStatus: PropTypes.string,
		score: PropTypes.string,
		startDate: PropTypes.string,
		league: PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
			sport: PropTypes.shape({
				id: PropTypes.string,
				name: PropTypes.string,
			}),
		}),
		eventParticipants: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
				type: PropTypes.string,
				participant: PropTypes.shape({
					id: PropTypes.string,
					name: PropTypes.string,
				}),
			})
		),
	}).isRequired,
};
