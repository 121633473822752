import styled from 'styled-components';

export const CountryViewBarContainer = styled.div`
  .sports-filter {
    position: relative;
    .active {
        color: var(--text-color);
        text-shadow: var(--text-shadow);
    }
    .sports-icon {
      margin-bottom: 0;
      height: 14px;
      display: flex;
      align-items: center;
      svg {
        width: 18px !important;
        height: inherit !important;
      }
    }
    .MuiTabs-indicator {
      display: none;
    }
    .MuiTabs-fixed {
      -webkit-mask-image: var(--textMaskBg);
    }
    padding: 1rem;
    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      background-color: var(--casino-filter-btn);
      border: 1px solid var(--casino-filter-btn);
      color: var(--white);
      font-size: 12px;
      transition: all 0.3s ease 0s;
      border-radius: 8px;
      font-weight: 700;
      min-height: auto !important;
      padding: 7px 6px;
      margin-right: 8px;

      img {
        margin-bottom: 0;
        width: 17px;
        height: 17px;
      }
      &.Mui-selected {
        color: var(--text-color);
        text-shadow: var(--text-shadow);
        svg {
          filter: var(--drop-shadow);
          path {
            fill: var(--text-color);
          }
        }
        border: 1px solid transparent;
        img {
          filter: invert(1);
        }
      }
    }
    // .left-effect, .right-effect {
    //   position: absolute;
    //   z-index:3;
    //   pointer-events: none;
    //   inset: 0px 0px auto auto;
    //   width: 40px;
    //   height: 100%;
    //   background: linear-gradient(to left, rgb(23, 25, 36), rgba(23, 25, 36, 0));
    // }
  }
  .top-heading {
    padding: 1rem 1rem 0 1rem;
    display: flex;
    h4 {
      font-size: 22px;
      font-weight: bold;
      align-items: center;
      color: var(--white);
      @media screen and (max-width: 575px){
        font-size: 18px;
      }
      img {
        margin-right: 8px;
        @media screen and (max-width: 575px){
          width: 25px;
        }
      }
    }
    .view-all--btn {
      color: var(--headingLink);
      font-size: 0.75rem;
      font-weight: 600;
      position: relative;
      top: 3px;
      display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    @media screen and (max-width: 575px){
      top: 0px;
    }
    &:hover {
      text-decoration: underline;
    }
}
    }
  }
  .MuiTabs-scrollButtons {
    svg {
        color: var(--white);
		font-size: 2rem;
    }
  }
`;
