/* eslint-disable react/prop-types */
import React from 'react';
import MainLayout from 'components/layout/MainLayout/index';

const PublicRoute = ({ Component, child, showFooter = false }) => (
	<MainLayout showFooter={showFooter}>
		<Component child={child} />
	</MainLayout>
);

export default PublicRoute;
