/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

// eslint-disable-next-line react/prop-types
const PaginationItem = ({ active, onClick, label, disabled }) => (
	<button
		type="button"
		className={`pagination-btn   ${active ? 'active selected-page' : ''}`}
		onClick={() => {
			if (disabled) {
				return;
			}
			onClick();
		}}
	>
		{label}
	</button>
);

const Pagination = (props) => {
	const {
		page,
		setPage,
		limit,
		showNo = true,
		showBtn = true,
		dataLength,
	} = props;

	const { t } = useTranslation();

	const totalPages = Math.ceil(props?.totalPages);

	const items = [];

	if (totalPages > 5) {
		const showFirstPages = page < 3;
		const showLastPages = page > totalPages - 2;

		if (!showFirstPages) {
			items.push(
				<PaginationItem
					key={1}
					active={page === 1}
					onClick={() => setPage(1)}
					label="...1"
				/>
			);
		} else {
			for (let i = 1; i <= 2; i++) {
				items.push(
					<PaginationItem
						key={i}
						active={i === page}
						onClick={() => setPage(i)}
						label={i}
					/>
				);
			}
		}

		if (showLastPages) {
			for (let i = totalPages - 2; i <= totalPages; i++) {
				items.push(
					<PaginationItem
						key={i}
						active={i === page}
						onClick={() => setPage(i)}
						label={i}
					/>
				);
			}
		}
		if (!showFirstPages && !showLastPages) {
			items.push(
				<PaginationItem
					key={page - 1}
					active={page === page - 1}
					onClick={() => setPage(page - 1)}
					label={page - 1}
				/>
			);

			items.push(
				<PaginationItem
					key={page}
					active
					onClick={() => setPage(page)}
					label={page}
				/>
			);

			items.push(
				<PaginationItem
					key={page + 1}
					active={page === page + 1}
					onClick={() => setPage(page + 1)}
					label={page + 1}
				/>
			);
		}

		if (!showLastPages) {
			items.push(
				<PaginationItem
					key={totalPages}
					active={page === totalPages}
					onClick={() => setPage(totalPages)}
					label={`...${totalPages}`}
				/>
			);
		}
	} else {
		for (let i = 1; i <= totalPages; i++) {
			items.push(
				<PaginationItem
					key={i}
					active={i === page}
					onClick={() => setPage(i)}
					label={i}
				/>
			);
		}
	}

	return (
		<div className="pagination-container">
			{showBtn && (
				<button
					type="button"
					className={`pagination-btn ${page === 1 ? 'disabled-btn' : 'active'}`}
					onClick={() => {
						page !== 1 && setPage(page - 1);
					}}
					disabled={page === 1 || (page === 1 && dataLength <= limit)}
				>
					{t('paginationPrevBtn')}
				</button>
			)}

			{showNo && items.map((item) => item)}

			{showBtn && (
				<button
					type="button"
					className={`pagination-btn ${
						page === totalPages ? 'disabled-btn' : 'active'
					}`}
					onClick={() => {
						page < totalPages && setPage(page + 1);
					}}
					disabled={page === totalPages || (page === 1 && dataLength < limit)}
				>
					{t('paginationNextBtn')}
				</button>
			)}
		</div>
	);
};
Pagination.defaultProps = {
	showNo: true,
	showBtn: true,
};
Pagination.propTypes = {
	page: PropTypes.number.isRequired,
	setPage: PropTypes.func.isRequired,
	limit: PropTypes.number.isRequired,
	totalPages: PropTypes.number.isRequired,
	showNo: PropTypes.bool,
	showBtn: PropTypes.bool,
	dataLength: PropTypes.number.isRequired,
};
export default memo(Pagination);
