/* eslint-disable radix */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { PropTypes } from 'prop-types';

// const range = (start, end, step) => {
// 	const array = [];
// 	for (let i = start; i <= end; i += step) {
// 		array.push(i);
// 	}
// 	return array;
// };

const DatePickerInput = ({
	selected,
	startDate,
	endDate,
	selectsStart,
	selectsEnd,
	handleDateChange,
	maxDate,
	minDate,
	dateFormat,
}) => (
	<DatePicker
		dateFormat={dateFormat}
		selected={selected}
		onChange={handleDateChange}
		startDate={startDate}
		selectsStart={selectsStart}
		selectsEnd={selectsEnd}
		endDate={endDate}
		maxDate={maxDate}
		minDate={minDate}
	/>
);
// {
// const currentYear = new Date().getFullYear();
// const years = range(1990, currentYear, 1);
// const months = [
// 	'January',
// 	'February',
// 	'March',
// 	'April',
// 	'May',
// 	'June',
// 	'July',
// 	'August',
// 	'September',
// 	'October',
// 	'November',
// 	'December',
// ];

// return (
// 	<DatePicker
// 		renderCustomHeader={({
// 			date,
// 			changeYear,
// 			changeMonth,
// 			decreaseMonth,
// 			increaseMonth,
// 			prevMonthButtonDisabled,
// 			nextMonthButtonDisabled,
// 		}) => (
// 			<div
// 				style={{
// 					margin: 10,
// 					display: 'flex',
// 					justifyContent: 'center',
// 				}}
// 			>
// 				<button
// 					onClick={decreaseMonth}
// 					disabled={prevMonthButtonDisabled}
// 					style={{ color: 'black' }}
// 				>
// 					{'<'}
// 				</button>
// 				<select
// 					value={date.getFullYear()}
// 					onChange={({ target: { value } }) => changeYear(parseInt(value))}
// 					style={{ color: 'black' }}
// 				>
// 					{years.map((option) => (
// 						<option
// 							key={option}
// 							value={option}
// 							style={{ backgroundColor: 'white', color: 'black' }}
// 						>
// 							{option}
// 						</option>
// 					))}
// 				</select>

// 				<select
// 					value={months[date.getMonth()]}
// 					onChange={({ target: { value } }) =>
// 						changeMonth(months.indexOf(value))
// 					}
// 					style={{ color: 'black' }}
// 				>
// 					{months.map((option) => (
// 						<option
// 							key={option}
// 							value={option}
// 							style={{ backgroundColor: 'white', color: 'black' }}
// 						>
// 							{option}
// 						</option>
// 					))}
// 				</select>

// 				<button
// 					onClick={increaseMonth}
// 					disabled={nextMonthButtonDisabled}
// 					style={{ color: 'black' }}
// 				>
// 					{'>'}
// 				</button>
// 			</div>
// 		)}
// 		dateFormat={dateFormat}
// 		selected={selected}
// 		onChange={handleDateChange}
// 		startDate={startDate}
// 		selectsStart={selectsStart}
// 		selectsEnd={selectsEnd}
// 		endDate={endDate}
// 		maxDate={maxDate}
// 		minDate={minDate}
// 	/>
// );
// };

DatePickerInput.defaultProps = {
	selected: '',
	startDate: '',
	endDate: '',
	selectsStart: '',
	selectsEnd: '',
	maxDate: '',
	minDate: '',
	dateFormat: 'dd-MM-yyyy',
};

DatePickerInput.propTypes = {
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	selected: PropTypes.string,
	selectsEnd: PropTypes.string,
	selectsStart: PropTypes.string,
	maxDate: PropTypes.string,
	minDate: PropTypes.string,
	handleDateChange: PropTypes.func.isRequired,
	dateFormat: PropTypes.string,
};

export default DatePickerInput;
