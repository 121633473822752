const config = {
	apiGateways: {
		BASE_URL_1: `${process.env.REACT_APP_USER_API_URL}`,
	},
	SOCKET_URL: `${process.env.REACT_APP_USER_BACKEND_SOCKET_URL}`,
	META_MASK_SIGN_MESSAGE: `${process.env.REACT_APP_META_MASK_SIGN_MESSAGE}`,
	SPORTBOOK_IFRAMEID: `${process.env.REACT_APP_SPORT_IFRAME_ID}`,
	SPORTBOOK_SCRIPT_URL: `${process.env.REACT_APP_RENDER_SCRIPT_URL}`,
	REACT_APP_CHAT_URL: `${process.env.REACT_APP_CHAT_URL}`
};

export default config;
