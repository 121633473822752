import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Formik, ErrorMessage } from 'formik';
import moment from 'moment';
import Button from 'components/molecules/Button/index';
import DatePickerInput from 'components/molecules/DatePicker/DatePicker';
import { ProfileUserIcon } from 'components/ui-kit/Icons/iconComponents/ProfileUserIcon';
import { LOADER_HANDLER_TYPES, ROUTE_PATHS } from 'constants/index';
import InputField from 'components/molecules/Input/InputField';
import { momentDate } from 'helpers/moment.helpers';
import { useDispatch, useSelector } from 'react-redux';
import { openErrorToaster } from 'helpers/toaster.helpers';
import { preventSymbolsInNumField } from 'utils/helperFunctions.utils';
import { updateUserInformation } from 'redux-thunk/thunk/user.thunk';
import Loader from 'components/ui-kit/Loader/index';
import { privateDataSchema } from './schema';

const PrivateData = () => {
	const dispatch = useDispatch();
	const formik = useRef();
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const { user, userDetailsUpdateLoading } = useSelector((state) => state.user);

	const handlePrivateData = async (formData) => {
		const tempFormData = { ...formData };
		if (tempFormData.dateOfBirth) {
			tempFormData.dateOfBirth = momentDate(tempFormData.dateOfBirth);
		}
		if (
			(tempFormData.phone && !tempFormData.phoneCode) ||
			(!tempFormData.phone && tempFormData.phoneCode)
		) {
			openErrorToaster({
				message: tempFormData.phone
					? 'Please enter Phone Code'
					: 'Please enter Phone Number',
			});
			return;
		}
		dispatch(
			updateUserInformation({
				params: tempFormData,
			})
		);
	};

	return (
		<div
			className={`tab-pane fade ${
				pathname === ROUTE_PATHS.PROFILE ? 'show active' : ''
			}`}
			id="v-pills-profile"
			role="tabpanel"
			aria-labelledby="v-pills-profile-tab"
		>
			<div className="profile-tab-content-wrap">
				<Formik
					enableReinitialize
					innerRef={formik}
					initialValues={{
						username: user?.user?.username || '',
						firstName: user?.user?.firstName || '',
						lastName: user?.user?.lastName || '',
						dateOfBirth:
							user?.user?.dateOfBirth ||
							moment().subtract(18, 'years').utc().toDate(),
						phoneCode: user?.user?.phoneCode,
						phone: user?.user?.phone,
					}}
					validationSchema={privateDataSchema(t)}
					validateOnMount
					validateOnBlur
					validateOnChange
					onSubmit={(formValues) => handlePrivateData(formValues)}
				>
					{({ values, handleBlur, handleChange, setFieldValue }) => (
						<Form autoComplete="off">
							<div className="profile-heading mb-3">
								<h4 className="m-0">
									<ProfileUserIcon /> {t('accountInfo')}
								</h4>
							</div>
							<div className="d-flex col-lg-12 gap-1 mb-2 form-group">
								<div className="col-lg-6 col-6">
									<InputField
										type="text"
										placeholder={t('profileFName')}
										className="form-control shadow-none"
										id="firstName"
										name="firstName"
										value={values.firstName}
										onChange={handleChange}
										onBlur={handleBlur}
										maxLength={50}
										haslabel="yes"
										required
										labeltext={t('profileFName')}
									/>
									<ErrorMessage
										component="div"
										name="firstName"
										className="error-message"
									/>
								</div>
								<div className="col-lg-6 col-6">
									<InputField
										type="text"
										placeholder={t('profileLname')}
										className="form-control shadow-none"
										id="lastName"
										name="lastName"
										value={values.lastName}
										onChange={handleChange}
										onBlur={handleBlur}
										maxLength={50}
										haslabel="yes"
										required
										labeltext={t('Last Name')}
									/>
									<ErrorMessage
										component="div"
										name="lastName"
										className="error-message"
									/>
								</div>
							</div>

							<div className="d-flex col-lg-12 gap-1 mb-3 form-group">
								<div className="col-lg-6 col-6">
									<InputField
										type="text"
										placeholder={t('profileUserName')}
										className="form-control shadow-none"
										id="username"
										name="username"
										value={values.username}
										onChange={handleChange}
										onBlur={handleBlur}
										maxLength={50}
										haslabel="yes"
										required
										disabled
										labeltext={t('User Name')}
									/>
									<ErrorMessage
										component="div"
										name="username"
										className="error-message"
									/>
								</div>
								<div className="col-lg-6 col-6">
									<div className="d-flex flex-column justify-content-end h-100">
										<label htmlFor="DOB" className="form-label">
											{t('Date of Birth')}
											<span className="color-red">&nbsp;*</span>
										</label>
										<DatePickerInput
											className="form-control shadow-none"
											selected={new Date(values.dateOfBirth)}
											handleDateChange={(date) =>
												date && setFieldValue('dateOfBirth', momentDate(date))
											}
											placeholder={t('Date of Birth')}
										/>
										<ErrorMessage
											component="div"
											name="dateOfBirth"
											className="error-message"
										/>
									</div>
								</div>
							</div>
							<div className="d-flex col-lg-12 gap-1 mb-2 form-group">
								<div className="col-lg-6 col-6">
									<InputField
										type="number"
										placeholder="Phone Code"
										className="form-control shadow-none"
										id="phoneCode"
										name="phoneCode"
										value={values.phoneCode}
										onChange={handleChange}
										onKeyDown={preventSymbolsInNumField}
										onBlur={handleBlur}
										maxLength={50}
										haslabel="yes"
										labeltext="Phone Code"
									/>
									<ErrorMessage
										component="div"
										name="phoneCode"
										className="error-message"
									/>
								</div>
								<div className="col-lg-6 col-6">
									<InputField
										type="number"
										placeholder="Phone Number"
										className="form-control shadow-none"
										id="phone"
										name="phone"
										value={values.phone}
										onChange={handleChange}
										onBlur={handleBlur}
										onKeyDown={preventSymbolsInNumField}
										maxLength={50}
										haslabel="yes"
										labeltext="Phone Number"
									/>
									<ErrorMessage
										component="div"
										name="phone"
										className="error-message"
									/>
								</div>
							</div>
							<div className="auth-btn-wrap w-100 d-flex justify-content-end align-items-center">
								<Button
									type="submit"
									className="btn btn-primary "
									disabled={userDetailsUpdateLoading}
									buttonlabel={
										userDetailsUpdateLoading ? (
											<Loader variant={LOADER_HANDLER_TYPES.submit} />
										) : (
											<span>{t('update')}</span>
										)
									}
								/>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};
export default React.memo(PrivateData);
