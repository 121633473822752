import React from 'react';
import { Box, Typography, List, ListItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useBetslip from '../hooks/useBetslip';

const ExchangeBetslipBottom = () => {
	const { t } = useTranslation();
	const { calculation } = useBetslip();

	return (
		<Box className="exchangeBetslipBottom">
			<List>
				<ListItem className="liability">
					<Typography variant="span">{t('liabilitySE')}</Typography>
					<Typography variant="span">{calculation.liability}</Typography>
				</ListItem>

				<ListItem className="profit">
					<Typography variant="span">{t('profitSE')}</Typography>
					<Typography variant="span">{calculation.profit}</Typography>
				</ListItem>

				<ListItem className="payout">
					<Typography variant="span">{t('payoutSE')}</Typography>
					<Typography variant="span">{calculation.payout}</Typography>
				</ListItem>
			</List>
		</Box>
	);
};

export default ExchangeBetslipBottom;
