import { openErrorToaster } from 'helpers/toaster.helpers';
// import { getTranslation } from 'helpers/translations.helpers';
import {
	depositAmountServiceRequest,
	depositNetellerAmountServiceRequest,
	depositCoinPaymentAmountServiceRequest,
} from 'network/services/depositAmount.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const depositAmountsRequest = createAsyncThunk(
	'wallet/depositAmount',
	async ({ callBack, payload }, thunkApi) => {
		try {
			const res = await depositAmountServiceRequest(payload);
			if (callBack) {
				callBack();
			}
			// openSuccessToaster({ message: getTranslation('amountDepositedSuccess') });

			if (res?.paymentUrl) {
				window.open(res.paymentUrl, '_blank');
			} else {
				openErrorToaster({ message: 'Something went wrong' });
			}
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
				// openErrorToaster({ message:error && error[0] && error[0]?.description })
			);
		}
	}
);
export const depositNetellerAmountsRequest = createAsyncThunk(
	'wallet/depositNetellerAmount',
	async ({ callBack, payload }, thunkApi) => {
		try {
			const res = await depositNetellerAmountServiceRequest(payload);
			if (callBack) {
				callBack();
			}
			// openSuccessToaster({ message: getTranslation('amountDepositedSuccess') });

			if (res?.paymentUrl?.[0]?.href) {
				window.open(res.paymentUrl[0].href, '_blank');
			} else {
				openErrorToaster({ message: 'Something went wrong' });
			}

			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const depositCoinPaymentAmountsRequest = createAsyncThunk(
	'wallet/depositCoinPaymentAmount',
	async ({ callBack, payload }, thunkApi) => {
		try {
			const res = await depositCoinPaymentAmountServiceRequest(payload);
			if (callBack) {
				callBack();
			}
			// openSuccessToaster({ message: getTranslation('amountDepositedSuccess') });

			if (res?.paymentUrl) {
				window.open(res.paymentUrl, '_blank');
			} else {
				openErrorToaster({ message: 'Something went wrong' });
			}
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
				// openErrorToaster({ message:error && error[0] && error[0]?.description })
			);
		}
	}
);
