/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
// import { isEmpty } from 'lodash';
// import PropTypes from 'prop-types';
import {
	ListItem,
	Grid,
	List,
	Tooltip,
	Fade,
	// Stack,
	// Switch,
	// Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
// import { styled } from '@mui/material/styles';
import { ReactSVG } from 'react-svg';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { useDispatch, useSelector } from 'react-redux';
// import { getSportInfo } from 'SportsBook/BetBalancer/Store/thunk/sportInfo.thunk';
import { setIsExchange } from 'SportsBook/BetBalancer/Store/slice/general.slice';
import { getAuthToken } from 'helpers/cookie.helpers';
// import { isEmpty } from 'lodash';
import { removeAllBets } from 'SportsBook/BetBalancer/Store/slice/betslip.slice';
import Modal from 'SportsBook/BetBalancer/Components/Modal/Modal';
import { isEmpty } from 'lodash';
import { getSports } from 'SportsBook/BetBalancer/Store/thunk/sports.thunk';
import { SB_ROUTE_PATHS } from 'SportsBook/BetBalancer/SportsRoutes/routeConstant';
import { TopSportsBarContainer } from './style';
import mybet from '../../../Assets/Images/sports-icon/mybet.svg';
// import search from '../../../Assets/Images/sports-icon/search.svg';s
import downarrow from '../../../Assets/Images/sports-icon/downarrow.svg';
import { HOT_BADGE, ITEMS, SPORTS_ICON } from './constant';
import soccer from '../../../Assets/Images/sports-icon/soccer.svg';

// const AntSwitch = styled(Switch)(({ theme }) => ({
//     width: 52,
//     height: 24,
//     padding: 0,
//     display: 'flex',
//     '&:active': {
//         '& .MuiSwitch-thumb': {
//             width: 15,
//         },
//         '& .MuiSwitch-switchBase.Mui-checked': {
//             transform: 'translateX(2px)',
//         },
//     },
//     '& .MuiSwitch-switchBase': {
//         padding: 2,
//         top: '2px',
//         transform: 'translateX(2px)',
//         '&.Mui-checked': {
//             transform: 'translateX(30px)',
//             color: '#000',
//             '& + .MuiSwitch-track': {
//                 opacity: 1,
//                 border:
//                     theme.palette.mode === 'dark'
//                         ? '1px solid transparent'
//                         : '1px solid transparent',
//                 backgroundColor: '#fff',
//             },
//             '& .MuiSwitch-thumb': {
//                 boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
//                 width: 16,
//                 height: 16,
//                 borderRadius: '50%',
//                 backgroundImage: 'url(https://i.ibb.co/zmP19D9/quickbet.png)',
//                 backgroundSize: 'cover',
//                 transition: theme.transitions.create(['width'], {
//                     duration: 200,
//                 }),
//             },
//         },
//     },
//     '& .MuiSwitch-thumb': {
//         boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
//         width: 16,
//         height: 16,
//         borderRadius: '50%',
//         backgroundImage: 'url(https://i.ibb.co/zmP19D9/quickbet.png)',
//         backgroundSize: 'cover',
//         transition: theme.transitions.create(['width'], {
//             duration: 200,
//         }),
//     },
//     '& .MuiSwitch-track': {
//         borderRadius: '50px',
//         opacity: 1,
//         border: theme.palette.mode === 'dark' ? '1px solid #fff' : '1px solid #fff',
//         boxSizing: 'border-box',
//         backgroundColor: 'transparent',
//     },
// }));

const TopSportsBar = () => {
	const token = !!getAuthToken();
	const { t } = useTranslation();
	const [isClassAdded, setClassAdded] = useState(false);
	const [showWarn, setWarn] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const sports = useSelector((state) => state.SBsports.sports);
	// const bets = useSelector((state) => state.SBbetslip.bets);
	const { isExchange } = useSelector((state) => state.SBgeneral);

	const toggleClass = () => {
		setClassAdded(!isClassAdded);
	};

	const handleHomeSport = ({ providerId }) => {
		navigate(`${SB_ROUTE_PATHS.SPORT_EVENTS}?sportId=${providerId}`);
	};

	const handleRoute = (link) => {
		navigate(link);
	};

	useEffect(() => {
		if (isEmpty(sports)) {
			dispatch(getSports());
		}
	}, []);

	// const handleExchange = (e) => {
	//     e.preventDefault();
	//     if (isEmpty(bets)) {
	//         dispatch(setIsExchange(e.target.checked))
	//     } else {
	//         setWarn(true);
	//     }
	// }

	const closeWarn = () => {
		setWarn(false);
	};

	const acceptWarn = () => {
		dispatch(removeAllBets());
		dispatch(setIsExchange(!isExchange));
		setWarn(false);
	};

	return (
		<TopSportsBarContainer>
			<Grid className="position-relative ">
				<nav role="navigation" className="sports-navigation">
					<Grid className="sb-sports-listing">
						<List>
							{ITEMS?.map((item) => (
								<ListItem
									key={item?.title}
									onClick={() => handleRoute(item?.link)}
								>
									<Tooltip
										title={item?.name}
										arrow
										TransitionComponent={Fade}
										TransitionProps={{ timeout: 600 }}
										placement="top"
									>
										<NavLink to="#">
											<ReactSVG
												src={item?.image}
												className="live-icon"
												wrapper="span"
											/>
											{item?.tagIcon === 'hoticon' && (
												<span className="hot-icon">
													<WhatshotIcon />
												</span>
											)}
										</NavLink>
									</Tooltip>
								</ListItem>
							))}
							{sports?.slice(0, 40)?.map((sport) => (
								<ListItem
									key={sport?.title}
									onClick={() => handleHomeSport(sport)}
								>
									<Tooltip
										title={sport?.name}
										arrow
										TransitionComponent={Fade}
										TransitionProps={{ timeout: 600 }}
										placement="top"
									>
										<NavLink to="#">
											<ReactSVG
												src={SPORTS_ICON[sport.providerId] || soccer}
												className="live-icon"
												wrapper="span"
											/>
											{sport?.isHotIcon ||
												(HOT_BADGE.includes(sport?.providerId) &&
													SPORTS_ICON[sport.providerId] && (
														<span className="hot-icon">
															<WhatshotIcon />
														</span>
													))}
										</NavLink>
									</Tooltip>
								</ListItem>
							))}
						</List>
						<Grid>
							<Tooltip
								title={t('allSports')}
								arrow
								TransitionComponent={Fade}
								TransitionProps={{ timeout: 600 }}
								placement="top"
							>
								<a
									href="javascript:void(0)"
									aria-haspopup="true"
									className={`drop-down-icon ${
										isClassAdded ? 'dropdown show' : 'dropdown'
									}`}
									onClick={toggleClass}
								>
									<img
										src={downarrow}
										alt={downarrow}
										style={{
											transform: `${isClassAdded ? 'rotate(180deg)' : ''}`,
										}}
									/>
								</a>
							</Tooltip>
						</Grid>
					</Grid>

					{/* <Stack direction="row" spacing={1} alignItems="center">
                        <Typography className="text-light">
                            {isExchange ? 'Exchange' : 'SportsBook'}
                        </Typography>
                        <AntSwitch
                            checked={isExchange}
                            onChange={handleExchange}
                            inputProps={{ 'aria-label': 'ant design' }}
                        />
                    </Stack> */}
					<Grid className="right-button--group">
						{!!token && (
							<Tooltip
								title={t('myBets')}
								arrow
								TransitionComponent={Fade}
								TransitionProps={{ timeout: 600 }}
								placement="top"
							>
								<button
									type="button"
									onClick={() => navigate(SB_ROUTE_PATHS.MY_BETS)}
								>
									<img src={mybet} alt="mybet" />
								</button>
							</Tooltip>
						)}

						{/* <Tooltip
							title="Search"
							arrow
							TransitionComponent={Fade}
							TransitionProps={{ timeout: 600 }}
							placement="top"
						>
							<button type="button">
								<img src={search} alt="search" />
							</button>
						</Tooltip> */}
					</Grid>
				</nav>

				<Grid
					className={
						isClassAdded ? 'extra-sports-icon show' : 'extra-sports-icon'
					}
				>
					<List className="dropdown-list" aria-label="submenu">
						{ITEMS.map((item) => (
							<ListItem key={item.text}>
								<Tooltip
									title={item?.name}
									arrow
									TransitionComponent={Fade}
									TransitionProps={{ timeout: 600 }}
									placement="top"
								>
									<NavLink to="#">
										<img src={item.image} alt={item.alt} />
										<span>{item.text}</span>
									</NavLink>
								</Tooltip>
							</ListItem>
						))}
						{sports?.map((sport) => (
							<ListItem
								key={sport.title}
								onClick={() => handleHomeSport(sport)}
							>
								<Tooltip
									title={sport?.name}
									arrow
									TransitionComponent={Fade}
									TransitionProps={{ timeout: 600 }}
									placement="top"
								>
									<NavLink to="#">
										<img
											src={SPORTS_ICON[sport.providerId] || soccer}
											alt={sport.alt}
										/>
										<span>{sport.name}</span>
									</NavLink>
								</Tooltip>
							</ListItem>
						))}
					</List>
				</Grid>
			</Grid>
			<Modal
				content="All your betslip bets would be removed! Are you sure you want to proceed!"
				cancelText="No"
				yesText="Yes"
				isOpen={showWarn}
				title={t('warning')}
				onCloseModal={closeWarn}
				handleCancel={closeWarn}
				handleYes={acceptWarn}
			/>
		</TopSportsBarContainer>
	);
};

export default TopSportsBar;

TopSportsBar.propTypes = {
	// selectMenuSport: PropTypes.func.isRequired,
};
