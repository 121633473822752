/* eslint-disable consistent-return */
import { signOut } from 'helpers/cookie.helpers';
import { openErrorToaster } from 'helpers/toaster.helpers';
import { getTranslation } from 'helpers/translations.helpers';
import { store } from 'App';
import errorMessages from 'network/messages/errorMessages';
import { stopLoader } from 'redux-thunk/redux/slices/loader.slice';
import moment from 'moment';
import { resetAuthState } from 'redux-thunk/redux/slices/auth.slice';
import { resetUserState } from 'redux-thunk/redux/slices/user.slice';
// import { setLocation } from 'redux-thunk/redux/slices/general.slice';
import { chatLogout } from 'utils/helper';

export const errorHandler = (error) => {
	if (error.response.status === 500) {
		// Snackbar Internal Server Error
		openErrorToaster({
			message: getTranslation(errorMessages.internalServerError),
		});
		store.dispatch(stopLoader(error.response.config.loader));
		return Promise.reject(error.response.data.errors);
	}
	if (error.response.status === 401) {
		// Snackbar UnAuthed
		// openErrorToaster({
		// 	message:
		// 		(error.response.data &&
		// 			error.response.data?.errors &&
		// 			error.response.data?.errors[0]?.description) ||
		// 		getTranslation(errorMessages.unAuthorized),
		// });
		signOut();
		chatLogout();
		store.dispatch(stopLoader(error.response.config.loader));
		store.dispatch(resetAuthState());
		store.dispatch(resetUserState());
		openErrorToaster({ message: 'Session Expired, Please Login' });
		setTimeout(() => {
			window.location.href = '/';
		}, 1500);
		return Promise.reject(error.response.data.errors);
	}
	if (error.response.status === 404) {
		// Snackbar UnAuthed
		// openErrorToaster({
		//   message: getTranslation(errorMessages.endPointNotFound),
		// })
		store.dispatch(stopLoader(error.response.config.loader));
		return;
	}
	if (error.response.status === 403) {
		window.location.href = '/';
		signOut();
		chatLogout();
		store.dispatch(resetAuthState());
		store.dispatch(resetUserState());
		store.dispatch(stopLoader(error.response.config.loader));
		return;
	}
	if (
		error?.response?.data?.errors[0]?.description === 'EXCLUDED_TEMPORARILY'
	) {
		const tempTime = JSON.parse(
			error.response.data?.errors[0]?.description
		)?.time;
		const time = moment(tempTime).fromNow(tempTime);
		const errorMsg = `${getTranslation(errorMessages.disabledForTime)} ${time}`;
		openErrorToaster({ message: errorMsg });
		store.dispatch(stopLoader(error.response.config.loader));
		return;
	}
	if (error.response.config?.loader) {
		// Other errors
		store.dispatch(stopLoader(error.response.config.loader));
	}

	if (error?.response?.data?.errors[0]?.fields?.description) {
		openErrorToaster({
			message: error?.response?.data?.errors[0]?.fields?.description,
		});
	}
	if (error?.response?.data?.errors[0]?.fields?.body) {
		openErrorToaster({
			message: error?.response?.data?.errors[0]?.fields.body[0].split('/')[1],
		});
	}
	// Open Error Toaster
	const errorMessage =
		(error.response.data &&
			error.response.data?.errors &&
			error.response.data?.errors[0]?.description) ||
		'Something went wrong!';
	openErrorToaster({
		message: getTranslation(
			errorMessages[errorMessage] ? errorMessages[errorMessage] : errorMessage
		),
	});

	return Promise.reject(error.response.data.errors);
};
