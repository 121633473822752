/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef, useState } from 'react';
import {
	DialogTitle,
	DialogContent,
	Grid,
	Typography,
	DialogActions,
} from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from 'SportsBook/BetBalancer/Components/Button/index';
import { ModalStyle, DialogContentContainer } from './style';

const CashoutModal = ({ isOpen, onCloseModal, handleAcceptCashout }) => {
	const [timer, setTimer] = useState(10);
	const { cashout, cashoutLoading } = useSelector((state) => state.SBcashout);
	const { t } = useTranslation();
	const ref = useRef(null);

	useEffect(() => {
		if (isOpen) {
			setTimer(10);
			ref.current = setInterval(() => {
				setTimer((prev) => prev - 1);
			}, 1000);
		}
		return () => {
			clearInterval(ref.current);
		};
	}, [isOpen]);

	useEffect(() => {
		if (timer <= 0) {
			onCloseModal();
		}
	}, [timer]);

	return (
		<ModalStyle
			open={isOpen}
			fullWidth
			maxWidth="xs"
			onClose={onCloseModal}
			scroll="paper"
			aria-labelledby="contained-modal-title-vcenter"
		>
			<DialogTitle>
				<Grid display="flex" justifyContent="space-between">
					<Typography>
						{t('cashoutExpiresIn')} : {timer}
					</Typography>
					<Button className="bg-transparent border-0" onClick={onCloseModal}>
						<CloseIcon />
					</Button>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<DialogContentContainer>
					<Grid className="cashout-description">
						<h6>{t('cashoutDescription')}</h6>
						<Grid>
							<p>
								{t('cashoutAmount')} : {cashout?.cashoutAmount || 0}
							</p>
						</Grid>
					</Grid>
				</DialogContentContainer>
			</DialogContent>
			<DialogActions>
				<Button onClick={onCloseModal} className="btn btn-secondary">
					{t('cancel')}
				</Button>
				<Button
					disabled={cashoutLoading}
					onClick={(e) => handleAcceptCashout(e, cashout?.betslipId)}
					autoFocus
					className="btn btn-secondary"
				>
					{t('cashout')}
				</Button>
			</DialogActions>
		</ModalStyle>
	);
};

CashoutModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onCloseModal: PropTypes.func.isRequired,
	handleAcceptCashout: PropTypes.func.isRequired,
};

export default React.memo(CashoutModal);
