import React from 'react';
import {
	Grid,
	Button,
	Typography,
	List,
	ListItem,
	TableCell,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { outcomeFormatter } from 'SportsBook/BetBalancer/Helper/formatterFunctions';
import { SB_ROUTE_PATHS } from 'SportsBook/BetBalancer/SportsRoutes/routeConstant';
import useMarkets from './hooks/useMarkets';
import Odds from '../../../Components/Odds/index';

const MainMarkets = ({ eventMarkets, match }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { filteredMainMarkets } = useMarkets(eventMarkets);

	const handleNavigate = () => {
		navigate(`${SB_ROUTE_PATHS.ALL_MARKETS}?eventId=${match?.fixtureId}`);
	};

	const getClasses = (idx) => {
		switch (idx) {
			case 0:
				return '';
			case 1:
				return 'second-table-cell';
			default:
				return '';
		}
	};

	return (
		<>
			{filteredMainMarkets.slice(0, 2).map((market, idx) => (
				<TableCell className={`desktop-view ${getClasses(idx)}`} align="right">
					<Grid className="market-name--wrapper" mb={1}>
						<List>
							<ListItem>{market?.market?.name}</ListItem>
						</List>
					</Grid>

					<Grid className="market-odds-wrapper">
						<List>
							{market?.outcomes?.map((odds) => (
								<ListItem>
									<Button>
										<span>
											{outcomeFormatter(
												odds,
												1,
												2,
												filteredMainMarkets[0]?.market?.providerId
											)}
										</span>
										<h5>
											<Odds
												odds={odds}
												market={filteredMainMarkets[0]?.market}
												match={match}
											/>
										</h5>
									</Button>
								</ListItem>
							))}
						</List>
					</Grid>
				</TableCell>
			))}
			<TableCell className="desktop-view" align="right">
				<Grid
					className="market-odds-wrapper"
					sx={{ marginTop: '28px' }}
					onClick={handleNavigate}
				>
					<List className="more-market">
						<ListItem>
							<Typography display="flex" flexDirection="column">
								{/* <span>+{filteredMainMarkets?.length || 1}</span> */}
								<span>{t('allMarkets')}</span>
							</Typography>
						</ListItem>
					</List>
				</Grid>
			</TableCell>
		</>
	);
};

export default MainMarkets;

MainMarkets.propTypes = {
	eventMarkets: PropTypes.arrayOf(
		PropTypes.shape({
			status: PropTypes.number,
			marketId: PropTypes.string,
			market: PropTypes.shape({
				providerId: PropTypes.string,
				name: PropTypes.string,
			}),
			outcomes: PropTypes.arrayOf(
				PropTypes.shape({
					providerId: PropTypes.string,
					name: PropTypes.string,
					status: PropTypes.string,
					base_line: PropTypes.string,
					price: PropTypes.string,
				})
			),
		})
	).isRequired,
	match: PropTypes.shape({
		id: PropTypes.string,
		fixtureId: PropTypes.string,
		status: PropTypes.string,
		betStatus: PropTypes.string,
		score: PropTypes.string,
		startDate: PropTypes.string,
		league: PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
			sport: PropTypes.shape({
				id: PropTypes.string,
				name: PropTypes.string,
			}),
		}),
		eventParticipants: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
				type: PropTypes.string,
				participant: PropTypes.shape({
					id: PropTypes.string,
					name: PropTypes.string,
				}),
			})
		),
	}).isRequired,
};
