/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom'
import {
	CASINO_LOAD_PAGINATION_LIMIT,
	LOADER_HANDLER_TYPES,
} from 'constants/index';
import Loader from 'components/ui-kit/Loader/index';
import { openErrorToaster } from 'helpers/toaster.helpers';
import { setShowSearchPopup } from 'redux-thunk/redux/slices/settings.slice';
import CasinoGameCard from 'pages/Casino/components/Lobby/Components/CasinoGameCard/index';
import { getAuthToken } from 'helpers/cookie.helpers';
import {
	favoriteGameHandlerGenric,
	getSearchedGames,
} from 'redux-thunk/thunk/game.thunk';
import ProvidersSearchBar from '../ProvidersSearchBar/index';

const SearchPopup = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const token = getAuthToken();
	// const navigate = useNavigate()
	// const { searchGame } = useSelector((state) => state?.lobby);
	const { searchPopupGames } = useSelector((state) => state.games);
	const { user } = useSelector((state) => state.user);

	const {
		[LOADER_HANDLER_TYPES.submit]: loading,
		[LOADER_HANDLER_TYPES.content]: contentLoading,
	} = useSelector((state) => state.loader);
	// const [items, setItems] = useState()
	const [categoryId, setCategoryId] = useState('');
	const [providerId, setProviderId] = useState('');
	const [searchedValue, setSearchedValue] = useState('');
	const [page, setPage] = useState(1);

	const fetchMore = () => {
		setPage(page + 1);
		dispatch(
			getSearchedGames({
				data: {
					limit: CASINO_LOAD_PAGINATION_LIMIT,
					name: searchedValue,
					categories: categoryId ? `["${categoryId}"]` : [],
					providers: providerId ? `["${providerId}"]` : [],
					pageNo: page + 1,
				},
				hasMore: true,
				popupGames: true,
			})
		);
	};

	const handleFavouriteGame = (game) => {
		if (!token) {
			return openErrorToaster({ message: t('loginFirst') });
		}
		dispatch(
			favoriteGameHandlerGenric({
				gameData: game,
				userId: user?.user?.id,
				isPopupGames: true,
			})
		);
	};

	useEffect(() => {
		setPage(1);
		dispatch(
			getSearchedGames({
				data: {
					limit: CASINO_LOAD_PAGINATION_LIMIT,
					name: searchedValue,
					categories: categoryId ? `["${categoryId}"]` : [],
					providers: providerId ? `["${providerId}"]` : [],
					pageNo: 1,
				},
				popupGames: true,
			})
		);
	}, [providerId, searchedValue, categoryId]);

	return (
		<div
			className="modal fade show"
			style={{ display: 'block' }}
			id="search_modal"
			onClick={() => dispatch(setShowSearchPopup(false))}
		>
			<div
				className="modal-dialog search-modal-wrap modal-dialog-centered"
				onClick={(e) => e.stopPropagation()}
			>
				<div className="modal-content search-modal">
					<div className="modal-body p-0 search-popup">
						<div className="search-modal-wrap">
							<div className="row">
								<div className="col-12">
									<div className="search-modal-right-section">
										<section className="search-modal-heading">
											<h4>{t('searchGames')}</h4>
											<button
												type="button"
												className="btn-close shadow-none"
												onClick={() => dispatch(setShowSearchPopup(false))}
											/>
										</section>
										<ProvidersSearchBar
											setSearchedValue={setSearchedValue}
											providerId={providerId}
											setProviderId={setProviderId}
											categoryId={categoryId}
											setCategoryId={setCategoryId}
										/>
										<section className="golabl-casino-section">
											<div
												className="tab-content view-all-page-wrap"
												id="v-pills-tabContent"
											>
												<div
													className="p-0 tab-pane fade show active"
													id="v-pills-all-games"
													role="tabpanel"
													aria-labelledby="v-pills-all-games-tab"
												>
													<div
														className={
															searchPopupGames?.casinoGames?.count > 0 &&
															'search-game-list'
														}
													>
														{searchPopupGames?.casinoGames?.count > 0 &&
															searchPopupGames?.casinoGames?.rows?.map(
																(game) => (
																	<div key={uuidv4()} className="">
																		<div className="theme-card-wrap">
																			<div className="theme-card">
																				<CasinoGameCard
																					handleFavouriteGame={
																						handleFavouriteGame
																					}
																					game={game}
																					searchPopup
																					closeModal={(status) =>
																						dispatch(setShowSearchPopup(status))
																					}
																				/>
																			</div>
																		</div>
																	</div>
																)
															)}
													</div>
													{!searchPopupGames?.casinoGames?.count > 0 && (
														<span className="d-block text-center text-muted p-4 small no-games">
															{contentLoading ? (
																<Loader
																	variant={LOADER_HANDLER_TYPES.content}
																/>
															) : (
																t('noGames')
															)}
														</span>
													)}
													{/* {loading && <Loader variant={LOADER_HANDLER_TYPES.content} />} */}
												</div>
											</div>
										</section>
										{searchPopupGames?.casinoGames?.count > 0 &&
											!(
												searchPopupGames?.casinoGames?.rows?.length >=
												searchPopupGames?.casinoGames?.count
											) && (
												<div className="load-more-wrap text-center my-3">
													<button
														type="button"
														className="btn btn-secondary"
														style={{ minWidth: '110px' }}
														// disabled={searchPopupGames?.casinoGames?.rows?.length >= searchPopupGames?.casinoGames?.count}
														onClick={() => {
															if (
																searchPopupGames?.casinoGames?.rows?.length <
																searchPopupGames?.casinoGames?.count
															) {
																fetchMore();
															}
														}}
													>
														{!loading ? (
															t('loadMore')
														) : (
															<CircularProgress
																size="1rem"
																className="mt-2 color-black"
															/>
														)}
													</button>
												</div>
											)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default React.memo(SearchPopup);
