import config from 'config/app.config';
import React, { useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { PropTypes } from 'prop-types';

export const GameImg = ({ imgSrc, shouldLoad = true, playerCount = null }) => {
	const [load, setLoad] = useState(true);
	const [error, isError] = useState(false);

	const styles = {
		filter: load ? 'blur(20px)' : 'none',
		transition: load ? 'none' : 'filter 0.1s ease-out',
		objectFit: 'cover',
		borderRadius: '8px',
	};

	const imgStyle = shouldLoad || error ? styles : {};

	return (
		<div className="game-image-wrapper">
			<img
				src={error ? config.IMAGE_URL + imgSrc : imgSrc}
				loading="lazy"
				style={imgStyle}
				onLoad={() => {
					setLoad(false);
				}}
				onError={() => {
					isError(true);
				}}
				alt=""
			/>
			{playerCount !== null ? (
				<span className="Game-count">
					<PersonIcon fontSize="small" />
					{playerCount}
				</span>
			) : null}
		</div>
	);
};
GameImg.defaultProps = {
	shouldLoad: false,
	playerCount: null,
};
GameImg.propTypes = {
	imgSrc: PropTypes.string.isRequired,
	shouldLoad: PropTypes.bool,
	playerCount: PropTypes.number,
};
