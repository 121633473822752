/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';

import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { UploadDocVerificationIcon } from 'components/ui-kit/Icons/iconComponents/UploadDocVerificationIcon';
import { ImageUploadContainer, ImageUploadError } from './style';
// import { Box, Typography } from "../../../../node_modules/@mui/material/index";

const ImageUpload = (props) => {
	const { t } = useTranslation();
	const { setImageDoc, selectedDocument, selectedTab, arrayValue } = props;
	const {
		fileRejections,
		getRootProps,
		getInputProps,
		isFocused,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		accept: {
			'image/*': [],
		},
		maxFiles: 1,
		onDrop: (acceptedFiles) => {
			const data = {
				...selectedDocument,
				[selectedTab]: acceptedFiles[0],
				labelData: arrayValue,
			};
			setImageDoc(data);
		},
	});

	const fileRejectionItems = fileRejections.map(({ file, errors }) => (
		<div key={file.path} className="error-box">
			<h6 className="file-size-text">
				{file.path} - {file.size} bytes
			</h6>
			<h6 className="error-text">{errors.map((e) => e.message)}</h6>
		</div>
	));

	return (
		<>
			<ImageUploadContainer
				{...getRootProps({ isFocused, isDragAccept, isDragReject })}
			>
				<div className="MuiBox-root">
					<input {...getInputProps()} />
					<UploadDocVerificationIcon />
					<h6>{t('UPLOAD_IMG_MSG')}</h6>
				</div>
			</ImageUploadContainer>
			<ImageUploadError>{fileRejectionItems}</ImageUploadError>
		</>
	);
};

export default ImageUpload;
