import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { resetCashout } from 'SportsBook/BetBalancer/Store/slice/cashout.slice';
import {
	getCashoutAmount,
	acceptCashout,
} from '../../../Store/thunk/cashout.thunk';

const useCashout = (fetchMyBets) => {
	const dispatch = useDispatch();
	const [isCashoutModalOpen, setIsCashoutModalOpen] = useState(false);
	const { cashoutLoading, cashoutError, cashout } = useSelector(
		(state) => state.SBcashout
	);

	const closeCashoutModal = () => {
		setIsCashoutModalOpen(false);
		dispatch(resetCashout());
	};

	const cashoutSuccess = () => {
		fetchMyBets();
		closeCashoutModal();
	};

	const handleCashout = (e, betslipId) => {
		e.preventDefault();
		dispatch(
			getCashoutAmount({
				betslipId,
				callBack: () => setIsCashoutModalOpen(true),
			})
		);
	};

	const handleAcceptCashout = (e, betslipId) => {
		e.preventDefault();
		dispatch(acceptCashout({ betslipId, callBack: () => cashoutSuccess() }));
	};

	return {
		handleCashout,
		cashoutLoading,
		cashoutError,
		cashout,
		isCashoutModalOpen,
		closeCashoutModal,
		handleAcceptCashout,
	};
};

export default useCashout;
