import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index';
import axiosInstance from 'network/apis/index';
import { microServices } from 'network/apis/microservice/index';

export const getGamesListService = (params, hasMore = false) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/casino/get-all-games',
		{},
		{
			server: microServices.SERVICE_URL_1,
			params,
			loader: hasMore
				? LOADER_HANDLER_TYPES.submit
				: LOADER_HANDLER_TYPES.content,
		}
	);

export const getCasinoGameBigWins = () =>
	axiosInstance(
		METHOD_TYPES.get,
		'/casino/recent-big-wins',
		{},
		{
			server: microServices.SERVICE_URL_1,
		}
	);

export const addFavouriteGameService = (data) =>
	axiosInstance(METHOD_TYPES.post, '/casino/toggle-favorite-game', data);

export const getFavouriteGameService = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/casino/get-favorite-games',
		{},
		{
			server: microServices.SERVICE_URL_1,
			// loader: LOADER_HANDLER_TYPES.submit
			params: data,
		}
	);
export const getSboUrlService = (data = {}) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/sbo/player-login',
		{},
		{
			server: microServices.SERVICE_URL_1,
			// loader: LOADER_HANDLER_TYPES.submit
			params: data,
		}
	);
