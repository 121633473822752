const MATCH_VIEW_TYPE = {
	BOX: 'box',
	LIST: 'list',
};

export const FEEDPROVIDER = {
	BETBALANCER: 'betBalancer',
};

const STAKE_PRECISION = 2;

const WINNING_PRECISION = 2;

const BONUS_CODE = 'AED';

const BET_SETTLEMENT_STATUS = [
	{ name: 'All', value: 'all' },
	{ name: 'Won', value: 'won' },
	{ name: 'Refund', value: 'refund' },
	{ name: 'Pending', value: 'pending' },
	{ name: 'Cashout', value: 'cashout' },
	// { name: 'Half Won', value: 'half_won' },
	{ name: 'Lost', value: 'lost' },
	// { name: 'Half Lost', value: 'half_lost' },
];

const SETTLEMENT_STATUS = {
	WON: 'won',
	REFUND: 'refund',
	PENDING: 'pending',
	CASHOUT: 'cashout',
	LOST: 'lost',
};

const OUTCOME_SETTLEMENT_STATUS = {
	PENDING: '0',
	LOST: '1',
	WON: '2',
	HALF_LOST: '3',
	HALF_WON: '4',
	REFUND: '5',
	CANCELLED: '6',
};

const OUTCOME_STATUS = {
	OPEN: '1',
	SUSPENDED: '0',
	SETTLED: '2',
};

const EVENT_MARKET_STATUS = {
	LOCKED: '0',
	OPEN: '1',
	SUSPENDED: '2',
};

const ODD_TYPES = [
	{
		label: 'Decimal',
		value: 'decimal',
	},
	{
		label: 'American',
		value: 'american',
	},
	{
		label: 'Fraction',
		value: 'fraction',
	},
];

export {
	MATCH_VIEW_TYPE,
	STAKE_PRECISION,
	BET_SETTLEMENT_STATUS,
	SETTLEMENT_STATUS,
	ODD_TYPES,
	OUTCOME_SETTLEMENT_STATUS,
	OUTCOME_STATUS,
	EVENT_MARKET_STATUS,
	WINNING_PRECISION,
	BONUS_CODE,
};
