import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import SideNavBar from 'components/layout/SideNavBar/index';
import Header from 'components/layout/Header/index';
import { useSelector } from 'react-redux';
import { sportsRoutes } from 'SportsBook/BetBalancer/SportsRoutes/index';
import ChatDrawer from 'components/layout/ChatDrawer/index';
import NavigateToLocation from 'components/layout/NavigateToLocation/index';
import { isArray } from 'lodash';
import routes from './routesList';
import RouteValidator from './RouteValidator';

const NotFound = lazy(() => import('components/layout/404/index'));

const CustomRoutes = () => {
	const { settingData } = useSelector((state) => state.general);

	// Needed in V6 of react-router-dom
	const createMuliRoute = (allRoutes) => {
		const flattenRoutes = [];
		allRoutes.forEach((route) => {
			if (isArray(route?.path)) {
				flattenRoutes.push(
					...route.path.map((path) => ({
						path,
						component: route.component,
						isPrivate: route.isPrivate,
						showHeader: route.showHeader,
						showFooter: route.showFooter,
					}))
				);
			} else {
				flattenRoutes.push(route);
			}
		});
		return flattenRoutes;
	};

	return (
		<>
			{settingData?.maintenance ? null : (
				<>
					{' '}
					<SideNavBar /> <Header /> <NavigateToLocation />
					<ChatDrawer />
				</>
			)}

			<Routes>
				{createMuliRoute([...routes, ...sportsRoutes]).map((route) => (
					<Route
						path={route.path}
						key={route.path}
						exact
						// render={() => <RouteValidator route={route} />}
						element={<RouteValidator route={route} />}
					/>
				))}
				<Route path="*" element={<NotFound />} />
			</Routes>
		</>
	);
};

export default CustomRoutes;
