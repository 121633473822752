// import React from 'react'

// import { SPORTS_PROVIDER_ID, eventConfig } from 'SportsBook/BetBalancer/Helper/config';
import { DUMMY_MARKET } from 'SportsBook/BetBalancer/Helper/marketConstants';
import { useEffect, useState } from 'react';

const useMarkets = (eventMarkets) => {
	const [filteredMainMarkets, setFilteredMainMarkets] = useState([]);
	const [filteredAdditionalMarkets, setFilteredAdditionalMarkets] = useState(
		[]
	);

	useEffect(() => {
		const mkts = eventMarkets.filter(
			() =>
				// eventConfig[SPORTS_PROVIDER_ID.SOCCER].mainMarkets.includes(market.marketId)
				true
		);
        if(mkts?.length === 0) {
            mkts.push(...DUMMY_MARKET);
        } else if(mkts?.length === 1) {
            mkts.push(DUMMY_MARKET[1]);
        }
		setFilteredMainMarkets(mkts);
		setFilteredAdditionalMarkets([]);
	}, [eventMarkets]);

	return {
		eventMarkets,
		filteredMainMarkets,
		filteredAdditionalMarkets,
	};
};

export default useMarkets;
