import { createSlice } from '@reduxjs/toolkit';
// import { MATCHES } from 'SportsBook/BetBalancer/Helper/constants';
import { getPreMatches } from '../thunk/preMatch.thunk';

const initialState = {
	matches: [],
	totalEvents: '',
	loading: false,
	error: null,
};
// SB means SportsBook
const SBpreMatch = createSlice({
	name: 'SBpreMatch',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getPreMatches.pending, (state) => ({
				...state,
				loading: true,
			}))
			.addCase(getPreMatches.fulfilled, (state, action) => ({
				...state,
				matches: action.payload.events,
				totalEvents: action.payload.totalEvents,
                loading: false,
			}))
			.addCase(getPreMatches.rejected, (state, action) => ({
				...state,
				error: action.payload,
                loading: false,
			}));
	},
});

// export const {  } = SBpreMatch.actions;

export default SBpreMatch.reducer;
