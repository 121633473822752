import React from 'react';

import { MaintenanceModeContainer } from './MaintenanceMode.style';

const MaintenanceMode = () => (
	<MaintenanceModeContainer>
		<div className="mainen-mode-wrap ">
			<div className="logo-wrap profile-card-wrap">
				<img
					width={135}
					className="logo"
					src="/assets/images/logo/city99.png"
					alt="Company Logo"
				/>
			</div>
			<h6>Site Under Maintenance</h6>
			<p style={{ text: 'bold', fontSize: '15px' }}>
				Our website is currently down for scheduled maintenance. We are working
				hard to enhance our services and will be back online shortly.
			</p>
			<p style={{ text: 'bold', fontSize: '15px' }}>
				Thank you for your patience and understanding.
			</p>
			<p style={{ text: 'bold', fontSize: '15px' }}>
				For any urgent matters, please contact us:
			</p>
			<p className="contact">
				Email: support@city99.com
				<br />
			</p>
			<div className="footer">
				&copy; {new Date().getFullYear()} City99. All Rights Reserved
			</div>
		</div>
	</MaintenanceModeContainer>
);

export default MaintenanceMode;
