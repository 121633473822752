/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
// import { isMobile } from 'react-device-detect';
import { ROUTE_PATHS } from 'constants/index';
// import { startCase } from 'lodash';
import { CasinoMenuIcon } from 'components/ui-kit/Icons/iconComponents/CasinoMenuIcon';
import {
	categoryIcons,
	defaultCategoryIcon,
	// defaultSubCategoryIcon,
} from 'constants/casinoIcon';
import { Link } from 'react-router-dom';
import { Tooltip, Fade } from '@mui/material/index';
import { isMobile } from 'react-device-detect';
import Accordion from 'react-bootstrap/Accordion';
import { HouseIcon } from 'components/ui-kit/Icons/iconComponents/HouseIcon';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const SbSportsbook = ({
	settingData,
	pathname,
	t,
	dispatch,
	showSideNav,
	tab,
	categoriesData,
	setShowSideNav,
	handleLink,
}) => {
	const [hoverItems, setHoverItems] = useState([]);

	// const handleSubCategoryClick = (id) => {
	// 	navigate(`/sports/${id}`);
	// };

	useEffect(() => {
		if (isMobile) {
			dispatch(setShowSideNav(false));
		}
	}, [pathname]);

	const handleSubCategory = (items) => {
		setHoverItems(items || []);
	};

	return settingData?.sportsbook &&
		categoriesData &&
		categoriesData?.sboSportCategories?.length ? (
		<div onMouseLeave={() => handleSubCategory([])}>
			<li className="nav-item">
				<Accordion>
					{/* <Tooltip
							title={t('SB_Sportsbook')}
							arrow
							TransitionComponent={Fade}
							TransitionProps={{ timeout: 600 }}
							placement="right"
						> */}
					<Accordion.Item eventKey="0">
						<Accordion.Header>
							<div className="icon-box">
								<CasinoMenuIcon />
							</div>
							<span className="text">{t('SB_Sportsbook')}</span>
						</Accordion.Header>
						{showSideNav ? (
							isMobile ? (
								<Accordion.Body>
									<ul className="subnav-item-list">
										{categoriesData?.sboSportCategories &&
											categoriesData?.sboSportCategories.map((category) => (
												<Accordion key={category?.name?.EN}>
													<Accordion.Item eventKey="0">
														<Accordion.Header>
															<div className="icon-box">
																<CasinoMenuIcon />
															</div>
															<span className="text">{category?.name?.EN}</span>
														</Accordion.Header>
														<Accordion.Body>
															{category?.sboSubCategories?.map(
																(subCategory) => (
																	<li
																		key={`casinoCategoriesList ${subCategory?.name?.EN}`}
																		className={`subnav-item ${
																			pathname === `/sports/${subCategory.id}`
																				? 'active'
																				: ''
																		}`}
																	>
																		<Tooltip
																			title={subCategory?.name?.EN}
																			arrow
																			TransitionComponent={Fade}
																			TransitionProps={{ timeout: 600 }}
																			placement="right"
																		>
																			<button
																				onClick={() =>
																					handleLink(
																						`${ROUTE_PATHS.SPORTS}${subCategory.id}`
																					)
																				}
																				type="button"
																				// to={`/sports/${category?.id}`}
																				className="subnav-link"
																			>
																				<span className="icon-box">
																					{categoryIcons[subCategory?.id] ||
																						defaultCategoryIcon}
																				</span>
																				<span className="text">
																					{subCategory.name?.EN}
																				</span>
																				{/* <span>dfsf</span> */}
																			</button>
																		</Tooltip>
																	</li>
																)
															)}
														</Accordion.Body>
													</Accordion.Item>
												</Accordion>
											))}
									</ul>
								</Accordion.Body>
							) : (
								<Accordion.Body>
									<ul className="subnav-item-list">
										{categoriesData?.sboSportCategories.map((category) => (
											<li
												onMouseOver={() =>
													handleSubCategory(category?.sboSubCategories || [])
												}
												key={`casinoCategoriesList ${category?.name?.EN}`}
												className={` subnav-item ${
													tab === category?.name?.EN ? 'active' : ''
												}`}
											>
												<Tooltip
													title={category?.name?.EN}
													arrow
													TransitionComponent={Fade}
													TransitionProps={{ timeout: 600 }}
													placement="right"
												>
													<Link
														// onClick={() => {
														// 	handleSubCategoryClick(category?.id);
														// }}
														className="subnav-link"
													>
														<span className="icon-box">
															{categoryIcons[category?.id] ||
																defaultCategoryIcon}
														</span>
														<span className="text">{category.name?.EN}</span>
														<ArrowForwardIosIcon fontSize="small" />
													</Link>
												</Tooltip>
											</li>
										))}
									</ul>
								</Accordion.Body>
							)
						) : null}
					</Accordion.Item>
					{/* </Tooltip> */}
				</Accordion>
			</li>
			{hoverItems?.length ? (
				<div className="extra-nav-items-list extra-nav-items-list-show">
					<div className="sidebar-content-wrap">
						<div className="sidebar-menu-list">
							<ul className="nav-item-list">
								{hoverItems.map((item) => (
									<li className="nav-item">
										<Tooltip
											title={item?.name?.EN}
											arrow
											TransitionComponent={Fade}
											TransitionProps={{ timeout: 600 }}
											placement="right-start"
										>
											<button
												type="button"
												onClick={() =>
													handleLink(`${ROUTE_PATHS.SPORTS}${item.id}`)
												}
												className={`nav-link ${pathname === `${ROUTE_PATHS.SPORTS}${item.id}` ? 'active' : ''}`}
											>
												<span className="icon-box">
													<HouseIcon />
												</span>
												<span className="text">{item?.name?.EN}</span>
											</button>
										</Tooltip>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			) : null}
		</div>
	) : null;
};

export default SbSportsbook;
