/* eslint-disable prefer-template */
import * as Yup from 'yup';

export const referralsSchema = () =>
	Yup.object().shape({
		// amount: Yup.number()
		// 	.min(MIN_DEPOSIT, t('amountGreaterZero'))
		// 	.max(MAX_DEPOSIT, t('amountShouldBeLess') + MAX_DEPOSIT)
		// 	.required(t('amountGreaterZero')),
	});
