/* eslint-disable react/no-unknown-property */
import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LockIcon from '@mui/icons-material/Lock';
import { OUTCOME_STATUS } from 'SportsBook/BetBalancer/Helper/constants';
import { oddsFormatter } from 'SportsBook/BetBalancer/Helper/utils';
import { outcomeFormatter } from 'SportsBook/BetBalancer/Helper/formatterFunctions';
import useBetslip from './hooks/useBetslip';
import soccer from '../../Assets/Images/sports-icon/soccer.svg';

const SingleBets = () => {
	const { t } = useTranslation();
	const { bets, singleBetStake, handleSingleStake, handleRemoveBet } =
		useBetslip();
	const { isExchange, oddsType } = useSelector((state) => state.SBgeneral);

	return !isExchange ? (
		<Grid className="yScrolling">
			{Object.entries(bets || {}).map(([, item]) => {
				const isLocked =
					item?.outcome?.status === OUTCOME_STATUS.SUSPENDED ||
					item?.outcome?.status === OUTCOME_STATUS.SETTLED ||
					item?.outcome?.price <= 1.0;
				return (
					<Grid
						className="oddsSelection"
						display="flex"
						alignItems="center"
						sx={{ width: '100%' }}
					>
						<Grid
							className="closeOdds"
							onClick={(e) => handleRemoveBet(e, item)}
						>
							<CloseIcon />
						</Grid>

						<Grid className="oddsSelectionDetail">
							<Typography className="matchType">
								<img src={soccer} alt="soccer" />
								{outcomeFormatter(
									item.outcome,
									item?.match?.eventParticipants?.[0]?.participant?.name,
									item?.match?.eventParticipants?.[1]?.participant?.name,
									item?.providerMarketId
								)}
							</Typography>

							<Grid
								className="teamDetail"
								display="flex"
								alignItems="center"
								gap={1}
								sx={{ padding: '5px 0' }}
							>
								<Typography className="teamName">
									{item?.match?.eventParticipants[0]?.participant?.name}
								</Typography>
								<Typography className="vs">{t('vs')}</Typography>
								<Typography className="teamName">
									{item?.match?.eventParticipants[1]?.participant?.name}
								</Typography>
							</Grid>

							<Grid
								className="oddsStack"
								display="flex"
								alignItems="center"
								justifyContent="space-between"
							>
								<Grid className="odds" sx={{ flexGrow: '1' }}>
									{isLocked ? (
										<LockIcon fontSize="small" />
									) : (
										oddsFormatter(item.outcome.price, oddsType)
									)}
								</Grid>

								<Grid className="StackInput">
									{/* <Button variant='contained' className='acceptOdds'>
                    Accept Odds Changes
                  </Button> */}
									<input
										type="number"
										className="stackOddsInput"
										value={singleBetStake}
										onChange={(e) => handleSingleStake(e.target.value)}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				);
			})}
		</Grid>
	) : (
		<Grid className="yScrollingExchange">
			<Grid
				className="selectionHeading"
				display="flex"
				sx={{ padding: '10px 0' }}
			>
				<Typography className="selection" sx={{ flexGrow: 1 }}>
					{t('selection')}
				</Typography>
				<Grid display="flex" gap={0.5} className="rightDetail">
					<Typography variant="span" className="odds">
						{t('odds')}
					</Typography>
					<Typography variant="span" className="stake">
						{t('stake')}
					</Typography>
					<Typography variant="span" className="trashIcon" />
				</Grid>
			</Grid>

			{Object.entries(bets || {}).map(([, item]) => (
				<Grid sx={{ padding: '10px 0' }} className="betslipParent">
					<Grid
						display="flex"
						gap={2}
						alignItems="center"
						sx={{ paddingBottom: '10px' }}
					>
						<Typography className="teamName">
							<Typography variant="span" className="teamNameOne">
								{item?.match?.eventParticipants[0]?.participant?.name}
							</Typography>
							<Typography variant="span">{t('vs')}</Typography>
							<Typography variant="span" className="teamNameTwo">
								{item?.match?.eventParticipants[1]?.participant?.name}
							</Typography>
						</Typography>

						{item?.oddTypes === 'Back' ? (
							<Grid className="backlayButton">
								<Button variant="contained" className="backButton">
									{t('back')}
								</Button>
							</Grid>
						) : (
							<Grid className="backlayButton">
								<Button variant="contained" className="layButton">
									{t('lay')}
								</Button>
							</Grid>
						)}
					</Grid>

					<Grid display="flex">
						<Typography className="teamSelected" sx={{ flexGrow: 1 }}>
							{item?.marketName}
						</Typography>
						<Grid display="flex" className="selectedOdds">
							<input
								type="number"
								variant="span"
								className="oddsValue"
								value={item.odds}
							/>
							<input
								type="number"
								variant="span"
								className="stakeValue"
								value={singleBetStake}
								name="singleBet"
								onChange={(e) => handleSingleStake(e.target.value)}
							/>
							<Typography variant="span" className="trashIcon">
								<DeleteIcon />
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			))}
		</Grid>
	);
};

export default SingleBets;
