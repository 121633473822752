import React from 'react';
import ContentLoaderIcon from '../Images/Other/animation-loader-1.gif';
import { LoaderContainer } from './style';

const SBLoader = () => (
	<LoaderContainer>
		<div className="loader-parent-SB">
			<div className="full-body-loader-SB">
				{/* <ContentLoaderIcon /> */}
				<img src={ContentLoaderIcon} alt="ContentLoaderIcon" />
				<span>LOADING...</span>
			</div>
		</div>
	</LoaderContainer>
);

export default SBLoader;
