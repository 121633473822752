import React from 'react';
import TabsComponent from 'components/molecules/CustomTab/TabPanel';
import useFormData from './hooks/useFormData';

const UsersProfile = () => (
	<div>
		<div>
			<TabsComponent tabs={useFormData()} />
		</div>
	</div>
);
export default React.memo(UsersProfile);
