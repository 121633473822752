import { SPORTS_PROVIDER_ID } from 'SportsBook/BetBalancer/Helper/sports.config';
import { SB_ROUTE_PATHS } from 'SportsBook/BetBalancer/SportsRoutes/routeConstant';
import { getPreMatches } from 'SportsBook/BetBalancer/Store/thunk/preMatch.thunk';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const useMatches = () => {
	const dispatch = useDispatch();
	const { search, pathname } = useLocation();
	const params = new URLSearchParams(search);
	const selectedTopSport = params.get('sportId');
	const selectedLeagueId = params.get('leagueId');
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(12);
	const { matches, loading, totalEvents } = useSelector(
		(state) => state.SBpreMatch
	);
	const isSportEventPage = pathname?.includes(SB_ROUTE_PATHS.SPORT_EVENTS);

	const { selectedPreMatchSportId } = useSelector((state) => state.SBsports);

	useEffect(() => {
		if (selectedPreMatchSportId || selectedTopSport || selectedLeagueId) {
			dispatch(
				getPreMatches({
					sportId:
						!isSportEventPage && selectedPreMatchSportId
							? selectedPreMatchSportId
							: selectedTopSport || SPORTS_PROVIDER_ID.SOCCER,
					page,
					perPage,
					leagueId: selectedLeagueId === 'all' ? null : selectedLeagueId,
					// limit: 10,
					// sportCountryId: selectedCountryId,
					// countryId: selectedCountryProviderId,
					// search,
					// fromDate: calculatedStartDate,
					// endDate: calculatedEndDate,
				})
			);
		}
	}, [
		selectedPreMatchSportId,
		page,
		perPage,
		selectedLeagueId,
		selectedTopSport,
		isSportEventPage,
	]);

	useEffect(() => {
		if (selectedPreMatchSportId || selectedTopSport || selectedLeagueId) {
			setPage(1);
		}
	}, [selectedPreMatchSportId, selectedTopSport, selectedLeagueId]);

	return {
		matches,
		page,
		setPage,
		setPerPage,
		perPage,
		loading,
		totalEvents,
	};
};

export default useMatches;
