/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, Stack, Typography, Switch } from '@mui/material';
import { setShowBetslip } from 'SportsBook/BetBalancer/Store/slice/betslip.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import betslipIcon from '../../../Assets/Images/sports-icon/betslip.svg';
import downarrow from '../../../Assets/Images/sports-icon/downarrow.svg';

const AntSwitch = styled(Switch)(({ theme }) => ({
	width: 52,
	height: 24,
	padding: 0,
	display: 'flex',
	'&:active': {
		'& .MuiSwitch-thumb': {
			width: 15,
		},
		'& .MuiSwitch-switchBase.Mui-checked': {
			transform: 'translateX(2px)',
		},
	},
	'& .MuiSwitch-switchBase': {
		padding: 2,
		top: '2px',
		transform: 'translateX(2px)',
		'&.Mui-checked': {
			transform: 'translateX(30px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				opacity: 1,
				border:
					theme.palette.mode === 'dark'
						? '1px solid transparent'
						: '1px solid transparent',
				backgroundColor: '#000',
			},
			'& .MuiSwitch-thumb': {
				boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
				width: 16,
				height: 16,
				borderRadius: '50%',
				backgroundImage: 'url(https://i.ibb.co/zmP19D9/quickbet.png)',
				backgroundSize: 'cover',
				transition: theme.transitions.create(['width'], {
					duration: 200,
				}),
			},
		},
	},
	'& .MuiSwitch-thumb': {
		boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
		width: 16,
		height: 16,
		borderRadius: '50%',
		backgroundImage: 'url(https://i.ibb.co/zmP19D9/quickbet.png)',
		backgroundSize: 'cover',
		transition: theme.transitions.create(['width'], {
			duration: 200,
		}),
	},
	'& .MuiSwitch-track': {
		borderRadius: '50px',
		opacity: 1,
		border: theme.palette.mode === 'dark' ? '1px solid #000' : '1px solid #000',
		boxSizing: 'border-box',
		backgroundColor: 'transparent',
	},
}));
const BetslipHeader = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { showBetslip, betCount } = useSelector((state) => state.SBbetslip);

	return (
		<Grid
			className="betSlipHeader"
			display="flex"
			justifyContent="space-between"
			alignItems="center"
		>
			<Box className="betSlipHeaderSection" display="flex" alignItems="center">
				<img src={betslipIcon} alt="betslipIcon" />
				<a
					href="javascript:void(0)"
					onClick={() => dispatch(setShowBetslip(!showBetslip))}
				>
					<Typography display="flex" alignItems="center">
						<Typography
							variant="span"
							display="flex"
							alignItems="center"
							gap={1}
						>
							{t('betslip')}
							<Typography variant="span" className="badge pulse">
								{betCount}
							</Typography>
						</Typography>
						<img
							src={downarrow}
							alt="downarrow"
							style={{ transform: `${showBetslip ? '' : 'rotate(180deg)'}` }}
						/>
					</Typography>
				</a>
			</Box>
			<Box className="quickBet">
				<Stack direction="row" spacing={1} alignItems="center">
					<Typography>{t('quickBet')}</Typography>
					<AntSwitch
						defaultChecked
						inputProps={{ 'aria-label': 'ant design' }}
					/>
				</Stack>
			</Box>
		</Grid>
	);
};

export default BetslipHeader;
