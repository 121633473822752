import { createSlice } from '@reduxjs/toolkit';
import { calcOdds } from 'SportsBook/BetBalancer/Helper/utils';
import { placeSportsbookBet } from '../thunk/betslip.thunk';

const initialState = {
	// basic operation
	showBetslip: true,
	betError: '',

	// bets info
	bets: {},
	isBetPlaceLoading: false,
	betPlaceError: null,
	betCount: 0,

	// Calculation
	singleBetStake: 0,
	comboBetStake: 0,
	totalOdds: 0,
};

const addBetsMiddleware = (bets, newBets) => {
	let updatedBets = { ...bets };

	// Check for same fixture
	Object.values(bets || {}).forEach((bet) => {
		if (newBets.fixtureId === bet.fixtureId) {
			delete updatedBets[bet.providerOutcomeId];
		}
	});

	if (!bets[newBets.providerOutcomeId]) {
		updatedBets = { ...updatedBets, [newBets.providerOutcomeId]: newBets };
	} else {
		delete updatedBets[newBets.providerOutcomeId];
	}
	return updatedBets;
};

const removeBetMiddleware = (bets, betToRemove) => {
	let updatedBets = {};
	if (bets[betToRemove.providerOutcomeId]) {
		// const betCount = Object.keys(updatedBets || {})?.length;
		// if(betCount === 1) {
		// 	return {};
		// }
		updatedBets = { ...bets };
		delete updatedBets[betToRemove.providerOutcomeId];
	}
	return updatedBets;
};

// SB means SportsBook
const SBbetslip = createSlice({
	name: 'SBbetslip',
	initialState,
	reducers: {
		addBet: (state, action) => {
			const updatedBets = addBetsMiddleware(state.bets, action.payload);
			const betCount = Object.keys(updatedBets || {})?.length;
			const totalOdds = calcOdds(updatedBets);
			return {
				...state,
				bets: updatedBets,
				betCount,
				showBetslip: true,
				betError: '',
				totalOdds,
			};
		},
		removeBet: (state, action) => {
			const updatedBets = removeBetMiddleware(state.bets, action.payload);
			const betCount = Object.keys(updatedBets || {})?.length;
			const totalOdds = calcOdds(updatedBets);
			return {
				...state,
				bets: updatedBets,
				betCount,
				showBetslip: true,
				betError: '',
				totalOdds,
			};
		},
		removeAllBets: () => ({
			...initialState,
		}),
		setSingleStake: (state, action) => ({
			...state,
			singleBetStake: action.payload,
		}),
		setComboStake: (state, action) => ({
			...state,
			comboBetStake: action.payload,
		}),
		setShowBetslip: (state, action) => ({
			...state,
			showBetslip: action.payload,
		}),
		setBetError: (state, action) => ({
			...state,
			betError: action.payload,
		}),
	},
	extraReducers: (builder) => {
		builder
			.addCase(placeSportsbookBet.pending, (state) => ({
				...state,
				isBetPlaceLoading: true,
			}))
			.addCase(placeSportsbookBet.fulfilled, (state) => ({
				...state,
				bets: {},
				betCount: 0,
				isBetPlaceLoading: false,
				betPlaceError: null,
				betError: '',
				singleBetStake: 0,
				comboBetStake: 0,
				totalOdds: 0,
			}))
			.addCase(placeSportsbookBet.rejected, (state, action) => ({
				...state,
				betPlaceError: action.payload,
				isBetPlaceLoading: false,
			}));
	},
});

export const {
	addBet,
	removeAllBets,
	setSingleStake,
	setComboStake,
	setShowBetslip,
	removeBet,
	setBetError,
} = SBbetslip.actions;

export default SBbetslip.reducer;
