/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';

import {
	Grid,
	Box,
	Tab,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	List,
	ListItem,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TabPanel, TabList, TabContext } from '@mui/lab';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getAllMarkets } from 'SportsBook/BetBalancer/Store/thunk/allMarket.thunk';
import Odds from 'SportsBook/BetBalancer/Components/Odds';
import {
	getMarketName,
	outcomeFormatter,
} from 'SportsBook/BetBalancer/Helper/formatterFunctions';
import NoDataFound from 'SportsBook/BetBalancer/Components/NoDataFound/NoDataFound';
import SBLoader from 'SportsBook/BetBalancer/Assets/Loader/SBLoader';
import TopSportsBar from '../SportBookLobby/TopSportsBar/index';
import { ExtraMarketContainer } from './style';
import { SportsBookContainer } from '../SportBookLobby/style';
import Betslip from '../Betslip/index';
import { TABS_DATA } from './AllMarketConstants';
import TopScoreSection from './TopScoreSection';
import TrackerSection from './TrackerSection';

const AllMarkets = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const serachParams = useLocation();
	const fixtureId = new URLSearchParams(serachParams.search).get('eventId');
	const [value, setValue] = React.useState('1');
	const { showSideNav } = useSelector((state) => state?.settings);
	const { allMarkets, allMarketsloading } = useSelector(
		(state) => state?.SBallMarket
	);
	const { allMarketMatch } = useSelector((state) => state.SBglobalPersist);
	const { isExchange } = useSelector((state) => state.SBgeneral);

	useEffect(() => {
		if (fixtureId) {
			dispatch(getAllMarkets({ fixtureId }));
		}
	}, [fixtureId]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<SportsBookContainer>
			<ExtraMarketContainer>
				<Grid className={`${!showSideNav ? 'sidebar-close' : ''}`}>
					<Box className="sports-custom-spacer  content-container container-lg ">
						<TopSportsBar />
						<Grid display="flex">
							<Grid className="extra-market-middle-section">
								<TopScoreSection allMarketMatch={allMarketMatch} />

								<Box
									sx={{ width: '100%', typography: 'body1' }}
									className="market-type-tabs"
								>
									<TabContext value={value} sx={{ minHeight: 'auto' }}>
										<Box>
											<TabList
												onChange={handleChange}
												aria-label="lab API tabs example"
												variant="scrollable"
												scrollButtons={false}
												sx={{ minHeight: 'auto' }}
												value={value}
											>
												<Tab
													key="1"
													label={
														<p className="market-name">
															{t('main')}{' '}
															{/* <span className="number-bg">{tab.number}</span> */}
														</p>
													}
													className="market-value"
													value="1"
												/>
												{TABS_DATA.map((tab) => (
													<Tab
														key={tab.value}
														label={
															<p className="market-name">
																{tab.label} <span className="number-bg">0</span>
															</p>
														}
														className="market-value"
														value={tab.value}
													/>
												))}
											</TabList>
										</Box>

										<TabPanel value="1" sx={{ padding: '1rem 0' }}>
											{!allMarketsloading && allMarkets?.length ? (
												allMarkets.map((market) => (
													<Accordion
														key={market.id}
														className="accordion"
														defaultExpanded
													>
														<AccordionSummary
															aria-controls={`${market.id}-content`}
															id={`${market.id}-header`}
															expandIcon={<ArrowDropDownIcon />}
															additionalIcon={
																<InfoIcon style={{ marginLeft: 'auto' }} />
															}
															className="accordion-header"
															sx={{ flexGrow: 'inherit' }}
														>
															<Typography className="extra-market--name">
																{getMarketName(
																	allMarketMatch?.eventParticipants?.[0]
																		.participant.name,
																	allMarketMatch?.eventParticipants?.[1]
																		.participant.name,
																	market,
																	'en'
																) || ''}
															</Typography>
														</AccordionSummary>
														<AccordionDetails className="accordion-detail">
															<List
																className={
																	isExchange
																		? 'accordion-detail-ul exchange-book-accordion'
																		: 'accordion-detail-ul'
																}
															>
																{market?.outcomes?.map((odds) => (
																	<ListItem
																		// className={
																		// 	market.outcomes.length % 2 === 0
																		// 		? 'accordion-detail-li total-accordion-detail-li'
																		// 		: 'accordion-detail-li'
																		// }
																		className={`accordion-detail-li
                                                                            ${
																																							market?.groupCount
																																								? market.groupCount ===
																																									2
																																									? 'total-accordion-detail-li'
																																									: ''
																																								: market
																																											.outcomes
																																											.length %
																																											2 ===
																																									  0
																																									? 'total-accordion-detail-li'
																																									: ''
																																						}
                                                                            `}
																	>
																		<Typography>
																			<Typography
																				variant="span"
																				className="more-market--team---name"
																			>
																				{outcomeFormatter(
																					odds,
																					allMarketMatch?.eventParticipants?.[0]
																						?.participant?.name,
																					allMarketMatch?.eventParticipants?.[1]
																						?.participant?.name,
																					market?.market?.providerId
																				)}
																			</Typography>
																			<Typography
																				variant="span"
																				className="odds-1"
																			>
																				<Odds
																					odds={odds}
																					market={market?.market}
																					match={allMarketMatch}
																				/>
																			</Typography>
																		</Typography>
																	</ListItem>
																))}
															</List>
														</AccordionDetails>
													</Accordion>
												))
											) : allMarketsloading ? (
												<SBLoader />
											) : (
												<NoDataFound title={t('noMarketsFound')} />
											)}
										</TabPanel>
									</TabContext>
								</Box>
							</Grid>

							<TrackerSection />
						</Grid>
					</Box>
				</Grid>
			</ExtraMarketContainer>
			<Grid className="betslipSection">
				<Betslip />
			</Grid>
		</SportsBookContainer>
	);
};

export default AllMarkets;
