import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Formik, ErrorMessage } from 'formik';
import Button from 'components/molecules/Button/index';
import { ProfileUserIcon } from 'components/ui-kit/Icons/iconComponents/ProfileUserIcon';
import { LOADER_HANDLER_TYPES, ROUTE_PATHS } from 'constants/index';
import InputField from 'components/molecules/Input/InputField';
import { FormGroup, FormControlLabel, Checkbox, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserInformation } from 'redux-thunk/thunk/user.thunk';
import Loader from 'components/ui-kit/Loader/index';
import { Buffer } from 'buffer';
import { changePasswordSchema } from './schema';

const ChangePassword = () => {
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { userDetailsUpdateLoading } = useSelector((state) => state.user);
	const handleChangePassword = async (formData) => {
		const tempFormData = { ...formData };
		if (tempFormData.password.length > 2) {
			tempFormData.password = Buffer.from(tempFormData.password).toString(
				'base64'
			);
			tempFormData.confirmPassword = Buffer.from(
				tempFormData.password
			).toString('base64');
		}
		dispatch(
			updateUserInformation({
				params: tempFormData,
			})
		);
	};

	const formik = useRef();
	const [isChecked, setIsChecked] = useState(false);
	return (
		<>
			<Box sx={{ mb: 2 }}>
				<FormGroup className="checkBox">
					<FormControlLabel
						style={{ color: 'white' }}
						control={
							<Checkbox
								checked={isChecked}
								onChange={(event) => setIsChecked(event.target.checked)}
							/>
						}
						label={t('wantToChangePass')}
					/>
				</FormGroup>
			</Box>
			{isChecked && (
				<div
					className={`tab-pane fade ${
						pathname === ROUTE_PATHS.PROFILE ? 'show active' : ''
					}`}
					id="v-pills-profile"
					role="tabpanel"
					aria-labelledby="v-pills-profile-tab"
				>
					<div className="profile-tab-content-wrap">
						<Formik
							enableReinitialize
							innerRef={formik}
							initialValues={{
								password: '',
								confirmPassword: '',
							}}
							validationSchema={changePasswordSchema(t)}
							validateOnMount
							validateOnBlur
							validateOnChange
							onSubmit={(formValues) => handleChangePassword(formValues)}
						>
							{({ handleBlur, handleChange }) => (
								<Form autoComplete="off">
									<div className="profile-heading mb-2">
										<h4 className="m-0">
											<ProfileUserIcon /> {t('Change Password')}
										</h4>
									</div>
									<div className="row signup-form-detail">
										<div className="form-group col-lg-6 col-sm-12">
											<InputField
												type="password"
												placeholder="Password"
												className="form-control"
												id="password"
												name="password"
												onChange={handleChange}
												onBlur={handleBlur}
												maxLength={50}
												minLength={8}
												haslabel="yes"
												required
												labeltext={t('profilePassword')}
											/>
											<ErrorMessage
												component="div"
												name="password"
												className="error-message"
											/>
										</div>
										<div className="form-group col-lg-6 col-sm-12">
											<InputField
												type="password"
												placeholder="Confirm Password"
												className="form-control"
												id="confirmPassword"
												name="confirmPassword"
												onChange={handleChange}
												onBlur={handleBlur}
												maxLength={50}
												minLength={8}
												haslabel="yes"
												required
												labeltext={t('Confirm Password')}
											/>
											<ErrorMessage
												component="div"
												name="confirmPassword"
												className=" error-message "
											/>
										</div>
									</div>

									<div className="auth-btn-wrap w-100 d-flex justify-content-end align-items-center">
										<Button
											type="submit"
											disabled={userDetailsUpdateLoading}
											className="btn btn-primary"
											style={{
												margin: '10px',
											}}
											buttonlabel={
												userDetailsUpdateLoading ? (
													<Loader variant={LOADER_HANDLER_TYPES.submit} />
												) : (
													<span>{t('update')}</span>
												)
											}
										/>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			)}
		</>
	);
};

export default React.memo(ChangePassword);
