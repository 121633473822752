import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material/index';
import { getSports } from 'SportsBook/BetBalancer/Store/thunk/sports.thunk';
import TopSportsBar from './TopSportsBar/index';
import MatchesContainer from './MatchesContainer/index';
// import FeaturedMatches from './FeaturedMatches/index';
import CountryFilter from '../CountryFilter/index';
import { SportsBookContainer } from './style';
import Banner from '../Banner';
import OddsType from './OddsType/index';
import Betslip from '../Betslip/index';

const SportEvents = () => {
	const dispatch = useDispatch();
	const { showSideNav } = useSelector((state) => state?.settings);

	useEffect(() => {
		dispatch(getSports());
	}, []);

	return (
		<SportsBookContainer>
			<section className={`${!showSideNav ? 'sidebar-close' : ''}`}>
				<div className="sports-custom-spacer">
					<TopSportsBar />

					<CountryFilter />

					<Banner />

					<MatchesContainer />

					<OddsType />

					{/* <FeaturedMatches /> */}
				</div>
				<Grid className="betslipSection">
					<Betslip />
				</Grid>
			</section>
		</SportsBookContainer>
	);
};

export default SportEvents;
