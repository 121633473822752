import React from 'react';
import { Box, Grid, Typography, Button, List, ListItem } from '@mui/material';
import { useSelector } from 'react-redux';
import useBetslip from '../hooks/useBetslip';
import { STAKE_AMOUNT } from '../betslip.config';

const BetslipBottom = () => {
	const { betCount, comboBetStake } = useSelector((state) => state.SBbetslip);
	const { handleComboStake, handleSingleStake } = useBetslip();

	const handleStake = (val) => {
		if (betCount > 1) {
			handleComboStake(val);
		} else {
			handleSingleStake(val);
		}
	};

	return (
		<Box className="betSlipBottomSection">
			<Grid className="oddsValue">
				<List display="flex" justifyContent="space-between" gap={1.5}>
					<ListItem>
						<Grid className="combo-bet">
							<input
								type="number"
								className="stackOddsInput"
								value={comboBetStake}
								onChange={(e) => handleStake(e.target.value)}
							/>
							<Typography className="currency-type">$</Typography>
						</Grid>
					</ListItem>
					{STAKE_AMOUNT.map((st) => (
						<ListItem>
							<Button
								className={comboBetStake === st.value ? 'active' : ''}
								onClick={() => handleStake(st.value)}
							>
								{st.label}
							</Button>
						</ListItem>
					))}
				</List>
			</Grid>
		</Box>
	);
};

export default BetslipBottom;
