import { createSlice } from '@reduxjs/toolkit';
import {
	depositAmountsRequest,
	depositNetellerAmountsRequest,
	depositCoinPaymentAmountsRequest,
} from 'redux-thunk/thunk/depositAmount.thunk';

const initialState = {
	depositsLoading: false,
	depositError: '',
};
const {
	actions: { setDepositData, setShowWalletPopup },
	reducer,
} = createSlice({
	name: 'depositPayment',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(depositAmountsRequest.pending, (state) => ({
				...state,
				depositsLoading: true,
			}))
			.addCase(depositAmountsRequest.fulfilled, (state) => ({
				...state,
				depositsLoading: false,
			}))
			.addCase(depositAmountsRequest.rejected, (state, action) => ({
				...state,
				depositsLoading: false,
				depositError: action.payload,
			}))
			.addCase(depositNetellerAmountsRequest.pending, (state) => ({
				...state,
				depositsLoading: true,
			}))
			.addCase(depositNetellerAmountsRequest.fulfilled, (state) => ({
				...state,
				depositsLoading: false,
			}))
			.addCase(depositNetellerAmountsRequest.rejected, (state, action) => ({
				...state,
				depositsLoading: false,
				depositError: action.payload,
			}))
			.addCase(depositCoinPaymentAmountsRequest.pending, (state) => ({
				...state,
				depositsLoading: true,
			}))
			.addCase(depositCoinPaymentAmountsRequest.fulfilled, (state) => ({
				...state,
				depositsLoading: false,
			}))
			.addCase(depositCoinPaymentAmountsRequest.rejected, (state, action) => ({
				...state,
				depositsLoading: false,
				depositError: action.payload,
			}));
	},
});

export default reducer;
export { setDepositData, setShowWalletPopup };
