import { createSlice } from '@reduxjs/toolkit';
// import { MATCHES } from 'SportsBook/BetBalancer/Helper/constants';
import { getMarketsByOrder } from 'SportsBook/BetBalancer/Helper/formatterFunctions';
import { getAllMarkets } from '../thunk/allMarket.thunk';

const initialState = {
	allMarkets: [],
	allMarketsloading: false,
	error: null,
};
// SB means SportsBook
const SBAllMarkets = createSlice({
	name: 'SBallMarkets',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllMarkets.pending, (state) => ({
				...state,
				allMarketsloading: true,
			}))
			.addCase(getAllMarkets.fulfilled, (state, action) => ({
				...state,
				allMarkets: getMarketsByOrder(action.payload),
				allMarketsloading: false,
			}))
			.addCase(getAllMarkets.rejected, (state, action) => ({
				...state,
				error: action.payload,
				allMarketsloading: false,
			}));
	},
});

export default SBAllMarkets.reducer;
