import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index';
import axiosInstance from 'network/apis/index';
import { microServices } from 'network/apis/microservice/index';
// import { SuccessMessage } from 'network/messages/successMessages';

/**
 * Service to update user info
//  * @param {object} data - object contains name to be updated for user
 */
export const depositAmountServiceRequest = (data) =>
	axiosInstance(METHOD_TYPES.post, '/payment/skrill/deposit', data, {
		server: microServices.SERVICE_URL_1,
		loader: LOADER_HANDLER_TYPES.submit,
	});

export const depositNetellerAmountServiceRequest = (data) =>
	axiosInstance(METHOD_TYPES.post, '/payment/neteller/deposit', data, {
		server: microServices.SERVICE_URL_1,
		loader: LOADER_HANDLER_TYPES.submit,
	});
export const depositCoinPaymentAmountServiceRequest = (data) =>
	axiosInstance(
		METHOD_TYPES.post,
		'/payment/coin-payment/generate-address',
		data,
		{
			server: microServices.SERVICE_URL_1,
			loader: LOADER_HANDLER_TYPES.submit,
		}
	);
