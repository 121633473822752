import React from "react";
import { Grid, Skeleton } from "@mui/material";
import { PropTypes } from 'prop-types';

const ListSkeleton = ({ skeletonRow }) => {
    const skeletons = Array(skeletonRow).fill(null);
    const innerSkeleton = [...Array(1).keys()];

    return (
        <>
            {skeletons.map(() => (
                <Grid container spacing={2} mt={0}>
                    {innerSkeleton.map((index) => (
                        <Grid
                            key={index}
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            sx={{ display: 'flex' }}
                        >
                            <Grid xs={4} md={4} lg={4}>
                                <Skeleton
                                    sx={{ bgcolor: '#757575', height: 10, margin: '10px 0' }}
                                    variant="rounded"
                                />
                                <Grid xs={12} md={12} lg={12} sx={{ display: 'flex' }}>
                                    <Grid xs={3} md={3} lg={3}>
                                        <Skeleton
                                            sx={{ bgcolor: '#757575', height: 10, margin: '10px 2px' }}
                                            variant="rounded"
                                        />
                                        <Skeleton
                                            sx={{ bgcolor: '#757575', height: 10, margin: '10px 2px' }}
                                            variant="rounded"
                                        />
                                    </Grid>
                                    <Grid xs={6} md={6} lg={6}>
                                        <Skeleton
                                            sx={{ bgcolor: '#757575', height: 10, margin: '10px 2px' }}
                                            variant="rounded"
                                        />
                                        <Skeleton
                                            sx={{ bgcolor: '#757575', height: 10, margin: '10px 2px' }}
                                            variant="rounded"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={1} md={1} lg={1} />
                            <Grid xs={7} md={7} lg={7} sx={{ display: 'flex' }}>
                                {Array(6).fill(null).map((_, i) => (
                                    <Grid xs={1.5} md={1.5} lg={1.5} sx={{ margin: `${(i + 1)%3 === 0 ? '0 10px 0 5px' : '0 5px'}` }}>
                                        <Skeleton
                                            sx={{ bgcolor: '#757575', height: 50, margin: '10px 0' }}
                                            variant="rounded"
                                        />
                                    </Grid>
                                ))}
                                <Grid xs={1} md={1} lg={1} sx={{ margin: '0 20px' }}>
                                    <Skeleton
                                        sx={{ bgcolor: '#757575', height: 50, margin: '10px 0' }}
                                        variant="rounded"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            ))}
        </>
    );
}

export default ListSkeleton;

ListSkeleton.defaultProps = {
    skeletonRow: 10,
    // isLive: false,
};

ListSkeleton.propTypes = {
    skeletonRow: PropTypes.number,
    // isLive: PropTypes.bool,
};
