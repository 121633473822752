/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setLocation } from 'redux-thunk/redux/slices/general.slice';

const NavigateToLocation = () => {
	const navigateTo = useSelector((state) => state.general.navigateTo);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		if (navigateTo) {
			navigate(navigateTo);
			dispatch(setLocation(''));
		}
	}, [navigateTo]);

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <></>;
};

export default NavigateToLocation;
