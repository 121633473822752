import { createSlice } from '@reduxjs/toolkit';
// import { MATCHES } from 'SportsBook/BetBalancer/Helper/constants';
import { getCashoutAmount, acceptCashout } from '../thunk/cashout.thunk';

const initialState = {
	cashout: '',
	cashoutLoading: false,
	cashoutSuccess: '',
	cashoutError: null,
};
// SB means SportsBook
const SBcashout = createSlice({
	name: 'SBcashout',
	initialState,
	reducers: {
		resetCashout: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(getCashoutAmount.pending, (state) => ({
				...state,
				cashoutLoading: true,
			}))
			.addCase(getCashoutAmount.fulfilled, (state, action) => ({
				...state,
				cashout: action.payload,
				cashoutLoading: false,
			}))
			.addCase(getCashoutAmount.rejected, (state, action) => ({
				...state,
				cashoutError: action.payload,
				cashoutLoading: false,
			}))
			.addCase(acceptCashout.pending, (state) => ({
				...state,
				cashoutLoading: true,
			}))
			.addCase(acceptCashout.fulfilled, (state) => ({
				...state,
				cashoutLoading: false,
			}))
			.addCase(acceptCashout.rejected, (state, action) => ({
				...state,
				cashoutError: action.payload,
				cashoutLoading: false,
			}));
	},
});
export const { resetCashout } = SBcashout.actions;

export default SBcashout.reducer;
