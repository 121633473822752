import React from 'react';
import { CountryViewBarContainer } from './style';
import PreMatches from '../PreMatches';

const CountryViewBar = () => (
	<CountryViewBarContainer>
		<PreMatches />
	</CountryViewBarContainer>
);
CountryViewBar.defaultProps = {
	// Live_matches: '',
};

CountryViewBar.propTypes = {
	// text: PropTypes.string.isRequired,
	// src: PropTypes.string.isRequired,
	// Live_matches: PropTypes.string,
};

export default CountryViewBar;
