export const ODDS_DATA = {
  0: { en: 0, pt: 0, es: 0 },
  1: { en: 1, pt: 1, es: 1 },
  2: { en: 2, pt: 2, es: 2 },
  3: { en: 3, pt: 3, es: 3 },
  4: { en: 4, pt: 4, es: 4 },
  5: { en: 5, pt: 5, es: 5 },
  6: { en: 6, pt: 6, es: 6 },
  7: { en: 7, pt: 7, es: 7 },
  8: { en: 8, pt: 8, es: 8 },
  9: { en: 9, pt: 9, es: 9 },
  10: { en: 10, pt: 10, es: 10 },
  11: { en: 11, pt: 11, es: 11 },
  "{$competitor1}": {
    en: "{$competitor1}",
    pt: "{$competitor1}",
    es: "{$competitor1}"
  },
  draw: { en: "draw", pt: "empate", es: "empate" },
  "{$competitor2}": {
    en: "{$competitor2}",
    pt: "{$competitor2}",
    es: "{$competitor2}"
  },
  "{$competitor1} regular time": {
    en: "{$competitor1} regular time",
    pt: "{$competitor1} tempo regular",
    es: "{$competitor1} tiempo regular"
  },
  "{$competitor2} regular time": {
    en: "{$competitor2} regular time",
    pt: "{$competitor2} tempo regular",
    es: "{$competitor2} tiempo regular"
  },
  "{$competitor1} overtime": {
    en: "{$competitor1} overtime",
    pt: "{$competitor1} prolongamento",
    es: "{$competitor1} prórroga"
  },
  "{$competitor2} overtime": {
    en: "{$competitor2} overtime",
    pt: "{$competitor2} prolongamento",
    es: "{$competitor2} prórroga"
  },
  "{$competitor1} penalties": {
    en: "{$competitor1} penalties",
    pt: "{$competitor1} penalidade",
    es: "{$competitor1} penales"
  },
  "{$competitor2} penalties": {
    en: "{$competitor2} penalties",
    pt: "{$competitor2} penalidade",
    es: "{$competitor2} penales"
  },
  none: { en: "none", pt: "nenhum", es: "ninguno" },
  "{$competitor1} or draw": {
    en: "{$competitor1} or draw",
    pt: "{$competitor1} ou empate",
    es: "{$competitor1} o empate"
  },
  "{$competitor1} or {$competitor2}": {
    en: "{$competitor1} or {$competitor2}",
    pt: "{$competitor1} ou {$competitor2}",
    es: "{$competitor1} o {$competitor2}"
  },
  "draw or {$competitor2}": {
    en: "draw or {$competitor2}",
    pt: "empate ou {$competitor2}",
    es: "empate o {$competitor2}"
  },
  "{$competitor1} ({hcp})": {
    en: "{$competitor1} ({hcp})",
    pt: "{$competitor1} ({hcp})",
    es: "{$competitor1} ({hcp})"
  },
  "draw ({hcp})": {
    en: "draw ({hcp})",
    pt: "empate ({hcp})",
    es: "empate ({hcp})"
  },
  "{$competitor2} ({hcp})": {
    en: "{$competitor2} ({hcp})",
    pt: "{$competitor2} ({hcp})",
    es: "{$competitor2} ({hcp})"
  },
  "{$competitor1} by 1": {
    en: "{$competitor1} by 1",
    pt: "{$competitor1} by 1",
    es: "{$competitor1} por 1"
  },
  "{$competitor1} by 2": {
    en: "{$competitor1} by 2",
    pt: "{$competitor1} by 2",
    es: "{$competitor1} por 2"
  },
  "{$competitor1} by 3+": {
    en: "{$competitor1} by 3+",
    pt: "{$competitor1} by 3+",
    es: "{$competitor1} por 3+"
  },
  "{$competitor2} by 1": {
    en: "{$competitor2} by 1",
    pt: "{$competitor2} by 1",
    es: "{$competitor2} por 1"
  },
  "{$competitor2} by 2": {
    en: "{$competitor2} by 2",
    pt: "{$competitor2} by 2",
    es: "{$competitor2} por 2"
  },
  "{$competitor2} by 3+": {
    en: "{$competitor2} by 3+",
    pt: "{$competitor2} by 3+",
    es: "{$competitor2} por 3+"
  },
  "{$competitor1} by 11+": {
    en: "{$competitor1} by 11+",
    pt: "{$competitor1} by 11+",
    es: "{$competitor1} por 11+"
  },
  "{$competitor1} by 6-10": {
    en: "{$competitor1} by 6-10",
    pt: "{$competitor1} by 6-10",
    es: "{$competitor1} por 6-10"
  },
  "{$competitor1} by 1-5": {
    en: "{$competitor1} by 1-5",
    pt: "{$competitor1} by 1-5",
    es: "{$competitor1} por 1-5"
  },
  "{$competitor2} by 1-5": {
    en: "{$competitor2} by 1-5",
    pt: "{$competitor2} by 1-5",
    es: "{$competitor2} por 1-5"
  },
  "{$competitor2} by 6-10": {
    en: "{$competitor2} by 6-10",
    pt: "{$competitor2} by 6-10",
    es: "{$competitor2} por 6-10"
  },
  "{$competitor2} by 11+": {
    en: "{$competitor2} by 11+",
    pt: "{$competitor2} by 11+",
    es: "{$competitor2} por 11+"
  },
  "{$competitor1} by 15+": {
    en: "{$competitor1} by 15+",
    pt: "{$competitor1} by 15+",
    es: "{$competitor1} por 15+"
  },
  "{$competitor1} by 8-14": {
    en: "{$competitor1} by 8-14",
    pt: "{$competitor1} by 8-14",
    es: "{$competitor1} por 8-14"
  },
  "{$competitor1} by 1-7": {
    en: "{$competitor1} by 1-7",
    pt: "{$competitor1} by 1-7",
    es: "{$competitor1} por 1-7"
  },
  "{$competitor2} by 1-7": {
    en: "{$competitor2} by 1-7",
    pt: "{$competitor2} by 1-7",
    es: "{$competitor2} por 1-7"
  },
  "{$competitor2} by 8-14": {
    en: "{$competitor2} by 8-14",
    pt: "{$competitor2} by 8-14",
    es: "{$competitor2} por 8-14"
  },
  "{$competitor2} by 15+": {
    en: "{$competitor2} by 15+",
    pt: "{$competitor2} by 15+",
    es: "{$competitor2} por 15+"
  },
  "{$competitor1} ({+hcp})": {
    en: "{$competitor1} ({+hcp})",
    pt: "{$competitor1} ({+hcp})",
    es: "{$competitor1} ({+hcp})"
  },
  "{$competitor2} ({-hcp})": {
    en: "{$competitor2} ({-hcp})",
    pt: "{$competitor2} ({-hcp})",
    es: "{$competitor2} ({-hcp})"
  },
  "over {total}": {
    en: "over {total}",
    pt: "mais de {total}",
    es: "más de {total}"
  },
  "under {total}": {
    en: "under {total}",
    pt: "menos de {total}",
    es: "menos de {total}"
  },
  "6+": { en: "6+", pt: "6+", es: "6+" },
  "9+": { en: "9+", pt: "9+", es: "9+" },
  "5+": { en: "5+", pt: "5+", es: "5+" },
  "3+": { en: "3+", pt: "3+", es: "3+" },
  "0-1": { en: "0-1", pt: "0-1", es: "0-1" },
  "2-3": { en: "2-3", pt: "2-3", es: "2-3" },
  "4-5": { en: "4-5", pt: "4-5", es: "4-5" },
  "4-6": { en: "4-6", pt: "4-6", es: "4-6" },
  "7+": { en: "7+", pt: "7+", es: "7+" },
  odd: { en: "odd", pt: "impar", es: "impar" },
  even: { en: "even", pt: "par", es: "par" },
  yes: { en: "yes", pt: "sim", es: "sí" },
  no: { en: "no", pt: "não", es: "no" },
  "only {$competitor1}": {
    en: "only {$competitor1}",
    pt: "apenas {$competitor1}",
    es: "solo {$competitor1}"
  },
  "only {$competitor2}": {
    en: "only {$competitor2}",
    pt: "apenas {$competitor2}",
    es: "solo {$competitor2}"
  },
  "both teams": {
    en: "both teams",
    pt: "ambas as equipas",
    es: "ambos equipos"
  },
  "{$competitor1} &amp; yes": {
    en: "{$competitor1} &amp; yes",
    pt: "{$competitor1} e sim",
    es: "{$competitor1} y sí"
  },
  "{$competitor1} &amp; no": {
    en: "{$competitor1} &amp; no",
    pt: "{$competitor1} e não",
    es: "{$competitor1} y no"
  },
  "draw &amp; yes": {
    en: "draw &amp; yes",
    pt: "empate e sim",
    es: "empate y sí"
  },
  "draw &amp; no": {
    en: "draw &amp; no",
    pt: "empate e não",
    es: "empate y no"
  },
  "{$competitor2} &amp; yes": {
    en: "{$competitor2} &amp; yes",
    pt: "{$competitor2} e sim",
    es: "{$competitor2} y sí"
  },
  "{$competitor2} &amp; no": {
    en: "{$competitor2} &amp; no",
    pt: "{$competitor2} e não",
    es: "{$competitor2} y no"
  },
  "over {total} &amp; yes": {
    en: "over {total} &amp; yes",
    pt: "mais de {total} e sim",
    es: "más de {total} y sí"
  },
  "under {total} &amp; yes": {
    en: "under {total} &amp; yes",
    pt: "menos de {total} e sim",
    es: "menos de {total} y sí"
  },
  "over {total} &amp; no": {
    en: "over {total} &amp; no",
    pt: "mais de {total} e não",
    es: "más de {total} y no"
  },
  "under {total} &amp; no": {
    en: "under {total} &amp; no",
    pt: "menos de {total} e não",
    es: "menos de {total} y no"
  },
  "{$competitor1} &amp; under {total}": {
    en: "{$competitor1} &amp; under {total}",
    pt: "{$competitor1} e menos de {total}",
    es: "{$competitor1} y menos de {total}"
  },
  "{$competitor1} &amp; over {total}": {
    en: "{$competitor1} &amp; over {total}",
    pt: "{$competitor1} e mais de {total}",
    es: "{$competitor1} y más de {total}"
  },
  "draw &amp; under {total}": {
    en: "draw &amp; under {total}",
    pt: "empate e menos de {total}",
    es: "empate y menos de {total}"
  },
  "draw &amp; over {total}": {
    en: "draw &amp; over {total}",
    pt: "empate e mais de {total}",
    es: "empate y más de {total}"
  },
  "{$competitor2} &amp; under {total}": {
    en: "{$competitor2} &amp; under {total}",
    pt: "{$competitor2} e menos de {total}",
    es: "{$competitor2} &amp; menos de {total}"
  },
  "{$competitor2} &amp; over {total}": {
    en: "{$competitor2} &amp; over {total}",
    pt: "{$competitor2} e mais de {total}",
    es: "{$competitor2} &amp; más de {total}"
  },
  "no goal": { en: "no goal", pt: "sem golo", es: "sin gol" },
  "0:0": { en: "0:0", pt: "0:0", es: "0:0" },
  "1:0": { en: "1:0", pt: "1:0", es: "1:0" },
  "2:0": { en: "2:0", pt: "2:0", es: "2:0" },
  "3:0": { en: "3:0", pt: "3:0", es: "3:0" },
  "4:0": { en: "4:0", pt: "4:0", es: "4:0" },
  "5:0": { en: "5:0", pt: "5:0", es: "5:0" },
  "6:0": { en: "6:0", pt: "6:0", es: "6:0" },
  "0:1": { en: "0:1", pt: "0:1", es: "0:1" },
  "1:1": { en: "1:1", pt: "1:1", es: "1:1" },
  "2:1": { en: "2:1", pt: "2:1", es: "2:1" },
  "3:1": { en: "3:1", pt: "3:1", es: "3:1" },
  "4:1": { en: "4:1", pt: "4:1", es: "4:1" },
  "5:1": { en: "5:1", pt: "5:1", es: "5:1" },
  "0:2": { en: "0:2", pt: "0:2", es: "0:2" },
  "1:2": { en: "1:2", pt: "1:2", es: "1:2" },
  "2:2": { en: "2:2", pt: "2:2", es: "2:2" },
  "3:2": { en: "3:2", pt: "3:2", es: "3:2" },
  "4:2": { en: "4:2", pt: "4:2", es: "4:2" },
  "0:3": { en: "0:3", pt: "0:3", es: "0:3" },
  "1:3": { en: "1:3", pt: "1:3", es: "1:3" },
  "2:3": { en: "2:3", pt: "2:3", es: "2:3" },
  "3:3": { en: "3:3", pt: "3:3", es: "3:3" },
  "0:4": { en: "0:4", pt: "0:4", es: "0:4" },
  "1:4": { en: "1:4", pt: "1:4", es: "1:4" },
  "2:4": { en: "2:4", pt: "2:4", es: "2:4" },
  "0:5": { en: "0:5", pt: "0:5", es: "0:5" },
  "1:5": { en: "1:5", pt: "1:5", es: "1:5" },
  "0:6": { en: "0:6", pt: "0:6", es: "0:6" },
  "4:3": { en: "4:3", pt: "4:3", es: "4:3" },
  "3:4": { en: "3:4", pt: "3:4", es: "3:4" },
  "4:4": { en: "4:4", pt: "4:4", es: "4:4" },
  other: { en: "other", pt: "other", es: "otro" },
  "0:0 0:0": { en: "0:0 0:0", pt: "0:0 0:0", es: "0:0 0:0" },
  "0:0 0:1": { en: "0:0 0:1", pt: "0:0 0:1", es: "0:0 0:1" },
  "0:0 0:2": { en: "0:0 0:2", pt: "0:0 0:2", es: "0:0 0:2" },
  "0:0 0:3": { en: "0:0 0:3", pt: "0:0 0:3", es: "0:0 0:3" },
  "0:0 1:0": { en: "0:0 1:0", pt: "0:0 1:0", es: "0:0 1:0" },
  "0:0 1:1": { en: "0:0 1:1", pt: "0:0 1:1", es: "0:0 1:1" },
  "0:0 1:2": { en: "0:0 1:2", pt: "0:0 1:2", es: "0:0 1:2" },
  "0:0 2:0": { en: "0:0 2:0", pt: "0:0 2:0", es: "0:0 2:0" },
  "0:0 2:1": { en: "0:0 2:1", pt: "0:0 2:1", es: "0:0 2:1" },
  "0:0 3:0": { en: "0:0 3:0", pt: "0:0 3:0", es: "0:0 3:0" },
  "0:0 4+": { en: "0:0 4+", pt: "0:0 4+", es: "0:0 4+" },
  "0:1 0:1": { en: "0:1 0:1", pt: "0:1 0:1", es: "0:1 0:1" },
  "0:1 0:2": { en: "0:1 0:2", pt: "0:1 0:2", es: "0:1 0:2" },
  "0:1 0:3": { en: "0:1 0:3", pt: "0:1 0:3", es: "0:1 0:3" },
  "0:1 1:1": { en: "0:1 1:1", pt: "0:1 1:1", es: "0:1 1:1" },
  "0:1 1:2": { en: "0:1 1:2", pt: "0:1 1:2", es: "0:1 1:2" },
  "0:1 2:1": { en: "0:1 2:1", pt: "0:1 2:1", es: "0:1 2:1" },
  "0:1 4+": { en: "0:1 4+", pt: "0:1 4+", es: "0:1 4+" },
  "0:2 0:2": { en: "0:2 0:2", pt: "0:2 0:2", es: "0:2 0:2" },
  "0:2 0:3": { en: "0:2 0:3", pt: "0:2 0:3", es: "0:2 0:3" },
  "0:2 1:2": { en: "0:2 1:2", pt: "0:2 1:2", es: "0:2 1:2" },
  "0:2 4+": { en: "0:2 4+", pt: "0:2 4+", es: "0:2 4+" },
  "0:3 0:3": { en: "0:3 0:3", pt: "0:3 0:3", es: "0:3 0:3" },
  "0:3 4+": { en: "0:3 4+", pt: "0:3 4+", es: "0:3 4+" },
  "1:0 1:0": { en: "1:0 1:0", pt: "1:0 1:0", es: "1:0 1:0" },
  "1:0 1:1": { en: "1:0 1:1", pt: "1:0 1:1", es: "1:0 1:1" },
  "1:0 1:2": { en: "1:0 1:2", pt: "1:0 1:2", es: "1:0 1:2" },
  "1:0 2:0": { en: "1:0 2:0", pt: "1:0 2:0", es: "1:0 2:0" },
  "1:0 2:1": { en: "1:0 2:1", pt: "1:0 2:1", es: "1:0 2:1" },
  "1:0 3:0": { en: "1:0 3:0", pt: "1:0 3:0", es: "1:0 3:0" },
  "1:0 4+": { en: "1:0 4+", pt: "1:0 4+", es: "1:0 4+" },
  "1:1 1:1": { en: "1:1 1:1", pt: "1:1 1:1", es: "1:1 1:1" },
  "1:1 1:2": { en: "1:1 1:2", pt: "1:1 1:2", es: "1:1 1:2" },
  "1:1 2:1": { en: "1:1 2:1", pt: "1:1 2:1", es: "1:1 2:1" },
  "1:1 4+": { en: "1:1 4+", pt: "1:1 4+", es: "1:1 4+" },
  "1:2 1:2": { en: "1:2 1:2", pt: "1:2 1:2", es: "1:2 1:2" },
  "1:2 4+": { en: "1:2 4+", pt: "1:2 4+", es: "1:2 4+" },
  "2:0 2:0": { en: "2:0 2:0", pt: "2:0 2:0", es: "2:0 2:0" },
  "2:0 2:1": { en: "2:0 2:1", pt: "2:0 2:1", es: "2:0 2:1" },
  "2:0 3:0": { en: "2:0 3:0", pt: "2:0 3:0", es: "2:0 3:0" },
  "2:0 4+": { en: "2:0 4+", pt: "2:0 4+", es: "2:0 4+" },
  "2:1 2:1": { en: "2:1 2:1", pt: "2:1 2:1", es: "2:1 2:1" },
  "2:1 4+": { en: "2:1 4+", pt: "2:1 4+", es: "2:1 4+" },
  "3:0 3:0": { en: "3:0 3:0", pt: "3:0 3:0", es: "3:0 3:0" },
  "3:0 4+": { en: "3:0 4+", pt: "3:0 4+", es: "3:0 4+" },
  "4+ 4+": { en: "4+ 4+", pt: "4+ 4+", es: "4+ 4+" },
  "{$competitor1}/{$competitor1}": {
    en: "{$competitor1}/{$competitor1}",
    pt: "{$competitor1}/{$competitor1}",
    es: "{$competitor1}/{$competitor1}"
  },
  "{$competitor1}/draw": {
    en: "{$competitor1}/draw",
    pt: "{$competitor1}/empate",
    es: "{$competitor1}/empate"
  },
  "{$competitor1}/{$competitor2}": {
    en: "{$competitor1}/{$competitor2}",
    pt: "{$competitor1}/{$competitor2}",
    es: "{$competitor1}/{$competitor2}"
  },
  "draw/{$competitor1}": {
    en: "draw/{$competitor1}",
    pt: "empate/{$competitor1}",
    es: "empate/{$competitor1}"
  },
  "draw/draw": { en: "draw/draw", pt: "empate/empate", es: "empate/empate" },
  "draw/{$competitor2}": {
    en: "draw/{$competitor2}",
    pt: "empate/{$competitor2}",
    es: "empate/{$competitor2}"
  },
  "{$competitor2}/{$competitor1}": {
    en: "{$competitor2}/{$competitor1}",
    pt: "{$competitor2}/{$competitor1}",
    es: "{$competitor2}/{$competitor1}"
  },
  "{$competitor2}/draw": {
    en: "{$competitor2}/draw",
    pt: "{$competitor2}/empate",
    es: "{$competitor2}/empate"
  },
  "{$competitor2}/{$competitor2}": {
    en: "{$competitor2}/{$competitor2}",
    pt: "{$competitor2}/{$competitor2}",
    es: "{$competitor2}/{$competitor2}"
  },
  "1st half": { en: "1st half", pt: "1ª parte", es: "1º mitad" },
  "2nd half": { en: "2nd half", pt: "2ª parte", es: "2º mitad" },
  equal: { en: "equal", pt: "igual", es: "igual" },
  "no/no": { en: "no/no", pt: "não/não", es: "no/no" },
  "yes/no": { en: "yes/no", pt: "sim/não", es: "si/no" },
  "yes/yes": { en: "yes/yes", pt: "sim/sim", es: "sí/sí" },
  "no/yes": { en: "no/yes", pt: "não/sim", es: "no/sí" },
  "2+": { en: "2+", pt: "2+", es: "2+" },
  "1-15": { en: "1-15", pt: "1-15", es: "1-15" },
  "16-30": { en: "16-30", pt: "16-30", es: "16-30" },
  "31-45": { en: "31-45", pt: "31-45", es: "31-45" },
  "46-60": { en: "46-60", pt: "46-60", es: "46-60" },
  "61-75": { en: "61-75", pt: "61-75", es: "61-75" },
  "76-90": { en: "76-90", pt: "76-90", es: "76-90" },
  "1-10": { en: "1-10", pt: "1-10", es: "1-10" },
  "11-20": { en: "11-20", pt: "11-20", es: "11-20" },
  "21-30": { en: "21-30", pt: "21-30", es: "21-30" },
  "31-40": { en: "31-40", pt: "31-40", es: "31-40" },
  "41-50": { en: "41-50", pt: "41-50", es: "41-50" },
  "51-60": { en: "51-60", pt: "51-60", es: "51-60" },
  "61-70": { en: "61-70", pt: "61-70", es: "61-70" },
  "71-80": { en: "71-80", pt: "71-80", es: "71-80" },
  "81-90": { en: "81-90", pt: "81-90", es: "81-90" },
  "0-3": { en: "0-3", pt: "0-3", es: "0-3" },
  "12+": { en: "12+", pt: "12+", es: "12+" },
  "4+": { en: "4+", pt: "4+", es: "4+" },
  "0-30": { en: "0-30", pt: "0-30", es: "0-30" },
  "76+": { en: "76+", pt: "76+", es: "76+" },
  "0-10": { en: "0-10", pt: "0-10", es: "0-10" },
  "11-25": { en: "11-25", pt: "11-25", es: "11-25" },
  "26-40": { en: "26-40", pt: "26-40", es: "26-40" },
  "41+": { en: "41+", pt: "41+", es: "41+" },
  "0-8": { en: "0-8", pt: "0-8", es: "0-8" },
  "9-11": { en: "9-11", pt: "9-11", es: "9-11" },
  "0-2": { en: "0-2", pt: "0-2", es: "0-2" },
  "3-4": { en: "3-4", pt: "3-4", es: "3-4" },
  "5-6": { en: "5-6", pt: "5-6", es: "5-6" },
  "0-4": { en: "0-4", pt: "0-4", es: "0-4" },
  "{$competitor1} goal &amp; {$competitor1}": {
    en: "{$competitor1} goal &amp; {$competitor1}",
    pt: "{$competitor1} golo e {$competitor1}",
    es: "{$competitor1} gol y {$competitor1}"
  },
  "{$competitor1} goal &amp; draw": {
    en: "{$competitor1} goal &amp; draw",
    pt: "{$competitor1} golo e empate",
    es: "{$competitor1} gol y empate"
  },
  "{$competitor1} goal &amp; {$competitor2}": {
    en: "{$competitor1} goal &amp; {$competitor2}",
    pt: "{$competitor1} golo e {$competitor2}",
    es: "{$competitor1} gol y {$competitor2}"
  },
  "{$competitor2} goal &amp; {$competitor1}": {
    en: "{$competitor2} goal &amp; {$competitor1}",
    pt: "{$competitor2} golo e {$competitor1}",
    es: "{$competitor2} gol y {$competitor1}"
  },
  "{$competitor2} goal &amp; draw": {
    en: "{$competitor2} goal &amp; draw",
    pt: "{$competitor2} golo e empate",
    es: "{$competitor2} gol y empate"
  },
  "{$competitor2} goal &amp; {$competitor2}": {
    en: "{$competitor2} goal &amp; {$competitor2}",
    pt: "{$competitor2} golo e {$competitor2}",
    es: "{$competitor2} gol y {$competitor2}"
  },
  "5:2": { en: "5:2", pt: "5:2", es: "5:2" },
  "5:3": { en: "5:3", pt: "5:3", es: "5:3" },
  "5:4": { en: "5:4", pt: "5:4", es: "5:4" },
  "4:5": { en: "4:5", pt: "4:5", es: "4:5" },
  "3:5": { en: "3:5", pt: "3:5", es: "3:5" },
  "2:5": { en: "2:5", pt: "2:5", es: "2:5" },
  "6:1": { en: "6:1", pt: "6:1", es: "6:1" },
  "6:2": { en: "6:2", pt: "6:2", es: "6:2" },
  "6:3": { en: "6:3", pt: "6:3", es: "6:3" },
  "6:4": { en: "6:4", pt: "6:4", es: "6:4" },
  "6:5": { en: "6:5", pt: "6:5", es: "6:5" },
  "5:6": { en: "5:6", pt: "5:6", es: "5:6" },
  "4:6": { en: "4:6", pt: "4:6", es: "4:6" },
  "3:6": { en: "3:6", pt: "3:6", es: "3:6" },
  "2:6": { en: "2:6", pt: "2:6", es: "2:6" },
  "1:6": { en: "1:6", pt: "1:6", es: "1:6" },
  "0:7": { en: "0:7", pt: "0:7", es: "0:7" },
  "7:0": { en: "7:0", pt: "7:0", es: "7:0" },
  "1.5:0.5": { en: "1.5:0.5", pt: "1.5:0.5", es: "1.5:0.5" },
  "0.5:1.5": { en: "0.5:1.5", pt: "0.5:1.5", es: "0.5:1.5" },
  "{$competitor1}/draw &amp; yes": {
    en: "{$competitor1}/draw &amp; yes",
    pt: "{$competitor1}/empate e sim",
    es: "{$competitor1}/empate y sí"
  },
  "{$competitor1}/draw &amp; no": {
    en: "{$competitor1}/draw &amp; no",
    pt: "{$competitor1}/empate e não",
    es: "{$competitor1}/empate y no"
  },
  "{$competitor1}/{$competitor2} &amp; yes": {
    en: "{$competitor1}/{$competitor2} &amp; yes",
    pt: "{$competitor1}/{$competitor2} e sim",
    es: "{$competitor1}/{$competitor2} y sí"
  },
  "{$competitor1}/{$competitor2} &amp; no": {
    en: "{$competitor1}/{$competitor2} &amp; no",
    pt: "{$competitor1}/{$competitor2} e não",
    es: "{$competitor1}/{$competitor2} y no"
  },
  "draw/{$competitor2} &amp; yes": {
    en: "draw/{$competitor2} &amp; yes",
    pt: "empate/{$competitor2} e sim",
    es: "empate/{$competitor2} y sí"
  },
  "draw/{$competitor2} &amp; no": {
    en: "draw/{$competitor2} &amp; no",
    pt: "empate/{$competitor2} e não",
    es: "empate/{$competitor2} y no"
  },
  "{$competitor1}/draw &amp; under {total}": {
    en: "{$competitor1}/draw &amp; under {total}",
    pt: "{$competitor1}/empate e menos de {total}",
    es: "{$competitor1}/empate y menos de {total}"
  },
  "{$competitor1}/{$competitor2} &amp; under {total}": {
    en: "{$competitor1}/{$competitor2} &amp; under {total}",
    pt: "{$competitor1}/{$competitor2} e menos de {total}",
    es: "{$competitor1}/{$competitor2} y menos de {total}"
  },
  "draw/{$competitor2} &amp; under {total}": {
    en: "draw/{$competitor2} &amp; under {total}",
    pt: "empate/{$competitor2} e menos de {total}",
    es: "empate/{$competitor2} y menos de {total}"
  },
  "{$competitor1}/draw &amp; over {total}": {
    en: "{$competitor1}/draw &amp; over {total}",
    pt: "{$competitor1}/empate e mais de {total}",
    es: "{$competitor1}/empate y más de {total}"
  },
  "{$competitor1}/{$competitor2} &amp; over {total}": {
    en: "{$competitor1}/{$competitor2} &amp; over {total}",
    pt: "{$competitor1}/{$competitor2} e mais de {total}",
    es: "{$competitor1}/{$competitor2} y más de {total}"
  },
  "draw/{$competitor2} &amp; over {total}": {
    en: "draw/{$competitor2} &amp; over {total}",
    pt: "empate/{$competitor2} e mais de {total}",
    es: "empate/{$competitor2} y más de {total}"
  },
  "1-2": { en: "1-2", pt: "1-2", es: "1-2" },
  "1-3": { en: "1-3", pt: "1-3", es: "1-3" },
  "1-4": { en: "1-4", pt: "1-4", es: "1-4" },
  "1-5": { en: "1-5", pt: "1-5", es: "1-5" },
  "1-6": { en: "1-6", pt: "1-6", es: "1-6" },
  "2-4": { en: "2-4", pt: "2-4", es: "2-4" },
  "2-5": { en: "2-5", pt: "2-5", es: "2-5" },
  "2-6": { en: "2-6", pt: "2-6", es: "2-6" },
  "3-5": { en: "3-5", pt: "3-5", es: "3-5" },
  "3-6": { en: "3-6", pt: "3-6", es: "3-6" },
  "1:0, 2:0 or 3:0": {
    en: "1:0, 2:0 or 3:0",
    pt: "1:0, 2:0 ou 3:0",
    es: "1:0, 2:0 o 3:0"
  },
  "0:1, 0:2 or 0:3": {
    en: "0:1, 0:2 or 0:3",
    pt: "0:1, 0:2 ou 0:3",
    es: "0:1, 0:2 o 0:3"
  },
  "4:0, 5:0 or 6:0": {
    en: "4:0, 5:0 or 6:0",
    pt: "4:0, 5:0 ou 6:0",
    es: "4:0, 5:0 o 6:0"
  },
  "0:4, 0:5 or 0:6": {
    en: "0:4, 0:5 or 0:6",
    pt: "0:4, 0:5 ou 0:6",
    es: "0:4, 0:5 o 0:6"
  },
  "2:1, 3:1 or 4:1": {
    en: "2:1, 3:1 or 4:1",
    pt: "2:1, 3:1 ou 4:1",
    es: "2:1, 3:1 o 4:1"
  },
  "1:2, 1:3 or 1:4": {
    en: "1:2, 1:3 or 1:4",
    pt: "1:2, 1:3 ou 1:4",
    es: "1:2, 1:3 o 1:4"
  },
  "3:2, 4:2, 4:3 or 5:1": {
    en: "3:2, 4:2, 4:3 or 5:1",
    pt: "3:2, 4:2, 4:3 ou 5:1",
    es: "3:2, 4:2, 4:3 o 5:1"
  },
  "2:3, 2:4, 3:4 or 1:5": {
    en: "2:3, 2:4, 3:4 or 1:5",
    pt: "2:3, 2:4, 3:4 ou 1:5",
    es: "2:3, 2:4, 3:4 o 1:5"
  },
  "other homewin": {
    en: "other homewin",
    pt: "outra vitórialocal",
    es: "otra victoria local"
  },
  "other awaywin": {
    en: "other awaywin",
    pt: "outra vitóriavisitante",
    es: "otra victoria visitante"
  },
  "{$competitor1}/{$competitor1} &amp; under {total}": {
    en: "{$competitor1}/{$competitor1} &amp; under {total}",
    pt: "{$competitor1}/{$competitor1} e menos {",
    es: "{$competitor1}/{$competitor1} &amp; menos de {total}"
  },
  "draw/{$competitor1} &amp; under {total}": {
    en: "draw/{$competitor1} &amp; under {total}",
    pt: "empate/{$competitor1} e menos {total}",
    es: "empate/{$competitor1} &amp; menos de {total}"
  },
  "draw/draw &amp; under {total}": {
    en: "draw/draw &amp; under {total}",
    pt: "empate/empate e menos {total}",
    es: "empate/empate &amp; menos de {total}"
  },
  "{$competitor2}/{$competitor1} &amp; under {total}": {
    en: "{$competitor2}/{$competitor1} &amp; under {total}",
    pt: "{$competitor2}/{$competitor1} e menos {total}",
    es: "{$competitor2}/{$competitor1} &amp; menos de {total}"
  },
  "{$competitor2}/draw &amp; under {total}": {
    en: "{$competitor2}/draw &amp; under {total}",
    pt: "{$competitor2}/empate e menos {total}",
    es: "{$competitor2}/empate &amp; menos de {total}"
  },
  "{$competitor2}/{$competitor2} &amp; under {total}": {
    en: "{$competitor2}/{$competitor2} &amp; under {total}",
    pt: "{$competitor2}/{$competitor2} e menos {total}",
    es: "{$competitor2}/{$competitor2} &amp; menos de {total}"
  },
  "{$competitor1}/{$competitor1} &amp; over {total}": {
    en: "{$competitor1}/{$competitor1} &amp; over {total}",
    pt: "{$competitor1}/{$competitor1} e mais {total}",
    es: "{$competitor1}/{$competitor1} &amp; más de {total}"
  },
  "draw/{$competitor1} &amp; over {total}": {
    en: "draw/{$competitor1} &amp; over {total}",
    pt: "empate/{$competitor1} e mais {total}",
    es: "empate /{$competitor1} &amp; más de {total}"
  },
  "draw/draw &amp; over {total}": {
    en: "draw/draw &amp; over {total}",
    pt: "empate/empate e mais {total}",
    es: "empate/empate &amp; más de {total}"
  },
  "{$competitor2}/{$competitor1} &amp; over {total}": {
    en: "{$competitor2}/{$competitor1} &amp; over {total}",
    pt: "{$competitor2}/{$competitor1} e mais {total}",
    es: "{$competitor2}/{$competitor1} &amp; más de {total}"
  },
  "{$competitor2}/draw &amp; over {total}": {
    en: "{$competitor2}/draw &amp; over {total}",
    pt: "{$competitor2}/empate e mais {total}",
    es: "{$competitor2}/empate &amp; más de {total}"
  },
  "{$competitor2}/{$competitor2} &amp; over {total}": {
    en: "{$competitor2}/{$competitor2} &amp; over {total}",
    pt: "{$competitor2}/{$competitor2} e mais {total}",
    es: "{$competitor2}/{$competitor2} &amp; más de {total}"
  },
  "draw/draw &amp; 0": {
    en: "draw/draw &amp; 0",
    pt: "empate/empate e 0",
    es: "empate/empate &amp; 0"
  },
  "{$competitor1}/{$competitor1} &amp; 1": {
    en: "{$competitor1}/{$competitor1} &amp; 1",
    pt: "{$competitor1}/{$competitor1} e 1",
    es: "{$competitor1}/{$competitor1} &amp; 1"
  },
  "draw/{$competitor1} &amp; 1": {
    en: "draw/{$competitor1} &amp; 1",
    pt: "empate/{$competitor1} e 1",
    es: "empate/{$competitor1} &amp; 1"
  },
  "draw/{$competitor2} &amp; 1": {
    en: "draw/{$competitor2} &amp; 1",
    pt: "empate/{$competitor2} e 1",
    es: "empate/{$competitor2} &amp; 1"
  },
  "{$competitor2}/{$competitor2} &amp; 1": {
    en: "{$competitor2}/{$competitor2} &amp; 1",
    pt: "{$competitor2}/{$competitor2} e 1",
    es: "{$competitor2}/{$competitor2} &amp; 1"
  },
  "{$competitor1}/{$competitor1} &amp; 2": {
    en: "{$competitor1}/{$competitor1} &amp; 2",
    pt: "{$competitor1}/{$competitor1} e 2",
    es: "{$competitor1}/{$competitor1} &amp; 2"
  },
  "{$competitor1}/draw &amp; 2": {
    en: "{$competitor1}/draw &amp; 2",
    pt: "{$competitor1}/empate e 2",
    es: "{$competitor1}/empate &amp; 2"
  },
  "draw/{$competitor1} &amp; 2": {
    en: "draw/{$competitor1} &amp; 2",
    pt: "empate/{$competitor1} e 2",
    es: "empate/{$competitor1} &amp; 2"
  },
  "draw/draw &amp; 2": {
    en: "draw/draw &amp; 2",
    pt: "empate/empate e 2",
    es: "empate/empate &amp; 2"
  },
  "draw/{$competitor2} &amp; 2": {
    en: "draw/{$competitor2} &amp; 2",
    pt: "empate/{$competitor2} e 2",
    es: "empate/{$competitor2} &amp; 2"
  },
  "{$competitor2}/draw &amp; 2": {
    en: "{$competitor2}/draw &amp; 2",
    pt: "{$competitor2}/empate e 2",
    es: "{$competitor2}/empate&amp; 2"
  },
  "{$competitor2}/{$competitor2} &amp; 2": {
    en: "{$competitor2}/{$competitor2} &amp; 2",
    pt: "{$competitor2}/{$competitor2} e 2",
    es: "{$competitor2}/{$competitor2} &amp; 2"
  },
  "{$competitor1}/{$competitor1} &amp; 3": {
    en: "{$competitor1}/{$competitor1} &amp; 3",
    pt: "{$competitor1}/{$competitor1} e 3",
    es: "{$competitor1}/{$competitor1} &amp; 3"
  },
  "{$competitor1}/{$competitor2} &amp; 3": {
    en: "{$competitor1}/{$competitor2} &amp; 3",
    pt: "{$competitor1}/{$competitor2} e 3",
    es: "{$competitor1}/{$competitor2} &amp; 3"
  },
  "draw/{$competitor1} &amp; 3": {
    en: "draw/{$competitor1} &amp; 3",
    pt: "empate/{$competitor1} e 3",
    es: "empate/{$competitor1} &amp; 3"
  },
  "draw/{$competitor2} &amp; 3": {
    en: "draw/{$competitor2} &amp; 3",
    pt: "empate/{$competitor2} e 3",
    es: "empate/{$competitor2} &amp; 3"
  },
  "{$competitor2}/{$competitor1} &amp; 3": {
    en: "{$competitor2}/{$competitor1} &amp; 3",
    pt: "{$competitor2}/{$competitor1} e 3",
    es: "{$competitor2}/{$competitor1} &amp; 3"
  },
  "{$competitor2}/{$competitor2} &amp; 3": {
    en: "{$competitor2}/{$competitor2} &amp; 3",
    pt: "{$competitor2}/{$competitor2} e 3",
    es: "{$competitor2}/{$competitor2} &amp; 3"
  },
  "{$competitor1}/{$competitor1} &amp; 4": {
    en: "{$competitor1}/{$competitor1} &amp; 4",
    pt: "{$competitor1}/{$competitor1} e 4",
    es: "{$competitor1}/{$competitor1} &amp; 4"
  },
  "{$competitor1}/draw &amp; 4": {
    en: "{$competitor1}/draw &amp; 4",
    pt: "{$competitor1}/empate e 4",
    es: "{$competitor1}/empate &amp; 4"
  },
  "{$competitor1}/{$competitor2} &amp; 4": {
    en: "{$competitor1}/{$competitor2} &amp; 4",
    pt: "{$competitor1}/{$competitor2} e 4",
    es: "{$competitor1}/{$competitor2} &amp; 4"
  },
  "draw/{$competitor1} &amp; 4": {
    en: "draw/{$competitor1} &amp; 4",
    pt: "empate/{$competitor1} e 4",
    es: "empate/{$competitor1} &amp; 4"
  },
  "draw/draw &amp; 4": {
    en: "draw/draw &amp; 4",
    pt: "empate/empate e 4",
    es: "empate/empate &amp; 4"
  },
  "draw/{$competitor2} &amp; 4": {
    en: "draw/{$competitor2} &amp; 4",
    pt: "empate/{$competitor2} e 4",
    es: "empate/{$competitor2} &amp; 4"
  },
  "{$competitor2}/{$competitor1} &amp; 4": {
    en: "{$competitor2}/{$competitor1} &amp; 4",
    pt: "{$competitor2}/{$competitor1} e 4",
    es: "{$competitor2}/{$competitor1} &amp; 4"
  },
  "{$competitor2}/draw &amp; 4": {
    en: "{$competitor2}/draw &amp; 4",
    pt: "{$competitor2}/empate e 4",
    es: "{$competitor2}/empate &amp; 4"
  },
  "{$competitor2}/{$competitor2} &amp; 4": {
    en: "{$competitor2}/{$competitor2} &amp; 4",
    pt: "{$competitor2}/{$competitor2} e 4",
    es: "{$competitor2}/{$competitor2} &amp; 4"
  },
  "{$competitor1}/{$competitor1} &amp; 5+": {
    en: "{$competitor1}/{$competitor1} &amp; 5+",
    pt: "{$competitor1}/{$competitor1} e 5+",
    es: "{$competitor1}/{$competitor1} &amp; 5+"
  },
  "{$competitor1}/draw &amp; 5+": {
    en: "{$competitor1}/draw &amp; 5+",
    pt: "{$competitor1}/empate e 5+",
    es: "{$competitor1}/empate &amp; 5+"
  },
  "{$competitor1}/{$competitor2} &amp; 5+": {
    en: "{$competitor1}/{$competitor2} &amp; 5+",
    pt: "{$competitor1}/{$competitor2} e 5+",
    es: "{$competitor1}/{$competitor2} &amp; 5+"
  },
  "draw/{$competitor1} &amp; 5+": {
    en: "draw/{$competitor1} &amp; 5+",
    pt: "empate/{$competitor1} e 5+",
    es: "empate/{$competitor1} &amp; 5+"
  },
  "draw/draw &amp; 5+": {
    en: "draw/draw &amp; 5+",
    pt: "empate/empate e +5",
    es: "empate/empate &amp; 5+"
  },
  "draw/{$competitor2} &amp; 5+": {
    en: "draw/{$competitor2} &amp; 5+",
    pt: "empate/{$competitor2} e 5+",
    es: "empate/{$competitor2} &amp; 5+"
  },
  "{$competitor2}/{$competitor1} &amp; 5+": {
    en: "{$competitor2}/{$competitor1} &amp; 5+",
    pt: "{$competitor2}/{$competitor1} e 5+",
    es: "{$competitor2}/{$competitor1} &amp; 5+"
  },
  "{$competitor2}/draw &amp; 5+": {
    en: "{$competitor2}/draw &amp; 5+",
    pt: "{$competitor2}/empate e 5+",
    es: "{$competitor2}/empate &amp; 5+"
  },
  x: {
    en: "Draw",
    es: "Empate",
    pt: "Empate"
  },
  "{$competitor2}/{$competitor2} &amp; 5+": {
    en: "{$competitor2}/{$competitor2} &amp; 5+",
    pt: "{$competitor2}/{$competitor2} e 5+",
    es: "{$competitor2}/{$competitor2} &amp; 5+"
  },
  "Goal before 29:00 min.": {
    en: "Goal before 29:00 min.",
    pt: "Goal before 29:00 min.",
    es: "Goal before 29:00 min."
  },
  "No Goal before 29:00 min.": {
    en: "No Goal before 29:00 min.",
    pt: "No Goal before 29:00 min.",
    es: "No Goal before 29:00 min."
  },
  "Over 7.5": { en: "Over 7.5", pt: "Over 7.5", es: "Over 7.5" },
  "Under 7.5": { en: "Under 7.5", pt: "Under 7.5", es: "Under 7.5" },
  "Over 8.5": { en: "Over 8.5", pt: "Over 8.5", es: "Over 8.5" },
  "Under 8.5": { en: "Under 8.5", pt: "Under 8.5", es: "Under 8.5" },
  "Over 9.5": { en: "Over 9.5", pt: "Over 9.5", es: "Over 9.5" },
  "Under 9.5": { en: "Under 9.5", pt: "Under 9.5", es: "Under 9.5" },
  "Over 10.5": { en: "Over 10.5", pt: "Over 10.5", es: "Over 10.5" },
  "Under 10.5": { en: "Under 10.5", pt: "Under 10.5", es: "Under 10.5" },
  "Over 11.5": { en: "Over 11.5", pt: "Over 11.5", es: "Over 11.5" },
  "Under 11.5": { en: "Under 11.5", pt: "Under 11.5", es: "Under 11.5" },
  "Over 12.5": { en: "Over 12.5", pt: "Over 12.5", es: "Over 12.5" },
  "Under 12.5": { en: "Under 12.5", pt: "Under 12.5", es: "Under 12.5" },
  "Over 13.5": { en: "Over 13.5", pt: "Over 13.5", es: "Over 13.5" },
  "Under 13.5": { en: "Under 13.5", pt: "Under 13.5", es: "Under 13.5" },
  "Over 14.5": { en: "Over 14.5", pt: "Over 14.5", es: "Over 14.5" },
  "Under14.5": { en: "Under14.5", pt: "Under14.5", es: "Under14.5" },
  "Over 3.5": { en: "Over 3.5", pt: "Over 3.5", es: "Over 3.5" },
  "Under 3.5": { en: "Under 3.5", pt: "Under 3.5", es: "Under 3.5" },
  "Over 4.5": { en: "Over 4.5", pt: "Over 4.5", es: "Over 4.5" },
  "Under 4.5": { en: "Under 4.5", pt: "Under 4.5", es: "Under 4.5" },
  "Over 5.5": { en: "Over 5.5", pt: "Over 5.5", es: "Over 5.5" },
  "Under 5.5": { en: "Under 5.5", pt: "Under 5.5", es: "Under 5.5" },
  "0-5": { en: "0-5", pt: "0-5", es: "0-5" },
  "0-6": { en: "0-6", pt: "0-6", es: "0-6" },
  "4+/5+": { en: "4+/5+", pt: "4+/5+", es: "4+/5+" },
  "4+/6+": { en: "4+/6+", pt: "4+/6+", es: "4+/6+" },
  "4+/7+": { en: "4+/7+", pt: "4+/7+", es: "4+/7+" },
  "5+/5+": { en: "5+/5+", pt: "5+/5+", es: "5+/5+" },
  "5+/6+": { en: "5+/6+", pt: "5+/6+", es: "5+/6+" },
  "5+/4+": { en: "5+/4+", pt: "5+/4+", es: "5+/4+" },
  "5+/7+": { en: "5+/7+", pt: "5+/7+", es: "5+/7+" },
  "6+/4+": { en: "6+/4+", pt: "6+/4+", es: "6+/4+" },
  "6+/5+": { en: "6+/5+", pt: "6+/5+", es: "6+/5+" },
  "6+/6+": { en: "6+/6+", pt: "6+/6+", es: "6+/6+" },
  "6+/7+": { en: "6+/7+", pt: "6+/7+", es: "6+/7+" },
  "4+/4+": { en: "4+/4+", pt: "4+/4+", es: "4+/4+" },
  Draw: { en: "Draw", pt: "Empate", es: "Empate" },
  "1-1": { en: "1-1", pt: "1-1", es: "1-1" },
  "1-X": { en: "1-X", pt: "1-Empate", es: "1-Empate" },
  "X-1": { en: "Draw-1", pt: "Empate-1", es: "Empate-1" },
  "X-X": { en: "Draw-Draw", pt: "Empate-Empate", es: "Empate-Empate" },
  "X-2": { en: "Draw-2", pt: "Empate-2", es: "Empate-2" },
  "2-1": { en: "2-1", pt: "2-1", es: "2-1" },
  "2-X": { en: "2-Draw", pt: "2-Empate", es: "2-Empate" },
  "2-2": { en: "2-2", pt: "2-2", es: "2-2" },
  Neither: { en: "Neither", pt: "Neither", es: "Neither" },
  Yes: { en: "Yes", pt: "Yes", es: "Yes" },
  No: { en: "No", pt: "No", es: "No" },
  Over: { en: "Over", pt: "Over", es: "Over" },
  Under: { en: "Under", pt: "Under", es: "Under" },
  "1st quarter": { en: "1st quarter", pt: "1º quarto", es: "1º cuarto" },
  "2nd quarter": { en: "2nd quarter", pt: "2º quarto", es: "2º cuarto" },
  "3rd quarter": { en: "3rd quarter", pt: "3º quarto", es: "3º cuarto" },
  "4th quarter": { en: "4th quarter", pt: "4º quarto", es: "4º cuarto" },
  "{$competitor1} by 6+": {
    en: "{$competitor1} by 6+",
    pt: "{$competitor1} by 6+",
    es: "{$competitor1} por 6+"
  },
  "{$competitor2} by 6+": {
    en: "{$competitor2} by 6+",
    pt: "{$competitor2} by 6+",
    es: "{$competitor2} por 6+"
  },
  "{$competitor1} by 11-15": {
    en: "{$competitor1} by 11-15",
    pt: "{$competitor1} by 11-15",
    es: "{$competitor1} por 11-15"
  },
  "{$competitor1} by 16-20": {
    en: "{$competitor1} by 16-20",
    pt: "{$competitor1} by 16-20",
    es: "{$competitor1} por 16-20"
  },
  "{$competitor1} by 21-25": {
    en: "{$competitor1} by 21-25",
    pt: "{$competitor1} by 21-25",
    es: "{$competitor1} por 21-25"
  },
  "{$competitor1} by 26+": {
    en: "{$competitor1} by 26+",
    pt: "{$competitor1} by 26+",
    es: "{$competitor1} por 26+"
  },
  "{$competitor2} by 11-15": {
    en: "{$competitor2} by 11-15",
    pt: "{$competitor2} by 11-15",
    es: "{$competitor2} por 11-15"
  },
  "{$competitor2} by 16-20": {
    en: "{$competitor2} by 16-20",
    pt: "{$competitor2} by 16-20",
    es: "{$competitor2} por 16-20"
  },
  "{$competitor2} by 21-25": {
    en: "{$competitor2} by 21-25",
    pt: "{$competitor2} by 21-25",
    es: "{$competitor2} por 21-25"
  },
  "{$competitor2} by 26+": {
    en: "{$competitor2} by 26+",
    pt: "{$competitor2} by 26+",
    es: "{$competitor2} por 26+"
  },
  "exact {total}": {
    en: "exact {total}",
    pt: "exato {total}",
    es: "exacto {total}"
  },
  "0-27": { en: "0-27", pt: "0-27", es: "0-27" },
  "28-34": { en: "28-34", pt: "28-34", es: "28-34" },
  "35-41": { en: "35-41", pt: "35-41", es: "35-41" },
  "42-48": { en: "42-48", pt: "42-48", es: "42-48" },
  "49-55": { en: "49-55", pt: "49-55", es: "49-55" },
  "56-62": { en: "56-62", pt: "56-62", es: "56-62" },
  "63+": { en: "63+", pt: "63+", es: "63+" },
  "0-100": { en: "0-100", pt: "0-100", es: "0-100" },
  "101-110": { en: "101-110", pt: "101-110", es: "101-110" },
  "111-120": { en: "111-120", pt: "111-120", es: "111-120" },
  "121-130": { en: "121-130", pt: "121-130", es: "121-130" },
  "131-140": { en: "131-140", pt: "131-140", es: "131-140" },
  "141-150": { en: "141-150", pt: "141-150", es: "141-150" },
  "151-160": { en: "151-160", pt: "151-160", es: "151-160" },
  "161-170": { en: "161-170", pt: "161-170", es: "161-170" },
  "171-180": { en: "171-180", pt: "171-180", es: "171-180" },
  "180.5+": { en: "180.5+", pt: "180.5+", es: "180.5+" },
  "0-150": { en: "0-150", pt: "0-150", es: "0-150" },
  "181-190": { en: "181-190", pt: "181-190", es: "181-190" },
  "191-200": { en: "191-200", pt: "191-200", es: "191-200" },
  "201-210": { en: "201-210", pt: "201-210", es: "201-210" },
  "211-220": { en: "211-220", pt: "211-220", es: "211-220" },
  "221-230": { en: "221-230", pt: "221-230", es: "221-230" },
  "231-240": { en: "231-240", pt: "231-240", es: "231-240" },
  "241-250": { en: "241-250", pt: "241-250", es: "241-250" },
  "250.5+": { en: "250.5+", pt: "250.5+", es: "250.5+" },
  "0-20": { en: "0-20", pt: "0-20", es: "0-20" },
  "21-25": { en: "21-25", pt: "21-25", es: "21-25" },
  "26-30": { en: "26-30", pt: "26-30", es: "26-30" },
  "31-35": { en: "31-35", pt: "31-35", es: "31-35" },
  "36-40": { en: "36-40", pt: "36-40", es: "36-40" },
  "41-45": { en: "41-45", pt: "41-45", es: "41-45" },
  "46+": { en: "46+", pt: "46+", es: "46+" },
  "6-10": { en: "6-10", pt: "6-10", es: "6-10" },
  "11-15": { en: "11-15", pt: "11-15", es: "11-15" },
  "16-20": { en: "16-20", pt: "16-20", es: "16-20" },
  "31+": { en: "31+", pt: "31+", es: "31+" },
  "{$competitor1} ({+hcp}) &amp;amp; over {total}": {
    en: "{$competitor1} ({+hcp}) &amp;amp; over {total}",
    pt: "{$competitor1} ({+hcp}) &amp;amp; over {total}",
    es: "{$competitor1} ({+hcp}) &amp;amp; mas de {total}"
  },
  "{$competitor1} ({+hcp}) &amp;amp; under {total}": {
    en: "{$competitor1} ({+hcp}) &amp;amp; under {total}",
    pt: "{$competitor1} ({+hcp}) &amp;amp; under {total}",
    es: "{$competitor1} ({+hcp}) &amp;amp; menos de {total}"
  },
  "{$competitor2} ({-hcp}) &amp;amp; over {total}": {
    en: "{$competitor2} ({-hcp}) &amp;amp; over {total}",
    pt: "{$competitor2} ({-hcp}) &amp;amp; over {total}",
    es: "{$competitor2} ({-hcp}) &amp;amp; mas de {total}"
  },
  "{{$competitor2} ({-hcp}) &amp;amp; under {total}": {
    en: "{{$competitor2} ({-hcp}) &amp;amp; under {total}",
    pt: "{{$competitor2} ({-hcp}) &amp;amp; under {total}",
    es: "{{$competitor2} ({-hcp}) &amp;amp; menos de {total}"
  },
  "{$competitor1} &amp;amp; under {total}": {
    en: "{$competitor1} &amp;amp; under {total}",
    pt: "{$competitor1} &amp;amp; under {total}",
    es: "{$competitor1} &amp;amp; menos de {total}"
  },
  "{$competitor1} &amp;amp; over {total}": {
    en: "{$competitor1} &amp;amp; over {total}",
    pt: "{$competitor1} &amp;amp; over {total}",
    es: "{$competitor1} &amp;amp; mas de {total}"
  },
  "draw &amp;amp; under {total}": {
    en: "draw &amp;amp; under {total}",
    pt: "Empate &amp;amp; under {total}",
    es: "empate &amp;amp; menos de {total}"
  },
  "draw &amp;amp; over {total}": {
    en: "draw &amp;amp; over {total}",
    pt: "Empate &amp;amp; over {total}",
    es: "empate &amp;amp; mas de {total}"
  },
  "{$competitor2} &amp;amp; under {total}": {
    en: "{$competitor2} &amp;amp; under {total}",
    pt: "{$competitor2} &amp;amp; under {total}",
    es: "{$competitor2} &amp;amp; menos de {total}"
  },
  "{$competitor2} &amp;amp; over {total}": {
    en: "{$competitor2} &amp;amp; over {total}",
    pt: "{$competitor2} &amp;amp; over {total}",
    es: "{$competitor2} &amp;amp; mas de {total}"
  },
  "{$competitor2} ({-hcp}) &amp;amp; under {total}": {
    en: "{$competitor2} ({-hcp}) &amp;amp; under {total}",
    pt: "{$competitor2} ({-hcp}) &amp;amp; under {total}",
    es: "{$competitor2} ({-hcp}) &amp;amp; menos de {total}"
  },
  "1st inning": { en: "1st inning", pt: "1º inning", es: "1º inning" },
  "2nd inning": { en: "2nd inning", pt: "2º inning", es: "2º inning" },
  "3rd inning": { en: "3rd inning", pt: "3º inning", es: "3º inning" },
  "4th inning": { en: "4th inning", pt: "4º inning", es: "4º inning" },
  "5th inning": { en: "5th inning", pt: "5º inning", es: "5º inning" },
  "6th inning": { en: "6th inning", pt: "6º inning", es: "6º inning" },
  "7th inning": { en: "7th inning", pt: "7º inning", es: "7º inning" },
  "8th inning": { en: "8th inning", pt: "8º inning", es: "8º inning" },
  "9th inning": { en: "9th inning", pt: "9º inning", es: "9º inning" },
  "top of 9th inning": {
    en: "top of 9th inning",
    pt: "melhor do 9º inning",
    es: "mejor del 9º inning"
  },
  "bottom of 9th inning": {
    en: "bottom of 9th inning",
    pt: "pior do 9º inning",
    es: "peor del 9º inning"
  },
  "any extra inning": {
    en: "any extra inning",
    pt: "qualquer inning extra",
    es: "cualquier extra inning"
  },
  "{!inningnr} inning": {
    en: "{!inningnr} inning",
    pt: "{!inningnr} inning",
    es: "{!inningnr} inning"
  },
  "{!(inningnr+1)} inning": {
    en: "{!(inningnr+1)} inning",
    pt: "{!(inningnr+1)} inning",
    es: "{!(inningnr+1)} inning"
  },
  "{!(inningnr+2)} inning": {
    en: "{!(inningnr+2)} inning",
    pt: "{!(inningnr+2)} inning",
    es: "{!(inningnr+2)} inning"
  },
  "other inning or no run": {
    en: "other inning or no run",
    pt: "outro inning ou sem run",
    es: "otro inning o sin carrera"
  },
  "1st period": { en: "1st period", pt: "1º período", es: "1º periodo" },
  "2nd period": { en: "2nd period", pt: "2º período", es: "2º periodo" },
  "3rd period": { en: "3rd period", pt: "3º período", es: "3º periodo" },
  "7:5": { en: "7:5", pt: "7:5", es: "7:5" },
  "7:6": { en: "7:6", pt: "7:6", es: "7:6" },
  "5:7": { en: "5:7", pt: "5:7", es: "5:7" },
  "6:7": { en: "6:7", pt: "6:7", es: "6:7" },
  "{$competitor1} by ko": {
    en: "{$competitor1} by ko",
    pt: "{$competitor1} by ko",
    es: "{$competitor1} por ko"
  },
  "{$competitor1} by submission": {
    en: "{$competitor1} by submission",
    pt: "{$competitor1} by submission",
    es: "{$competitor1} por submission"
  },
  "{$competitor1} by decision": {
    en: "{$competitor1} by decision",
    pt: "{$competitor1} by decision",
    es: "{$competitor1} por decision"
  },
  "{$competitor2} by ko": {
    en: "{$competitor2} by ko",
    pt: "{$competitor2} by ko",
    es: "{$competitor2} por ko"
  },
  "{$competitor2} by submission": {
    en: "{$competitor2} by submission",
    pt: "{$competitor2} by submission",
    es: "{$competitor2} por submission"
  },
  "{$competitor2} by decision": {
    en: "{$competitor2} by decision",
    pt: "{$competitor2} by decision",
    es: "{$competitor2} por decision"
  },
  "{$competitor1} &amp;amp; 1": {
    en: "{$competitor1} &amp;amp; 1",
    pt: "{$competitor1} &amp;amp; 1",
    es: "{$competitor1} &amp;amp; 1"
  },
  "{$competitor1} &amp;amp; 2": {
    en: "{$competitor1} &amp;amp; 2",
    pt: "{$competitor1} &amp;amp; 2",
    es: "{$competitor1} &amp;amp; 2"
  },
  "{$competitor1} &amp;amp; 3": {
    en: "{$competitor1} &amp;amp; 3",
    pt: "{$competitor1} &amp;amp; 3",
    es: "{$competitor1} &amp;amp; 3"
  },
  "{$competitor1} &amp;amp; decision": {
    en: "{$competitor1} &amp;amp; decision",
    pt: "{$competitor1} &amp;amp; decision",
    es: "{$competitor1} &amp;amp; decision"
  },
  "{$competitor2} &amp;amp; 1": {
    en: "{$competitor2} &amp;amp; 1",
    pt: "{$competitor2} &amp;amp; 1",
    es: "{$competitor2} &amp;amp; 1"
  },
  "{$competitor2} &amp;amp; 2": {
    en: "{$competitor2} &amp;amp; 2",
    pt: "{$competitor2} &amp;amp; 2",
    es: "{$competitor2} &amp;amp; 2"
  },
  "{$competitor2} &amp;amp; 3": {
    en: "{$competitor2} &amp;amp; 3",
    pt: "{$competitor2} &amp;amp; 3",
    es: "{$competitor2} &amp;amp; 3"
  },
  "{$competitor2} &amp;amp; decision": {
    en: "{$competitor2} &amp;amp; decision",
    pt: "{$competitor2} &amp;amp; decision",
    es: "{$competitor2} &amp;amp; decision"
  },
  "{$competitor1} &amp;amp; 4": {
    en: "{$competitor1} &amp;amp; 4",
    pt: "{$competitor1} &amp;amp; 4",
    es: "{$competitor1} &amp;amp; 4"
  },
  "{$competitor1} &amp;amp; 5": {
    en: "{$competitor1} &amp;amp; 5",
    pt: "{$competitor1} &amp;amp; 5",
    es: "{$competitor1} &amp;amp; 5"
  },
  "{$competitor2} &amp;amp; 4": {
    en: "{$competitor2} &amp;amp; 4",
    pt: "{$competitor2} &amp;amp; 4",
    es: "{$competitor2} &amp;amp; 4"
  },
  "{$competitor2} &amp;amp; 5": {
    en: "{$competitor2} &amp;amp; 5",
    pt: "{$competitor2} &amp;amp; 5",
    es: "{$competitor2} &amp;amp; 5"
  },
  "{$competitor1} &amp;amp; 6": {
    en: "{$competitor1} &amp;amp; 6",
    pt: "{$competitor1} &amp;amp; 6",
    es: "{$competitor1} &amp;amp; 6"
  },
  "{$competitor1} &amp;amp; 7": {
    en: "{$competitor1} &amp;amp; 7",
    pt: "{$competitor1} &amp;amp; 7",
    es: "{$competitor1} &amp;amp; 7"
  },
  "{$competitor1} &amp;amp; 8": {
    en: "{$competitor1} &amp;amp; 8",
    pt: "{$competitor1} &amp;amp; 8",
    es: "{$competitor1} &amp;amp; 8"
  },
  "{$competitor1} &amp;amp; 9": {
    en: "{$competitor1} &amp;amp; 9",
    pt: "{$competitor1} &amp;amp; 9",
    es: "{$competitor1} &amp;amp; 9"
  },
  "{$competitor1} &amp;amp; 10": {
    en: "{$competitor1} &amp;amp; 10",
    pt: "{$competitor1} &amp;amp; 10",
    es: "{$competitor1} &amp;amp; 10"
  },
  "{$competitor1} &amp;amp; 11": {
    en: "{$competitor1} &amp;amp; 11",
    pt: "{$competitor1} &amp;amp; 11",
    es: "{$competitor1} &amp;amp; 11"
  },
  "{$competitor1} &amp;amp; 12": {
    en: "{$competitor1} &amp;amp; 12",
    pt: "{$competitor1} &amp;amp; 12",
    es: "{$competitor1} &amp;amp; 12"
  },
  "{$competitor2} &amp;amp; 6": {
    en: "{$competitor2} &amp;amp; 6",
    pt: "{$competitor2} &amp;amp; 6",
    es: "{$competitor2} &amp;amp; 6"
  },
  "{$competitor2} &amp;amp; 7": {
    en: "{$competitor2} &amp;amp; 7",
    pt: "{$competitor2} &amp;amp; 7",
    es: "{$competitor2} &amp;amp; 7"
  },
  "{$competitor2} &amp;amp; 8": {
    en: "{$competitor2} &amp;amp; 8",
    pt: "{$competitor2} &amp;amp; 8",
    es: "{$competitor2} &amp;amp; 8"
  },
  "{$competitor2} &amp;amp; 9": {
    en: "{$competitor2} &amp;amp; 9",
    pt: "{$competitor2} &amp;amp; 9",
    es: "{$competitor2} &amp;amp; 9"
  },
  "{$competitor2} &amp;amp; 10": {
    en: "{$competitor2} &amp;amp; 10",
    pt: "{$competitor2} &amp;amp; 10",
    es: "{$competitor2} &amp;amp; 10"
  },
  "{$competitor2} &amp;amp; 11": {
    en: "{$competitor2} &amp;amp; 11",
    pt: "{$competitor2} &amp;amp; 11",
    es: "{$competitor2} &amp;amp; 11"
  },
  "{$competitor2} &amp;amp; 12": {
    en: "{$competitor2} &amp;amp; 12",
    pt: "{$competitor2} &amp;amp; 12",
    es: "{$competitor2} &amp;amp; 12"
  },
  "{$competitor1} &amp;amp; 1-3": {
    en: "{$competitor1} &amp;amp; 1-3",
    pt: "{$competitor1} &amp;amp; 1-3",
    es: "{$competitor1} &amp;amp; 1-3"
  },
  "{$competitor1} &amp;amp; 4-6": {
    en: "{$competitor1} &amp;amp; 4-6",
    pt: "{$competitor1} &amp;amp; 4-6",
    es: "{$competitor1} &amp;amp; 4-6"
  },
  "{$competitor1} &amp;amp; 7-9": {
    en: "{$competitor1} &amp;amp; 7-9",
    pt: "{$competitor1} &amp;amp; 7-9",
    es: "{$competitor1} &amp;amp; 7-9"
  },
  "{$competitor1} &amp;amp; 10-12": {
    en: "{$competitor1} &amp;amp; 10-12",
    pt: "{$competitor1} &amp;amp; 10-12",
    es: "{$competitor1} &amp;amp; 10-12"
  },
  "{$competitor2} &amp;amp; 1-3": {
    en: "{$competitor2} &amp;amp; 1-3",
    pt: "{$competitor2} &amp;amp; 1-3",
    es: "{$competitor2} &amp;amp; 1-3"
  },
  "{$competitor2} &amp;amp; 4-6": {
    en: "{$competitor2} &amp;amp; 4-6",
    pt: "{$competitor2} &amp;amp; 4-6",
    es: "{$competitor2} &amp;amp; 4-6"
  },
  "{$competitor2} &amp;amp; 7-9": {
    en: "{$competitor2} &amp;amp; 7-9",
    pt: "{$competitor2} &amp;amp; 7-9",
    es: "{$competitor2} &amp;amp; 7-9"
  },
  "{$competitor2} &amp;amp; 10-12": {
    en: "{$competitor2} &amp;amp; 10-12",
    pt: "{$competitor2} &amp;amp; 10-12",
    es: "{$competitor2} &amp;amp; 10-12"
  },
  "{$competitor1} with try": {
    en: "{$competitor1} with try",
    pt: "{$competitor1} com ensaio",
    es: "{$competitor1} con try"
  },
  "{$competitor1} with penalty": {
    en: "{$competitor1} with penalty",
    pt: "{$competitor1} com penalty",
    es: "{$competitor1} con penal"
  },
  "{$competitor1} with drop goal": {
    en: "{$competitor1} with drop goal",
    pt: "{$competitor1} com drop goal",
    es: "{$competitor1} con drop goal"
  },
  "{$competitor2} with try": {
    en: "{$competitor2} with try",
    pt: "{$competitor2} com ensaio",
    es: "{$competitor2} con try"
  },
  "{$competitor2} with penalty": {
    en: "{$competitor2} with penalty",
    pt: "{$competitor2} com penalty",
    es: "{$competitor2} con penal"
  },
  "{$competitor2} with drop goal": {
    en: "{$competitor2} with drop goal",
    pt: "{$competitor2} com drop goal",
    es: "{$competitor2} con drop goal"
  },
  "11-14": { en: "11-14", pt: "11-14", es: "11-14" },
  "15-18": { en: "15-18", pt: "15-18", es: "15-18" },
  "19-22": { en: "19-22", pt: "19-22", es: "19-22" },
  "23-26": { en: "23-26", pt: "23-26", es: "23-26" },
  "27-30": { en: "27-30", pt: "27-30", es: "27-30" },
  "7-13": { en: "7-13", pt: "7-13", es: "7-13" },
  "14-20": { en: "14-20", pt: "14-20", es: "14-20" },
  "21-27": { en: "21-27", pt: "21-27", es: "21-27" },
  "42+": { en: "42+", pt: "42+", es: "42+" },
  "0-99": { en: "0-99", pt: "0-99", es: "0-99" },
  "100-119": { en: "100-119", pt: "100-119", es: "100-119" },
  "120-129": { en: "120-129", pt: "120-129", es: "120-129" },
  "130-139": { en: "130-139", pt: "130-139", es: "130-139" },
  "140-149": { en: "140-149", pt: "140-149", es: "140-149" },
  "150-159": { en: "150-159", pt: "150-159", es: "150-159" },
  "160-169": { en: "160-169", pt: "160-169", es: "160-169" },
  "170-189": { en: "170-189", pt: "170-189", es: "170-189" },
  "190+": { en: "190+", pt: "190+", es: "190+" },
  "0-49": { en: "0-49", pt: "0-49", es: "0-49" },
  "50-59": { en: "50-59", pt: "50-59", es: "50-59" },
  "60-64": { en: "60-64", pt: "60-64", es: "60-64" },
  "65-69": { en: "65-69", pt: "65-69", es: "65-69" },
  "70-74": { en: "70-74", pt: "70-74", es: "70-74" },
  "75-79": { en: "75-79", pt: "75-79", es: "75-79" },
  "80-84": { en: "80-84", pt: "80-84", es: "80-84" },
  "85-94": { en: "85-94", pt: "85-94", es: "85-94" },
  "95+": { en: "95+", pt: "95+", es: "95+" },
  "0-24": { en: "0-24", pt: "0-24", es: "0-24" },
  "25-29": { en: "25-29", pt: "25-29", es: "25-29" },
  "30-34": { en: "30-34", pt: "30-34", es: "30-34" },
  "35-39": { en: "35-39", pt: "35-39", es: "35-39" },
  "40-44": { en: "40-44", pt: "40-44", es: "40-44" },
  "45-49": { en: "45-49", pt: "45-49", es: "45-49" },
  "50-54": { en: "50-54", pt: "50-54", es: "50-54" },
  "55-59": { en: "55-59", pt: "55-59", es: "55-59" },
  "60+": { en: "60+", pt: "60+", es: "60+" },
  "7:1": { en: "7:1", pt: "7:1", es: "7:1" },
  "7:2": { en: "7:2", pt: "7:2", es: "7:2" },
  "7:3": { en: "7:3", pt: "7:3", es: "7:3" },
  "7:4": { en: "7:4", pt: "7:4", es: "7:4" },
  "6:6": { en: "6:6", pt: "6:6", es: "6:6" },
  "4:7": { en: "4:7", pt: "4:7", es: "4:7" },
  "3:7": { en: "3:7", pt: "3:7", es: "3:7" },
  "2:7": { en: "2:7", pt: "2:7", es: "2:7" },
  "1:7": { en: "1:7", pt: "1:7", es: "1:7" },
  "9:0": { en: "9:0", pt: "9:0", es: "9:0" },
  "9:1": { en: "9:1", pt: "9:1", es: "9:1" },
  "9:2": { en: "9:2", pt: "9:2", es: "9:2" },
  "9:3": { en: "9:3", pt: "9:3", es: "9:3" },
  "9:4": { en: "9:4", pt: "9:4", es: "9:4" },
  "9:5": { en: "9:5", pt: "9:5", es: "9:5" },
  "9:6": { en: "9:6", pt: "9:6", es: "9:6" },
  "9:7": { en: "9:7", pt: "9:7", es: "9:7" },
  "9:8": { en: "9:8", pt: "9:8", es: "9:8" },
  "8:9": { en: "8:9", pt: "8:9", es: "8:9" },
  "7:9": { en: "7:9", pt: "7:9", es: "7:9" },
  "6:9": { en: "6:9", pt: "6:9", es: "6:9" },
  "5:9": { en: "5:9", pt: "5:9", es: "5:9" },
  "4:9": { en: "4:9", pt: "4:9", es: "4:9" },
  "3:9": { en: "3:9", pt: "3:9", es: "3:9" },
  "2:9": { en: "2:9", pt: "2:9", es: "2:9" },
  "1:9": { en: "1:9", pt: "1:9", es: "1:9" },
  "0:9": { en: "0:9", pt: "0:9", es: "0:9" },
  "10:0": { en: "10:0", pt: "10:0", es: "10:0" },
  "10:1": { en: "10:1", pt: "10:1", es: "10:1" },
  "10:2": { en: "10:2", pt: "10:2", es: "10:2" },
  "10:3": { en: "10:3", pt: "10:3", es: "10:3" },
  "10:4": { en: "10:4", pt: "10:4", es: "10:4" },
  "10:5": { en: "10:5", pt: "10:5", es: "10:5" },
  "10:6": { en: "10:6", pt: "10:6", es: "10:6" },
  "10:7": { en: "10:7", pt: "10:7", es: "10:7" },
  "10:8": { en: "10:8", pt: "10:8", es: "10:8" },
  "10:9": { en: "10:9", pt: "10:9", es: "10:9" },
  "9:10": { en: "9:10", pt: "9:10", es: "9:10" },
  "8:10": { en: "8:10", pt: "8:10", es: "8:10" },
  "7:10": { en: "7:10", pt: "7:10", es: "7:10" },
  "6:10": { en: "6:10", pt: "6:10", es: "6:10" },
  "5:10": { en: "5:10", pt: "5:10", es: "5:10" },
  "4:10": { en: "4:10", pt: "4:10", es: "4:10" },
  "3:10": { en: "3:10", pt: "3:10", es: "3:10" },
  "2:10": { en: "2:10", pt: "2:10", es: "2:10" },
  "1:10": { en: "1:10", pt: "1:10", es: "1:10" },
  "0:10": { en: "0:10", pt: "0:10", es: "0:10" },
  "11:0": { en: "11:0", pt: "11:0", es: "11:0" },
  "11:1": { en: "11:1", pt: "11:1", es: "11:1" },
  "11:2": { en: "11:2", pt: "11:2", es: "11:2" },
  "11:3": { en: "11:3", pt: "11:3", es: "11:3" },
  "11:4": { en: "11:4", pt: "11:4", es: "11:4" },
  "11:5": { en: "11:5", pt: "11:5", es: "11:5" },
  "11:6": { en: "11:6", pt: "11:6", es: "11:6" },
  "11:7": { en: "11:7", pt: "11:7", es: "11:7" },
  "11:8": { en: "11:8", pt: "11:8", es: "11:8" },
  "11:9": { en: "11:9", pt: "11:9", es: "11:9" },
  "11:10": { en: "11:10", pt: "11:10", es: "11:10" },
  "10:11": { en: "10:11", pt: "10:11", es: "10:11" },
  "9:11": { en: "9:11", pt: "9:11", es: "9:11" },
  "8:11": { en: "8:11", pt: "8:11", es: "8:11" },
  "7:11": { en: "7:11", pt: "7:11", es: "7:11" },
  "6:11": { en: "6:11", pt: "6:11", es: "6:11" },
  "5:11": { en: "5:11", pt: "5:11", es: "5:11" },
  "4:11": { en: "4:11", pt: "4:11", es: "4:11" },
  "3:11": { en: "3:11", pt: "3:11", es: "3:11" },
  "2:11": { en: "2:11", pt: "2:11", es: "2:11" },
  "1:11": { en: "1:11", pt: "1:11", es: "1:11" },
  "0:11": { en: "0:11", pt: "0:11", es: "0:11" },
  "8:0": { en: "8:0", pt: "8:0", es: "8:0" },
  "8:1": { en: "8:1", pt: "8:1", es: "8:1" },
  "8:2": { en: "8:2", pt: "8:2", es: "8:2" },
  "8:3": { en: "8:3", pt: "8:3", es: "8:3" },
  "8:4": { en: "8:4", pt: "8:4", es: "8:4" },
  "8:5": { en: "8:5", pt: "8:5", es: "8:5" },
  "8:6": { en: "8:6", pt: "8:6", es: "8:6" },
  "8:7": { en: "8:7", pt: "8:7", es: "8:7" },
  "7:8": { en: "7:8", pt: "7:8", es: "7:8" },
  "6:8": { en: "6:8", pt: "6:8", es: "6:8" },
  "5:8": { en: "5:8", pt: "5:8", es: "5:8" },
  "4:8": { en: "4:8", pt: "4:8", es: "4:8" },
  "3:8": { en: "3:8", pt: "3:8", es: "3:8" },
  "2:8": { en: "2:8", pt: "2:8", es: "2:8" },
  "1:8": { en: "1:8", pt: "1:8", es: "1:8" },
  "0:8": { en: "0:8", pt: "0:8", es: "0:8" }
};
