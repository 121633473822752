import React from 'react';
import { PropTypes } from 'prop-types';
// import { LOADER_HANDLER_TYPES, ROUTE_PATHS } from 'constants/index'

const TabsComponent = ({ tabs }) => {
	const [openTab, setOpenTab] = React.useState({ ...tabs[0], idx: 0 });
	return (
		<div>
			<div className="profile-tabs">
				<div className="nav  nav-pills horizontal-theme-tabs me-3 gap-2 p-0">
					{tabs.map((tab, idx) => (
						<button
							type="button"
							onClick={() => setOpenTab({ ...tab, idx })}
							key={tab.name}
							className={`${
								tab.name === openTab.name ? 'nav-link active' : 'nav-link'
							}`}
						>
							<a href={tab.link}>{tab.name}</a>
						</button>
					))}
				</div>
			</div>
			<div className="profile-card-wrap">{tabs[openTab.idx]?.content()}</div>
		</div>
	);
};

TabsComponent.propTypes = {
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			content: PropTypes.func.isRequired,
		})
	).isRequired,
};
export default TabsComponent;
