import { createSlice } from '@reduxjs/toolkit';
import { getCategoryData } from 'redux-thunk/thunk/general.thunk';

const initialState = {
	categoriesDataLoading: false,
	categoriesData: [],
	categoriesDataError: '',
	siteData: null,
	settingData: null,
	categoryGames: {},
	navigateTo: '',
};

const {
	actions: {
		getCategoriesDataSuccess,
		getCategoriesDataStart,
		getCategoriesDataFail,
		getSettingDataSuccess,
		setCategoryGames,
		setLocation,
	},
	reducer,
} = createSlice({
	name: 'general',
	initialState,
	reducers: {
		getCategoriesDataSuccess: (state, action) => ({
			...state,
			categoriesDataLoading: false,
			categoriesData: action.payload,
		}),

		getCategoriesDataStart: (state) => ({
			...state,
			categoriesDataLoading: true,
		}),
		getCategoriesDataFail: (state) => ({
			...state,
			categoriesDataLoading: false,
			categoriesDataError: true,
		}),

		getSettingDataSuccess: (state, { payload }) => ({
			...state,
			settingData: payload,
		}),
		setCategoryGames: (state, action) => ({
			...state,
			categoryGames: {
				...state.categoryGames,
				[action.payload.id]: action.payload?.categoryInfo || {},
			},
		}),
		setLocation: (state, action) => ({ ...state, navigateTo: action.payload }),
	},
	extraReducers: (builder) => {
		builder
			.addCase(getCategoryData.pending, (state) => ({
				...state,
				categoriesDataLoading: true,
			}))
			.addCase(getCategoryData.fulfilled, (state) => ({
				...state,
				categoriesDataLoading: false,
			}))
			.addCase(getCategoryData.rejected, (state, action) => ({
				...state,
				categoriesDataLoading: false,
				categoriesDataError: action.payload,
			}));
	},
});

export default reducer;
export {
	getCategoriesDataSuccess,
	getCategoriesDataStart,
	getCategoriesDataFail,
	getSettingDataSuccess,
	setCategoryGames,
	setLocation,
};
