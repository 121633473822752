/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import LockIcon from '@mui/icons-material/Lock';
import { OUTCOME_STATUS } from 'SportsBook/BetBalancer/Helper/constants';
import { addBet } from '../../Store/slice/betslip.slice';
import { oddsFormatter } from '../../Helper/utils';
import { OddsWrapper } from './style';

const Odds = ({ odds, market, match }) => {
    const dispatch = useDispatch();
    const [diffType, setDiffType] = useState('');
    const { oddsType } = useSelector((state) => state.SBgeneral);
    const { bets } = useSelector((state) => state.SBbetslip);
    const prevProps = useRef({});

    const isLocked = odds.status === OUTCOME_STATUS.SUSPENDED || odds.status === OUTCOME_STATUS.SETTLED || odds.price <= 1.00

    const handleAddBet = (e) => {
        e.stopPropagation();
        if (!isLocked) {
            dispatch(
                addBet({
                    outcome: odds,
                    fixtureId: match?.fixtureId,
                    providerMarketId: market.providerId,
                    providerOutcomeId: odds.providerId,
                    oddTypes: 'Back',
                    marketName: market.name,
                    match,
                })
            );
        }
    };

    useEffect(() => {
        if (prevProps.current.price !== odds.price) {
            if (+odds.price - +prevProps.current.price > 0) {
                setDiffType('increased');
            } else if (+odds.price - +prevProps.current.price < 0) {
                setDiffType('decreased');
            }
            setTimeout(() => {
                setDiffType('');
            }, 4000);
            prevProps.current = odds.price;
        }
    }, []);

    return (
        <OddsWrapper
            id={odds.providerId}
            variant="span"
            className={bets[odds.providerId] ? 'bounce2 active' : 'bounce2'}
            onClick={(e) => handleAddBet(e, odds)}
        >
            {
                isLocked
                    ? <LockIcon fontSize='small' />
                    : oddsFormatter(odds.price, oddsType)
            }
            {diffType === 'decreased' ? (
                <span className="odds-down pulse" />
            ) : diffType === 'increased' ? (
                <span className="odds-up pulse" />
            ) : null}
        </OddsWrapper>
    );
};

export default Odds;

Odds.propTypes = {
    odds: PropTypes.shape({
        providerId: PropTypes.string,
        name: PropTypes.string,
        status: PropTypes.string,
        base_line: PropTypes.string,
        price: PropTypes.string,
    }).isRequired,
    market: PropTypes.shape({
        name: PropTypes.string,
        providerId: PropTypes.string,
    }).isRequired,
    fixtureId: PropTypes.string.isRequired,
    match: PropTypes.shape({
        id: PropTypes.string,
        fixtureId: PropTypes.string,
        status: PropTypes.string,
        betStatus: PropTypes.string,
        score: PropTypes.string,
        startDate: PropTypes.string,
        league: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            sport: PropTypes.shape({
                id: PropTypes.string,
                name: PropTypes.string,
            }),
        }),
        eventParticipants: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                type: PropTypes.string,
                participant: PropTypes.shape({
                    id: PropTypes.string,
                    name: PropTypes.string,
                }),
            })
        ),
    }).isRequired,
};
