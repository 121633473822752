/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from 'components/molecules/Button/index';
import DatePickerInput from 'components/molecules/DatePicker/DatePicker';
import { ProfileUserIcon } from 'components/ui-kit/Icons/iconComponents/ProfileUserIcon';
import { ROUTE_PATHS } from 'constants/index';
import InputField from 'components/molecules/Input/InputField';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserStatistics } from 'redux-thunk/thunk/user.thunk';
// import Loader from 'components/ui-kit/Loader/index';
import { Grid, TextField, MenuItem } from '@mui/material';
import CustomCollapse from 'components/ui-kit/Collapse/index';

const Statistics = () => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const userStatistics = useSelector((state) => state.user.userStatistics);
	const dateObj = new Date();
	const initialEndDate = dateObj.setUTCHours(0, 0, 0, 0);
	const initialStartDate = dateObj.setDate(dateObj.getDate() - 30);
	const [purposeType, setPurposeType] = useState('last7days');
	const [date, setDate] = useState({
		startDate: new Date(initialStartDate),
		endDate: new Date(initialEndDate),
	});
	const [totalLossAmount, setTotalLossAmount] = useState(0);

	useEffect(() => {
		dispatch(
			fetchUserStatistics({
				dateOptions: purposeType,
				startDate: null,
				endDate: null,
			})
		);
	}, []);

	useEffect(() => {
		const lossAmount =
			Number(userStatistics?.totalBetAmount || 0) -
			Number(userStatistics?.totalWinAmount || 0);
		setTotalLossAmount((lossAmount > 0 ? lossAmount : 0).toFixed(2));
	}, [userStatistics]);

	const clearFilterData = () => {
		setPurposeType('last7days');
		setDate({
			startDate: new Date(initialStartDate),
			endDate: new Date(initialEndDate),
		});
		dispatch(
			fetchUserStatistics({
				dateOptions: 'last7days',
				fromDate: null,
				toDate: null,
			})
		);
	};

	const purposeFilterData = (type) => {
		if (type !== 'custom') {
			dispatch(
				fetchUserStatistics({
					dateOptions: type,
					fromDate: null,
					toDate: null,
				})
			);
		}
		setPurposeType(type);
	};

	const handleDateChange = (data, type) => {
		setDate((prevState) => ({
			...prevState,
			...(type === 'start'
				? { startDate: data || new Date() }
				: { endDate: data || new Date() }),
		}));
		dispatch(
			fetchUserStatistics({
				dateOptions: purposeType,
				fromDate: type === 'start' ? data || new Date() : date.startDate,
				toDate: type === 'end' ? data || new Date() : date.endDate,
			})
		);
	};

	return (
		<div
			className={`tab-pane fade ${
				pathname === ROUTE_PATHS.PROFILE ? 'show active' : ''
			}`}
			id="v-pills-profile"
			role="tabpanel"
			aria-labelledby="v-pills-profile-tab"
		>
			<div className="profile-tab-content-wrap">
				<Grid container spacing={2} style={{ marginBottom: 10 }}>
					<Grid item md={6} xs={12}>
						<div className="profile-heading mb-3">
							<h4 className="m-0">
								<ProfileUserIcon /> Statistics
							</h4>
						</div>
					</Grid>
				</Grid>
				<CustomCollapse open>
					<Grid container spacing={2} marginBottom="20px">
						<Grid
							item
							xs={12}
							sm={3}
							lg={3}
							className="d-flex flex-column transaction-type"
						>
							<label className="form-label  " htmlFor="types">
								Duration
								<span className="color-red">&nbsp;*</span>
							</label>
							<TextField
								id="transaction-type"
								variant="outlined"
								select
								value={purposeType}
								onChange={(e) => {
									// setPage(1);
									purposeFilterData(e.target.value);
								}}
								className="nav transaction-tab horizontal-theme-tabs "
							>
								<MenuItem value="today">Today </MenuItem>
								<MenuItem value="yesterday">Yesterday </MenuItem>
								<MenuItem value="last7days">Last 7 Days</MenuItem>
								<MenuItem value="last30days">Last 30 days</MenuItem>
								<MenuItem value="last90days">Last 90 days</MenuItem>
								<MenuItem value="custom">Custom</MenuItem>
							</TextField>
						</Grid>
						{purposeType === 'custom' ? (
							<>
								<Grid item xs={12} sm={3} lg={3} className="d-flex flex-column">
									<label htmlFor="DOB" className="form-label  ">
										{t('Start Date')}
										<span className="color-red">&nbsp;*</span>
									</label>
									<DatePickerInput
										className="form-control shadow-none"
										selected={date.startDate}
										showYearDropdown
										handleDateChange={(data) => handleDateChange(data, 'start')}
										selectsStart
										maxDate={date.endDate || initialStartDate}
										// minDate={initialStartDate}
										placeholder={t('inputsStart')}
									/>
								</Grid>
								<Grid item xs={12} sm={3} lg={3} className="d-flex flex-column">
									<label htmlFor="End Date" className="form-label  ">
										{t('End Date')}
										<span className="color-red">&nbsp;*</span>
									</label>
									<DatePickerInput
										className="form-control shadow-none "
										selected={date.endDate}
										handleDateChange={(data) => handleDateChange(data, 'end')}
										// disableFuture
										showYearDropdown
										startDate={date.startDate}
										maxDate={initialEndDate}
										minDate={date.startDate}
										placeholder={t('End Date')}
									/>
								</Grid>{' '}
							</>
						) : null}

						<Grid item xs={12} sm={3} lg={3} fullWidth>
							<Button
								onClick={() => clearFilterData()}
								className="btn reset-btn"
							>
								{t('resetButton')}
							</Button>
						</Grid>
					</Grid>
				</CustomCollapse>

				<div className="d-flex col-lg-12 gap-1 mb-2 form-group">
					<div className="col-lg-4 col-4">
						<InputField
							type="text"
							className="form-control shadow-none"
							id="totalBetAmount"
							name="totalBetAmount"
							value={`${userStatistics?.currency?.symbol || ''} ${userStatistics?.totalBetAmount ? Number(userStatistics?.totalBetAmount || 0).toFixed(2) : '-'}`}
							maxLength={50}
							haslabel="yes"
							labeltext="Total Bet Amount"
							readOnly
						/>
					</div>
					<div className="col-lg-4 col-4">
						<InputField
							type="text"
							className="form-control shadow-none"
							id="totalWinAmount"
							name="totalWinAmount"
							value={`${userStatistics?.currency?.symbol || ''} ${userStatistics?.totalWinAmount ? Number(userStatistics?.totalWinAmount || 0).toFixed(2) : '-'}`}
							maxLength={50}
							haslabel="yes"
							labeltext="Total Win Amount"
							readOnly
						/>
					</div>
					<div className="col-lg-4 col-4">
						<InputField
							type="text"
							className="form-control shadow-none"
							id="totalLossAmount"
							name="totalLossAmount"
							value={`${userStatistics?.currency?.symbol || ''} ${userStatistics?.totalBetAmount && userStatistics?.totalWinAmount ? totalLossAmount : '-'}`}
							maxLength={50}
							haslabel="yes"
							labeltext="Total Loss Amount"
							readOnly
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
export default React.memo(Statistics);
