/* eslint-disable import/extensions */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Lottie from 'lottie-react';
// import ARC_LOGO from '../../Icons/ARC_Logo';
import loader from '../../../../assets/json/loader.json';

const GameLoader = () => (
	<div className="loader-parent">
		<div className="full-body-loader">
			<Lottie animationData={loader} loop style={{ width: '90%' }} />
		</div>
	</div>
);

export default GameLoader;
