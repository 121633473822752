export const message = {
	LOGIN_SUCCESS: 'MESSAGE.LOGIN_SUCCESS',
	FORGOT_SUCCESS: 'MESSAGE.FORGOT_SUCCESS',
	WrongEAndPwd: 'MESSAGE.WrongEAndPwd',
	EMPTY_EMAIL: 'MESSAGE.EMPTY_EMAIL',
	EMPTY_PASSWORD: 'MESSAGE.EMPTY_PASSWORD',
	EMPTY_TRANSACTION_PASSWORD: 'MESSAGE.EMPTY_TRANSACTION_PASSWORD',
	INVALID_EMAIL: 'MESSAGE.INVALID_EMAIL',
	NAME_REQUIRED: 'MESSAGE.NAME_REQUIRED',
	USER_NAME_REQUIRED: 'MESSAGE.USER_NAME_REQUIRED',
	FULL_NAME_REQUIRED: 'MESSAGE.FULL_NAME_REQUIRED',
	FIRST_NAME_REQUIRED: 'MESSAGE.FIRST_NAME_REQUIRED',
	LAST_NAME_REQUIRED: 'MESSAGE.LAST_NAME_REQUIRED',
	PHONE_REQUIRED: 'MESSAGE.PHONE_REQUIRED',
	PHONE_LENGTH_REQUIRED: 'MESSAGE.PHONE_LENGTH_REQUIRED',
	ACCOUNT_NO_REQUIRED: 'MESSAGE.ACCOUNT_NO_REQUIRED',
	ACCOUNT_HOLDER_REQUIRED: 'MESSAGE.ACCOUNT_HOLDER_REQUIRED',
	ONLY_CHARECTERS: 'MESSAGE.ONLY_CHARECTERS',
	VALID_PHONE: 'MESSAGE.VALID_PHONE',
	EMPTY_CONFIRM_PASSWORD: 'MESSAGE.EMPTY_CONFIRM_PASSWORD',
	EMPTY_CONFIRM_TRANSACTION_PASSWORD:
		'MESSAGE.EMPTY_CONFIRM_TRANSACTION_PASSWORD',
	PASSWORD_LENGTH: 'MESSAGE.PASSWORD_LENGTH',
	PASSWORD_NOT_MATCH: 'MESSAGE.PASSWORD_NOT_MATCH',
	EMPTY_ACCESS_TOKEN: 'MESSAGE.EMPTY_ACCESS_TOKEN',
	EMPTY_OTP: 'MESSAGE.EMPTY_OTP',
	EMPTY_BANKNAME: 'MESSAGE.EMPTY_BANKNAME',
	SIMPLE_SIGNUP_SUCCESS: 'MESSAGE.SIMPLE_SIGNUP_SUCCESS',
	NUMBER_REQUIRED: 'MESSAGE.NUMBER_REQUIRED',
	DOB_REQUIRED: 'MESSAGE.DOB_REQUIRED',
	ADDRESS_REQUIRED: 'MESSAGE.ADDRESS_REQUIRED',
	EMPTY_COUNTRY: 'MESSAGE.EMPTY_COUNTRY',
	EMPTY_CITY: 'MESSAGE.EMPTY_CITY',
	SOME_THING_WRONG: 'MESSAGE.SOME_THING_WRONG',
	PROJECT_NAME_REQUIRED: 'MESSAGE.PROJECT_NAME_REQUIRED',
	PROJECT_DOMAIN_REQUIRED: 'MESSAGE.PROJECT_DOMAIN_REQUIRED',
	PROJECT_DOMAIN_INVALID: 'MESSAGE.PROJECT_DOMAIN_INVALID',
	FRONTEND_DOMAIN_INVALID: 'MESSAGE.FRONTEND_DOMAIN_INVALID',
	CLIENT_PUBLIC_KEY_REQUIRED: 'MESSAGE.CLIENT_PUBLIC_KEY_REQUIRED',
	BANNER_TITLE_REQUIRED: 'MESSAGE.BANNER_TITLE_REQUIRED',
	BANNER_TYPE_REQUIRED: 'MESSAGE.BANNER_TYPE_REQUIRED',
	BANNER_CATEGORY_REQUIRED: 'MESSAGE.BANNER_CATEGORY_REQUIRED',
	ZIP_CODE_REQUIRED: 'MESSAGE.ZIP_CODE_REQUIRED',
	AGENT_CODE_REQUIRED: 'MESSAGE.AGENT_CODE_REQUIRED',
	CAPTCHA_REQUIRED: 'MESSAGE.CAPTCHA_REQUIRED',
	MUST_BE_18: 'MESSAGE.MUST_BE_18',
	VALID_DATE: 'MESSAGE.VALID_DATE',
	CURRENCY_REQUIRED: 'MESSAGE.CURRENCY_REQUIRED',
	GENDER_REQUIRED: 'MESSAGE.GENDER_REQUIRED',
	NEWS_CHECK_REQUIRED: 'MESSAGE.NEWS_CHECK_REQUIRED',
	SMS_REQUIRED: 'MESSAGE.SMS_REQUIRED',
};

export const errorMessages = {
	betHistory: 'No bet history found',
	promotionPage: 'No promotions found',
	bonusPage: 'No bonuses found',
	bonusPageHistory: 'No history found',
	bonusPageYourBonuses: 'No bonuses found',
	withdrawalHistory: 'No withdawal history found',
	favoriteGames: 'No favorite games found',
	cms: 'No Cms Found',
};

export const selectedLanguage = 'EN';

export const CHAT_ACTIONS = {
	LOGIN: 'addToken',
    LOGOUT: 'removeToken',
    CLOSE_CHAT: 'closeChat',
    LOGIN_PROMPT: 'showLoginPrompt',
    SET_TENANT: 'setTenant',
}