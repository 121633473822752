import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMyBetsService } from 'SportsBook/BetBalancer/Network/Services/mybets.service';

const getMyBets = createAsyncThunk('get/my-bets', async (payload, thunkApi) => {
	try {
		const res = await getMyBetsService(payload.payload);
		return {...res.data?.data, isFetchMore: payload?.isFetchMore};
	} catch (err) {
		return thunkApi.rejectWithValue(
			(err && err[0] && err[0]?.description) ||
				err?.message ||
				'Got error while getting my bets data'
		);
	}
});

export { getMyBets };
