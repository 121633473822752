import React from 'react'
import TablePagination from '@mui/material/TablePagination'
import { PropTypes } from 'prop-types'
// import { useSelector } from 'react-redux';
import { PaginationContainer } from './style'

export const Pagination = ({ setPage, page, totalEvents, perPage }) => {
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1)
  }

  return (
    <PaginationContainer>
      <div className='d-flex justify-content-center pagination-main'>
        <TablePagination
          component='div'
          count={totalEvents}
          page={page - 1}
          onPageChange={handleChangePage}
          rowsPerPage={perPage}
          rowsPerPageOptions={[]}
        />
      </div>
    </PaginationContainer>
  )
}

Pagination.propTypes = {
  setPage: PropTypes.number.isRequired,
  // matches: PropTypes.arrayOf(
  // 	PropTypes.shape({
  // 		id: PropTypes.string,
  // 		fixtureId: PropTypes.string,
  // 		status: PropTypes.string,
  // 		betStatus: PropTypes.string,
  // 		score: PropTypes.string,
  // 		startDate: PropTypes.string,
  // 		league: PropTypes.shape({
  // 			id: PropTypes.string,
  // 			name: PropTypes.string,
  // 			sport: PropTypes.shape({
  // 				id: PropTypes.string,
  // 				name: PropTypes.string,
  // 			}),
  // 		}),
  // 		eventParticipants: PropTypes.arrayOf(
  // 			PropTypes.shape({
  // 				id: PropTypes.string,
  // 				type: PropTypes.string,
  // 				participant: PropTypes.shape({
  // 					id: PropTypes.string,
  // 					name: PropTypes.string,
  // 				}),
  // 			})
  // 		),
  // 	}).isRequired
  // ).isRequired,
  totalEvents: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired
}
