/* eslint-disable import/no-extraneous-dependencies */
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducer from './redux';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['SBbetslip', 'SBgeneral', 'SBsettings', 'SBglobalPersist'],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
	reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;
