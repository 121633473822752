import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSportBookExchangeService } from 'SportsBook/BetBalancer/Network/Services/sportsbook.exchage';

const getSportBookExchange = createAsyncThunk(
	'bet/sport-book-exchange',
	async (payload, thunkApi) => {
		try {
			const res = await getSportBookExchangeService(payload);
			return res.data?.data;
		} catch (err) {
			return thunkApi.rejectWithValue(
				(err && err[0] && err[0]?.description) ||
					err?.message ||
					'Got error while getting my bets data'
			);
		}
	}
);

export { getSportBookExchange };
