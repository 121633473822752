/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { PropTypes } from 'prop-types';
// import { styled } from '@mui/material/styles';
import {
	TableCell,
	Typography,
	Table,
	TableBody,
	Grid,
	TableContainer,
	TableRow,
} from '@mui/material';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import StarIcon from '@mui/icons-material/Star';
import { useTranslation } from 'react-i18next';
// import CountryViewBar from '../CountryViewBar/index';
import NoDataFound from 'SportsBook/BetBalancer/Components/NoDataFound/NoDataFound';
import moment from 'moment';
import { MatchesListContainer } from './style';
import MainMarkets from './MainMarkets';
import ListSkeleton from '../SkeltonLoaders/ListSkeleton';
import MainMarketsMobileView from './MainMarketsMobileView';

const MatchesList = ({ matches, loading }) => {
	const { t } = useTranslation();
	return (
		<MatchesListContainer>
			{!loading && !matches?.length ? (
				<NoDataFound title={t('matchesNotAvailable')} />
			) : (
				<Grid className="table-wrapper">
					<TableContainer>
						<Table aria-label="customized table">
							<TableBody>
								{!loading && matches?.length ? (
									matches?.map((match) => (
										<TableRow key={match.id}>
											<TableCell>
												<Grid
													display="flex"
													gap={1.5}
													alignItems="center"
													mb={1}
												>
													<Typography
														display="flex"
														alignItems="center"
														gap={1}
													>
														<span>
															<StarIcon />
														</span>
														<span>
															<SignalCellularAltIcon />
														</span>
													</Typography>
													<h4>{match?.league?.name}</h4>
												</Grid>
												<Grid>
													<Grid className="team-logo--name---parent">
														<Grid
															className="match-time-date"
															display="flex"
															justifyContent="center"
															alignItems="center"
															flexDirection="column"
														>
															<Typography variant="span">
																<b>
																	{moment(match?.startDate).format(
																		'MM-DD-yyyy hh:mm a'
																	)}
																</b>
															</Typography>
															{/* <Typography variant="span">01:30</Typography> */}
														</Grid>

														<Grid
															className="team-logo--name"
															display="flex"
															justifyContent="center"
															alignItems="center"
															flexDirection="column"
															gap={2}
														>
															<Grid
																sx={{ width: '100%' }}
																display="flex"
																justifyContent="center"
																alignItems="center"
																gap={1}
															>
																<img
																	src="https://files-immutable-4cbc033nbd3.sptpub.com/flags_by_code/ECU.svg"
																	alt="ECU"
																/>
																<Typography>
																	<span className="team-name">
																		{
																			match?.eventParticipants[0]?.participant
																				?.name
																		}
																	</span>
																</Typography>
															</Grid>

															<Grid
																sx={{ width: '100%' }}
																display="flex"
																justifyContent="center"
																alignItems="center"
																gap={1}
															>
																<img
																	src="https://files-immutable-4cbc033nbd3.sptpub.com/flags_by_code/ECU.svg"
																	alt=" Bolivia "
																/>
																<Typography>
																	<span className="team-name">
																		{
																			match?.eventParticipants[1]?.participant
																				?.name
																		}
																	</span>
																</Typography>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
												<MainMarketsMobileView
													eventMarkets={match?.eventMarkets || []}
													match={match}
												/>
											</TableCell>
											<MainMarkets
												eventMarkets={match?.eventMarkets || []}
												match={match}
											/>
										</TableRow>
									))
								) : (
									<ListSkeleton />
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			)}
		</MatchesListContainer>
	);
};
MatchesList.defaultProps = {
	// text: '',
};

MatchesList.propTypes = {
	// text: PropTypes.string,
	matches: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			fixtureId: PropTypes.string,
			status: PropTypes.string,
			betStatus: PropTypes.string,
			score: PropTypes.string,
			startDate: PropTypes.string,
			league: PropTypes.shape({
				id: PropTypes.string,
				name: PropTypes.string,
				sport: PropTypes.shape({
					id: PropTypes.string,
					name: PropTypes.string,
				}),
			}),
			eventParticipants: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.string,
					type: PropTypes.string,
					participant: PropTypes.shape({
						id: PropTypes.string,
						name: PropTypes.string,
					}),
				})
			),
		}).isRequired
	).isRequired,
	loading: PropTypes.bool.isRequired,
};
export default MatchesList;
