/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { Grid, Button, TextField, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DatePickerInput from 'components/molecules/DatePicker/DatePicker';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { getUserTransactions } from 'redux-thunk/thunk/user.thunk';
import {
	LOADER_HANDLER_TYPES,
	ROUTE_PATHS,
	TABLE_CELL_TYPE,
	TRANSACTIONS_LIMIT,
} from 'constants/index';
import { DepositProfileIcon } from 'components/ui-kit/Icons/iconComponents/DepositProfileIcon';
// import AutorenewSharpIcon from '@mui/icons-material/AutorenewSharp';
import './UsersTransactions.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { momentAPIDate } from 'helpers/moment.helpers';
import Pagination from 'components/ui-kit/Pagination/index';
import Loader from 'components/ui-kit/Loader/index';
import PropTypes from 'prop-types';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CustomCollapse from 'components/ui-kit/Collapse/index';

const tableColumns = [
	{
		id: uuidv4(),
		label: 'Date Time',
		value: 'createdAt',
		type: TABLE_CELL_TYPE.date,
	},

	{
		id: uuidv4(),
		label: 'Amount',
		value: 'targetWallet',
		key: 'amount',
		type: TABLE_CELL_TYPE.object,
	},
	{
		id: uuidv4(),
		label: 'Bonus',
		value: 'targetWallet',
		key: 'amount',
		type: TABLE_CELL_TYPE.object,
	},
	{
		id: uuidv4(),
		label: 'Debit / Credit',
		value: 'targetWallet',
		key: 'amount',
		type: TABLE_CELL_TYPE.object,
	},
	{
		id: uuidv4(),
		label: 'Detail',
		value: 'transactionType',
		key: 'userName',
		type: TABLE_CELL_TYPE.text,
	},
	{
		id: uuidv4(),
		label: 'Status',
		value: 'status',
		type: TABLE_CELL_TYPE.text,
	},
];

const UsersTransactions = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { isUserTransactionLoading } = useSelector(
		(state) => state.transactions
	);
	// const [transactionType, setTransactionType] = useState('all');
	const [currencyType, setCurrencyType] = useState('all');
	const [purposeType, setPurposeType] = useState('all');
	// eslint-disable-next-line no-unused-vars
	const [numberOfRecords, setNumberOfRecords] = useState(10);
	const dateObj = new Date();
	const initialEndDate = dateObj.setUTCHours(0, 0, 0, 0);
	const initialStartDate = dateObj.setDate(dateObj.getDate() - 30);
	const [date, setDate] = useState({
		startDate: new Date(initialStartDate),
		endDate: new Date(initialEndDate),
	});
	const [page, setPage] = useState(1);
	const [open, setOpen] = useState(false);
	const { allCurrencies } = useSelector((state) => state?.gameSetting);
	const formattedCurrencies = [{ name: 'All', id: 'all' }, ...allCurrencies];

	const currencyFilterData = (type) => {
		setCurrencyType(type);
	};
	const purposeFilterData = (type) => {
		setPurposeType(type);
	};

	const clearFilterData = () => {
		// setTransactionType('all');
		setCurrencyType('all');
		setPurposeType('all');
		setDate({
			startDate: new Date(initialStartDate),
			endDate: new Date(initialEndDate),
		});
		setPage(1);
	};
	useEffect(() => {
		dispatch(
			getUserTransactions({
				startDate: momentAPIDate(date.startDate || moment().startOf('day')),
				endDate: momentAPIDate(date.endDate || moment().endOf('day')),
				page: page || 0,
				perPage: numberOfRecords || 10,
				purposeType: purposeType === 'all' ? '' : purposeType,
				currencyId: currencyType === 'all' ? '' : currencyType,
			})
		);
	}, [date, page, currencyType, purposeType, numberOfRecords]);

	const { usersTransactions } = useSelector((state) => state.user);

	const transactions = usersTransactions?.transactions;

	return (
		<div
			className={`tab-pane fade ${
				pathname === ROUTE_PATHS.TRANSACTIONS ? 'show active' : ''
			}`}
			id="v-pills-transaction"
			role="tabpanel"
			aria-labelledby="v-pills-transaction-tab"
		>
			<div className="profile-card-wrap">
				{/* <h1 className='section-heading'> {t('accountMenuTransactions')}</h1> */}
				<Grid container spacing={2} style={{ marginBottom: 10 }}>
					<Grid item md={6} xs={12}>
						<div className="profile-heading mb-3">
							<h4 className="m-0">
								<DepositProfileIcon /> {t('Transactions')}
							</h4>
						</div>
					</Grid>
					<Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
						<Button
							variant="outlined"
							onClick={() => setOpen(!open)}
							endIcon={<FilterAltOutlinedIcon />}
							className="btn btn-secondary"
						>
							Filters
						</Button>
					</Grid>
				</Grid>
				<CustomCollapse open={open}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={3} className="d-flex flex-column">
							<label htmlFor="DOB" className="form-label  ">
								{t('Start Date')}
								<span className="color-red">&nbsp;*</span>
							</label>
							<DatePickerInput
								className="form-control shadow-none"
								selected={date.startDate}
								showYearDropdown
								handleDateChange={(data) => {
									setDate((prevState) => ({
										...prevState,
										startDate: data || new Date(),
									}));
									setPage(1);
								}}
								selectsStart
								maxDate={date.endDate || initialStartDate}
								// minDate={initialStartDate}
								placeholder={t('inputsStart')}
							/>
						</Grid>
						<Grid item xs={12} sm={3} className="d-flex flex-column">
							<label htmlFor="End Date" className="form-label  ">
								{t('End Date')}
								<span className="color-red">&nbsp;*</span>
							</label>
							<DatePickerInput
								className="form-control shadow-none "
								selected={date.endDate}
								handleDateChange={(data) => {
									setDate((prevState) => ({
										...prevState,
										endDate: data || new Date(),
									}));
									setPage(1);
								}}
								// disableFuture
								showYearDropdown
								startDate={date.startDate}
								maxDate={initialEndDate}
								minDate={date.startDate}
								placeholder={t('End Date')}
							/>
						</Grid>
						{/* <Grid
							item
							xs={12}
							sm={3}
							className="d-flex flex-column transaction-type"
						>
							<label className="form-label  " htmlFor="types">
								{t('Types')}
								<span className="color-red">&nbsp;*</span>
							</label>
							<TextField
								id="transaction-type"
								variant="outlined"
								select
								value={transactionType}
								onChange={(e) => {
									filterData(e.target.value);
								}}
								className="nav transaction-tab horizontal-theme-tabs"
							>
								<MenuItem value="all">{t('all')} </MenuItem>
								<MenuItem value="Deposit">{t('credit')}</MenuItem>
								<MenuItem value="Withdraw">{t('debit')}</MenuItem>
							</TextField>
						</Grid> */}
						<Grid
							item
							xs={12}
							sm={3}
							className="d-flex flex-column transaction-type"
						>
							<label className="form-label  " htmlFor="types">
								Debit / Credit
								<span className="color-red">&nbsp;*</span>
							</label>
							<TextField
								id="transaction-type"
								variant="outlined"
								select
								value={purposeType}
								onChange={(e) => {
									setPage(1);
									purposeFilterData(e.target.value);
								}}
								className="nav transaction-tab horizontal-theme-tabs "
							>
								<MenuItem value="all">{t('all')} </MenuItem>
								<MenuItem value="credit">{t('CREDIT')} </MenuItem>
								<MenuItem value="debit">{t('DEBIT')}</MenuItem>
							</TextField>
						</Grid>
						<Grid
							item
							xs={12}
							sm={3}
							className="d-flex flex-column transaction-type"
						>
							<label className="form-label  " htmlFor="types">
								{t('currencyTypes')}

								<span className="color-red">&nbsp;*</span>
							</label>
							<TextField
								id="transaction-type"
								variant="outlined"
								select
								value={currencyType}
								onChange={(e) => {
									setPage(1);
									currencyFilterData(e.target.value);
								}}
								className="nav transaction-tab horizontal-theme-tabs "
							>
								{formattedCurrencies.map((item) => (
									<MenuItem value={item.id}>{item.name} </MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid xs={12} display="flex" justifyContent="end">
							<Button
								onClick={() => clearFilterData()}
								className="btn  trans-btn"
							>
								{t('resetButton')}
							</Button>
						</Grid>
					</Grid>
				</CustomCollapse>

				<Grid xs={12}>
					<div className="table-responsive">
						<table className="table theme-table">
							<thead>
								<tr>
									{tableColumns.map((column) => (
										<th
											key={column?.id}
											scope="col"
											className="  text-center text-nowrap"
										>
											{column.label}
										</th>
									))}
								</tr>
							</thead>
							{isUserTransactionLoading ? (
								<tbody>
									<td colSpan={tableColumns?.length || 4}>
										<Loader variant={LOADER_HANDLER_TYPES.content} />
									</td>
								</tbody>
							) : (
								<tbody>
									{transactions?.length > 0 ? (
										transactions?.map((data) => (
											<UsersTransactionsRow data={data} />
										))
									) : (
										<tr>
											<td colSpan={7} className="text-center text-white">
												{/* {loading ? <Loader variant={LOADER_HANDLER_TYPES.table} /> : 'Data Not Found'} */}
												{t('dataNotFound')}
											</td>
										</tr>
									)}
								</tbody>
							)}
						</table>
					</div>
					{transactions?.length !== 10 && page === 1 ? null : (
						<Pagination
							page={page}
							setPage={setPage}
							limit={TRANSACTIONS_LIMIT}
							dataLength={transactions?.length}
							totalPages={usersTransactions?.totalPages}
						/>
					)}
				</Grid>
			</div>
		</div>
	);
};

const UsersTransactionsRow = ({ data }) => (
	// const { t } = useTranslation();

	<tr key={data?.transactionId}>
		<td className="text-center">
			<div className="text-box justify-content-start">
				<span className="icon" />
				<span className="text">
					{moment.utc(data?.createdAt).local().format('DD/MM/YYYY')}
					&nbsp;&nbsp;
					{moment.utc(data?.createdAt).local().format('h:mm A')}
				</span>
			</div>
		</td>

		<td className="text-center">
			<div className="text-box">
				<span className="text">
					{data?.ledger?.amount} {`${data?.ledger?.currency?.code}`}
				</span>
			</div>
		</td>
		<td className="text-center">
			<div className="text-box">
				<span className="text">
					{data?.ledger?.bonusAmount} {`${data?.ledger?.currency?.code}`}
				</span>
			</div>
		</td>
		<td className="text-center">
			<div className="text-box">
				<span className="icon" />
				<span className="text  ">
					{!data?.ledger?.fromWalletId ? (
						<span className="text-green">Credit</span>
					) : (
						<span className="text-red">Debit</span>
					)}
				</span>
			</div>
		</td>
		<td className="text-center">
			<div className="text-box justify-content-end">
				<span className="text  ">{data?.ledger?.purpose}</span>
				<span className="icon" />
			</div>
		</td>
		<td className="text-center">
			<div className="text-box justify-content-end">
				<span className="text" style={{ textTransform: 'capitalize' }}>
					{' '}
					{data?.status || ''}
				</span>
			</div>
		</td>
	</tr>
);
// const UsersTransactionsFilters = () => <></>;

UsersTransactionsRow.propTypes = {
	data: PropTypes.shape({
		transactionId: PropTypes.number.isRequired,
		createdAt: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.instanceOf(Date),
		]).isRequired,
		ledger: PropTypes.shape({
			type: PropTypes.string.isRequired,
			purpose: PropTypes.string.isRequired,
			currencyId: PropTypes.number.isRequired,
			amount: PropTypes.number.isRequired,
			fromWalletId: PropTypes.string,
			currency: PropTypes.shape({
				code: PropTypes.string,
			}),
		}).isRequired,
		status: PropTypes.string.isRequired,
	}).isRequired,
};

export default UsersTransactions;
