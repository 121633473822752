import { openErrorToaster } from 'SportsBook/BetBalancer/Helper/toaster';
import i18n from 'i18next';
import { signOut } from 'SportsBook/BetBalancer/Helper/storage';
import ERROR_MESSAGE from './errorMessages';

export const responseHandler = (response) => response;

export const errorHandler = (err) => {
	if (err.response.status === 500) {
		// Snackbar Internal Server Error
		openErrorToaster({
			message: i18n.t(ERROR_MESSAGE.internalServerError),
		});
		return Promise.reject(err.response.data.errors);
	}
	if (err.response.status === 401) {
		// Snackbar UnAuthed
		openErrorToaster({
			message:
				(err.response.data &&
					err.response.data?.errors &&
					err.response.data?.errors[0]?.description) ||
				i18n.t(ERROR_MESSAGE.unAuthorized),
		});
		signOut();
		return Promise.reject(err.response.data.errors);
	}
	// Open Error Toaster
	const errorMessage =
		(err.response.data &&
			err.response.data?.errors &&
			err.response.data?.errors[0]?.description) ||
		'Something went wrong!';
	openErrorToaster({
		message: i18n.t(
			ERROR_MESSAGE[errorMessage] ? ERROR_MESSAGE[errorMessage] : errorMessage
		),
	});

	return Promise.reject(err.response.data.errors);
};
