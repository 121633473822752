import * as Yup from 'yup';

// Schema for EN with Extra Validations
export const userSigninSchemaEN = (regFields, t) =>
	regFields &&
	Yup.object().shape({
		email:
			regFields.email &&
			Yup.string()
				.email(t('invalidEmail'))
				.max(50, t('max50Characters'))
				.required(t('emailRequired')),

		password:
			regFields.password &&
			Yup.string()
				.matches(
					/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,}$/,
					t('invalidPass')
				)
				.max(50, t('max50Characters'))
				.required(t('passwordRequired')),
		// confirmPassword:
		// 	regFields.confirmPassword &&
		// 	Yup.string()
		// 		.max(50, t('max50Characters'))
		// 		.oneOf([Yup.ref('password'), null], t('matchPassword'))
		// 		.required(t('confirmPasswordRequired')),
		firstName:
			regFields.firstName &&
			Yup.string()
				.min(3, t('fName3Chars'))
				.matches(/^([^0-9]*)$/, t('notNumbers'))
				.max(50)
				.required(t('firstNameRequired')),
		lastName:
			regFields.lastName === 2
				? Yup.string()
						.matches(/^([^0-9]*)$/, t('notNumbers'))
						.min(3, t('lName3Chars'))
						.max(50)
						.required(t('lastNameRequired'))
				: Yup.string()
						.matches(/^([^0-9]*)$/, t('notNumbers'))
						.min(3, t('lName3Chars'))
						.max(50)
						.nullable(),
		// phone:
		// 	regFields.phone === 2
		// 		? Yup.string().required(t('phoneRequired')).nullable()
		// 		: regFields.phone !== 0 && Yup.string().nullable(),
		// dateOfBirth:
		// 	regFields.dateOfBirth === 2
		// 		? Yup.date()
		// 				.max(
		// 					new Date(
		// 						new Date().getFullYear() - 18,
		// 						new Date().getMonth(),
		// 						new Date().getDate() - 1
		// 					),
		// 					t('mustBe18')
		// 				)
		// 				.typeError(t('validDOB'))
		// 				.required(t('dateOfBirthRequired'))
		// 		: regFields.dateOfBirth !== 0 &&
		// 			Yup.date()
		// 				.max(
		// 					new Date(
		// 						new Date().getFullYear() - 18,
		// 						new Date().getMonth(),
		// 						new Date().getDate() - 1
		// 					),
		// 					t('mustBe18')
		// 				)
		// 				.typeError(t('validDOB'))
		// 				.nullable(),
		// gender:
		// 	regFields.gender === 2
		// 		? Yup.string().required(t('genderRequired'))
		// 		: regFields.gender !== 0 && Yup.string().nullable(),
		username:
			regFields.username === 2
				? Yup.string()
						.max(20, t('max20Characters'))
						.min(6, t('min6Characters'))
						.matches(/^[A-Za-z][^\s]*$/, t('userNameAllowed'))
						.required(t('userNameRequired'))
				: regFields.username !== 0 &&
					Yup.string()
						.max(20, t('max20Characters'))
						.min(6, t('min6Characters'))
						.matches(/^[A-Za-z][^\s]*$/, t('userNameAllowed'))
						.nullable(),

		// address:
		// 	regFields.address === 2
		// 		? Yup.string()
		// 				.max(100, t('max100Characters'))
		// 				.required(t('addressRequired'))
		// 				.nullable()
		// 		: regFields.address !== 0 &&
		// 			Yup.string().max(100, t('max100Characters')).nullable(),
		// city:
		// 	regFields.address === 2
		// 		? Yup.string()
		// 				.max(50, t('max50Characters'))
		// 				.required(t('cityRequired'))
		// 				.nullable()
		// 		: regFields.address !== 0 &&
		// 			Yup.string().max(50, t('max50Characters')).nullable(),
		// zipCode:
		// 	regFields.address === 2
		// 		? Yup.string().required(t('postCodeRequired')).nullable()
		// 		: regFields.address !== 0 && Yup.string().nullable(),
		// currencyCode:
		// 	regFields.currencyCode &&
		// 	Yup.string().required(t('currencyCodeRequired')),
		// countryCode:
		// 	regFields.countryCode === 2
		// 		? Yup.string().required(t('countryRequired'))
		// 		: regFields.countryCode !== 0 && Yup.string().nullable(),
		// preferredLanguage:
		// 	regFields.preferredLanguage === 2
		// 		? Yup.string().required(t('preferredLanguageRequired'))
		// 		: regFields.preferredLanguage !== 0 && Yup.string().nullable(),
	});

// Schema for Other Languages
export const userSigninSchema = (regFields, t) =>
	regFields &&
	Yup.object().shape({
		email:
			regFields.email &&
			Yup.string()
				.email(t('invalidEmail'))
				.max(50)
				.required(t('emailRequired')),

		password:
			regFields.password &&
			Yup.string()
				.matches(
					/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,}$/,
					t('invalidPass')
				)
				.min(8)
				.max(50)
				.required(t('passwordRequired')),
		// confirmPassword:
		// 	regFields.confirmPassword &&
		// 	Yup.string()
		// 		.min(8)
		// 		.max(50)
		// 		.oneOf([Yup.ref('password'), null], t('matchPassword'))
		// 		.required(t('confirmPasswordRequired')),
		// firstName:
		// 	regFields.firstName &&
		// 	Yup.string()
		// 		.min(3, t('fName3Chars'))
		// 		.matches(/^([^0-9]*)$/, t('notNumbers'))
		// 		.matches(/^[A-Za-z0-9\s]+$/, t('noSpecialChars'))
		// 		.matches(/^(?!\s)/, t('cannotStartWithSpace'))
		// 		.max(50, t('max50Characters'))
		// 		.required(t('firstNameRequired')),
		// lastName:
		// 	regFields.lastName === 2
		// 		? Yup.string()
		// 				.min(3, t('lName3Chars'))
		// 				.matches(/^(?!\s)/, t('cannotStartWithSpace'))
		// 				.max(50, t('max50Characters'))
		// 				.matches(/^[A-Za-z0-9]+$/, t('noSpecialChars'))
		// 				.matches(/^([^0-9]*)$/, t('notNumbers'))
		// 				.required(t('lastNameRequired'))
		// 		: Yup.string()
		// 				.min(3, t('lName3Chars'))
		// 				.max(50, t('max50Characters'))
		// 				.matches(/^([^0-9]*)$/, t('notNumbers'))
		// 				.nullable(),
		// phone:
		// 	regFields.phone === 2
		// 		? Yup.string().required(t('phoneRequired')).nullable()
		// 		: regFields.phone !== 0 && Yup.string().nullable(),
		// dateOfBirth:
		// 	regFields.dateOfBirth === 2
		// 		? Yup.date()
		// 				.max(
		// 					new Date(
		// 						new Date().getFullYear() - 18,
		// 						new Date().getMonth(),
		// 						new Date().getDate() - 1
		// 					),
		// 					t('mustBe18')
		// 				)
		// 				.typeError(t('validDOB'))
		// 				.required(t('dateOfBirthRequired'))
		// 		: regFields.dateOfBirth !== 0 &&
		// 			Yup.date()
		// 				.max(
		// 					new Date(
		// 						new Date().getFullYear() - 18,
		// 						new Date().getMonth(),
		// 						new Date().getDate() - 1
		// 					),
		// 					t('mustBe18')
		// 				)
		// 				.typeError(t('validDOB'))
		// 				.nullable()
		// 				.required(t('dateOfBirthRequired')),

		// gender:
		// 	regFields.gender === 2
		// 		? Yup.string().required(t('genderRequired'))
		// 		: regFields.gender !== 0 && Yup.string().nullable(),
		username:
			regFields.username === 2
				? Yup.string()
						.max(20, t('max20Characters'))
						.min(6, t('min6Characters'))
						.matches(/^[A-Za-z][A-Za-z0-9]*$/, t('userNameAllowed'))
						.required(t('userNameRequired'))
				: regFields.username !== 0 &&
					Yup.string()
						.max(20, t('max20Characters'))
						.min(6, t('min6Characters'))
						.matches(/^[A-Za-z][A-Za-z0-9]*$/, t('userNameAllowed'))
						.nullable(),

		// address:
		// 	regFields.address === 2
		// 		? Yup.string()
		// 				.max(100, t('max100Characters'))
		// 				.required(t('addressRequired'))
		// 				.nullable()
		// 		: regFields.address !== 0 &&
		// 			Yup.string().max(100, t('max100Characters')).nullable(),
		// city:
		// 	regFields.address === 2
		// 		? Yup.string()
		// 				.max(50, t('max50Characters'))
		// 				.required(t('cityRequired'))
		// 				.nullable()
		// 		: regFields.address !== 0 &&
		// 			Yup.string().max(50, t('max50Characters')).nullable(),
		// zipCode:
		// 	regFields.address === 2
		// 		? Yup.string().required(t('postCodeRequired')).nullable()
		// 		: regFields.address !== 0 && Yup.string().nullable(),
		// currencyCode:
		// 	regFields.currencyCode &&
		// 	Yup.string().required(t('currencyCodeRequired')),
		// countryCode:
		// 	regFields.countryCode === 2
		// 		? Yup.string().required(t('countryRequired'))
		// 		: regFields.countryCode !== 0 && Yup.string().nullable(),
		// preferredLanguage:
		// 	regFields.preferredLanguage === 2
		// 		? Yup.string().required(t('preferredLanguageRequired'))
		// 		: regFields.preferredLanguage !== 0 && Yup.string().nullable(),
	});
