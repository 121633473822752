import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	depositData: null,
	showWalletPopup: false,
};
const {
	actions: { setDepositData, setShowWalletPopup },
	reducer,
} = createSlice({
	name: 'wallet',
	initialState,
	reducers: {
		setShowWalletPopup: (state, action) => ({
			...state,
			showWalletPopup: action.payload,
		}),
		setDepositData: (state) => ({
			...state,
			depositData: null,
		}),
	},
});

export default reducer;
export { setDepositData, setShowWalletPopup };
