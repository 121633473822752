import styled from 'styled-components';

export const CountryContainer = styled.div`
	.countyFilterSection {
		padding: 1rem;
		button {
			display: flex;
			flex-direction: row;
			gap: 10px;
			background-color: var(--topNavigationBg);
			min-height: auto;
			color: var(--white);
			margin-right: 1rem;
			border-radius: 50px;
			font-size: 12px;
			padding: 0.28rem 0.8rem;
			.country-icon {
				margin-bottom: 0;
				svg {
					width: 20px;
				}
			}
		}
		.odds-section {
			svg {
				color: var(--white);
			}
			.MuiOutlinedInput-root {
				border: 1px solid #25394f;
				fieldset {
					display: none;
				}
			}
		}
	}

	.MuiTabs-indicator {
		display: none;
	}

	button.Mui-selected {
		background-color: var(--secondaryRadialBtn);
		color: var(--button-text);
		border-color: var(--secondry-border);
		box-shadow:
			rgba(255, 176, 25, 0.4) 0px 0px 0px,
			rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
			rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
			rgb(255, 135, 25) 0px 0px 15px inset;
	}

	.MuiTabs-scrollButtons {
		svg {
			color: var(--white);
			font-size: 2rem;
		}
	}
`;
