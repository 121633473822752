/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Grid } from '@mui/material/index';
import { getSports } from 'SportsBook/BetBalancer/Store/thunk/sports.thunk';
import { selectPreMatchSport } from 'SportsBook/BetBalancer/Store/slice/sports.slice';
import { SPORTS_PROVIDER_ID } from 'SportsBook/BetBalancer/Helper/sports.config';
import GameLoader from 'components/ui-kit/Loader/GameLoader/GameLoader';
import { getSboUrl } from 'redux-thunk/thunk/game.thunk';
import { emptySboUrl } from 'redux-thunk/redux/slices/games.slice';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';
// import TopSportsBar from './TopSportsBar/index';
// import MatchesContainer from './MatchesContainer';
import { SportsBookContainer } from './style';
// import Banner from '../Banner/index';
// import OddsType from './OddsType/index';
// import Betslip from '../Betslip/index';

const SportBookLobby = () => {
	const dispatch = useDispatch();
	const { showSideNav } = useSelector((state) => state?.settings);
	const { subCategory = 0 } = useParams();
	const loginData = useSelector((state) => state.auth?.loginData);
	const url = useSelector((state) => state.games.sboUrl);
	const [sboUrlDevice, setSboUrlDevice] = useState('d');
	const [gameLoader, gameLoaderStatus] = useState(true);
	const [width, setWidth] = useState(window.innerWidth);

	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	useEffect(() => {
		dispatch(getSports());
		dispatch(selectPreMatchSport(SPORTS_PROVIDER_ID.SOCCER));
		return () => {
			dispatch(emptySboUrl());
		};
	}, []);

	useEffect(() => {
		if (loginData?.accessToken) {
			dispatch(
				getSboUrl({
					isMobile,
					...(subCategory ? { sboSubCategoryId: subCategory } : {}),
				})
			);
			gameLoaderStatus(true);
		}
	}, [subCategory]);

	// useEffect(() => {
	// 	let timer;
	// 	clearTimeout(timer);
	// 	timer = setTimeout(() => {
	// 		dispatch(getSboUrl());
	// 	}, [1000]);
	// 	return () => clearTimeout(timer);
	// }, [width]);

	useEffect(() => {
		if (width <= 768) {
			setSboUrlDevice('m');
		} else setSboUrlDevice('d');
	}, [width]);
	return (
		<SportsBookContainer>
			{gameLoader && loginData?.accessToken && (
				<div className="game-loader" textAlign="center">
					<GameLoader />
				</div>
			)}
			{loginData?.accessToken && url && (
				<section className={`${!showSideNav ? 'sidebar-close' : ''}`}>
					<div className="sports-custom-spacer">
						<iframe
							className="sports-iframe"
							src={`${url}&device=${sboUrlDevice}`}
							width="100%"
							height="100%"
							title="sports"
							alt="Web site is not avaialable"
							onLoad={() => gameLoaderStatus(false)}
						/>
					</div>
				</section>
			)}
			{!loginData?.accessToken && (
				<section className={`${!showSideNav ? 'sidebar-close' : ''}`}>
					<div className="sports-custom-spacer">
						<div className="sports-login-msg">
							<img src="/assets/images/homePage/restricted-area.png" alt="" />
							<span>Please Login To Access Sports</span>
						</div>
					</div>
				</section>
			)}
		</SportsBookContainer>
	);
};

export default SportBookLobby;
