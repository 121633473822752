import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSportInfoService } from 'SportsBook/BetBalancer/Network/Services/sportInfo.service';

const getSportInfo = createAsyncThunk(
	'get/sports-info',
	async (payload, thunkApi) => {
		try {
			const res = await getSportInfoService(payload);
			return res.data.data;
		} catch (err) {
			return thunkApi.rejectWithValue(
				(err && err[0] && err[0]?.description) ||
					err?.message ||
					'Got error while getting sports data'
			);
		}
	}
);

export { getSportInfo };
