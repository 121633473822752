import styled from 'styled-components';

export const MatchesListContainer = styled.div`
  .table-wrapper {
    padding: 0 1rem;
    .top-heading {
      padding: 1rem 1rem 0 1rem;
      display: flex;
      h4 {
        font-size: 15px;
        font-weight: bold;
        align-items: center;
        color: var(--white);
        img {
          margin-right: 8px;
        }
      }
      .view-all--btn {
        color: var(--headingLink);
        font-size: 0.75rem;
        font-weight: 600;
        position: relative;
        top: 3px;
        @media screen and (max-width: 575px){
          top: 0px;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
    table {
      tbody {
        .second-table-cell {
          @media screen and (max-width: 1199px){
            display: none;
          }
        }
        tr {
          background-color: var(--boxViewBg);
          border-bottom: 3px solid var(--darkGray);
          .desktop-view {
            @media screen and (max-width: 767px){
              display: none;
            }
          }
          .mobile-view {
            display: none;
            @media screen and (max-width: 767px){
              display: block;
              margin-top: 10px;
            }
            .more-market--button {
              @media screen and (max-width: 767px){
                display: none;
              }
            }
          }
          td {
            color: rgba(255, 255, 255, 0.5);
            border-bottom: 1px solid transparent;
            padding: 10px;
            h4 {
              font-size: 14px;
              margin-bottom: 0;
            }
            p {
              span {
                svg {
                  font-size: 1.2rem;
                  width: 1.2rem !important;
                  cursor: pointer;
                  height: inherit !important;
                }
              }
            }
          }
        }
      }
    }

    .team-logo--name---parent {
      display: flex;
      align-items: center;
      gap: 1rem;
      .match-time-date {
        min-width: 40px;
        align-items: flex-start;
      }
      .team-logo--name {
        align-items: flex-start;
        gap: 8px;
        width: 100%;
        p {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          span.team-name {
            overflow: hidden;
            white-space: nowrap;
            max-width: 350px;
            font-size: 13px;
          }
          span.match-score {
            color: rgb(255, 255, 255);
    border: 1px solid rgba(255, 255, 255,0.1);
    height: 26px;
    width: 26px;
    padding: 4px;
    font-size: 12px;
    background-color: rgba(255, 255, 255, 0.05);
    box-sizing: border-box;
    align-items: center;
    font-weight: bold;
    line-height: 16px;
    border-radius: 5px;
    justify-content: center;
    display: flex;
}
          }
        }
        img {
          width: 20px;
        }
      }
      .match-time-date {
        span {
          font-size: 12px;
        }
      }
    }
    .market-odds-wrapper {
      .more-market {
        cursor: pointer;
        li {
          min-height: 56px;
          @media screen and (max-width: 767px){
            min-height: auto;
          }
        }
        p {
          font-size: 12px;
        }
      }

      ul {
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 8px;
        background-color: var(--oddsParentBg);
        li {
          border-right: 1px solid rgba(255, 255, 255, 0.5);
          padding: 5px !important;
          &:last-child {
            border-right: 1px solid transparent;
          }
          button {
            span {
              text-transform: lowercase;
              line-height: normal;
              margin-bottom: 0.5rem;
            }
            h5 {
              gap: 5px;
              width: 100%;
              span {
                background-color: var(--sidebar-bg);
                width: 100% !important;
                padding: 5px;
                border-radius: 5px;
                font-size: 12px;
                @media (max-width: 1199px) {
                  font-size: 12px;
                }
                margin-bottom: 0;
                &.active, &:hover {
                  background-color: var(--secondaryRadialBtn) !important;
                  color: var(--button-text);
                }
                position: relative;
                .odds-down,
                .odds-up {
                  position: absolute;
                  top: 3px;
                  right: 3px;
                  height: 7px;
                  width: 7px !important;
                  border-radius: 50%;
                  display: inline-block;
                  padding: 0;
                }
                .odds-up {
                  background-color: green !important;
                }
                .odds-down {
                  background-color: red !important;
                }
              }
            }
          }
        }
      }
    }
    .market-odds-wrapper .static{
        background-color: var(--sidebar-bg);
        width: 100%!important;
        padding: 5px;
        border-radius: 5px;
        font-size: 12px;
        margin-bottom: 0;
        position: relative;
        color: #fff;
        display: flex;
        justify-content: center;
    }
    .market-name--wrapper,
    .market-odds-wrapper {
      ul {
        display: flex;
        padding: 0;
        li {
          // width: 100%;
          text-align: center;
          justify-content: center;
          padding: 0;
          font-size: 12px;
          @media (max-width: 1199px) {
            font-size: 12px;
          }
          button {
            display: flex;
            flex-direction: column;
            // width: 100%;
            padding: 0;
            span {
              font-size: 12px;
              color: var(--white);
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              width: 85px;
              @media (max-width: 1199px) {
                font-size: 12px;
              }
            }
            h5 {
              // width: 100%;
              display: flex;
              justify-content: space-between;
              margin-bottom: 0;
              font-size: 14px;
              span {
                svg {
                  font-size: 12px;
                  height: auto;
                }
              }
            }
          }
        }
      }
    }
  }
`;
