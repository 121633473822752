import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { removeAllBets } from 'SportsBook/BetBalancer/Store/slice/betslip.slice';
import { oddsFormatter } from 'SportsBook/BetBalancer/Helper/utils';
import useBetslip from './hooks/useBetslip';

const ActionBottom = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { isBetPlaceLoading, betError, totalOdds } = useSelector(
		(state) => state.SBbetslip
	);
	const { oddsType } = useSelector((state) => state.SBgeneral);
	const { placeSportsBet, calculation, token } = useBetslip();

	return (
		<Box className="betSlipBottomSection">
			<Grid
				className="totalValue"
				display="flex"
				alignItems="center"
				justifyContent="space-between"
			>
				<Typography className="totalBetText">{t('odds')}</Typography>
				<Typography className="totalBetValue">
					{oddsFormatter(totalOdds, oddsType)}
				</Typography>
			</Grid>

			<Grid
				className="potentialWin"
				display="flex"
				alignItems="center"
				justifyContent="space-between"
			>
				<Typography className="potentialText">{t('possibleWin')}</Typography>
				<Typography className="potentialValue">
					{calculation.possibleWin} $
				</Typography>
			</Grid>
			<Grid>
				<Typography className="betslip-error">{betError || null}</Typography>
			</Grid>
			<Grid className="btnGrp" display="flex" gap={1.25}>
				<Button
					variant="contained"
					className="placeBetButton"
					onClick={placeSportsBet}
					disabled={isBetPlaceLoading || betError || !token}
				>
					{t('placeBet')}
				</Button>

				<Button variant="contained" className="shareButton" disabled>
					{t('share')}
				</Button>
			</Grid>

			<Grid
				className="trashSetting"
				display="flex"
				alignItems="center"
				justifyContent="space-between"
			>
				<Typography className="trash" onClick={() => dispatch(removeAllBets())}>
					<Button>
						<DeleteIcon />
						{t('trash')}
					</Button>
				</Typography>

				<Typography className="setting">
					<Button disabled>
						<SettingsIcon />
						{t('setting')}
					</Button>
				</Typography>
			</Grid>
		</Box>
	);
};

export default ActionBottom;
