/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { startCase } from 'lodash';
import { useDebounce } from 'react-use';
import { useTranslation } from 'react-i18next';
import { SearchIconIcon } from 'components/ui-kit/Icons/iconComponents/SearchIconIcon';

const ProvidersSearchBar = (props) => {
	const {
		setSearchedValue,
		providerId,
		setProviderId,
		categoryId,
		setCategoryId,
	} = props;
	const { categoriesData } = useSelector((state) => state.general);
	const { language } = useSelector((state) => state?.auth);
	const { t } = useTranslation();
	const [selectedProviderName, setSelectedProviderName] = useState('All');
	const [searchVal, setSearchVal] = useState('');
	const [inputProvider, setInputProvider] = useState('');
	const [selectedCategory, setSelectedCategory] = useState('All Games');
	const { casinoProviderList } = useSelector((state) => state.casino);
	const [filteredProviders, setFilteredProviders] = useState([]);

	const handleSearchGame = (e) => {
		setSearchVal(e?.target?.value);
	};

	const handleSearchProvider = (e) => {
		setInputProvider(e?.target?.value);
	};

	const closeProviderDropdown = useCallback(() => {
		const element = document?.getElementById('providers_dropdown');
		const button = document?.getElementById('providers_dropdown_button');
		button?.setAttribute('aria-expanded', 'false');
		element?.classList.remove('show');
		button?.classList.remove('show');
		setInputProvider('');
	}, []);

	const closeCategoryDropdown = useCallback(() => {
		const element = document?.getElementById('category_dropdown_button');
		const button = document?.getElementById('category_dropdown_list');
		button?.setAttribute('aria-expanded', 'false');
		element?.classList.remove('show');
		button?.classList.remove('show');
		setInputProvider('');
	}, []);

	useDebounce(
		() => {
			setSearchedValue(searchVal);
		},
		1000,
		[searchVal]
	);

	useEffect(() => {
		if (inputProvider === '') {
			setFilteredProviders(
				casinoProviderList?.map(({ name, id }) => ({
					name: name[language],
					providerId: id,
				}))
			);
		} else {
			const filteredArray = [];
			casinoProviderList.forEach((item) => {
				if (
					item?.name[language]
						?.toLowerCase()
						?.includes(inputProvider.toLowerCase())
				) {
					filteredArray.push({
						name: item?.name[language],
						providerId: item?.id,
					});
				}
			});
			setFilteredProviders(filteredArray);
		}
	}, [inputProvider]);

	useEffect(() => {
		setFilteredProviders(
			casinoProviderList?.map(({ name, id }) => ({
				name: name[language],
				providerId: id,
			}))
		);
	}, [casinoProviderList]);

	return (
		<section className="casino-search d-md-flex align-items-center justify-content-between">
			<div className="casino-search-content w-100 me-2 mb-2 mb-md-0">
				<input
					type="text"
					className="form-control shadow-none"
					value={searchVal}
					id="casinoSearch"
					placeholder={t('searchGames')}
					onChange={(e) => {
						handleSearchGame(e);
					}}
				/>
				<SearchIconIcon className="search-icon" />
			</div>

			<div className="search-filter-right d-flex align-items-center">
				<div className="dropdown me-2">
					<button
						className="btn btn-primary dropdown-toggle"
						type="button"
						id="category_dropdown_button"
						data-bs-toggle="dropdown"
						aria-expanded="false"
						onClick={() => {
							closeProviderDropdown();
						}}
					>
						{t('category')}: <span>{startCase(selectedCategory)}</span>
					</button>
					<ul
						className="dropdown-menu provider-list"
						id="category_dropdown_list"
						aria-labelledby="category_dropdown_list"
					>
						<li>
							<button
								type="button"
								className={`dropdown-item ${categoryId === '' ? 'active' : ''}`}
								onClick={() => {
									setSelectedCategory(t('allGames'));
									setCategoryId('');
									closeCategoryDropdown();
								}}
							>
								{t('allGames')}
							</button>
						</li>
						{/* {isLoggedIn && <li>
              <button
                className={`dropdown-item ${providerId === '' ? 'active' : ''}`}
                onClick={() => {
                  setSelectedCategory(t('favorite'))
                  closeCategoryDropdown()
                  setCategoryId('')
                }}
              >
                {t('favorite')}
              </button>
            </li>} */}
						{categoriesData?.casinoCategories?.map((item) => (
							<li key={`casinoCategories searchGame ${item.id}`}>
								<button
									type="button"
									className={`dropdown-item ${
										categoryId === '' ? 'active' : ''
									}`}
									onClick={() => {
										setSelectedCategory(startCase(item.name?.EN));
										closeCategoryDropdown();
										setCategoryId(item.id);
									}}
								>
									{startCase(item.name?.EN)}
								</button>
							</li>
						))}
					</ul>
				</div>
				<div className="me-2">
					<button
						className="btn btn-primary dropdown-toggle"
						type="button"
						id="providers_dropdown_button"
						data-bs-toggle="dropdown"
						aria-expanded="false"
						onClick={() => {
							closeCategoryDropdown();
						}}
					>
						{t('provider')}: <span>{startCase(selectedProviderName)}</span>
					</button>
					<ul
						className="dropdown-menu provider-list"
						aria-labelledby="providers_dropdown"
						id="providers_dropdown"
					>
						<section className="casino-search">
							<div className="casino-search-content">
								<input
									type="text"
									className="form-control shadow-none"
									id="providerSearch"
									placeholder={`${t('search')}...`}
									value={inputProvider}
									onChange={(e) => handleSearchProvider(e)}
								/>
								<SearchIconIcon className="search-icon" />
							</div>
						</section>
						<div className="provider-list-wrap">
							<li>
								<button
									type="button"
									className={`dropdown-item ${
										providerId === '' ? 'active' : ''
									}`}
									onClick={() => {
										setProviderId('');
										setSelectedProviderName('All');
										closeProviderDropdown();
									}}
								>
									{t('all')}
								</button>
							</li>
							{filteredProviders?.map((item) => (
								<li key={item?.providerId}>
									<button
										type="button"
										className={`dropdown-item ${
											providerId === item?.providerId ? 'active' : ''
										}`}
										onClick={() => {
											setProviderId(item.providerId);
											setSelectedProviderName(item?.name);
											closeProviderDropdown();
										}}
									>
										{startCase(item?.name)}
									</button>
								</li>
							))}
						</div>
					</ul>
				</div>
			</div>
		</section>
	);
};

export default React.memo(ProvidersSearchBar);
