export const TOKEN = 'AccessToken';

export const getAuthToken = () => localStorage.getItem(TOKEN);
// cookies.get(TOKEN);

export const setAuthToken = (authAccessToken) => {
	// cookies.set(TOKEN, authAccessToken, { // For latter use
	//     secure: false,
	//     sameSite: 'lax'
	// });
	localStorage.setItem(TOKEN, authAccessToken);
};

export const removeAuthToken = () => {
	// cookies.remove(TOKEN, { path: '/' });
	localStorage.removeItem(TOKEN);
};

export const signIn = (token) => {
	setAuthToken(token);
	return new Promise((res) => {
		setTimeout(() => res(true), 500);
	});
};

export const signOut = () => {
	removeAuthToken();
};
