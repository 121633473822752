import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
	'header-user-menu': {
		// '& .MuiPaper-root': {
		// 	backgroundColor: 'transparent',
		// 	top: '68px !important',
		// 	left: 'auto !important',
		// 	right: '15px !important',
		// },
		'& .wallet-container': {
			// '@media (min-width:"768px")': {
			//  display: "none"
			// },
			padding: '0 1rem 0rem 1.5rem',
			'& .selectProvider__menu-list': {
				background: '#121c27',
			},
			[theme.breakpoints.up('767')]: {
				display: 'none',
			},
		},
	},
	'header-user-menu-dropdown': {
		display: 'none',
	},
	'selectProvider__single-value': {
		'& .MuiBox-root': {
			gap: '4rem',
		},
	},

	'balance-btn': {
		textTransform: 'unset !important',
		padding: '0.25rem 0.625rem',
		background: 'var(--socialLinksBg)',
		borderColor: 'var(--socialLinksBg)',
		minHeight: '40px',
		borderRadius: '5px',
		alignItems: 'center',
		display: 'flex',
		color: 'var(--white)',
		fontSize: '0.74rem',
		fontWeight: 'bold',
		cursor: 'pointer',
		'& img': {
			width: '33px',
			marginRight: '0',
		},
	},

	'header-wallet-section': {
		backgroundColor: 'var(--socialLinksBg)',
		minWidth: '14.5rem',
		border: 'none',
		// overflow: 'hidden',
		padding: '1rem !important',
		gap: '10px',
		flexDirection: 'column',
		display: 'flex',
		'& .ChooseCurrency': {
			padding: '0.5rem !important',
			'& h4': {
				marginBottom: '0.25rem',
				paddingLeft: '15px',
			},
			'& .selectProvider__control': {
				paddingLeft: '4px',
			},
		},
		'& li': {
			margin: '0',
			padding: '0.5rem 1.5rem',
			background: 'var(--active-bg)',
			borderRadius: '8px',
		},

		'& .dropdown-item': {
			color: 'var(--white)',
			fontSize: '0.875rem',
			padding: '0.625rem',
			fontWeight: '800 !important',
			borderRadius: '8px',
			backgroundColor: 'var(--casino-filter-btn)',
			border: 'none',
			svg: { marginRight: '0.625rem', width: '1.2rem' },
			'&:hover,\n      &.active': {
				backgroundColor: 'var(--casino-filter-btn)',
				img: {
					filter:
						'brightness(0) saturate(100%) invert(21%) sepia(74%)\n            saturate(2846%) hue-rotate(177deg) brightness(94%) contrast(99%)',
				},
			},
		},
		'& .user-name': {
			'& h4': { fontSize: '0.875rem', fontWeight: 400, color: 'var(--white)' },
			'& p': { fontWeight: 'bold' },
		},
	},

	'user-section-list': {
		backgroundColor: 'var(--socialLinksBg)',
		margin: '0',
		gap: '0.1rem',
		display: 'flex',
		flexDirection: 'column',

		'& li': {
			// padding: '1rem',
			margin: '0 .5rem',
			padding: '0',
			// backgroundColor: 'var(--active-bg)',
			borderRadius: '8px',

			'&:nth-child(1)': {
				backgroundColor: 'var(--socialLinksBg)',
				padding: '0.5rem 0',
			},

			'& svg': {
				width: '15px',
			},

			'& a': {
				padding: '0.75rem',
				color: 'var(--white)',
				display: 'flex',
				fontWeight: '600',
				gap: '10px',
				alignItems: 'center',
				fontSize: '0.875rem',

				'&.active': {
					color: 'var(--text-color)',
					textShadow: 'var(--text-shadow)',
					backgroundColor: 'transparent',

					'& svg': {
						color: 'var(--text-color)',
						filter: 'var(--drop-shadow)',
						'& path': {
							fill: 'var(--text-color)',
						},
					},
				},

				'&:hover': {
					backgroundColor: 'transparent',
				},
			},
			'&:hover': {
				backgroundColor: 'var(--active-bg)',
				'& a': {
					color: 'var(--text-color)',
				},
			},
		},

		'& .user-name': {
			'& h4': {
				textTransform: 'none',
				fontSize: '14px',
				fontWeight: '600',
				padding: '0.5rem 1rem',
			},
		},
	},
}));
