import styled from 'styled-components'

export const MyBetsContainer = styled.div`
	.mybet-custom--spacer {
		max-width: 100%;
		padding: 10px 1rem 0 1rem;
		margin-top: 3.75rem;
		color: var(--white);
		.grid-section {
			display: grid;
			align-items: stretch;
			grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
			gap: 24px;
			font-size: 14px;
			// @media screen and (max-width: 1620px) {
			// 	grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
			// }
			@media screen and (max-width: 1620px) {
				grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
			}
			// @media screen and (max-width: 1023px) {
			// 	grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
			// }
			@media screen and (max-width: 991px) {
				grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
			}
			// @media screen and (max-width: 767px) {
			// 	grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
			// }
			@media screen and (max-width: 767px) {
				grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
			}
		}
		.box-grid {
			padding: 1rem;
			border-radius: 8px;
			height: 100%;
			background: var(--boxViewBg);
			transition: transform 0.15s ease-out 0s;
			.pending {
				color: var(--pending);
        font-weight: bold;
        text-transform: uppercase !important;
			}
			.won {
				color: var(--won);
        font-weight: bold;
        text-transform: uppercase !important;
			}

			.loss {
				color: var(--loss);
        font-weight: bold;
        text-transform: uppercase !important;
			}

			.case-out {
				color: var(--caseOut);
        font-weight: bold;
        text-transform: uppercase !important;
			}
			.team-ellip {
				width: 150px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: inline-block;
				white-space: nowrap;
			}
			.grid-bg {
				background: var(--oddsParentBg);
				padding: 1rem;
				margin: 0.5rem 0;
				border-radius: 8px;
				display: flex;
				flex-direction: column;
				gap: 15px;
			}
			.bet-status,
			.font14 {
				font-size: 14px;
			}
			.match-type-status {
				background: #000;
				padding: 5px 10px;
				border-radius: 5px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: fit-content;
			}
			.cash-out---btn button {
				background: var(--socialLinksBg);
				border-color: transparent;
				text-transform: capitalize;
				border: none;
				// box-shadow:
				// 	rgba(255, 176, 25, 0.4) 0px 0px 10px,
				// 	rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
				// 	rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
				// 	rgb(255, 135, 25) 0px 0px 15px inset;
				color: var(--white);
				border-radius: 8px;
				padding: 0.6rem 3rem;
				font-weight: bold;
			}
		}
	}
	.mybet-tabs {
		margin-bottom: 1rem;
		.tabs-button {
			min-height: auto !important;
			.MuiTabs-indicator {
				display: none !important;
			}
			button {
				justify-content: center;
				background: var(--primaryBtn);
				border: 1px solid var(--theme-border);
				margin: 0;
				line-height: 11px;
				padding: 0.7rem 1rem;
				margin-right: 10px;
				color: var(--white);
				border-radius: 8px;
				min-height: auto;
				&.Mui-selected {
					background: var(--secondaryBtn);
					color: var(--button-text);
					border-color: var(--secondry-border);
				}
			}
		}
		.react-datepicker-wrapper {
			input {
				background-color: var(--inputbg);
				border: 1px solid var(--inputBorder);
				color: var(--white);
				padding: 7px 12px;
				border-radius: 8px;
				height: 45px;
				font-size: 1rem;
				&:focus {
					border-color: var(--text-color);
				}
			}
		}

		.odds-section {
			margin-bottom: 3rem;
			.odds-select {
        .MuiSelect-select, svg {
          color: var(--white);
        }
				background-color: linear-gradient(
					to right,
					rgba(20, 32, 46, 1) 0%,
					rgba(33, 50, 68, 1) 100%
				);
				border-radius: 8px;
				border: 1px solid #25394f;
				min-height: auto;
			}
			label {
				color: var(--white);
				opacity: 0.4;
			}
			input {
				padding: 5px 14px;
				color: var(--white);
			}
			fieldset {
				border: transparent;
			}
			svg,
			div {
				color: rgba(255, 255, 255, 0.4);
			}
		}
	}
`
