import FR from 'SportsBook/BetBalancer/Translation/locales/fr'

export default {
	404: '404',
	register: 'Registrieren',
	login: 'Anmeldung',
	grand: 'OSSARTIG',
	major: 'WESENTLICH',
	minor: 'UNERHEBLICH',
	mini: 'MINI',
	crashGameTitle: 'Crash-Spiel',
	betAmount: 'montoapuesta',
	messagesNoBetHistory: 'Votre historique de paris est vide',
	messagesCopyNotSupported:
		'Votre navigateur ne prend pas en charge la copie automatique dans le presse-papiers.\ns',
	messagesCopied: 'Adresse copiée.',
	deposit: 'Deposit',
	statReset: 'Reset stats',
	maxProfit: 'Max Profit',
	rollOver: 'Roll Over',
	buttonTextPlaceBet: 'Place Bet',
	buttonTextCancelBet: 'Cancel Bet',
	buttonTextEscapeBet: 'Cashout',
	buttonTextStartAutoBet: 'Start Autobet',
	buttonTextCancelAutoBet: 'Cancel Autobet',
	buttonTextLoading: 'Loading',
	inputsNoOfBets: 'Número de apuestas',
	inputsAuto: 'Auto',
	inputsHalf: '1/2',
	inputsDouble: '2',
	inputsStart: 'Comenzar',
	inputsCancel: 'Cancelar',
	buttonsMin: 'mínimo',
	buttonsMax: 'máx.',
	prediction: 'Predicción',
	luckyNumber: 'Número de la suerte',
	multiplier: 'Multiplicador',
	chanceToWin: 'Oportunidad de ganar',
	betBtn: 'Apuesta',
	bets: 'apuestas',
	depositBtn: 'Depósito',
	rollUnder: 'rodar debajo',
	messagesInvalidBetAmount: 'Cantidad de apuesta no válida',
	symbolsX: 'X',
	symbolsPercent: '%',
	navBarMyBets: 'Mes paris',
	navBarAllBets: 'Tous les paris',
	navBarHighRollers: 'Gros joueurs',
	navBarRoundHistory: 'Historique des rondes',
	navBarTotal: 'Paris totaux',
	topHeaderBetId: 'ID de pari',
	topHeaderUser: 'Utilisateur',
	topHeaderPayout: 'Paiement',
	topHeadeAmount: 'Montant',
	topHeaderTime: 'Temps',
	topHeaderProfit: 'Profit',
	topHeaderRoundCrashed: 'Écrasé',
	topHeaderRoundEscape: '@',
	topHeaderRoundRoundHash: 'Hacher',
	topHeaderRoundBet: 'Pari',
	topHeaderRoundProfit: 'Profit',
	topHeaderRoundSignature: 'Signature',
	roundHistoryTitle: 'INFORMATIONS SUR LE JEU',
	roundHistoryDescGame: 'Jeu',
	roundHistoryDescCrashAt: 'Crash à',
	roundHistoryDescDate: 'Date :',
	roundHistoryDescBetHeader: 'Pari',
	roundHistoryTableTitlePlayer: 'JOUEUR',
	roundHistoryTableTitleBet: 'PARI',
	roundHistoryTableTitleCashedOut: 'ENCAISSÉ',
	roundHistoryTableTitleProfit: 'PROFIT',
	roundHistoryTableTitleBetId: 'ID DE PARI',
	roundHistoryMessagesNoBetHistory: 'Votre historique de paris est vide',
	placedBetsTabPlaced: 'Pari placé',
	placedBetsTabPrev: 'Pari précédent',
	placedBetsTitleUser: 'Utilisateur',
	placedBetsTitleEscape: 'Multi.x',
	placedBetsTitleBet: 'Pari',
	placedBetsTitleProfit: 'Profit  ',
	placedBetsTitleCashout: 'Encaissement',
	placedBetsNoPlacedBet: 'Aucun pari placé.',
	provablyFairContentCheckBtn: 'Chèque Prouvable Juste',
	provablyFairContentTitle: 'Prouvablement juste',
	provablyFairContentDesc:
		'Le résultat d’une avance à quatre peut être prouvé comme juste. ',
	provablyFairContentInputFieldsCrashRateTitle: 'Taux d’accident',
	provablyFairContentInputFieldsCrashRatePlaceHolder: 'Taux de crash sans x',
	provablyFairContentInputFieldsRoundHashTitle: 'Hachage rond',
	provablyFairContentInputFieldsRoundHashPlaceHolder: 'par exemple.  ',
	provablyFairContentInputFieldsSubmitBtn: 'Générer une signature',
	provablyFairContentSignature: 'Signature ronde',
	provablyFairContentErrorsCrashRate:
		'veuillez entrer le nombre dans le champ du taux d’accident',
	levelGrand: 'OSSARTIG',
	levelMajor: 'WESENTLICH',
	levelMinor: 'UNERHEBLICH',
	levelMini: 'MINI',
	bannerContentWinDaily: 'Gewinnen Sie täglich $1000',
	bannerContentContest: 'Gewinnspiele jetzt ',
	bannerContentJoin:
		'Nehmen Sie an unserem täglichen Wettwettbewerb teil und werden Sie zum Helden!  ',
	bannerContentPlayNow: 'Reproducir ahora',
	bannerContentTitle: 'Jackpot gewinnen',
	bannerContentContent: 'Spiel mehr',
	bannerContentGameName: 'CRASH-SPIEL',
	bannerContentGameDesc: 'Spiel mehr',
	//     partners: 'Partner',
	gamesGoldenDragon: 'goldener Drache',
	gamesCrashGame: 'Crash-Spiel',
	gamesDice: 'Würfel',
	gamesHilo: 'Hilo',
	allBetsTitle: 'Alle Wetten',
	allBetsBetId: 'Wett-ID',
	allBetsUser: 'Benutzer',
	allBetsPayout: 'Auszahlung',
	allBetsAmount: 'Menge',
	allBetsTime: 'Zeit',
	allBetsProfit: 'Profitieren',
	loginWithOtp: 'Melden Sie sich mit Otp an',
	enterLoginOtp: 'Geben Sie Login-Otp ein',
	submit: 'Einreichen',
	footerAboutUs: 'Über uns',
	footerFaq: 'Häufig gestellte Fragen',
	footerSitePolicy: 'Website-Richtlinie',
	footerResponsibleGambling: 'Verantwortungsvolles Spielen',
	footerTerms: 'Geschäftsbedingungen',
	footerCopywrite: '2022 KRYPTO-LINIE. ',
	signinTitle: 'Anmeldung',
	signinUsername: 'Benutzername / E-Mail',
	signinPassword: 'Passwort',
	signinLoginBtn: 'Anmeldung',
	signinSignup: 'Anmeldung!',
	signinForgetPassword: 'Passwort vergessen?',
	signinDontHaveAccount: 'Sie haben kein Konto? ',
	signinCreateAccount: 'Benutzerkonto erstellen',
	signinLoginDescription:
		'ARC Gaming kann Ihnen gelegentlich kostenlose Angebote und Sonderaktionen per E-Mail oder SMS zusenden. ',
	signupErrorsPasswordRequired: '*Passwort ist erforderlich.',
	signupErrorsPasswordMinLength: 'Mindestens 8 Zeichen erlaubt.',
	signupErrorsPasswordMaxLength: 'Maximal 16 Zeichen erlaubt.',
	signupErrorsPasswordPattern:
		'*Passwort sollte aus 8 bis 16 alphanumerischen Zeichen und Sonderzeichen bestehen. ',
	placeholderUserName: 'Mindest. ',
	placeholderEmail: 'z.B. ',
	placeholderPassword: 'Mindest. ',
	signUpSomethingWentWrong: 'Etwas ist schief gelaufen!',
	signupGoogle: 'Melden Sie sich erfolgreich bei Google an.',
	signupRegistered: 'Erfolgreich registriert.',
	signupConfirmAge: 'Bitte bestätigen Sie, dass Sie 18 Jahre alt sind.',
	signupFacebook: 'Melde dich erfolgreich bei Facebook an.',
	signupCookiesConsent:
		'Bitte erlauben Sie Cookies von Drittanbietern in den Einstellungen Ihres Browsers, um Social Login zu verwenden.',
	signupRegisterEmailPopupContent:
		'Wir haben Ihnen eine Bestätigungs-E-Mail gesendet. ',
	accountMenuDeposit: 'Kaution',
	accountMenuCashout: 'Auszahlen',
	accountMenuJackpotWinners: 'Jackpot-Gewinner',
	accountMenuTransactions: 'Transaktionen',
	accountMenuPromotions: 'Werbeaktionen',
	accountMenuProfile: 'Profil',
	accountMenuLogout: 'Ausloggen',
	accountMenuSound: 'Klang',
	accountMenuMusic: 'Musik',
	accountMenuWithdraw: 'Zurückziehen',
	pageNotFound: 'Wir können die gesuchte Seite nicht finden!',
	error: 'Fehler',
	somethingWentWrong: 'Etwas ist schief gelaufen!',
	verifyEmailThankYou: 'Danke schön!',
	verifyEmailEmailIsVerified: 'Ihre E-Mail ist verifiziert.',
	verifyEmailGoToLogin: 'Gehen Sie zur Anmeldeseite',
	verifyEmailWentWrong: 'Etwas ist schief gelaufen!',
	verifyEmailGoToHome: 'Gehen Sie zur Startseite',
	verifyEmailSuccessMsg: 'E-Mail erfolgreich bestätigt.',
	//     gameCardTitle:
	//       'ARC Gaming nachweislich faire Krypto-Glücksspielseite',
	bonusSectionTitle: 'BITSPIEL',
	bonusSectionSpan_text: 'präsentiert die großzügigsten und vielfältigsten',
	bonusSectionBonuses: 'Boni',
	bonusSectionSubtitle_text:
		'Spielen Sie mit Vergnügen und erhalten Sie während des Spiels zusätzliche Belohnungen. ',
	bonusSectionHeading_text: 'Boni von ARC Gaming',
	bonusSectionDesc:
		'Als bestes Bitcoin-Casino verfügt ARC Gaming über ein ausgeklügeltes System großzügiger Boni. ',
	joiningBonusTitle: 'Beitrittsbonus',
	joiningBonusButton: 'Beanspruche jetzt',
	joiningBonusDesc:
		'Solche Boni ermutigen Online-Casino-Spieler, mit Bitcoin oder anderen Kryptos zu spielen. ',
	depositBonusTitle: 'Einzahlungsbonus',
	depositBonusButton: 'Kaution',
	depositBonusDesc:
		'Solche Boni ermutigen Online-Casino-Spieler, mit Bitcoin oder anderen Kryptos zu spielen. ',
	referBonusTitle: 'Empfehlungsbonus',
	referBonusButton: 'Kaution',
	referBonusDesc:
		'Solche Boni ermutigen Online-Casino-Spieler, mit Bitcoin oder anderen Kryptos zu spielen. ',
	aboutSectionTitle: 'Was ist ARC Gaming',
	aboutSectionSpan_text1:
		' ARC Gaming ist eine innovative Bitcoin-Casinoseite, die mit Blick auf neue Konzepte des Online-Glücksspiels erstellt wurde. ',
	aboutSectionSpan_text2:
		'Das Online-Casino ermöglicht es Ihnen, Ihre Lieblingsspiele entweder kostenlos oder für Kryptowährung zu spielen. ',
	aboutSectionSpan_text3:
		' In dieser virtuellen Welt des Bitcoin-Glücksspiels können Sie viele Möglichkeiten genießen – Ihre Lieblings-Casinospiele spielen, Boni erhalten, an Wettbewerben und Werbeaktionen teilnehmen.',
	verifiedSectionTitle: 'Verifizierte und lizenzierte Software',
	verifiedSectionSpan_text1:
		'Wir kopieren oder übernehmen keine Software von anderen Websites. ',
	//     faqSectionTitle: 'FAQ von Spielern',
	faqSectionForgetPasswordTitle: 'Ich habe mein Passwort vergessen.',
	faqSectionForgetPasswordDesc:
		'Sie haben keine Wiederherstellungs-E-Mail-Adresse für Ihr Konto angegeben. ',
	faqSectionMyDepositCreditedTitle:
		'Meine Einzahlung wurde nicht gutgeschrieben.',
	faqSectionMyDepositCreditedDesc:
		' Platzhalterinhalt für dieses Akkordeon, der die Klasse demonstrieren soll. ',
	SupportedCurrencyTitle: 'Unterstützte Währung',
	SupportedCurrencySpan_text1:
		'Wir kopieren oder übernehmen keine Software von anderen Websites. ',
	CarouselSectionTitle: 'HODL-ABSTURZ',
	CarouselSectionSubTitle:
		' Spielen Sie mehr Spiele und erhalten Sie die Chance zu gewinnen. ',
	WalletSettingTitle: 'Wallet-Einstellung',
	WalletSettingHideZero: 'Nullsalden ausblenden',
	WalletSettingHideZeroInfo:
		'Ihr Nullguthaben wird nicht in Ihrer Brieftasche angezeigt',
	WalletSettingDisplayFiat: 'Krypto in Fiat anzeigen',
	WalletSettingDisplayFiatInfo: 'Alle Wetten',
	WalletSettingNoteForApprox:
		'Bitte beachten Sie, dass es sich hierbei um ungefähre Währungsangaben handelt.',
	WalletSettingSave: 'Speichern',
	WalletSettingNoWalletFound: 'Keine Brieftasche gefunden',
	promotionsTitle: 'Promotions',
	promotionsNoLosingData:
		'Désolé, les promotions de bonus No Losing sont disponibles pour ',
	promotionsNoDepositData:
		'Désolé, les promotions de bonus sans dépôt sont disponibles pour ',
	promotionsCurrency: ' monnaie.',
	promotionsViewBtn: 'Voir',
	promotionsClaimSuccess: 'Vous avez réclamé avec succès',
	promotionsAvailability: 'Disponibilité: ',
	promotionsTabLosing: 'Perte de bonus',
	promotionsTabDeposit: 'Bonus de dépôt',
	promotionsDepositBonusTableCode: 'Code',
	promotionsDepositBonusTableMinDeposit: 'Min. ',
	promotionsDepositBonusTablePercentage: 'Pourcentage',
	promotionsDepositBonusTableMaxBonus: 'Bonus maximum',
	promotionsDepositBonusTableRolloverMultipler: 'Multiplicateur de roulement',
	promotionsDepositBonusTableMaxRolloverPerBet: 'Renversement maximum par pari',
	promotionsDepositBonusTableTimeToAchieveRolloverTarget:
		'Temps nécessaire pour atteindre l’objectif de roulement',
	promotionsBonusCode: 'CODE BONUS: ',
	promotionsLossesClaim:
		'Les pertes peuvent être réclamées pour (pendant la période de campagne) : ',
	promotionslosingBonusTablePercentage: 'Pourcentage',
	promotionslosingBonusTableMinLosingAmount: 'Min. ',
	promotionsBonusStatus: 'Statut bonus : ',
	promotionsBonusAmount: 'Montant de la prime : ',
	promotionsRolloverTarget: 'Objectif de roulement : ',
	promotionsRolloverAchieved: 'Roulement atteint : ',
	promotionsActivatebtn: 'ACTIVER',
	promotionsClaimBtn: 'RÉCLAMATION',
	promotionsCancelBtn: 'ANNULER',
	promotionsBackBtn: 'DOS',
	promotionsBonus: 'Prime',
	promotionsTermAndConditions: ' Conditions',
	promotionsClaimDepositBonusTitle: 'Étapes pour réclamer le bonus de dépôt',
	promotionsClaimDepositBonusFirst: 'Activer le code bonus',
	promotionsClaimDepositBonusSecond: 'Montant du dépôt dans le portefeuille',
	promotionsClaimDepositBonusThird:
		'Atteindre l’objectif de roulement pour réclamer le bonus',
	promotionsClaimLosingBonusTitle: 'Étapes pour réclamer le bonus de perte',
	promotionsClaimLosingBonusFirst: 'Activer le code bonus',
	promotionsClaimLosingBonusSecond: 'Jouez à vos jeux préférés',
	promotionsClaimLosingBonusThird:
		"Cliquez sur 'Réclamer les pertes' pour réclamer les pertes pendant la période de campagne",
	promotionsWentWrong: 'Quelque chose s’est mal passé !',
	transactionTitle: 'Transactions',
	transactionBetId: 'ID de pari',
	transactionUser: 'Utilisateur',
	transactionPayout: 'Paiement',
	transactionAmount: 'Montant',
	transactionTime: 'Date',
	transactionProfit: 'Profit',
	transactionCashout: 'Encaissement',
	transactionBet: 'Pari',
	transactionMultix: 'Multi.x',
	transactionWin: 'Gagner',
	transactionFairness: 'Justice',
	transactionReferFriend: 'Référez un ami',
	transactionTotal: 'Paris totaux',
	transactionWins: 'Gagne',
	transactionRefresh: 'Rafraîchir',
	transactionFilterTopMultipliers: 'Principaux multiplicateurs',
	transactionFilterHugeWins: 'Énormes victoires',
	transactionFilterBiggestWins: 'Plus grandes victoires',
	transactionFilterMultipliers: 'Multiplicateurs',
	transactionRealBalance: 'Équilibre réel',
	transactionTabMyBets: 'Dépôt',
	transactionTabDeposit: 'Encaissements',
	transactionTabAllbets: 'Tous les paris',
	transactionTabTopbets: 'Meilleurs paris',
	transactionTabBonus: 'Prime',
	transactionTabJackpot: 'Cagnotte',
	transactionTabBetHistory: 'Historique des paris',
	transactionDepositWithdrawTXdate: 'Date',
	transactionDepositWithdrawTXAmount: 'Montant',
	transactionDepositWithdrawTXTxId: 'TxID',
	transactionDepositWithdrawTXTxType: 'Type de transaction',
	transactionDepositWithdrawTXStatus: 'Statut',
	transactionDepositWithdrawTXNoData: 'Pas de données disponibles.',
	transactionDeposittitle: 'Dépôt',
	transactionDeposithistory: 'Histoire',
	transactionDepositsub_title:
		'L’utilisateur ne peut pas déposer ou retirer de la monnaie fiduciaire. ',
	transactionDepositcopyNotSupported:
		'Votre navigateur ne prend pas en charge la copie automatique dans le presse-papiers.\n',
	transactiondDepositcopied: 'Adresse copiée.',
	transactionDepositaddressCreated: 'Adresse créée avec succès.',
	transactionDepositgenerateAddress: 'Générer une adresse',
	transactionDepositwarningText:
		'Veuillez NE PAS déposer par transfert inter-chaînes',
	transactionWithdrawTitle: 'Retirer',
	transactionWithdrawSub_title:
		'L’utilisateur ne peut pas déposer ou retirer de la monnaie fiduciaire. ',
	transactionWithdrawNoteDesc:
		'Veuillez vous assurer que vous n’entrez PAS l’adresse BEP2, BEP20 (BSC) en tant qu’adresse de retrait LTC.',
	transactionWithdrawNote_Sublist:
		' Veuillez NE PAS retirer en tant que transfert inter-chaînes',
	transactionWithdrawHistory: 'Histoire',
	transactioWithdrawBtn: 'Demande de retrait',
	transactionWithdrawfees: 'Frais de retrait {{fees}} {{token}}',
	transactionWithdrawPending: 'Votre demande de retrait est en attente. ',
	transactionWithdrawEnterFields:
		'Entrez le montant du retrait et l’adresse de votre portefeuille.',
	transactionwithdrawSelectWallet:
		'Veuillez sélectionner le portefeuille à retirer.',
	transactionAmountFieldTitle: 'Montant (min. 0,03 {{wallet.currency.code}})',
	transactionAmountFieldPlaceholder: '0',
	transactionAddressFieldTitle: 'Ton {{wallet.currency.code}} adresse',
	transactionAddressFieldPlaceholder: 'Tapez votre adresse',
	transactionNoBalance: 'Pas assez d’équilibre.',
	transactionMinAmount:
		'Veuillez saisir un montant supérieur ou égal à {{min}} .',
	transactionFiatCurrencyInfo:
		'L’utilisateur ne peut pas déposer ou retirer de la monnaie fiduciaire. ',
	transactionPaginationNextBtn: 'Suivant',
	transactionPaginationPrevBtn: 'Précédent',
	transactionWentWrong: 'Quelque chose s’est mal passé.',
	transactionReferralTitle: 'Référence',
	transactionReferralSub_title: 'Parrainez vos amis',
	transactionReferraLearn: ' Gagnez 150 ₹ chacun',
	transactionReferralReferAndEarn:
		'Parrainez et gagnez un bonus pour chaque parrainage',
	transactionReferralReferPara:
		' Si vous renvoyez ce code à n’importe quel utilisateur',
	transactionReferralCopyReferralCode: 'Copier le code de parrainage',
	transactionReferralTotalRewards: 'Récompense totale',
	TwoWayAuthentication: 'Authentification à double sens',
	LoginRequired: 'Connexion requise!',
	BetPlacedSuccessfully: 'Pari placé avec succès',
	BetAddedInQueueSuccessfully: 'Pari ajouté à la file d’attente avec succès',
	BetRemovedFromQueueSuccessfully:
		'Pari retiré de la file d’attente avec succès',
	BetCancelledSuccessfully: 'Pari annulé avec succès',
	PlayerEscapedSuccessfully: 'Le joueur s’est échappé avec succès',
	CopiedSuccessfull: 'Copie réussie',
	NotEnoughBalance: 'Pas assez d’équilibre',
	AutoBetStarted: 'Pari automatique commencé ',
	AutoBetFinished: 'Pari automatique terminé ',
	IdCopied: 'Id copié',
	disabledForTime:
		"Vous n'êtes pas autorisé à vous connecter à votre compte désactivé pour",
	Nobetsfound: 'Aucun pari trouvé',
    ...FR
};
