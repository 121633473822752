import axiosInstance from 'network/apis/index';
import { microServices } from 'network/apis/microservice/index';
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index';

export const getCasinoProviderListService = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/casino/get-providers',
		{},
		{
			server: microServices.SERVICE_URL_1,
			loader: LOADER_HANDLER_TYPES.table,
			params: data,
		}
	);

export const getCasinoProviderGamesService = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/casino/get-providers-games',
		{},
		{
			server: microServices.SERVICE_URL_1,
			loader: LOADER_HANDLER_TYPES.table,
			params: data,
		}
	);

export const getGameDataServices = (data) =>
	axiosInstance(METHOD_TYPES.post, '/casino/init-game', data, {
		server: microServices.SERVICE_URL_1,
		loader: LOADER_HANDLER_TYPES.table,
	});

export const getLatestBets = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/casino/get-latest-bets',
		{},
		{
			server: microServices.SERVICE_URL_1,
			loader: LOADER_HANDLER_TYPES.table,
			params: data,
		}
	);

export const getHighPayoutBets = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'casino/get-latest-high-payout',
		{},
		{
			server: microServices.SERVICE_URL_1,
			loader: LOADER_HANDLER_TYPES.table,
			params: data,
		}
	);
