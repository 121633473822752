import { openSuccessToaster } from 'helpers/toaster.helpers';
import { getTranslation } from 'helpers/translations.helpers';
import {
	withdrawAmountServiceRequest,
	withdrawNatellerAmountServiceRequest,
	withdrawCoinPaymentAmountServiceRequest,
} from 'network/services/withdrawAmount.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const withdrawAmountsRequest = createAsyncThunk(
	'wallet/withdrawAmount',
	async ({ callBack, payload }, thunkApi) => {
		try {
			const res = await withdrawAmountServiceRequest(payload);
			if (callBack) {
				callBack();
			}
			openSuccessToaster({
				message: getTranslation('Amount Withdraw Success'),
			});
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const withdrawNatellerAmountsRequest = createAsyncThunk(
	'wallet/withdrawNatellerAmount',
	async ({ callBack, payload }, thunkApi) => {
		try {
			const res = await withdrawNatellerAmountServiceRequest(payload);
			if (callBack) {
				callBack();
			}
			openSuccessToaster({
				message: getTranslation('Amount Withdraw Success'),
			});
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);
export const withdrawCoinPaymentAmountsRequest = createAsyncThunk(
	'wallet/withdrawCoinPaymentAmount',
	async ({ callBack, payload }, thunkApi) => {
		try {
			const res = await withdrawCoinPaymentAmountServiceRequest(payload);
			if (callBack) {
				callBack();
			}
			openSuccessToaster({
				message: getTranslation('Amount Withdraw Success'),
			});
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);
