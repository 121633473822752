import React from 'react';
import { Collapse } from '@mui/material/index';
import PropTypes from 'prop-types';

const CustomCollapse = ({ children, open }) => (
	<Collapse in={open}>{children}</Collapse>
);

CustomCollapse.propTypes = {
	children: PropTypes.element.isRequired,
	open: PropTypes.bool.isRequired,
};

export default CustomCollapse;
