/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BalanceProfileIcon } from 'components/ui-kit/Icons/iconComponents/BalanceProfileIcon';
// import { goldICon } from 'components/ui-kit/Icons/png/index'
import {
	getDocumentsLabelRequest,
	getDocumentsInfoRequest,
	updateDocVerificationRequest,
} from 'redux-thunk/thunk/user.thunk';
import { LOADER_HANDLER_TYPES, ROUTE_PATHS } from 'constants/index';
import Loader from 'components/ui-kit/Loader/index';
import VerificationLabelInfo from './VerificationLabelInfo';
import VerificationBoxInfo from './VerificationBoxInfo';

const Verification = () => {
	const labels = useSelector(
		(state) => state.user.verificationLabels?.documentLabels
	);
	const {
		[LOADER_HANDLER_TYPES.content]: isGetLoading,
		[LOADER_HANDLER_TYPES.submit]: isSubmitLoading,
	} = useSelector((state) => state.loader);
	const documents = useSelector(
		(state) => state.user.verificationUserDocument?.documents
	);

	const userInfo = useSelector((state) => state.user?.user?.user);
	const [imgDoc, setImageDoc] = useState(null);
	const [selectedTab, setSelectedTab] = useState(null);
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getDocumentsLabelRequest());
		dispatch(getDocumentsInfoRequest());
	}, []);

	useEffect(() => {
		if (labels?.length > 0) {
			setSelectedTab(labels?.[0]?.name);
			// need to resolve
		}
	}, [labels]);
	const onChangeTabToggler = (item) => {
		setSelectedTab(item?.name);
	};
	const onUpdateForm = () => {
		dispatch(getDocumentsInfoRequest());
	};
	const onUpdateVerification = () => {
		const data = {};
		data[`${selectedTab}`] = imgDoc[selectedTab];
		data.labelId = imgDoc?.labelData?.id;
		const formData = new FormData();
		// const selectTabName = selectedTab;
		formData.append('file', imgDoc[selectedTab]);
		formData.append('labelId', imgDoc?.labelData?.id);

		dispatch(
			updateDocVerificationRequest({ data: formData, onSuccess: onUpdateForm })
		);
	};

	return (
		<div
			className={`tab-pane fade  ${
				pathname === ROUTE_PATHS.VERIFICATION ? 'show active' : ''
			}`}
			id="v-pills-verification"
			role="tabpanel"
			aria-labelledby="v-pills-verification-tab"
		>
			<div className="profile-tab-content-wrap">
				<div className="profile-heading  d-flex align-items-center justify-content-between">
					<h4 className="m-0">
						<BalanceProfileIcon /> {t('verification')}:{' '}
						{`${userInfo?.kycStatus}` === 'false' ? 'Incomplete' : 'Completed'}
					</h4>
				</div>
				{isGetLoading ? (
					<Loader variant={LOADER_HANDLER_TYPES.content} />
				) : userInfo?.kycStatus === 'false' ? (
					<>
						<div className="balance-tabs">
							<VerificationLabelInfo
								labels={labels}
								t={t}
								selectedTab={selectedTab}
								onChangeTabToggler={onChangeTabToggler}
								setImageDoc={setImageDoc}
								documents={documents}
							/>
						</div>
						<div className="profile-card-wrap">
							<VerificationBoxInfo
								labels={labels}
								selectedTab={selectedTab}
								documents={documents}
								imgDoc={imgDoc}
								setImageDoc={setImageDoc}
								onUpdateVerification={onUpdateVerification}
								isSubmitLoading={isSubmitLoading}
								t={t}
							/>
						</div>
					</>
				) : (
					<div className="documents-verified-wrapper profile-card-wrap">
						<div>
							<div className="message-wrap">
								Congratulations! Your KYC has been successfully Completed
							</div>
							<div className="message-img-wrap">
								<img
									src="/assets/images/common/check.svg"
									className="img-fluid"
									alt="Currency"
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default React.memo(Verification);
