/* eslint-disable no-param-reassign */
// import i18n from 'i18next'
// import { Buffer } from 'buffer';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	loginMetamaskService,
	verifyMetamaskSignatureService,
} from 'network/services/metaMask.service';
import { setAuthToken, signIn } from 'helpers/cookie.helpers';

import { setShowLoginPopup } from 'redux-thunk/redux/slices/gameSetting.slice';
import { signMessage } from 'utils/helper';
import { openErrorToaster } from 'helpers/toaster.helpers';

export const verifyMetamaskSignature = createAsyncThunk(
	'verify/signature',
	async ({ publicAddress, signature, onLoginSuccess }, thunkApi) => {
		try {
			const res = await verifyMetamaskSignatureService({
				publicAddress,
				signature,
			});
			thunkApi.dispatch(setShowLoginPopup(false));
			setAuthToken(res?.accessToken);
			onLoginSuccess();
			return res;
		} catch (error) {
			return thunkApi.rejected();
		}
	}
);

/**
 * User Login Thunk
 */

export const userMetamaskLogin = createAsyncThunk(
	'user/metamaskLogin',
	async ({ accountValue, onLoginSuccess }, thunkApi) => {
		try {
			const res = await loginMetamaskService({
				address: accountValue,
			});

			if (res) {
				const signature = await signMessage(`ARC:${res.nonce}`);
				if (!signature) {
					openErrorToaster({ message: 'User rejected !' });
					return null;
				}
				await signIn(res);

				await thunkApi.dispatch(
					verifyMetamaskSignature({
						publicAddress: accountValue,
						signature,
						onLoginSuccess,
					})
				);
			}
			return res;
		} catch (error) {
			// thunkApi.dispatch(setShowMetaMaskSignUpPopup(true));
			return null;

			// error && error[0] && error[0]?.description
		}
	}
);
