import { createSlice } from '@reduxjs/toolkit';
import { LOCATION_ALL } from 'SportsBook/BetBalancer/Helper/eventConstants';
import { getSportInfo } from '../thunk/sportInfo.thunk';

const initialState = {
	locations: [],
	loading: false,
	error: null,
	selectedLocation: 'all',
};
// SB means SportsBook
const SBlocation = createSlice({
	name: 'SBlocation',
	initialState,
	reducers: {
		selectLocation: (state, { payload }) => ({
			...state,
			selectedLocation: payload,
		}),
	},
	extraReducers: (builder) => {
		builder
			.addCase(getSportInfo.pending, (state) => ({
				...state,
				loading: true,
			}))
			.addCase(getSportInfo.fulfilled, (state, action) => ({
				...state,
				locations: [LOCATION_ALL, ...action.payload.locations],
				loading: false,
			}))
			.addCase(getSportInfo.rejected, (state, action) => ({
				...state,
				error: action.payload,
				loading: false,
			}));
	},
});

export const { selectLocation } = SBlocation.actions;

export default SBlocation.reducer;
