/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	getUserInfoService,
	updateUserInfoService,
	depositRequest,
	getUsersCasinoTransactionsService,
	getUsersTransactionsService,
	getDocumentsLabelService,
	getDocumentsInfoService,
	updateDocVerificationService,
	getLoyaltyDetailsService,
	getUserAddressesService,
	updateUserAddressService,
	addUserAddressService,
	getUsersSportsTransactionsService,
	withdrawRequest,
	generateAddress,
	getCryptoNetworksService,
	getCryptoNetworksFees,
	getUserStatistics,
} from 'network/services/users.service';
import {
	setUsersTransactions,
	setLoyaltyDetails,
	setUserAddresses,
	updateCurrencyAddress,
	setUserStatistics,
} from 'redux-thunk/redux/slices/user.slice';
import { openSuccessToaster } from 'helpers/toaster.helpers';
// import { getTranslation } from 'helpers/translations.helpers';
import {
	setCryptoNetworks,
	setDepositDetails,
	setNetworkFees,
} from 'redux-thunk/redux/slices/payment.slice';

/**
 * Fetch User info who is logged in
 */
export const fetchUserInformation = createAsyncThunk(
	'user/user-detail',
	async (_, thunkApi) => {
		try {
			const res = await getUserInfoService();
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const fetchUserStatistics = createAsyncThunk(
	'user/get-user-statistics',
	async (data, thunkApi) => {
		try {
			const res = await getUserStatistics(data);
			thunkApi.dispatch(
				setUserStatistics({ data: res?.totalAmountResult?.[0] || null })
			);
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);
/**
 * Update User info who is logged in
 */
export const updateUserInformation = createAsyncThunk(
	'user/update-user-details',
	async ({ params }, thunkApi) => {
		try {
			const res = await updateUserInfoService(params);
			thunkApi.dispatch(fetchUserInformation());

			// onSuccess()
			return res;
		} catch (error) {
			// onError()
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

// export const withdrawRequest = createAsyncThunk(
// 	'wallet/deposit-amount',
// 	async ({ values }, thunkApi) => {
// 		try {
// 			const res = await withdrawAmountRequest(values);
// 			return res;
// 		} catch (error) {
// 			return thunkApi.rejectWithValue(
// 				error && error[0] && error[0]?.description
// 			);
// 		}
// 	}
// );

export const getLoyaltyDetails = createAsyncThunk(
	'user/loyalty',
	async (_, thunkApi) => {
		try {
			const res = await getLoyaltyDetailsService();
			thunkApi.dispatch(setLoyaltyDetails(res));
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const depositAmountRequest = createAsyncThunk(
	'/payment/ccpay-deposite-address',
	async (data, thunkApi) => {
		try {
			const res = await depositRequest(data);
			thunkApi.dispatch(setDepositDetails(res));
			// openSuccessToaster({ message: getTranslation('amountDepositedSuccess') });
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const withdrawAmountRequest = createAsyncThunk(
	'payment/withdraw-amount',
	async (data, thunkApi) => {
		try {
			const res = await withdrawRequest(data);
			openSuccessToaster({ message: 'Withdraw Request Created Successfully' });
			// openSuccessToaster({ message: getTranslation('amountDepositedSuccess') });
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const generateAddressRequest = createAsyncThunk(
	'/payment/generate-deposit-address',
	async (data, thunkApi) => {
		try {
			const res = await generateAddress(data);
			thunkApi.dispatch(
				updateCurrencyAddress({
					code: res?.addressObj?.code,
					address: res?.addressObj?.address,
				})
			);
			// openSuccessToaster({ message: 'Withdraw Request Created Successfully' });
			// openSuccessToaster({ message: getTranslation('amountDepositedSuccess') });
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const getCryptoNetworks = createAsyncThunk(
	'/payment/ccpay-coin-networks',
	async (data, thunkApi) => {
		try {
			const res = await getCryptoNetworksService(data);
			const networks = Object.values(res?.coin?.networks || {});
			thunkApi.dispatch(setCryptoNetworks({ networks }));
			// openSuccessToaster({ message: 'Withdraw Request Created Successfully' });
			// openSuccessToaster({ message: getTranslation('amountDepositedSuccess') });
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const getCryptoNetworkFees = createAsyncThunk(
	'/payment/ccpay-network-fee',
	async (data, thunkApi) => {
		try {
			const res = await getCryptoNetworksFees(data);
			thunkApi.dispatch(setNetworkFees(res?.fee || {}));
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const getUserCasinoTransactions = createAsyncThunk(
	'user/casino-bet-history',
	async (params, thunkApi) => {
		try {
			const res = await getUsersCasinoTransactionsService(params);
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(error[0].description);
		}
	}
);

export const getUserSportsTransactions = createAsyncThunk(
	'user/sport-bet-history',
	async (params, thunkApi) => {
		try {
			const res = await getUsersSportsTransactionsService(params);
			return res.sportsbookTransaction;
		} catch (error) {
			return thunkApi.rejectWithValue(error[0].description);
		}
	}
);

export const getUserTransactions = createAsyncThunk(
	'user/casino-transactions',
	async (params, thunkApi) => {
		try {
			const res = await getUsersTransactionsService(params);
			thunkApi.dispatch(setUsersTransactions(res));
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(error[0].description);
		}
	}
);

export const getDocumentsLabelRequest = createAsyncThunk(
	'account/document-label',
	async (_, thunkApi) => {
		try {
			const res = await getDocumentsLabelService();
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(error[0].description);
		}
	}
);

export const getDocumentsInfoRequest = createAsyncThunk(
	'account/document-info',
	async (_, thunkApi) => {
		try {
			const res = await getDocumentsInfoService();
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(error[0].description);
		}
	}
);

export const updateDocVerificationRequest = createAsyncThunk(
	'user/update-user-verification',
	async (params, thunkApi) => {
		try {
			const res = await updateDocVerificationService(params.data);
			// thunkApi.dispatch(fetchUserInformation())

			params.onSuccess && params.onSuccess();
			return res;
		} catch (error) {
			// onError()
			return thunkApi.rejectWithValue(error[0].description);
		}
	}
);

export const getUserAddresses = createAsyncThunk(
	'user/get-addresses',
	async (_, thunkApi) => {
		try {
			const res = await getUserAddressesService();
			thunkApi.dispatch(setUserAddresses(res?.addresses));
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(error[0].description);
		}
	}
);

export const updateUserAddress = createAsyncThunk(
	'user/update-addresses',
	async (params, thunkApi) => {
		try {
			const res = await updateUserAddressService(params);
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(error[0].description);
		}
	}
);

export const addUserAddress = createAsyncThunk(
	'user/add-address',
	async (params, thunkApi) => {
		try {
			const res = await addUserAddressService(params);
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(error[0].description);
		}
	}
);
