import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index';
import axiosInstance from 'network/apis/index';
import { microServices } from 'network/apis/microservice/index';
import { SuccessMessage } from 'network/messages/successMessages';
/**
 * Service to get user info
 */
export const getUserInfoService = () =>
	axiosInstance(
		METHOD_TYPES.get,
		'/user/get-user',
		{},
		{
			server: microServices.SERVICE_URL_1,
		}
	);

/**
 * Service to update user info
//  * @param {object} data - object contains name to be updated for user
 */

export const updateUserInfoService = (data) =>
	axiosInstance(METHOD_TYPES.post, 'user/update', data, {
		server: microServices.SERVICE_URL_1,
		loader: LOADER_HANDLER_TYPES.submit,
		successMessage: SuccessMessage.profileUpdatedSuccess,
	});

export const getUserStatistics = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/user/get-user-statistics',
		{},
		{
			server: microServices.SERVICE_URL_1,
			params: data,
		}
	);

export const getLoyaltyDetailsService = () =>
	axiosInstance(METHOD_TYPES.get, '/user/get-loyalty-details', {
		server: microServices.SERVICE_URL_1,
		loader: LOADER_HANDLER_TYPES.page,
	});

export const depositRequest = (data) =>
	axiosInstance(METHOD_TYPES.post, '/payment/ccpay-deposite-address', data, {
		server: microServices.SERVICE_URL_1,
		loader: LOADER_HANDLER_TYPES.submit,
	});

export const withdrawRequest = (data) =>
	axiosInstance(METHOD_TYPES.post, '/payment/ccpay-apply-withdrawal', data, {
		server: microServices.SERVICE_URL_1,
		loader: LOADER_HANDLER_TYPES.submit,
		// successMessage: 'Withdraw request sent to Admin, Please wait for approval!,'
	});

export const generateAddress = (data) =>
	axiosInstance(METHOD_TYPES.post, '/payment/generate-deposit-address', data, {
		server: microServices.SERVICE_URL_1,
		loader: LOADER_HANDLER_TYPES.submit,
		// successMessage: 'Withdraw request sent to Admin, Please wait for approval!,'
	});

export const getCryptoNetworksService = (data) =>
	axiosInstance(METHOD_TYPES.post, '/payment/ccpay-coin-networks', data, {
		server: microServices.SERVICE_URL_1,
		loader: LOADER_HANDLER_TYPES.submit,
		// successMessage: 'Withdraw request sent to Admin, Please wait for approval!,'
	});

export const getCryptoNetworksFees = (data) =>
	axiosInstance(METHOD_TYPES.post, '/payment/ccpay-network-fee', data, {
		server: microServices.SERVICE_URL_1,
		loader: LOADER_HANDLER_TYPES.submit,
		// successMessage: 'Withdraw request sent to Admin, Please wait for approval!,'
	});

export const getUsersCasinoTransactionsService = (data) =>
	axiosInstance(METHOD_TYPES.get, '/casino/get-transactions', data, {
		server: microServices.SERVICE_URL_1,
		params: data,
		loader: LOADER_HANDLER_TYPES.table,
	});

export const getUsersSportsTransactionsService = (data) =>
	axiosInstance(METHOD_TYPES.get, '/bet/sport-book/get-transactions', data, {
		server: microServices.SERVICE_URL_1,
		params: data,
		loader: LOADER_HANDLER_TYPES.table,
	});

export const getUsersTransactionsService = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		`/user/get-transactions`,
		{},
		{
			server: microServices.SERVICE_URL_1,
			params: data,
			loader: LOADER_HANDLER_TYPES.table,
		}
	);

export const getDocumentsLabelService = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/document/get-document-labels',
		{},
		{
			server: microServices.SERVICE_URL_1,
			params: data,
			loader: LOADER_HANDLER_TYPES.content,
		}
	);

export const getDocumentsInfoService = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/document/get-documents',
		{},
		{
			server: microServices.SERVICE_URL_1,
			params: data,
			loader: LOADER_HANDLER_TYPES.content,
		}
	);
export const updateDocVerificationService = (data) =>
	axiosInstance(METHOD_TYPES.post, '/document/upload', data, {
		server: microServices.SERVICE_URL_1,
		loader: LOADER_HANDLER_TYPES.submit,
		successMessage: SuccessMessage.profileUpdatedSuccess,
		headers: { 'Content-Type': 'multipart/form-data' },
	});

export const getUserAddressesService = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/user/get-addresses',
		{},
		{
			server: microServices.SERVICE_URL_1,
			params: data,
			loader: LOADER_HANDLER_TYPES.content,
		}
	);

export const updateUserAddressService = (data) =>
	axiosInstance(METHOD_TYPES.post, '/user/update-address', data, {
		server: microServices.SERVICE_URL_1,
		loader: LOADER_HANDLER_TYPES.submit,
		successMessage: SuccessMessage.profileUpdatedSuccess,
	});

export const addUserAddressService = (data) =>
	axiosInstance(METHOD_TYPES.post, '/user/add-address', data, {
		server: microServices.SERVICE_URL_1,
		loader: LOADER_HANDLER_TYPES.submit,
		successMessage: SuccessMessage.profileUpdatedSuccess,
	});
