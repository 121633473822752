/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { PropTypes } from 'prop-types';

const DatePickerInput = ({
    selected,
    startDate,
    endDate,
    selectsStart,
    selectsEnd,
    handleDateChange,
    maxDate,
    dateFormat,
    label,
}) => (
    <DatePicker
        label={label}
        dateFormat={dateFormat}
        selected={selected}
        onChange={handleDateChange}
        startDate={startDate}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        endDate={endDate}
        maxDate={maxDate}
    />
);

export default DatePickerInput;

DatePickerInput.defaultProps = {
    selected: '',
    startDate: '',
    endDate: '',
    selectsStart: '',
    selectsEnd: '',
    maxDate: '',
    dateFormat: "dd-MM-yyyy",
    label: '',
};

DatePickerInput.propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    selected: PropTypes.string,
    selectsEnd: PropTypes.string,
    selectsStart: PropTypes.string,
    maxDate: PropTypes.string,
    handleDateChange: PropTypes.func.isRequired,
    dateFormat: PropTypes.string,
    label: PropTypes.string,
};
