/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { setShowWalletPopup } from 'redux-thunk/redux/slices/wallet.slice';
import { useDispatch } from 'react-redux';
import { Deposit } from './components/Deposit';
import { Withdraw } from './components/Withdraw';

const Wallet = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	return (
		<div
			className="modal fade show"
			id="wallet-modal"
			style={{ display: 'block' }}
			onClick={() => {
				dispatch(setShowWalletPopup(false));
			}}
		>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content" onClick={(e) => e.stopPropagation()}>
					<div className="modal-header py-3 border-0 px-4">
						<h5 className="modal-title fs-5 text-white ms-2">{t('wallet')}</h5>
						<button
							type="button"
							className="btn-close btn-close-white small"
							onClick={() => {
								dispatch(setShowWalletPopup(false));
							}}
						/>
					</div>
					<div className="modal-body">
						<ul
							className="nav nav-pills mb-3 d-flex mx-auto"
							id="pills-tab"
							role="tablist"
						>
							<li className="nav-item" role="presentation">
								<button
									className="nav-link active"
									id="pills-home-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-deposit"
									type="button"
									role="tab"
									aria-controls="pills-home"
									aria-selected="true"
								>
									{t('deposit')}
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className="nav-link"
									id="pills-contact-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-withdraw"
									type="button"
									role="tab"
									aria-controls="pills-contact"
									aria-selected="false"
								>
									{t('accountMenuWithdraw')}
								</button>
							</li>
						</ul>
						<div className="tab-content" id="pills-tabContent">
							<Deposit />
							<Withdraw />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Wallet;
