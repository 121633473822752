import * as yup from 'yup';

export const updateAddressSchema = (t) =>
	yup.object().shape({
		address: yup
			.string()
			.max(100, (props) => t('max100Characters', { ...props, maxNumber: 100 }))
			.required((props) => t('addressRequired', props)),
		city: yup.string().required((props) => t('cityRequired', props)),
		zipCode: yup.string().required(t('postCodeRequired')),
	});

export const privateDataSchema = (t) =>
	yup.object().shape({
		firstName: yup
			.string()
			.label(t('firstName'))
			.min(3, (props) => t('fName3Chars', { ...props, minNumber: 3 }))
			.max(50, (props) => t('max50Characters', { ...props, maxNumber: 50 }))
			.required((props) => t('firstNameRequired', props)),
		lastName: yup
			.string()
			.label(t('lastName'))
			.min(3, (props) => t('fName3Chars', { ...props, minNumber: 3 }))
			.max(50, (props) => t('max50Characters', { ...props, maxNumber: 50 }))
			.required((props) => t('lastNameRequired', props)),
		dateOfBirth: yup
			.date()
			.required((props) => t('dateOfBirthRequired', props))
			.max(
				new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
				t('minAge')
			),
		username: yup
			.string()
			.required((props) => t('userNameRequired', props))
			.min(4, t('signinErrorsUserNameMinLength'))
			.max(20, t('signinErrorsUserNameMaxLength'))
			.matches(/^[a-zA-Z_][a-zA-Z0-9_]*$/, t('signinErrorsUsernamePattern')),
		phone: yup
			.string()
			.nullable() // Keeping as string to preserve leading zeros or special characters like "+"
			.matches(
				/^(\+?\d{1,4}[\s-]?)?(\(?\d{1,4}\)?[\s-]?)?\d{1,4}[\s-]?\d{1,4}[\s-]?\d{1,4}$/,
				'Invalid Phone Number'
			),
		phoneCode: yup
			.string()
			.nullable()
			.matches(/^[0-9]{1,4}$/, 'Invalid phone code'),
	});

export const changePasswordSchema = (t) =>
	yup.object().shape({
		password: yup
			.string()
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,}$/,
				t('invalidPass')
			)
			.min(6, (props) =>
				t('mustbeAtleastCharacters', { ...props, minNumber: 6 })
			)
			.max(32, (props) =>
				t('cannotExceedCharacters', { ...props, maxNumber: 32 })
			)
			.required((props) => t('profileErrorsChangePasswordNewRequired', props)),
		confirmPassword: yup
			.string()
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,}$/,
				t('invalidPass')
			)
			.oneOf([yup.ref('password'), null], t('matchPassword'))
			.min(6, (props) =>
				t('mustbeAtleastCharacters', { ...props, minNumber: 6 })
			)
			.max(32, (props) =>
				t('cannotExceedCharacters', { ...props, maxNumber: 32 })
			)
			.required((props) =>
				t('profileErrorsChangePasswordNewRequiredConfirmPass', props)
			),
	});
