/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useState } from 'react';
// import { isMobile } from 'react-device-detect';
import { ROUTE_PATHS } from 'constants/index';
// import { startCase } from 'lodash';
import {
	setGameCategory,
	setProvider,
	setSearchGame,
	setTab,
} from 'redux-thunk/redux/slices/lobby.slice';
import { CasinoMenuIcon } from 'components/ui-kit/Icons/iconComponents/CasinoMenuIcon';
import {
	defaultCategoryIcon,
	// defaultSubCategoryIcon,
} from 'constants/casinoIcon';
import { useNavigate } from 'react-router-dom';
import { Tooltip, Fade } from '@mui/material/index';
import { isMobile } from 'react-device-detect';
import Accordion from 'react-bootstrap/Accordion';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { setShowLoginPopup } from 'redux-thunk/redux/slices/gameSetting.slice';

export const categoryIcon = {
	1: '/assets/images/casino/live-casino.png',
	3: '/assets/images/casino/slot.png',
	4: '/assets/images/casino/chess.png',
};

const SbCasino = ({
	settingData,
	pathname,
	// t,
	dispatch,
	showSideNav,
	tab,
	categoriesData,
	setShowSideNav,
	loginData,
}) => {
	const navigate = useNavigate();
	const [hoverItems, setHoverItems] = useState([]);

	const handleLinkClick = () => {
		dispatch(setTab('lobby'));
		dispatch(setProvider([]));
		dispatch(setSearchGame(''));
		dispatch(setGameCategory(''));
	};

	useEffect(() => {
		if (isMobile) {
			dispatch(setShowSideNav(false));
		}
		// if (pathname?.includes(`${ROUTE_PATHS.SPORTS}`)) {
		// 	setShowSportsbookOptions(true);
		// } else {
		// 	setShowSportsbookOptions(false);
		// }
	}, [pathname]);

	const handleSubCategory = (items) => {
		setHoverItems(items || []);
	};

	const handleLink = (path) => {
		if (loginData?.accessToken) {
			handleLinkClick();
			navigate(path);
		} else {
			dispatch(setShowLoginPopup(true));
		}
	};

	return settingData?.casino &&
		categoriesData &&
		categoriesData?.sboCategories?.length ? (
		<div onMouseLeave={() => handleSubCategory([])}>
			<li className="nav-item">
				<Accordion>
					{/* <Tooltip
							title={t('SB-Casino')}
							arrow
							TransitionComponent={Fade}
							TransitionProps={{ timeout: 600 }}
							placement="right"
						> */}
					<Accordion.Item eventKey="0">
						<Accordion.Header>
							<div className="icon-box">
								<CasinoMenuIcon />
							</div>
							<span className="text">SB-Casino</span>
						</Accordion.Header>
						{showSideNav ? (
							isMobile ? (
								<Accordion.Body>
									<ul className="subnav-item-list">
										{categoriesData?.sboCategories.map((category) => (
											<Accordion key={category?.name?.EN}>
												<Accordion.Item eventKey="0">
													<Accordion.Header>
														<span className="icon-box">
															{categoryIcon[category?.id] ? (
																<img src={categoryIcon[category?.id]} alt="" />
															) : (
																defaultCategoryIcon
															)}
														</span>
														<span className="text">{category?.name?.EN}</span>
													</Accordion.Header>
													<Accordion.Body>
														{category?.sboSubCategories?.map((subCategory) => (
															<li
																key={`casinoCategoriesList ${subCategory?.name?.EN}`}
																className={`subnav-item ${
																	pathname === `/sports/${subCategory.id}`
																		? 'active'
																		: ''
																}`}
															>
																<Tooltip
																	title={subCategory?.name?.EN}
																	arrow
																	TransitionComponent={Fade}
																	TransitionProps={{ timeout: 600 }}
																	placement="right"
																>
																	<button
																		onClick={() =>
																			handleLink(
																				`${ROUTE_PATHS.SPORTS}${subCategory.id}`
																			)
																		}
																		type="button"
																		// to={`/sports/${category?.id}`}
																		className="subnav-link"
																	>
																		<span className="icon-box">
																			<img
																				src="/assets/images/casino/new/fire-icon.png"
																				alt=""
																			/>
																		</span>
																		<span className="text">
																			{subCategory.name?.EN}
																		</span>
																	</button>
																</Tooltip>
															</li>
														))}
													</Accordion.Body>
												</Accordion.Item>
											</Accordion>

											// <li
											// onMouseOver={() =>
											// 	handleSubCategory(category?.sboSubCategories || [])
											// }
											// key={`casinoCategoriesList ${category?.name?.EN}`}
											// className={` subnav-item ${
											// 	tab === category?.name?.EN ? 'active' : ''
											// }`}
											// >
											// 	{console.log(category)}
											// 	<Tooltip
											// 		title={category?.name?.EN}
											// 		arrow
											// 		TransitionComponent={Fade}
											// 		TransitionProps={{ timeout: 600 }}
											// 		placement="right"
											// 	>
											// 		<Link
											// 			// to={`/sports/${category?.id}`}
											// 			className="subnav-link"
											// 		>
											// 			<span className="icon-box">
											// 				{categoryIcons[category?.id] ||
											// 					defaultCategoryIcon}
											// 			</span>
											// 			<span className="text">{category.name?.EN}</span>
											// 			{/* <span>dfsf</span> */}
											// 		</Link>
											// 	</Tooltip>
											// </li>
										))}
									</ul>
								</Accordion.Body>
							) : (
								<Accordion.Body>
									<ul className="subnav-item-list">
										{categoriesData?.sboCategories.map((category) => (
											<li
												onMouseOver={() =>
													handleSubCategory(category?.sboSubCategories || [])
												}
												key={`casinoCategoriesList ${category?.name?.EN}`}
												className={` subnav-item ${
													tab === category?.name?.EN ? 'active' : ''
												}`}
											>
												<Tooltip
													title={category?.name?.EN}
													arrow
													TransitionComponent={Fade}
													TransitionProps={{ timeout: 600 }}
													placement="right"
												>
													<span
														// to={`/sports/${category?.id}`}
														className="subnav-link"
													>
														<span className="icon-box">
															{categoryIcon[category?.id] ? (
																<img src={categoryIcon[category?.id]} alt="" />
															) : (
																defaultCategoryIcon
															)}
														</span>
														<span className="text">{category.name?.EN}</span>
														{/* <span>dfsf</span> */}
														<ArrowForwardIosIcon fontSize="small" />
													</span>
												</Tooltip>
											</li>
										))}
									</ul>
								</Accordion.Body>
							)
						) : null}
					</Accordion.Item>
					{/* </Tooltip> */}
				</Accordion>
			</li>
			{hoverItems?.length ? (
				<div className="extra-nav-items-list extra-nav-items-list-show">
					<div className="sidebar-content-wrap">
						<div className="sidebar-menu-list">
							<ul className="nav-item-list">
								{hoverItems.map((item) => (
									<li className="nav-item">
										<Tooltip
											title={item?.name?.EN}
											arrow
											TransitionComponent={Fade}
											TransitionProps={{ timeout: 600 }}
											placement="right-start"
										>
											<button
												type="button"
												onClick={() =>
													handleLink(`${ROUTE_PATHS.SPORTS}${item.id}`)
												}
												className={`nav-link ${pathname === `${ROUTE_PATHS.SPORTS}${item.id}` ? 'active' : ''}`}
											>
												<span className="icon-box">
													<img
														src="/assets/images/casino/new/fire-icon.png"
														alt=""
													/>
												</span>
												<span className="text">{item?.name?.EN}</span>
											</button>
										</Tooltip>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			) : null}
			{/* ) : (
					<div className="extra-nav-items-list extra-nav-items-list-show text-light">
						Coming Soon...
					</div>
				)} */}
		</div>
	) : null;
};

export default SbCasino;
