import styled from 'styled-components'

export const PaginationContainer = styled.div`
	.pagination-main{
    .MuiTablePagination-displayedRows {
      color: var(--white);
      margin-bottom: 0;
    }
    button {
      background-color: var(--sidebar-bg) !important;
      margin: 5px;
      svg {
        color: var(--white);
      }
    }
    button[disabled]{
      opacity: 0.4;
    }
  }
`
