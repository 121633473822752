/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
	getLocalWalletSetting,
	setLocalWalletSetting,
} from 'helpers/localstorage.helpers';
import { userLogout, verifyEmail } from 'redux-thunk/thunk/auth.thunk';
import {
	fetchUserInformation,
	getDocumentsLabelRequest,
	getDocumentsInfoRequest,
	updateDocVerificationRequest,
	updateUserInformation,
	updateUserAddress,
	fetchUserStatistics,
} from 'redux-thunk/thunk/user.thunk';

const initialState = {
	user: null,
	selectedWallet: null,
	redirectToAfterLogin: '',
	transactions: {
		rows: [],
		count: 0,
	},
	usersTransactions: {
		rows: [],
		count: 0,
	},
	topBetTransactions: {
		rows: [],
		count: 0,
	},
	withdrawRequestData: null,
	qrCodeURl: null,
	showLoginOtp: false,
	siteInfo: null,
	roundData: null,
	showRoundHistoryModel: false,
	hideZeroCurrency: getLocalWalletSetting()?.hideZeroCurrency || false,
	displayFiatCurrency: getLocalWalletSetting()?.displayFiatCurrency || false,
	selectedFiat: getLocalWalletSetting()?.selectedFiat || 'USD',
	activeJoiningBonus: null,
	activeReferralBonus: null,
	emailVerificationError: null,
	loyaltyData: null,
	chat: [],
	verificationLabels: null,
	verificationUserDocument: null,
	verificationError: null,
	defaultWallet: {},
	userDetailsUpdateLoading: false,
	addUserAddressLoading: false,
	userStatisticsLoading: false,
	userStatistics: null,
};
const {
	actions: {
		setUserData,
		setQrcodeUrl,
		setSelectedWallet,
		setRedirectToAfterLogin,
		setAfterResetPassword,
		setTransactions,
		showLoginOtpHandle,
		setRoundData,
		setWalletSetting,
		setTopBetTransactions,
		setChat,
		setUsersTransactions,
		setLoyaltyDetails,
		setUserAddresses,
		updateCurrencyAddress,
		setUserStatistics,
		resetUserState,
	},
	reducer,
} = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setChat: (state, action) => {
			if (action.payload?.chatData) {
				const { chatData } = action.payload;
				return {
					...state,
					chat: [...state.chat, ...chatData],
				};
			}
			return {
				...state,
				chat: [...state.chat, action.payload],
			};
		},
		setUserData: (state, action) => ({
			...state,
			user: action.payload,
		}),
		setQrcodeUrl: (state, action) => ({
			...state,
			qrCodeURl: action.payload,
		}),
		setSelectedWallet: (state, action) => ({
			...state,
			selectedWallet: action.payload,
		}),
		setRedirectToAfterLogin: (state, action) => ({
			...state,
			redirectToAfterLogin: action.payload,
		}),
		setAfterResetPassword: (state, action) => ({
			...state,
			afterResetPassword: action.payload,
		}),
		showLoginOtpHandle: (state, action) => ({
			...state,
			showLoginOtp: action.payload,
		}),
		setTransactions: (state, action) => ({
			...state,
			transactions: action.payload,
		}),
		setUsersTransactions: (state, action) => ({
			...state,
			usersTransactions: action.payload,
		}),
		setLoyaltyDetails: (state, action) => ({
			...state,
			loyaltyData: action.payload,
		}),

		setTopBetTransactions: (state, action) => ({
			...state,
			topBetTransactions: action.payload,
		}),
		setRoundData: (state, action) => ({
			...state,
			roundData: action.payload,
			showRoundHistoryModel: !!action.payload,
		}),
		setWalletSetting: (state, action) => {
			setLocalWalletSetting({
				hideZeroCurrency: action.payload.hideZero,
				displayFiatCurrency: action.payload.displayFiat,
				selectedFiat: action.payload.selectedFiatCurrency,
			});
			return {
				...state,
				hideZeroCurrency: action.payload.hideZero,
				displayFiatCurrency: action.payload.displayFiat,
				selectedFiat: action.payload.selectedFiatCurrency,
			};
		},
		setUserAddresses: (state, action) => ({
			...state,
			addresses: action.payload,
		}),
		updateCurrencyAddress: (state, action) => {
			const { address, code } = action.payload;
			const wallets = state?.user?.user.wallets;
			const currIndex = wallets.findIndex(
				(curr) => curr?.currency?.code === code
			);
			const wallet = wallets[currIndex];
			wallet.paymentAddress = address;
			wallets[currIndex] = wallet;
			state.user = { ...state.user, user: { ...state.user.user, wallets } };
		},
		setUserStatistics: (state, action) => {
			state.userStatistics = action?.payload?.data;
		},
		resetUserState: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUserInformation.fulfilled, (state, action) => ({
				...state,
				user: action.payload,
				selectedWallet:
					action.payload?.wallets?.find((ele) => ele.primary) ||
					action?.payload?.userWallet?.amount,
				defaultWallet: action.payload?.user?.wallets?.find(
					(wallet) => wallet?.currency?.isDefault
				),
			}))

			.addCase(userLogout.fulfilled, (state) => ({
				...state,
			}))
			.addCase(verifyEmail.fulfilled, (state, action) => ({
				...state,
				emailVerificationError: action.payload,
			}))
			.addCase(verifyEmail.rejected, (state, action) => ({
				...state,
				emailVerificationError: action.payload,
			}))
			.addCase(getDocumentsLabelRequest.fulfilled, (state, action) => ({
				...state,
				verificationLabels: action.payload,
			}))
			.addCase(getDocumentsLabelRequest.rejected, (state, action) => ({
				...state,
				verificationError: action.payload,
			}))
			.addCase(getDocumentsInfoRequest.fulfilled, (state, action) => ({
				...state,
				verificationUserDocument: action.payload,
			}))
			.addCase(getDocumentsInfoRequest.rejected, (state, action) => ({
				...state,
				verificationError: action.payload,
			}))
			.addCase(updateDocVerificationRequest.fulfilled, (state, action) => ({
				...state,
				verificationUserDocument: action.payload,
			}))
			.addCase(updateDocVerificationRequest.rejected, (state, action) => ({
				...state,
				verificationError: action.payload,
			}))
			.addCase(updateUserInformation.pending, (state) => ({
				...state,
				userDetailsUpdateLoading: true,
			}))
			.addCase(updateUserInformation.fulfilled, (state) => ({
				...state,
				userDetailsUpdateLoading: false,
			}))
			.addCase(updateUserInformation.rejected, (state) => ({
				...state,
				userDetailsUpdateLoading: false,
			}))
			.addCase(updateUserAddress.pending, (state) => ({
				...state,
				addUserAddressLoading: true,
			}))
			.addCase(updateUserAddress.fulfilled, (state) => ({
				...state,
				addUserAddressLoading: false,
			}))
			.addCase(fetchUserStatistics.pending, (state) => ({
				...state,
				userStatisticsLoading: true,
			}))
			.addCase(fetchUserStatistics.fulfilled, (state) => ({
				...state,
				userStatisticsLoading: false,
			}))
			.addCase(fetchUserStatistics.rejected, (state) => ({
				...state,
				userStatisticsLoading: false,
			}));
	},
});

export default reducer;
export {
	setUserData,
	setSelectedWallet,
	setRedirectToAfterLogin,
	setAfterResetPassword,
	setTransactions,
	setQrcodeUrl,
	showLoginOtpHandle,
	setRoundData,
	setWalletSetting,
	setTopBetTransactions,
	setChat,
	setUsersTransactions,
	setLoyaltyDetails,
	setUserAddresses,
	updateCurrencyAddress,
	setUserStatistics,
	resetUserState,
};
