import { store } from 'App';
import { getAuthToken } from 'helpers/cookie.helpers';
import { startLoader } from 'redux-thunk/redux/slices/loader.slice';

export const requestHandler = (request) => {
	if (request?.handlerEnabled) {
		const authToken = getAuthToken();
		request.withCredentials = true;
		if (authToken) {
			request.headers.Authorization = `AccessToken=${authToken}`;
			// request.headers.cookie = `AccessToken=${authToken}`;
			// request.headers.Cookie = `AccessToken=${authToken}`;
		}
		const { params } = request;
		const filteredParams = {};
		Object.keys(params || {}).forEach((key) => {
			if (
				params[key] === '' ||
				params[key] === '' ||
				params[key] === null ||
				params[key] === undefined
			) {
				// filteredParams[key] = params[key];
			} else {
				filteredParams[key] = params[key];
			}
		});
		request.params = { ...filteredParams };
	}

	// Loader Logic to add loader
	if (request?.loader) {
		store.dispatch(startLoader(request.loader));
	}

	return request;
};
