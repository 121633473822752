/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BetSlipContainer } from './style';
import SingleBets from './SingleBets';
import ComboBets from './ComboBets';
import ExchangeBetslipBottom from './Bottom/ExchangeBetslipBottom';
import BetslipBottom from './Bottom/BetslipBottom';
import BetslipHeader from './Header/BetslipHeader';
import EmptyBetslip from './EmptyBetslip';
import ActionBottom from './ActionBottom';
import BetslipMobileBadge from './Header/BetslipMobileBadge';

const CustomTabPanel = (props) => {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			className="tabPanel"
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
};

CustomTabPanel.propTypes = {
	children: PropTypes.element.isRequired,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const Betslip = () => {
	const { betCount, showBetslip } = useSelector((state) => state.SBbetslip);
	const { isExchange } = useSelector((state) => state.SBgeneral);
	const [tab, setTab] = useState(0);
	const { t } = useTranslation();

	useEffect(() => {
		if (betCount === 1) {
			setTab(0);
		} else if (betCount > 1) {
			setTab(1);
		}
	}, [betCount]);

	return (
		<BetSlipContainer>
			<BetslipMobileBadge />
			<Grid className="betslipSection">
				<BetslipHeader />
				<Grid className={`betSlipBody ${showBetslip ? '' : 'betslip-close'}`}>
					{betCount > 0 ? (
						<Grid className="betSlipTabPanel">
							<Box sx={{ width: '100%' }}>
								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									sx={{ width: '100%' }}
								>
									<Tabs
										className="betSlipTabsGrp"
										value={tab}
										aria-label="basic tabs example"
										display="flex"
										justifyContent="space-between"
										alignItems="center"
										sx={{ width: '100%' }}
									>
										<Tab label={t('single')} {...a11yProps(0)} />
										<Tab label={t('combo')} {...a11yProps(1)} />
										{/* <Tab label="" {...a11yProps(2)} disabled /> */}
									</Tabs>
								</Box>
							</Box>

							{betCount > 1 ? (
								<ComboBets />
							) : betCount === 1 ? (
								<SingleBets />
							) : null}

							{isExchange ? (
								<ExchangeBetslipBottom />
							) : betCount > 1 ? (
								<BetslipBottom />
							) : null}

							<ActionBottom />
						</Grid>
					) : (
						<EmptyBetslip />
					)}
				</Grid>
			</Grid>
		</BetSlipContainer>
	);
};

export default Betslip;
