import React, { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileUserIcon } from 'components/ui-kit/Icons/iconComponents/ProfileUserIcon';
import { LOADER_HANDLER_TYPES, ROUTE_PATHS } from 'constants/index';
import InputField from 'components/molecules/Input/InputField';
import { Form, Formik, ErrorMessage } from 'formik';
import Button from 'components/molecules/Button/index';
import { getAllCountriesStart } from 'redux-thunk/thunk/gameSetting';
import {
	addUserAddress,
	getUserAddresses,
	updateUserAddress,
} from 'redux-thunk/thunk/user.thunk';
import { ReactSelect } from 'components/molecules/ReactSelect/index';
import Loader from 'components/ui-kit/Loader/index';
import { updateAddressSchema } from './schema';

const Address = () => {
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const formik = useRef();

	useEffect(() => {
		dispatch(getAllCountriesStart());
		dispatch(getUserAddresses());
	}, [dispatch]);

	const { allCountries } = useSelector((state) => state.gameSetting);

	const { user, addUserAddressLoading } = useSelector((state) => state.user);
	const userAddresses = useSelector((state) => state.user.addresses);
	const userAddress = userAddresses?.find(
		(address) => address?.userId === user?.user?.id
	);

	const handleCustomSubmit = (formValues) => {
		const { city, zipCode, countryCode, address } = formValues || {};
		if (!userAddress) {
			dispatch(
				addUserAddress({
					city,
					zipCode: String(zipCode),
					countryCode,
					address,
				})
			);
		} else {
			dispatch(
				updateUserAddress({
					city,
					zipCode: String(zipCode),
					countryCode,
					address,
					addressId: userAddress?.id,
				})
			);
		}
	};

	const countryData = useMemo(() => {
		const updatedData = {};
		allCountries?.forEach((item) => {
			const temp = { ...item };
			temp.label = temp.name;
			temp.value = temp.code;
			updatedData[temp.code] = temp;
		});
		return updatedData;
	}, [allCountries]);
	return (
		<div
			className={`tab-pane fade ${
				pathname === ROUTE_PATHS.PROFILE ? 'show active' : ''
			}`}
			id="v-pills-profile"
			role="tabpanel"
			aria-labelledby="v-pills-profile-tab"
		>
			<div className="profile-tab-content-wrap">
				<Formik
					enableReinitialize
					innerRef={formik}
					initialValues={{
						address: userAddress?.address || '',
						city: userAddress?.city || '',
						zipCode: userAddress?.zipCode || '',
						countryCode: userAddress?.countryCode || '',
					}}
					validationSchema={updateAddressSchema(t)}
					validateOnMount
					validateOnBlur
					validateOnChange
					onSubmit={(formValues) => handleCustomSubmit(formValues)}
				>
					{({ values, handleBlur, handleChange, setFieldValue }) => (
						<Form autoComplete="off">
							<div className="profile-heading mb-3">
								<h4 className="m-0">
									<ProfileUserIcon /> {t('Address')}
								</h4>
							</div>
							<div className="d-flex col-lg-12 gap-1 mb-2 form-group">
								<div className="col-lg-6 col-6 w-2 ">
									<label htmlFor="country" className="form-label">
										{t('Country')}
										<span className="color-red">&nbsp;*</span>
									</label>
									<ReactSelect
										type="text"
										placeholder={t('Country')}
										id="countryCode"
										name="countryCode"
										classNamePrefix="selectProvider"
										// defaultMenuIsOpen
										style={{ height: '40px' }}
										value={countryData[values.countryCode]}
										options={Object.values(countryData || {})}
										onChange={(selectedOption) => {
											setFieldValue('countryCode', selectedOption.value);
										}}
										onBlur={handleBlur}
										maxLength={50}
										haslabel="yes"
										required
										labeltext={t('country')}
									/>
									<ErrorMessage
										component="div"
										name="country"
										className="error-message"
									/>
								</div>

								<div className="col-lg-6 col-6">
									<InputField
										type="text"
										placeholder={t('Address')}
										className="form-control shadow-none"
										id="address"
										name="address"
										value={values.address}
										onChange={handleChange}
										onBlur={handleBlur}
										maxLength={50}
										haslabel="yes"
										required
										labeltext={t('Address')}
									/>
									<ErrorMessage
										component="div"
										name="address"
										className="error-message"
									/>
								</div>
							</div>
							<div className="d-flex col-lg-12 gap-1 mb-2 form-group">
								<div className="col-lg-6 col-6">
									<InputField
										type="text"
										placeholder={t('City')}
										className="form-control shadow-none"
										id="city"
										name="city"
										value={values.city}
										onChange={handleChange}
										onBlur={handleBlur}
										maxLength={50}
										haslabel="yes"
										required
										labeltext={t('City')}
									/>
									<ErrorMessage
										component="div"
										name="city"
										className="error-message"
									/>
								</div>
								<div className="col-lg-6 col-6">
									<InputField
										type="number"
										placeholder={t('Zip Code')}
										className="form-control shadow-none"
										id="zipCode"
										name="zipCode"
										value={values.zipCode}
										onChange={handleChange}
										onBlur={handleBlur}
										maxLength={10}
										haslabel="yes"
										required
										labeltext={t('Zip Code')}
									/>
									<ErrorMessage
										component="div"
										name="zipCode"
										className="error-message"
									/>
								</div>
							</div>
							<div className="d-flex col-lg-12 gap-1 mb-2 form-group">
								<div className="auth-btn-wrap w-100 d-flex justify-content-end align-items-center">
									<Button
										type="submit"
										className="btn btn-primary"
										style={{
											margin: '10px',
										}}
										disabled={addUserAddressLoading}
										buttonlabel={
											addUserAddressLoading ? (
												<Loader variant={LOADER_HANDLER_TYPES.submit} />
											) : (
												<span>{t('update')}</span>
											)
										}
									/>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default React.memo(Address);
