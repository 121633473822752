/* eslint-disable react/forbid-prop-types */
import React from 'react';
import {
	Button,
	DialogTitle,
	DialogContent,
	Grid,
	Typography,
	DialogActions,
} from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { DialogWrapper } from './style';

const Modal = ({
	isOpen,
	title,
	content,
	onCloseModal,
	handleCancel,
	handleYes,
	cancelText,
	yesText,
}) => (
	<DialogWrapper
		open={isOpen}
		size="lg"
		onClose={onCloseModal}
		scroll="paper"
		className="mybet-modal-1"
		aria-labelledby="contained-modal-title-vcenter"
	>
		<DialogTitle>
			<Grid display="flex" justifyContent="space-between">
				<Typography>{title}</Typography>
				<Button className="bg-transparent border-0" onClick={onCloseModal}>
					<CloseIcon />
				</Button>
			</Grid>
		</DialogTitle>
		<DialogContent>{content}</DialogContent>
		<DialogActions>
			<Button onClick={handleCancel}>{cancelText}</Button>
			<Button onClick={handleYes} autoFocus>
				{yesText}
			</Button>
		</DialogActions>
	</DialogWrapper>
);

Modal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	onCloseModal: PropTypes.func.isRequired,
	content: PropTypes.element.isRequired,
	handleCancel: PropTypes.func.isRequired,
	handleYes: PropTypes.func.isRequired,
	cancelText: PropTypes.string.isRequired,
	yesText: PropTypes.string.isRequired,
};

export default React.memo(Modal);
