/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
// import { ErrorMessage as ErrorMessageField } from 'formik';

const ErrorMessage = (props) => {
	const { className } = props;
	return (
		<div
			{...props}
			className={className}
			style={{ color: '#dc3545', fontSize: '11px' }}
		>
			{props?.children ? props?.children : ''}
		</div>
	);
};

ErrorMessage.defaultProps = {
	className: 'color-red',
	id: '',
	style: {},
	message: '',
	children: null,
};

ErrorMessage.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	style: PropTypes.objectOf(PropTypes.any),
	message: PropTypes.string,
	children: PropTypes.objectOf(PropTypes.any),
};

export default memo(ErrorMessage);
