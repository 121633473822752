import { createSlice } from '@reduxjs/toolkit';
import { getSettings } from '../thunk/settings.thunk';

const initialState = {
    // basic operation
    settings: {},
    isSettingsLoading: false,
    settingError: false,
}


// SB means SportsBook
const SBsettings = createSlice({
    name: 'SBsettings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSettings.pending, (state) => ({
                ...state,
                isSettingsLoading: true,
            }))
            .addCase(getSettings.fulfilled, (state, { payload }) => ({
                ...state,
                settings: payload,
                isSettingsLoading: false
            }))
            .addCase(getSettings.rejected, (state, { payload }) => ({
                ...state,
                settingError: payload,
                isSettingsLoading: false,
            }));
    },
});

// export const {} = SBsettings.actions;

export default SBsettings.reducer;
