import styled from 'styled-components';

export const NoDataWrapper = styled.div`
	.wrapper {
		display: flex;
		justify-content: center;
		padding: 50px;
		flex-direction: column;
		align-items: center;
	}

	.noDataImage {
		width: 100%;
	}

	.noDataText {
		color: var(--white);
		margin-top: 20px;
	}
`;
