import { createSlice } from '@reduxjs/toolkit';
import {
	fetchLatestBets,
	fetchLatestHighPayout,
	getCasinoProviderGames,
} from 'redux-thunk/thunk/casino.thunk';

const initialState = {
	casinoProviderList: null,
	providerGamesLoading: false,
	providerGames: [],
	providerGamesCount: 0,
	providerGamesLoadMoreLoading: false,
	latestBetLoading: false,
	latestBets: [],
	latestBetsLoading: false,
	highPayoutBets: [],
	highPayoutBetsLoading: false,
};

const {
	actions: {
		setLiveData,
		setCasinoProviderListData,
		setProviderGames,
		setLatestBets,
		setHighPayoutBets,
	},
	reducer,
} = createSlice({
	name: 'casino',
	initialState,
	reducers: {
		setCasinoProviderListData: (state, action) => ({
			...state,
			casinoProviderList: action.payload,
		}),
		setProviderGames: (state, action) => ({
			...state,
			providerGames: [
				...state.providerGames,
				...action.payload.casinoGames.rows,
			],
			providerGamesCount: action.payload.casinoGames.count,
		}),
		setLatestBets: (state, action) => ({
			...state,
			latestBets: action?.payload?.data || [],
		}),
		setHighPayoutBets: (state, action) => ({
			...state,
			highPayoutBets: action?.payload?.data || [],
		}),
	},
	extraReducers: (builder) => {
		builder
			.addCase(getCasinoProviderGames.pending, (state, action) => ({
				...state,
				...(!action.meta.arg?.loadMore
					? {
							providerGamesLoading: true,
							providerGames: initialState.providerGames,
						}
					: {
							providerGamesLoadMoreLoading: true,
						}),
			}))
			.addCase(getCasinoProviderGames.fulfilled, (state) => ({
				...state,
				providerGamesLoading: false,
				providerGamesLoadMoreLoading: false,
			}))
			.addCase(getCasinoProviderGames.rejected, (state) => ({
				...state,
				providerGamesLoading: false,
			}))
			.addCase(fetchLatestBets.pending, (state) => ({
				...state,
				latestBetsLoading: true,
			}))
			.addCase(fetchLatestBets.fulfilled, (state) => ({
				...state,
				latestBetsLoading: false,
			}))
			.addCase(fetchLatestBets.rejected, (state) => ({
				...state,
				latestBetsLoading: false,
			}))
			.addCase(fetchLatestHighPayout.pending, (state) => ({
				...state,
				highPayoutBetsLoading: true,
			}))
			.addCase(fetchLatestHighPayout.fulfilled, (state) => ({
				...state,
				highPayoutBetsLoading: false,
			}))
			.addCase(fetchLatestHighPayout.rejected, (state) => ({
				...state,
				highPayoutBetsLoading: false,
			}));
	},
});

export {
	setLiveData,
	setCasinoProviderListData,
	setProviderGames,
	setLatestBets,
	setHighPayoutBets,
};
export default reducer;
