/* eslint-disable consistent-return */
import { TOKEN } from 'constants/index';

// const cookies = new Cookies();

// 24 hour * 60 minutes of every hour
// const COOKIE_EXPIRE_MIN = 24 * 60

export const getAuthToken = () => localStorage.getItem(TOKEN);
// cookies.get(TOKEN);
export const setAuthToken = (authAccessToken) => {
	// cookies.set(TOKEN, authAccessToken, { // For latter use
	//     secure: false,
	//     sameSite: 'lax'
	// });
	localStorage.setItem(TOKEN, authAccessToken);
};
export const removeAuthToken = () => {
	// cookies.remove(TOKEN, { path: '/' });
	localStorage.removeItem(TOKEN);
};

export const signIn = (token) => {
	setAuthToken(token);
	return new Promise((res) => {
		setTimeout(() => res(true), 500);
	});
};

export const signOut = () => {
	removeAuthToken();
};

/**
 * Parses payload object from jwt
 * @param {string} token
 * @returns {Object}
 */
export const getPayloadFromToken = (token) => {
	if (token) {
		return JSON.parse(atob(token.split('.')[1]));
	}
};
