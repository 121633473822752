export const DUMMY_MARKET = [
    {
        "status": "0",
        "id": "2242",
        "market": {
            "id": "1",
            "providerId": "1",
            "name": "1x2"
        },
        "outcomes": [
            {
                "id": "18691",
                "providerId": "1-2242-1--",
                "name": "{$competitor1}",
                "eventMarketId": "2242",
                "price": "2.80",
                "settlement": "0",
                "status": "0",
                "line": null,
                "baseLine": ""
            },
            {
                "id": "18692",
                "providerId": "1-2242-2--",
                "name": "draw",
                "eventMarketId": "2242",
                "price": "4.00",
                "settlement": "0",
                "status": "0",
                "line": null,
                "baseLine": ""
            },
            {
                "id": "18693",
                "providerId": "1-2242-3--",
                "name": "{$competitor2}",
                "eventMarketId": "2242",
                "price": "8.00",
                "settlement": "0",
                "status": "0",
                "line": null,
                "baseLine": ""
            }
        ]
    },
    {
        "status": "0",
        "id": "2247",
        "market": {
            "id": "8",
            "providerId": "10",
            "name": "Double chance"
        },
        "outcomes": [
            {
                "id": "18708",
                "providerId": "10-2247-9--",
                "name": "{$competitor1} or draw",
                "eventMarketId": "2247",
                "price": "2.40",
                "settlement": "0",
                "status": "0",
                "line": null,
                "baseLine": ""
            },
            {
                "id": "18709",
                "providerId": "10-2247-10--",
                "name": "{$competitor1} or {$competitor2}",
                "eventMarketId": "2247",
                "price": "1.90",
                "settlement": "0",
                "status": "0",
                "line": null,
                "baseLine": ""
            },
            {
                "id": "18710",
                "providerId": "10-2247-11--",
                "name": "draw or {$competitor2}",
                "eventMarketId": "2247",
                "price": "2.40",
                "settlement": "0",
                "status": "0",
                "line": null,
                "baseLine": ""
            }
        ]
    }
]
