/* ==========================================================================
  LANGUAGES
  ========================================================================== */
export const LANGUAGES = [
	{ label: 'English', languageCode: 'EN' },
	{ label: 'German', languageCode: 'DE' },
	{ label: 'Spanish', languageCode: 'ES' },
	{ label: 'French', languageCode: 'FR' },
	{ label: 'Portuguese', languageCode: 'PT' },
	{ label: 'Russian', languageCode: 'RU' },
];

/* ==========================================================================
  HTTP Method Types
========================================================================== */
export const METHOD_TYPES = Object.freeze({
	get: 'get',
	post: 'post',
	put: 'put',
	delete: 'delete',
	patch: 'patch',
});

/* ==========================================================================
LocalStorage / Cookie data
========================================================================== */
export const TOKEN = 'AccessToken';
export const LANGUAGE = 'local_language';
export const WALLETSETTING = 'operator_walletSetting';
export const CHAT = 'chat';
export const LIVE_DATA = 'liveData';
export const SHOW_SIDE_NAV = 'showSideNav';
export const PROVIDER_ID = 'providerId';

/* ==========================================================================
  TOASTER / NOTIFICATION Types
========================================================================== */
export const TOASTER_TYPE = Object.freeze({
	success: 'success',
	error: 'error',
	warning: 'warning',
	info: 'info',
});

/* ==========================================================================
  Loader Types
========================================================================== */
export const LOADER_HANDLER_TYPES = Object.freeze({
	page: 'pageLoader',
	submit: 'submitButtonLoader',
	table: 'tableLoader',
	content: 'contentLoader',
});

/* ==========================================================================
  All the navigation route Paths
========================================================================== */
export const ROUTE_PATHS = Object.freeze({
	HOME: '/',
	LOGIN: '/login',
	METAMASKSIGNUP: '/metamask',
	SIGNUP: '/signup',
	VERIFY_EMAIL: '/verify-email',
	RESET_PASSWORD: '/reset-password/:newPasswordKey/:uuid',
	SEND_RESET_LINK: '/reset-password',
	SITE_INFO: '/site-info/:pageName',
	GAME_CATAGORY: '/casino/:gameCategory',
	TERMS_AND_CONDITION: '/terms-condition',
	CASINO_PLAYGAME:
		'/casino/play-game/:gameId/:providerId/:gameAggregatorUniqueId/:gameName/:isDemo',
	TOURNAMENT_PLAYGAME:
		'/casino/play-game/:gameId/:providerId/:gameName/:isDemo/:tournamentId',
	CASINO: '/casino',
	PROMOTION: '/promotions',
	PROFILE: '/account/profile',
	BALANCE: '/account/balances',
	DEPOSIT: '/accountdeposits',
	WITHDRAWALS: '/account/withdrawals',
	DEPOSITAMOUNT: '/account/deposit-amount',
	WITHDRAWAMOUNT: '/account/withdraw-amount',
	REFERRALS: '/account/referrals',
	SETTINGS: '/account/settings',
	TRANSACTIONS: '/account/transactions',
	MY_BONUSES: '/account/my-bonuses',
	PROVIDERS: '/providers',
	PROVIDERS_CATEGORY: '/providers/:providerName',
	REWARDS: '/rewards',
	LOBBY: '/lobby',
	LOYALTY_BONUS: '/loyalty-bonus',
	RESULTS: '/results',
	CMS: '/cms/:cmsId',
	CASINO_BET: '/account/casino-bet-history',
	ORIGINAL_BET: '/account/original-bet-history',
	VERIFICATION: '/user/verification',
	SPORTS_BET: '/account/sport-bet-history',
	LOYALTY: '/account/loyalty',
	LOYALTY_BONUS_PAGE: '/loyalty-bonus',
	PROMOTIONS: '/promotions',
	TOURNAMENT: '/tournament',
	LIMITS: '/account/limits',
	PROMOTIONS_PAGE_ID: '/:language/promotion/:bonusId/:bonusType',
	TOURNAMENTS_PAGE_ID: '/tournament/:id',
	MAINTENANCE: '/maintenance',
	COMINGSOON: '/coming-soon',
	SPORTS: '/sports/',
	MY_BETS: '/sports/my-bets',
	SPORTBOOK: '/sportbook',
	PROVIDER_GAMES: '/provider/:providerName',
});

/* ==========================================================================
  Table Cell Type
========================================================================== */
export const TABLE_CELL_TYPE = Object.freeze({
	status: 'status',
	switch: 'switch',
	button: 'button',
	date: 'date',
	text: 'text',
	number: 'number',
	default: 'default',
	updateIconButton: 'updateIconButton',
	deleteIconButton: 'deleteIconButton',
	actionCell: 'actionCell',
	object: 'object',
});

/* ==========================================================================
  All Games default ids
========================================================================== */
export const DEFAULT_GAME_ID = Object.freeze({
	CRASH: '1',
});

/* ==========================================================================
  Default id of sports book
========================================================================== */
export const DEFAULT_SPORTS_BOOK_GAME_ID = 3;

/* ==========================================================================
  Bet Result constants
========================================================================== */
export const BET_RESULT = Object.freeze({
	WON: 'won',
	LOST: 'lost',
	CANCELLED: 'cancelled',
});

/* ==========================================================================
  Table tabs constants
========================================================================== */
export const TABLE_TABS = Object.freeze({
	VIVA_ORIGINALS: 'viva-originals',
	MY_BETS: 'my-bets',
	CASINO: 'casino',
});

/* ==========================================================================
  Table transactions tabs constants
========================================================================== */
export const TRANSACTION_TABS = Object.freeze({
	ALL_BETS: 'all-bets',
	HIGH_ROLLER: 'high-roller',
});

/* ==========================================================================
  Custom games icons constants
========================================================================== */
export const CUSTOM_GAMES_ICONS = Object.freeze({
	dice: '/assets/images/dice/dice-icon.svg',
	crash: '/assets/images/casino/crash-table.svg',
});

/* ==========================================================================
  Betting modes constants
========================================================================== */
export const BET_MODES = Object.freeze({
	MANUAL: 'manual',
	AUTO: 'auto',
	STRATEGY: 'strategy',
});

/* ==========================================================================
  Minimum payout for all games
========================================================================== */
export const MIN_PAYOUT = Object.freeze({
	CRASH: 1.01,
});

/* ==========================================================================
  Max increased by percentage for all games
========================================================================== */
export const MAX_INCREASED_BY_PERCENTAGE = Object.freeze({
	DICE_AUTO_BET: 100,
	CRASH_AUTO_BET: 100,
});

/* ==========================================================================
  Bonus status constants
========================================================================== */
export const BONUS_STATUS = Object.freeze({
	ACTIVE: 'active',
	CLAIMED: 'claimed',
	CANCELLED: 'cancelled',
	EXPIRED: 'expired',
	FORFEIT: 'forfeit',
	READY_TO_CLAIM: 'readyToClaim',
});

/* ==========================================================================
  Provable Fair Text
========================================================================== */
export const PROVABLE_FAIR_TEXT =
	'"Provably fair" is a term that refers to a system or algorithm used in online gambling or gaming that can be mathematically verified to ensure that the results are fair and not manipulated in any way. It is a way for players to know that the game or gambling platform they are using is legitimate and not rigged against them.';

/* ==========================================================================
  Transaction status and type constants
========================================================================== */
export const TRANSACTIONS_STATUS = Object.freeze({
	SUCCESS: 'success',
	PENDING: 'pending',
	FAILED: 'failed',
});

export const TRANSACTIONS_TYPE = Object.freeze({
	ALL: 'All',
	BONUS: 'Bonus',
	DEPOSIT: 'DEPOSIT',
	WITHDRAW: 'WITHDRAW',
	// CASINO: 'CASINO',
	// ORIGINALS: 'ORIGINALS',
});

/* ==========================================================================
  Casino Page Tabs
========================================================================== */
export const CASINO_TABS = Object.freeze({
	LOBBY: 'lobby',
	FAVOURITE: 'favourite',
	ORIGINALS: 'originals',
	GAME_SEARCH: 'game-search',
	ALL_PROVIDERS: 'all-providers',
});

export const GAME_CATEGORY = Object.freeze({
	FAVOURITE: 'favourite',
});

export const LIVE_WINS_TABS = Object.freeze({
	LIVE_WINS: 'live-wins',
	MONTH: 'month',
	WEEK: 'week',
	DAY: 'day',
});

export const SPORTS_BOOK_PATHS_SIDEBAR = Object.freeze({
	MY_BETS: 'bets',
	LIVE: 'live',
	FAVOURITES: 'favorites',
	SOCCER: 'soccer-1',
	TENNIS: 'tennis-5',
	BASKETBALL: 'basketball-2',
	VOLLEYBALL: 'volleyball-23',
	TABLE_TENNIS: 'table-tennis-20',
	AMERICAN_FOOTBALL: 'american-football-16',
	BASEBALL: 'baseball-3',
	GOLF: 'golf-9',
});

export const SPORTSBOOK_PATHS = {
	...SPORTS_BOOK_PATHS_SIDEBAR,
	FIFA: '300',
	ICE_HOKEY: 'ice-hockey-4',
	RUGBY: 'rugby-12',
	BOXING: 'boxing-10',
};

export const LIVE_WINS_TYPES = {
	CASINO_WIN: 'casino-win',
	CASINO_BET: 'casino-bet',
};

/* ==========================================================================
  Other constants
========================================================================== */
export const DEFAULT_PRECISION = 5;
export const PAGINATION_LIMIT = 1;
export const PAGINATION_LIMIT_FOR_ALLBETS = 10;
export const MIN_WITHDRAW_AMOUNT = 0.003;
export const WITHDRAW_FEES = 0.000006;
export const TAB_SELECTION = Object.freeze({
	placed: Symbol('placed'),
	previous: Symbol('previous'),
});
export const ROLLOVER = 'rollOver';
export const STRATEGY = 'strategiesSaved';
export const USER_DISCLAIMER = 'userDisclaimer';
export const LIVE_WINS_RECORDS_LIMIT = 10;

export const CASINO_PAGINATION_LIMIT = 10;
export const SPORTS_BOOK_PAGINATION_LIMIT = 10;
export const TRANSACTIONS_LIMIT = 10;

export const INITIAL_CASINO_LIMIT = 21;
export const CASINO_LOAD_PAGINATION_LIMIT = 28;
export const CASINO_SUB_CATE_GAME_LIMIT = 12;

export const AGGREGATOR_ID = {
	NUX: 1,
};

export const LEDGER_PURPOSE_VALUES = {
	Deposit: 'Deposit',
	Withdraw: 'Withdraw',
	Winnings: 'Winnings',
	Commission: 'Commission',
	BonusDeposit: 'Bonus Deposit',
	BonusWithdraw: 'Bonus Withdraw',
	BonusZeroedout: 'Bonus Zeroedout',
	BonusClaimed: 'Bonus Claimed',
	BonusLapsed: 'Bonus Lapsed',
	CasinoBet: 'Bet',
	CasinoWin: 'Win',
	CasinoRefund: 'Refund',
	CasinoBonusBet: 'Bonus Bet',
	CasinoBonusWin: 'Bonus Win',
	CasinoBonusRefund: 'Bonus Refund',
	SportsbookBet: 'Bet',
	SportsbookWin: 'Win',
	SportsbookRefund: 'Refund',
	SportsbookCashout: 'Cashout',
	SportsbookExchangeBet: 'ExchangeBet',
	SportsbookExchangeWin: 'ExchangeWin',
	SportsbookExchangeRefund: 'Exchange Refund',
	SportsbookExchangeCashout: 'Exchange Cashout',
	ResettlementLostToRefund: 'Resettlement Lost To Refund',
	ResettlementLostToWin: 'Resettlement Lost To Win',
	ResettlementOpenToWin: 'Resettlement Open To Win',
	ResettlementWinToOpen: 'Resettlement Win To Open',
	ResettlementWinToLost: 'Resettlement Win To Lost',
	ResettlementRefundToLost: 'Resettlement Refund To Lost',
	ResettlementRefundToOpen: 'Resettlement Refund To Open',
	ResettlementOpenToRefund: 'Resettlement Open To Refund',
	TournamentEnroll: 'Tournament Enroll',
	TournamentWin: 'Tournament Win',
	TournamentCancel: 'Tournament Cancel',
	TournamentRebuy: 'Tournament Rebuy',
	CasinoPromoWin: 'CasinoPromo Win',
	CasinoJackpotWin: 'Casino Jackpot Win',
	CasinoAdjustmentCredit: 'Casino Adjustment Credit',
	CasinoAdjustmentDebit: 'Casino Adjustment Debit',
	SportsbookReturnStack: 'Sportsbook Return Stack',
	ReferralDeposit: 'Referral Deposit',
};

export const MY_BONUS_STATUS2 = {
	all: 'All',
	pending: 'Pending',
	active: 'Active',
	cancelled: 'Cancelled',
	expired: 'Expired',
	claimed: 'Claimed',
	in_process: 'In Process',
	forfitted: 'Forfitted',
};
