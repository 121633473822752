import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSuccessToaster } from 'SportsBook/BetBalancer/Helper/toaster';
import { placeSportsbookBetService } from 'SportsBook/BetBalancer/Network/Services/betslip.service';

const placeSportsbookBet = createAsyncThunk(
	'sport-book/place-bet',
	async (payload, thunkApi) => {
		try {
			let res = await placeSportsbookBetService(payload);
			openSuccessToaster({ message: 'Bet placed successfully!' });
			res = res.data.data;
			return res;
		} catch (err) {
			return thunkApi.rejectWithValue(
				(err && err[0] && err[0]?.description) || err?.message
			);
		}
	}
);

export { placeSportsbookBet };
