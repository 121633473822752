import { createAsyncThunk } from '@reduxjs/toolkit';
import { paymentProviderServiceRequest } from 'network/services/paymentProvider.service';

export const paymentProviderRequest = createAsyncThunk(
	'payment/provider',
	async ({ payload }, thunkApi) => {
		try {
			const res = await paymentProviderServiceRequest(payload);

			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);
