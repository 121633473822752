import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	getGamesListService,
	getFavouriteGameService,
	addFavouriteGameService,
	getSboUrlService,
	getCasinoGameBigWins,
	// getGameStartService,
} from 'network/services/sportsBook.service';
import { getGameDataServices } from 'network/services/casino.service';

import { setCategoryGames } from 'redux-thunk/redux/slices/general.slice';
import { INITIAL_CASINO_LIMIT } from 'constants/index';
import {
	getGameDataSucces,
	getSboUrlSuccess,
	setAllGames,
	// setAllSubCategoryGames,
	setFavCasinoGames,
	setFilteredGames,
	setPopupGames,
} from '../redux/slices/games.slice';

export const getCasinoGamesList = createAsyncThunk(
	'fetch/casino-category-games',
	async ({ data }, thunkApi) => {
		try {
			const res = await getGamesListService({ ...data });
			const { categoryWiseGamesList } = thunkApi?.getState()?.games || {};
			const updatedList = [
				...categoryWiseGamesList,
				{ type: data?.type, ...res },
			];
			return updatedList;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const getSearchedGames = createAsyncThunk(
	'fetch/searched-games-list',
	async ({ data = {}, hasMore = false, popupGames = false }, thunkApi) => {
		try {
			const res = await getGamesListService({ ...data }, hasMore);
			if (hasMore) {
				const { filteredGames, searchPopupGames } =
					thunkApi?.getState()?.games || {};
				const gameData = popupGames ? searchPopupGames : filteredGames;
				res.casinoGames = {
					count: res?.casinoGames?.count,
					rows: [
						...(gameData?.casinoGames?.rows || []),
						...(res?.casinoGames?.rows || []),
					],
				};
			}
			res.popupGames = popupGames;
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const getRecentBigWins = createAsyncThunk(
	'/casino/recent-big-wins',
	async (thunkApi) => {
		try {
			const res = await getCasinoGameBigWins();
			return { bigWins: res?.recentBigWins || [] };
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const getFavCasinoGames = createAsyncThunk(
	'fetch/fav-casino',
	async (data, thunkApi) => {
		try {
			const res = await getFavouriteGameService(data.payload);
			thunkApi.dispatch(setAllGames(res));
			return { ...res.favoriteGames, isLoadMore: data?.isLoadMore };
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const favoriteGameHandlerGenric = createAsyncThunk(
	'fetch/add-favourite-games',
	async (
		{
			gameData,
			isFilteredGames,
			isPopupGames,
			categoryId,
			isFavoriteGames,
			userId,
		},
		thunkApi
	) => {
		const { id } = gameData;

		try {
			const {
				games: {
					filteredGames,
					searchPopupGames,
					// subCategoryGames,
					favoriteCasinoGames,
				},
				general: { categoryGames },
				lobby: { searchGame, provider },
			} = thunkApi?.getState() || {};

			if (isFavoriteGames) {
				const rows = favoriteCasinoGames?.rows?.filter(
					(item) => item.casinoGameId !== id
				);
				const newFavoritesGames = {
					rows,
					count: favoriteCasinoGames?.count
						? favoriteCasinoGames.count - 1
						: rows.length,
				};
				thunkApi?.dispatch(setFavCasinoGames(newFavoritesGames));
			}
			const res = await addFavouriteGameService({ casinoGameId: id, userId });

			if (isFilteredGames) {
				const rows = filteredGames.casinoGames.rows?.map((item) =>
					item.id === id ? { ...item, isFavorite: !item.isFavorite } : item
				);
				const newFilteredGames = {
					casinoGames: {
						rows,
						count: filteredGames?.casinoGames?.count || rows.length,
					},
				};
				thunkApi?.dispatch(setFilteredGames(newFilteredGames));

				const category = { ...categoryGames?.[categoryId] };
				const { games } = category;
				const newGames = games.map((item) =>
					item.id === id ? { ...item, isFavorite: !item.isFavorite } : item
				);
				category.games = newGames;
				thunkApi?.dispatch(
					setCategoryGames({ id: categoryId, categoryInfo: category })
				);
			} else if (isPopupGames) {
				const rows = searchPopupGames?.casinoGames?.rows?.map((item) =>
					item.id === id ? { ...item, isFavorite: !item.isFavorite } : item
				);
				const newPopupGames = {
					casinoGames: {
						rows,
						count: searchPopupGames?.casinoGames?.count || rows.length,
					},
				};
				thunkApi?.dispatch(setPopupGames(newPopupGames));
			} else if (categoryId) {
				const category = { ...categoryGames?.[categoryId] };
				const { games } = category;
				const newGames = games.map((item) =>
					item.id === id ? { ...item, isFavorite: !item.isFavorite } : item
				);
				category.games = newGames;
				thunkApi?.dispatch(
					setCategoryGames({ id: categoryId, categoryInfo: category })
				);
			} else if (isFavoriteGames) {
				if (INITIAL_CASINO_LIMIT < favoriteCasinoGames.count) {
					thunkApi?.dispatch(
						getFavCasinoGames({
							payload: {
								limit: INITIAL_CASINO_LIMIT,
								pageNo: 1,
								search: searchGame,
								categoryId: categoryId ? `["${categoryId}"]` : [],
								providers:
									provider?.length > 0
										? `[${provider.flatMap((gameId) => `"${gameId.value}"`)?.toString()}]`
										: [],
							},
						})
					);
				}
			}

			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const getGameData = createAsyncThunk(
	'/getGameData',
	async (data, thunkApi) => {
		try {
			const res = await getGameDataServices(data);
			thunkApi.dispatch(getGameDataSucces(res));
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);
export const getSboUrl = createAsyncThunk(
	'/getSboUrl',
	async (data, thunkApi) => {
		try {
			const res = await getSboUrlService(data);
			thunkApi.dispatch(getSboUrlSuccess(res?.sboUrl));
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);
