import styled from 'styled-components';

export const SportsBookContainer = styled.div`
	.page-container.sidebar-close {
		page-container sidebar-close {
			max-width: 1880px;
		}
	}

	.sports-custom-spacer {
		max-width: 100%;
		padding: 10px 0 0 0;
		/* margin-top: 3.75rem; */
		@media screen and (max-width: 767px) {
			padding: 0 !important;
		}
		.sports-login-msg {
			text-align: center;
			font-size: 1.5rem;
			font-weight: 500;
			// margin-top: 1.5rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 3rem;
			color: #fff;
			& img {
				width: 200px;
				height: 200px;
			}

			& span {
				overflow: hidden;
				// font-family: 'Source Code Pro', monospace;
				font-size: 28px;
				color: rgba(255, 255, 255, 0.7);
				margin: 0 auto;
			}

			@media screen and (max-width: 767px) {
				margin-top: 6.5rem;
			}
		}
	}

	.betslipSection {
		position: fixed;
		bottom: 0;
		right: 16px;
		transition: all 0.5s ease-in-out;
		z-index: 9;
	}
	.betslip-close {
		height: 0;
	}
	.sports-iframe {
		min-height: calc(-370px + 100vh);
		height: 90vh;
	}
`;
