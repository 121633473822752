/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useLocation, NavLink, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { isEmpty, startCase } from 'lodash';
import { Tooltip, Fade } from '@mui/material';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import {
	setShowChat,
	setShowSearchPopup,
	setShowSideNav,
} from 'redux-thunk/redux/slices/settings.slice';
import { getItem, setItem } from 'helpers/localstorage.helpers';
import { ROUTE_PATHS, SHOW_SIDE_NAV } from 'constants/index';
import {
	setGameCategory,
	setProvider,
	setSearchGame,
	setTab,
} from 'redux-thunk/redux/slices/lobby.slice';
import { setGameCategoryId } from 'redux-thunk/redux/slices/games.slice';
// import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import { HeaderSearchIcon } from 'components/ui-kit/Icons/iconComponents/HeaderSearchIcon';
import { ProfileUserIcon } from 'components/ui-kit/Icons/iconComponents/ProfileUserIcon';
import { ChatIcon } from 'components/ui-kit/Icons/iconComponents/ChatIcon';
import './sideNavBar.scss';
import { getAuthToken } from 'helpers/cookie.helpers';
import { PromotionsIcon } from 'components/ui-kit/Icons/iconComponents/PromotionsIcon';
// import LocalBarIcon from '@mui/icons-material/LocalBar';
import { HouseIcon } from 'components/ui-kit/Icons/iconComponents/HouseIcon';
import { FavouriteGreyIcon } from 'components/ui-kit/Icons/iconComponents/FavouriteGreyIcon';
import {
	defaultCategoryIcon,
	// defaultSubCategoryIcon,
} from 'constants/casinoIcon';
// import { BasketballBIcon } from 'components/ui-kit/Icons/iconComponents/BasketballBIcon';
// import { TennisBIcon } from 'components/ui-kit/Icons/iconComponents/TennisBIcon';
// import { SPORTS_PROVIDER_ID } from 'SportsBook/BetBalancer/Helper/sports.config';
// import { SB_ROUTE_PATHS } from 'SportsBook/BetBalancer/SportsRoutes/routeConstant';
import Accordion from 'react-bootstrap/Accordion';
import { getSports } from 'SportsBook/BetBalancer/Store/thunk/sports.thunk';
import { setShowLoginPopup } from 'redux-thunk/redux/slices/gameSetting.slice';
import SbCasino from './SbCasino';
import SbSportsbook from './SbSportsbook';
import { getCategoryData } from 'redux-thunk/thunk/general.thunk';
// import SbSportsbook from './SbSportsbook';
// import { SPORTS_ICON } from 'utils/sportsConstants';
// import mybet from '../../ui-kit/Icons/sports-icon/mybet.svg';

// const CASINO_CATEGORY = [
// 	'Slots',
// 	'Fish',
// 	'Live',
// 	'Arcade',
// 	'Bingo',
// 	'Sport',
// 	'Mini',
// ];

const SideNavbar = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	// const searchParams = new URLSearchParams(document.location.search);
	// const sbPath = searchParams?.get('sbpath');
	const { showSideNav, showChat } = useSelector((state) => state?.settings);
	const isLoggedIn = getAuthToken();
	// const { showAllFavGames } = useSelector((state) => state.games)
	// const { languageData } = useSelector((state) => state.language)
	const { loginData } = useSelector((state) => state.auth);
	const { tab } = useSelector((state) => state?.lobby);
	const { categoriesData, settingData } = useSelector((state) => state.general);
	const sports = useSelector((state) => state.SBsports.sports);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [windowHeight, setWindowHeight] = useState(window.innerHeight);
	// const [showSportsMenu, setShowSportsMenu] = useState(true);
	// const [showSportBookMenu, setShowSportBookMenu] = useState(false);
	// const [showCasinoCategoryOptions, setShowCasinoCategoryOptions] = useState(2);

	const setWindowDimensions = () => {
		setWindowWidth(window.innerWidth);
		setWindowHeight(window.innerHeight);
	};

	const sidenavExpand = () => {
		setItem(SHOW_SIDE_NAV, !showSideNav);
		dispatch(setShowSideNav(!showSideNav));
		// setShowSportsMenu(false);
		document
			.getElementsByClassName('sidebar-wrap')[0]
			?.classList.toggle('sidebar-close');
		document
			.getElementsByClassName('page-container')[0]
			?.classList.toggle('sidebar-close');
		document
			.getElementsByClassName('header')[0]
			?.classList.toggle('sidebar-close');
	};
	// const getSubCategoryGame = (id) => {
	// 	setShowCasinoCategoryOptions(id);
	// 	if (id === showCasinoCategoryOptions) {
	// 		setShowCasinoCategoryOptions('');
	// 	} else {
	// 		setShowCasinoCategoryOptions(id);
	// 	}
	// };
	const getCategoryGames = (item) => {
		dispatch(setGameCategoryId(item?.id));
		dispatch(setTab(item?.name.EN));
		dispatch(setProvider([]));
		dispatch(setSearchGame(''));
		navigate(`/casino/${item?.name.EN}`);
	};

	const getFavoriteGames = () => {
		navigate(`/casino/${t('favorite')}`);
		dispatch(setTab(t('favorite')));
		dispatch(setGameCategoryId(''));
		dispatch(setProvider([]));
		dispatch(setSearchGame(''));
	};

	useEffect(() => {
		if (isEmpty(sports)) {
			dispatch(getSports());
		}
	}, []);
	useEffect(() => {
		if (isEmpty(categoriesData)) {
			dispatch(getCategoryData());
		}
	}, []);

	useEffect(() => {
		if (!showSideNav) {
			// setShowCasinoOptions(false)
			// setShowSportsbookOptions(false)
		}
	}, [showSideNav]);

	useEffect(() => {
		dispatch(setShowSideNav(getItem(SHOW_SIDE_NAV)));
	}, []);

	useEffect(() => {
		window.addEventListener('resize', setWindowDimensions);
		return () => {
			window.removeEventListener('resize', setWindowDimensions);
		};
	}, []);

	useEffect(() => {
		if (windowWidth <= 768) {
			setItem(SHOW_SIDE_NAV, false);
			dispatch(setShowSideNav(false));
		}
	}, [windowHeight, windowWidth]);

	const handleLinkClick = () => {
		dispatch(setTab('lobby'));
		dispatch(setProvider([]));
		dispatch(setSearchGame(''));
		dispatch(setGameCategory(''));
	};

	const handleLink = (path) => {
		if (loginData?.accessToken) {
			handleLinkClick();
			navigate(path);
		} else {
			dispatch(setShowLoginPopup(true));
		}
	};

	const handleHomeClick = () => {
		dispatch(setGameCategoryId(''));
		dispatch(setProvider([]));
		dispatch(setSearchGame(''));
		dispatch(setTab('lobby'));
	};

	return (
		<>
			<div className={`sidebar-wrap ${!showSideNav ? 'sidebar-close' : ''}`}>
				<div className="sidebar-content-wrap">
					<div className="sidebar-menu-list">
						<ul className="nav-item-list">
							<li className="nav-item">
								<Tooltip
									title={t('home')}
									arrow
									TransitionComponent={Fade}
									TransitionProps={{ timeout: 600 }}
									placement="right-start"
								>
									<NavLink
										to={ROUTE_PATHS.HOME}
										onClick={() => handleHomeClick()}
										activeClassName={
											pathname === ROUTE_PATHS.HOME ? 'active' : ''
										}
										className="nav-link"
									>
										<span className="icon-box">
											<HouseIcon />
										</span>
										<span className="text">{t('home')}</span>
									</NavLink>
								</Tooltip>
							</li>

							{/* <li className="nav-item">
								<Tooltip
									title={t('sports')}
									arrow
									TransitionComponent={Fade}
									TransitionProps={{ timeout: 600 }}
									placement="right-start"
								>
									<NavLink
										to={ROUTE_PATHS.SPORTS}
										activeClassName={
											pathname === ROUTE_PATHS.SPORTS ? 'active' : ''
										}
										className="nav-link"
									>
										<span className="icon-box">
											<SportsBSoccerIcon />
										</span>
										<span className="text">{t('sports')}</span>
									</NavLink>
								</Tooltip>
							</li> */}

							{settingData?.casino && (
								<li
									className={`nav-item ${
										pathname.includes(`${ROUTE_PATHS.CASINO}/`)
											? 'inner-navigation-selected'
											: ''
									}`}
								>
									<Accordion>
										<Tooltip
											title={t('casino')}
											arrow
											TransitionComponent={Fade}
											TransitionProps={{ timeout: 600 }}
											placement="right-start"
										>
											<Accordion.Item eventKey="0">
												<Accordion.Header>
													<div className="icon-box">
														<img
															src="/assets/images/casino/casino-G.svg"
															alt=""
														/>
													</div>
													<span className="text">{t('casino')}</span>
												</Accordion.Header>
												<Accordion.Body>
													<ul className="subnav-item-list">
														{isLoggedIn && (
															<li
																className={` subnav-item ${
																	tab === t('favorite') ? 'active' : ''
																}`}
															>
																<Tooltip
																	title={t('favorite')}
																	arrow
																	TransitionComponent={Fade}
																	TransitionProps={{ timeout: 600 }}
																	placement="right-start"
																>
																	<NavLink
																		to={`/casino/${t('favorite')}`}
																		activeClassName={`${
																			tab === t('favorite') ? 'active' : ''
																		}`}
																		onClick={() => {
																			dispatch(getFavoriteGames);
																		}}
																		className="subnav-link"
																	>
																		<span className="icon-box">
																			<FavouriteGreyIcon />
																		</span>
																		<span className="text">
																			{t('favorite')}
																		</span>
																	</NavLink>
																</Tooltip>
															</li>
														)}
														{categoriesData?.casinoCategories &&
															categoriesData?.casinoCategories.map(
																(category) => {
																	if (!category?.isFeatured) {
																		return null;
																	}
																	return (
																		<li
																			key={`casinoCategoriesList ${category?.name?.EN}`}
																			className="subnav-item"
																		>
																			<Tooltip
																				title={category?.name?.EN}
																				arrow
																				TransitionComponent={Fade}
																				TransitionProps={{ timeout: 600 }}
																				placement="right-start"
																			>
																				<Link
																					to={`/casino/${category?.name?.EN}`}
																					onClick={() => {
																						getCategoryGames(category);
																					}}
																					className={` subnav-link ${
																						tab === category?.name?.EN
																							? 'active'
																							: ''
																					}`}
																				>
																					<span className="icon-box">
																						{/* {categoryIcons[category?.id] ||
																						defaultCategoryIcon} */}
																						{category?.iconUrl ? (
																							<img
																								src={category?.iconUrl}
																								alt=""
																							/>
																						) : (
																							defaultCategoryIcon
																						)}
																					</span>
																					<span className="text">
																						{category.name?.EN}
																					</span>
																					<span>
																						{isMobile
																							? startCase(
																									category?.gameCategory
																								)
																							: category?.gameCategory?.length >
																								  14
																								? `${startCase(
																										category?.gameCategory.substring(
																											0,
																											14
																										)
																									)}...`
																								: startCase(
																										category?.gameCategory
																									)}
																					</span>
																				</Link>
																			</Tooltip>
																		</li>
																	);
																}
															)}
													</ul>
												</Accordion.Body>
											</Accordion.Item>
										</Tooltip>
									</Accordion>
								</li>
							)}
							<SbCasino
								settingData={settingData}
								pathname={pathname}
								t={t}
								dispatch={dispatch}
								showSideNav={showSideNav}
								tab={tab}
								categoriesData={categoriesData}
								setShowSideNav={setShowSideNav}
								loginData={loginData}
							/>
							<SbSportsbook
								settingData={settingData}
								pathname={pathname}
								t={t}
								dispatch={dispatch}
								showSideNav={showSideNav}
								tab={tab}
								categoriesData={categoriesData}
								setShowSideNav={setShowSideNav}
								loginData={loginData}
								handleLink={handleLink}
							/>
							{/* {settingData?.sportsbook ? (
								<li className="nav-item">
									<Tooltip
										title="Sportsbook"
										arrow
										TransitionComponent={Fade}
										TransitionProps={{ timeout: 600 }}
										placement="right-start"
									>
										<button
											type="button"
											onClick={() => handleLink('/sports/6')}
											className={`nav-link ${pathname === '/sports/6' ? 'active' : ''}`}
										>
											<span className="icon-box">
												<img
													src="/assets/images/stock-images/sports-icon.png"
													alt=""
													width="20"
													height="20"
												/>
											</span>
											<span className="text">{t('SB_Sportsbook')}</span>
										</button>
									</Tooltip>
								</li>
							) : null} */}
							{/* {settingData?.sportsbook && (
								<li>
									<Tooltip
										title={t('sports')}
										arrow
										TransitionComponent={Fade}
										TransitionProps={{ timeout: 600 }}
										placement="right-start"
									>
										<NavLink
											to={ROUTE_PATHS.SPORTS}
											activeClassName={
												pathname.includes(`${ROUTE_PATHS.SPORTS}`)
													? 'active'
													: ''
											}
										>
											<div className="menu-left d-flex align-items-center">
												<div className="menu-icon">
													<SportsSoccerIcon />
												</div>
												<p className="m-0">{t('sports')}</p>
											</div>
											<span
												className="down-arrow d-flex justify-content-center align-items-center"
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													setShowSportsMenu((prev) => !prev);
												}}
											>
												{showSportsMenu ? (
													<ArrowDropUpIcon fontSize="small" />
												) : (
													<ArrowDropDownIcon fontSize="small" />
												)}
											</span>
											<span className="menu-arrow">
												<LongArrowRightIcon fontSize="small" />
											</span>
										</NavLink>
									</Tooltip>
									 {showSportsMenu && (
										<ul className="submenu-wrap px-0">
											{isLoggedIn && (
												<li className="submenu-wrap">
													<Tooltip
														title={t('myBets')}
														arrow
														TransitionComponent={Fade}
														TransitionProps={{ timeout: 600 }}
														placement="right-start"
													>
														<NavLink
															to={ROUTE_PATHS.MY_BETS}
															activeClassName={
																ROUTE_PATHS.MY_BETS === pathname ? 'active' : ''
															}
														>
															<img src={mybet} alt="mybet" />
															<span>{t('myBets')}</span>
														</NavLink>
													</Tooltip>
												</li>
											)}
											{sports?.slice(0, 3).map((sport) =>
												SPORTS_ICON[sport.providerId] ? (
													<li className="submenu-wrap">
														<Tooltip
															title={sport.name}
															arrow
															TransitionComponent={Fade}
															TransitionProps={{ timeout: 600 }}
															placement="right-start"
														>
															<NavLink
																to={`/sports/sport-events/?sportId=${sport.providerId}`}
																activeClassName={
																	searchParams?.get('sportId') ===
																	sport.providerId
																		? 'active'
																		: ''
																}
															>
																<img
																	src={SPORTS_ICON[sport.providerId]}
																	alt={sport.name}
																/>
																<span>{sport.name}</span>
															</NavLink>
														</Tooltip>
													</li>
												) : null
											)}
										</ul>
									)}
								</li>
							)} */}

							<li className="nav-item">
								<Tooltip
									title={t('accountMenuPromotions')}
									arrow
									TransitionComponent={Fade}
									TransitionProps={{ timeout: 600 }}
									placement="right-start"
								>
									<NavLink
										to={ROUTE_PATHS.PROMOTIONS}
										activeClassName={
											pathname === ROUTE_PATHS.PROMOTIONS ? 'active' : ''
										}
										onClick={() => {
											// e.preventDefault()
											// openInfoToaster({ message: 'Coming Soon!' })
										}}
										className="nav-link"
									>
										<span className="icon-box">
											<PromotionsIcon />
										</span>
										<span className="text">{t('accountMenuPromotions')}</span>
									</NavLink>
								</Tooltip>
							</li>
							{/* tournament */}
							{/* <li>
								<Tooltip
									title={t('accountMenuTournament')}
									arrow
									TransitionComponent={Fade}
									TransitionProps={{ timeout: 600 }}
									placement="right-start"
								>
									<NavLink
										to={ROUTE_PATHS.TOURNAMENT}
										activeClassName={
											pathname === ROUTE_PATHS.TOURNAMENT ? 'active' : ''
										}
										onClick={() => {
											// e.preventDefault()
											// openInfoToaster({ message: 'Coming Soon!' })
										}}
									>
										<div className="menu-left d-flex align-items-center">
											<div className="menu-icon">
												<LocalBarIcon />
											</div>
											<p className="m-0">{t('accountMenuTournament')}</p>
										</div>
										<span className="menu-arrow">
											<LongArrowRightIcon />
										</span>
									</NavLink>
								</Tooltip>
							</li> */}

							{/* {settingData?.sportsbook && (
								<li>
									<Tooltip
										title="SportBook"
										arrow
										TransitionComponent={Fade}
										TransitionProps={{ timeout: 600 }}
										placement="right-start"
									>
										<NavLink
											to={`${ROUTE_PATHS.SPORTBOOK}?sbpath=/sportsbook/sports/home`}
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												navigate(
													`${ROUTE_PATHS.SPORTBOOK}?sbpath=/sportsbook/sports/home`
												);
												if (
													window?.sbIframe &&
													window?.sbIframe?.navigatIframe
												) {
													window?.sbIframe?.navigatIframe({
														path: '/sportsbook/sports/home',
													});
												}
											}}
											activeClassName={
												pathname === `${ROUTE_PATHS.SPORTBOOK}` &&
												sbPath === '/sportsbook/sports/home'
													? 'active'
													: ''
											}
										>
											<div className="menu-left d-flex align-items-center">
												<div className="menu-icon">
													<SportsSoccerIcon />
												</div>
												<p className="m-0">{t('sportsThirdParty')}</p>
											</div>
											<span
												className="down-arrow d-flex justify-content-center align-items-center"
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													setShowSportBookMenu((prev) => !prev);
												}}
											>
												{showSportBookMenu ? (
													<ArrowDropUpIcon fontSize="small" />
												) : (
													<ArrowDropDownIcon fontSize="small" />
												)}
											</span>
											<span className="menu-arrow">
												<LongArrowRightIcon fontSize="small" />
											</span>
										</NavLink>
									</Tooltip>
									{showSportBookMenu && (
										<ul className="submenu-wrap px-0">
											<li className="submenu-wrap">
												<Tooltip
													title="Live"
													arrow
													TransitionComponent={Fade}
													TransitionProps={{ timeout: 600 }}
													placement="right-start"
												>
													<NavLink
														exact
														to={`${ROUTE_PATHS.SPORTBOOK}?sbpath=/sportsbook/sports/live`}
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															navigate(
																`${ROUTE_PATHS.SPORTBOOK}?sbpath=/sportsbook/sports/live`
															);
															if (
																window?.sbIframe &&
																window?.sbIframe?.navigatIframe
															) {
																window?.sbIframe?.navigatIframe({
																	path: '/sportsbook/sports/live',
																});
															}
														}}
														activeClassName={
															sbPath === '/sportsbook/sports/live'
																? 'active'
																: ''
														}
													>
														<img src={mybet} alt="Live" />
														<span>Live</span>
													</NavLink>
												</Tooltip>
											</li>
											<li className="submenu-wrap">
												<Tooltip
													title="soccer"
													arrow
													TransitionComponent={Fade}
													TransitionProps={{ timeout: 600 }}
													placement="right-start"
												>
													<NavLink
														exact
														to={`${ROUTE_PATHS.SPORTBOOK}?sbpath=/sportsbook/sports/1`}
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															navigate(
																`${ROUTE_PATHS.SPORTBOOK}?sbpath=/sportsbook/sports/1`
															);
															if (
																window?.sbIframe &&
																window?.sbIframe?.navigatIframe
															) {
																window?.sbIframe?.navigatIframe({
																	path: '/sportsbook/sports/1',
																});
															}
														}}
														activeClassName={
															sbPath === '/sportsbook/sports/1' ? 'active' : ''
														}
													>
														<SportsSoccerIcon />
														<span>Soccer</span>
													</NavLink>
												</Tooltip>
											</li>
										</ul>
									)}
								</li>
							)}  */}
						</ul>
					</div>
				</div>
			</div>
			<div className="mobile-bottom-navbar">
				<ul className="list-inline mb-0 d-flex justify-content-between overflow-auto flex-nowrap">
					<li className="list-inline-item">
						<button
							type="button"
							onClick={sidenavExpand}
							className="btn btn-primary"
						>
							<MenuIcon />
						</button>
					</li>
					{loginData?.accessToken && (
						<li className="list-inline-item">
							<button
								type="button"
								className="btn btn-primary search-btn"
								onClick={() => {
									// navigate(ROUTE_PATHS.PROFILE);
									dispatch(setShowChat(false));
								}}
							>
								<ProfileUserIcon />
							</button>
						</li>
					)}
					<li className="list-inline-item">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => dispatch(setShowSearchPopup(true))}
						>
							<HeaderSearchIcon />
						</button>
					</li>
					<li className="list-inline-item">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => dispatch(setShowChat(!showChat))}
						>
							<ChatIcon />
						</button>
					</li>
				</ul>
			</div>
		</>
	);
};

export default React.memo(SideNavbar);
