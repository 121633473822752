/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React from 'react';
import { ApproveVerificationIcon } from 'components/ui-kit/Icons/iconComponents/ApproveVerificationIcon';
// import { DeleteVerificationIcon } from 'components/ui-kit/Icons/iconComponents/DeleteVerificationIcon';
import { PendingVerificationIcon } from 'components/ui-kit/Icons/iconComponents/PendingVerificationIcon';
import { RejectVerificationIcon } from 'components/ui-kit/Icons/iconComponents/RejectVerificationIcon';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
// import { ReloadVerificationIcon } from 'components/ui-kit/Icons/iconComponents/ReloadVerificationIcon'
import Button from 'components/molecules/Button/index';
import Loader from 'components/ui-kit/Loader/index';
import { LOADER_HANDLER_TYPES } from 'constants/index';
// import { statusTxt } from './constant';
import ImageUpload from './ImageUpload';
import { ImagePreviewSection } from './style';

const VerificationBoxInfo = (props) => {
	const {
		labels,
		selectedTab,
		t,
		documents,
		setImageDoc,
		imgDoc,
		onUpdateVerification,
		isSubmitLoading,
	} = props;

	const arrayValue = labels?.find((item) => item.name === selectedTab);
	// console.log(arrayValue.name, 'lablesss');

	const selectedDocument =
		documents && documents?.find((u) => u.documentLabel?.name === selectedTab);

	let selectedTabDocURL;
	if (selectedDocument?.url?.length > 0) {
		selectedTabDocURL = selectedDocument?.url;
		// selectedDocument?.documentUrl[selectedDocument?.documentUrl?.length - 1];
	}
	// console.log(selectedDocument?.status, statusTxt[selectedDocument?.status]);
	return (
		<>
			<div className="verification-box-head">
				<span className="icon">
					{selectedDocument?.status === 1 && (
						<ApproveVerificationIcon className="approve-icon" fill="black" />
					)}
					{selectedDocument?.status === 2 && (
						<RejectVerificationIcon className="reject-icon" />
					)}
					{(!selectedDocument?.status || selectedDocument?.status === 0) && (
						<PendingVerificationIcon className="pending-icon" />
					)}
				</span>
				<span style={{ textTransform: 'capitalize' }} className="text">
					{/* {typeof selectedDocument?.status === 'number'
						? ` : ${statusTxt[`${selectedDocument?.status}`]}`
						: ' : PENDING  '} */}
					{`${arrayValue?.name} : ${selectedDocument?.status || 'PENDING'}`}
				</span>
			</div>
			<div className="verification-inte-box">
				{selectedDocument?.status !== 1 && (
					<div className="verification-uploadleft-box">
						<ImageUpload
							setImageDoc={setImageDoc}
							selectedDocument={selectedDocument}
							selectedTab={selectedTab}
							arrayValue={arrayValue}
						/>

						{/* {((selectedDocument &&
                (selectedDocument.status === 2 ||
                  selectedDocument.status === 0 ||
                  selectedDocument.status === 4) &&
                docs[label]) ||
                (selectedDocument === undefined &&
                  docs[label] &&
                  docs[label] !== null)) && (
                <>
                  {docs[label]?.name}
                  <Button
                    className="btn btn-icon"
                    onClick={() => {
                      setDocs({ ...docs, [label]: null });
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </>
              )} */}
					</div>
				)}

				<ImagePreviewSection>
					<div className="doc-img-preview">
						{(() => {
							if (imgDoc && Object.keys(imgDoc).length > 0 && imgDoc !== null) {
								const doc = imgDoc?.[selectedTab];
								if (doc instanceof Blob) {
									return (
										<>
											{doc.type.match('pdf') ? (
												<iframe
													title="iframe1"
													src={window.URL?.createObjectURL(doc)}
													className="img-preview"
												/>
											) : (
												<img
													src={window.URL?.createObjectURL(doc)}
													className="img-preview"
													alt="document-verify"
												/>
											)}
										</>
									);
								}
								console.error('Invalid document type:', doc);
								return <div>Unable to preview</div>;
							}
							return (
								<>
									{selectedTabDocURL ? (
										selectedTabDocURL?.match('.pdf') ? (
											<iframe
												title="iframe2"
												src={selectedTabDocURL}
												className="img-preview"
											/>
										) : (
											<img
												src={selectedTabDocURL}
												className="img-preview"
												onClick={() => window.open(selectedTabDocURL)}
												alt="document-verify"
											/>
										)
									) : (
										<PhotoCameraIcon fontSize="large" sx={{ color: 'black' }} />
									)}
								</>
							);
						})()}
					</div>
				</ImagePreviewSection>
			</div>

			{/* {imgDoc && Object.keys(imgDoc).length > 0 && imgDoc !== null &&  (
				<div className="doc-file-name">
					{imgDoc[selectedTab]?.name}
					<button
						type="button"
						aria-label="delete"
						className="btn btn-icon"
						onClick={() => {
							setImageDoc(null);
						}}
					>
						<DeleteVerificationIcon />
					</button>
				</div>
			)} */}
			<div className="verification-btn-bottom">
				{selectedDocument?.status !== 1 &&
					imgDoc &&
					Object.keys(imgDoc).length > 0 && (
						<Button
							type="button"
							className="btn btn-primary"
							onClick={onUpdateVerification}
							disabled={isSubmitLoading}
							buttonlabel={
								isSubmitLoading ? (
									<Loader variant={LOADER_HANDLER_TYPES.submit} />
								) : (
									<span>{t('upload')}</span>
								)
							}
						/>
					)}
			</div>
		</>
	);
};

export default VerificationBoxInfo;
