import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@mui/material';
import PropTypes from 'prop-types';
import { setItem } from 'helpers/localstorage.helpers';

const SessionAlertDialog = ({
	setToggle,
	openModal,
	onCloseModal,
	sessionLimit,
	handleLogout,
}) => {
	const onClickContinue = () => {
		setItem('sessionLimit', new Date());
		onCloseModal();
		setToggle((prev) => !prev);
	};
	const onClickLogout = () => {
		handleLogout();
		onCloseModal();
	};

	return (
		<Dialog
			open={openModal}
			size="md"
			className="limit-dialog-modal form-group"
			aria-labelledby="contained-modal-title-vcenter"
		>
			<DialogTitle>Session Time Over</DialogTitle>
			<DialogContent>
				<div>
					You have crossed the session limit ({sessionLimit} hours) that you
					have set to your account
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					className="btn btn-primary mt-3 float-right"
					onClick={onClickContinue}
				>
					Continue
				</Button>
				<Button
					className="btn btn-primary mt-3 float-right"
					onClick={onClickLogout}
				>
					Logout
				</Button>
			</DialogActions>
		</Dialog>
	);
};

SessionAlertDialog.propTypes = {
	openModal: PropTypes.bool.isRequired,
	onCloseModal: PropTypes.func.isRequired,
	setToggle: PropTypes.func.isRequired,
	sessionLimit: PropTypes.number.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default SessionAlertDialog;
