/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { Grid, Button, TextField, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
	LOADER_HANDLER_TYPES,
	MY_BONUS_STATUS2,
	ROUTE_PATHS,
	TABLE_CELL_TYPE,
} from 'constants/index';
// import AutorenewSharpIcon from '@mui/icons-material/AutorenewSharp';
import './myBonuses.scss';
import 'react-datepicker/dist/react-datepicker.css';
// import { momentAPIDate } from 'helpers/moment.helpers';
// import Pagination from 'components/ui-kit/Pagination/index';
import Loader from 'components/ui-kit/Loader/index';
import { ReawardIcon } from 'components/ui-kit/Icons/iconComponents/ReawardIcon';
import { cancelBonus, getMyBonuses } from 'redux-thunk/thunk/bonus.thunk';
import { selectedLanguage } from 'utils/constant';
import { MY_BONUS_STATUS } from 'constants/profile';
import CustomModal from 'components/ui-kit/Modal/index';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CustomCollapse from 'components/ui-kit/Collapse/index';
import NoDatafound from '../../../assets/images/no-data-found.png';
// import Tooltip from 'rc-tooltip';

const tableColumns = [
	{
		id: uuidv4(),
		label: 'Claimed Date',
		value: 'claimedAt',
		type: TABLE_CELL_TYPE.date,
	},
	{
		id: uuidv4(),
		label: 'Bonus Title',
		value: 'bonusTitle',
		type: TABLE_CELL_TYPE.text,
	},
	{
		id: uuidv4(),
		label: 'Expire Date',
		value: 'expireDate',
		type: TABLE_CELL_TYPE.object,
	},
	{
		id: uuidv4(),
		label: 'Wagered Amount',
		value: 'wageredAmount',
		type: TABLE_CELL_TYPE.object,
	},
	{
		id: uuidv4(),
		label: 'Amount to wager',
		value: 'amountToWager',
		type: TABLE_CELL_TYPE.object,
	},
	{
		id: uuidv4(),
		label: 'Status',
		value: 'status',
		type: TABLE_CELL_TYPE.text,
	},
	{
		id: uuidv4(),
		label: 'Actions',
		value: 'actions',
		type: TABLE_CELL_TYPE.text,
	},
];

const MyBonuses = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { myBonuses, myBonusesLoading } = useSelector((state) => state.bonus);
	const { language } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const [bonusType, setBonusType] = useState('all');
	const [status, setStatus] = useState('all');
	// eslint-disable-next-line no-unused-vars
	const [numberOfRecords, setNumberOfRecords] = useState(10);
	const [page, setPage] = useState(1);
	const [reload, setReload] = useState(false);
	const [open, setOpen] = useState(false);
	const [cancelBonusId, setCancelBonusId] = useState(null);

	const clearFilterData = () => {
		setBonusType('all');
		setStatus('all');
		setPage(1);
		setReload((prev) => !prev);
	};

	useEffect(() => {
		dispatch(
			getMyBonuses({
				pageNo: page,
				limit: numberOfRecords,
				status: status === 'all' ? '' : status,
				bonusType: bonusType === 'all' ? '' : bonusType,
			})
		);
	}, [page, numberOfRecords, status, bonusType, reload]);

	const successCallback = () => {
		dispatch(
			getMyBonuses({
				pageNo: page,
				limit: numberOfRecords,
				status: status === 'all' ? '' : status,
				bonusType: bonusType === 'all' ? '' : bonusType,
			})
		);
	};

	const cancelHandler = ({ id }) => {
		setCancelBonusId(null);
		dispatch(
			cancelBonus({
				payload: {
					userBonusId: id,
				},
				successCallback,
			})
		);
	};

	// const ClaimNow = (userBonusId) => {
	// 	const payload = { userBonusId: userBonusId };
	// 	dispatch(fetchBonusClaimThunk(payload));
	// };

	return (
		<>
			<div
				className={`tab-pane fade ${
					pathname === ROUTE_PATHS.MY_BONUSES ? 'show active' : ''
				}`}
				id="v-pills-transaction"
				role="tabpanel"
				aria-labelledby="v-pills-transaction-tab"
			>
				<div className="profile-card-wrap">
					<Grid container>
						<Grid item xs={12} md={6}>
							<div className="profile-heading ">
								<h4 className="m-0">
									<ReawardIcon /> {t('myBonuses')}
								</h4>
							</div>
						</Grid>
						<Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
							<Button
								variant="outlined"
								onClick={() => setOpen(!open)}
								endIcon={<FilterAltOutlinedIcon />}
								className="btn btn-secondary"
							>
								Filters
							</Button>
						</Grid>
					</Grid>
					<CustomCollapse open={open}>
						<Grid container spacing={2}>
							<Grid
								item
								xs={12}
								sm={3}
								className="d-flex flex-column transaction-type"
							>
								<label className="form-label " htmlFor="types">
									{t('Types')}
									<span className="color-red">&nbsp;*</span>
								</label>
								<TextField
									id="transaction-type"
									variant="outlined"
									select
									value={bonusType}
									onChange={(e) => {
										setBonusType(e.target.value);
										setPage(1);
									}}
									className="nav transaction-tab horizontal-theme-tabs  "
								>
									<MenuItem value="all">{t('all')} </MenuItem>
									<MenuItem value="deposit">{t('deposit')}</MenuItem>
									{/* <MenuItem value="freespins">{t('freespins')}</MenuItem> */}
									<MenuItem value="joining">{t('joining')}</MenuItem>
								</TextField>
							</Grid>
							<Grid
								item
								xs={12}
								sm={3}
								className="d-flex flex-column transaction-type"
							>
								<label className="form-label " htmlFor="types">
									{t('status')}
									<span className="color-red">&nbsp;*</span>
								</label>
								<TextField
									id="transaction-type"
									variant="outlined"
									select
									value={status}
									onChange={(e) => {
										setStatus(e.target.value);
										setPage(1);
									}}
									className="nav transaction-tab horizontal-theme-tabs me-3  "
								>
									{MY_BONUS_STATUS.map((sts) => (
										<MenuItem value={sts.value}>{sts.label} </MenuItem>
									))}
								</TextField>
							</Grid>
							<Grid xs={12} sm={2} display="flex" justifyContent="end">
								<Button
									onClick={() => clearFilterData()}
									className="btn btn-secondary-outline trans-btn"
								>
									{t('resetButton')}
								</Button>
							</Grid>
						</Grid>
					</CustomCollapse>
					<Grid xs={12}>
						<div className="table-responsive">
							<table className="table theme-table">
								<thead>
									<tr>
										{tableColumns.map((column) => (
											<th
												key={column?.id}
												scope="col"
												className=" text-center text-nowrap"
											>
												{column.label}
											</th>
										))}
									</tr>
								</thead>
								{myBonusesLoading ? (
									<tbody>
										<td colSpan={tableColumns?.length || 4}>
											<Loader variant={LOADER_HANDLER_TYPES.content} />
										</td>
									</tbody>
								) : (
									<tbody>
										{myBonuses?.userBonus?.length > 0 ? (
											myBonuses?.userBonus?.map((data) => (
												<tr key={data.id}>
													<td className="text-center">
														<div className="text-box justify-content-start">
															{data?.claimedAt ? (
																<span className="text">
																	{moment
																		.utc(data?.claimedAt)
																		.local()
																		.format('DD/MM/YYYY')}{' '}
																	{t('at')}{' '}
																	{moment
																		.utc(data?.claimedAt)
																		.local()
																		.format('h:mm A')}
																</span>
															) : (
																<span className="text">-</span>
															)}
														</div>
													</td>
													<td className="text-center td-ellipsis">
														<div className="text-box">
															<span className="icon" />
															<span className="text ">
																{data?.bonus?.promotionTitle?.[
																	selectedLanguage
																] || ''}
															</span>
														</div>
													</td>
													<td className="text-center">
														{data?.expireAt ? (
															<div className="text-box">
																{moment
																	.utc(data?.expireAt)
																	.format('DD/MM/YYYY')}{' '}
																{t('at')}{' '}
																{moment.utc(data?.expireAt).format('h:mm A')}{' '}
															</div>
														) : (
															<div className="text-box">-</div>
														)}
													</td>
													<td className="text-center">
														<div className="text-box justify-content-end">
															{/* <span className="text ">{data?.cashAmount}</span> */}

															<span className="text text-uppercase">
																{data?.wageredAmount}
															</span>
															<span className="icon" />
														</div>
													</td>
													<td className="text-center">
														<div className="text-box justify-content-end">
															<span className="text ">
																{data?.amountToWager ?? '-'}
															</span>
															<span className="icon" />
														</div>
													</td>
													<td className="text-center">
														<div className="text-box justify-content-end">
															<span className="text ">
																{MY_BONUS_STATUS2[data.status] || ''}
															</span>
														</div>
													</td>
													<td className="text-center">
														<div className="text-box justify-content-end d-flex">
															{/* <button
															className="btn btn-secondary mx-1"
															disabled={data?.status !== 'claiming'}
															onClick={() => ClaimNow(data?.userBonusId)}
														>

															<img
																src="/assets/images/common/check.svg"
																alt="check"
																width={15}
															/>
														</button> */}
															<button
																type="button"
																className="btn btn-primary"
																onClick={() => setCancelBonusId(data)}
																disabled={
																	!['pending', 'active', 'in_process'].includes(
																		data.status
																	)
																}
															>
																<img
																	src="/assets/images/common/red-cross.png"
																	alt="red cross"
																	width={15}
																/>
															</button>
															<button
																type="button"
																className="btn btn-primary mx-1"
																onClick={() => {
																	navigate(
																		`/${language?.toLowerCase()}/promotion/${
																			data.bonusId
																		}/${data?.bonus?.bonusType}`
																	);
																	window?.scrollTo(0, 0);
																}}
																disabled={false}
															>
																<i className="fa fa-eye" aria-hidden="true" />
															</button>
														</div>
													</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan={7} className="text-center text-white">
													{/* {loading ? <Loader variant={LOADER_HANDLER_TYPES.table} /> : 'Data Not Found'} */}
													{/* {t('dataNotFound')} */}
													<img src={NoDatafound} alt="" />
												</td>
											</tr>
										)}
									</tbody>
								)}
							</table>
						</div>
						{/* {myBonuses?.totalPages > 0 && (
						<Pagination
							page={page}
							setPage={setPage}
							limit={TRANSACTIONS_LIMIT}
							dataLength={transactions?.length}
							totalPages={usersTransactions?.totalPages}
						/>
					)} */}
					</Grid>
				</div>
			</div>
			<CustomModal
				open={cancelBonusId}
				handleClose={() => {
					setCancelBonusId(null);
				}}
				title=""
			>
				<Grid sx={{ display: 'flex', justifyContent: 'space-around' }}>
					<div
						id="transition-modal-title"
						// variant="h6"
						// component="h6"
						style={{
							paddingBottom: '1.25rem',
							paddingTop: '1.25rem',
							fontSize: '1.2 rem',
							lineHeight: '1.25 rem',
						}}
					>
						<p style={{ marginBottom: 0 }}>
							Are you sure you want to cancel this bonus ?
						</p>
						<p>
							{' '}
							Once cancelled all your wagering will be zero and can not
							reverted.
						</p>
					</div>
				</Grid>
				<Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<button
							type="button"
							className="btn btn-secondary text-uppercase w-100 text-end"
							onClick={() => cancelHandler(cancelBonusId)}
						>
							<span>Yes</span>
						</button>
					</div>
					{/* <Button
					type="button"
					
				/> */}
				</Grid>
			</CustomModal>
		</>
	);
};

export default MyBonuses;
