/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/prop-types */
import React from 'react'
import { useTranslation } from 'react-i18next'

const Popup = ({ handleClose, content }) => {
  const { t } = useTranslation()

  return (

    <div className='modal fade show d-block text-white' id='popup_modal'>
      <div className='modal-dialog modal-dialog-centered modal-lg'>
        <div className='modal-content '>
          <div className='modal-body content-box text-center'>
            <button
              style={{ float: 'right' }}
              type='button'
              className='btn-close rounded-circle border border-secondary btn-close-white '
              onClick={() => handleClose(false)}
            />
            <div className='row g-0 p-4'>
              <div className='col-md-12'>
                <div className='side-content-box'>
                  <h1 className='modal-title'>{t('signupTitle')}</h1>
                  <h5 className='modal-body'>{content} </h5>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  )
}

export default Popup
