import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index';
import axiosInstance from 'network/apis/index';
import { microServices } from 'network/apis/microservice/index';

export const getAllCurrencyService = () =>
	axiosInstance(
		METHOD_TYPES.get,
		'/common/get-currencies',
		{},
		{
			server: microServices.SERVICE_URL_1,
			loader: LOADER_HANDLER_TYPES.table,
		}
	);

export const getAllCmsSiteinfoService = () =>
	axiosInstance(
		METHOD_TYPES.get,
		`/common/get-pages`,
		{},
		{
			server: microServices.SERVICE_URL_1,
			loader: LOADER_HANDLER_TYPES.page,
		}
	);

export const getGameCountriesService = () =>
	axiosInstance(
		METHOD_TYPES.get,
		'/common/get-countries',
		{},
		{
			server: microServices.SERVICE_URL_1,
			loader: LOADER_HANDLER_TYPES.page,
		}
	);

export const getSignupRegistrationFieldsService = () =>
	axiosInstance(
		METHOD_TYPES.get,
		'/get-registration-fields',
		{},
		{
			server: microServices.SERVICE_URL_1,
			loader: LOADER_HANDLER_TYPES.page,
		}
	);
export const postDefaultCurrencyServiceRequest = (data) =>
	axiosInstance(METHOD_TYPES.post, '/user/wallet/set-default', data, {
		server: microServices.SERVICE_URL_1,
		loader: LOADER_HANDLER_TYPES.submit,
	});
