/* eslint-disable no-param-reassign */
// import i18n from 'i18next'
import { Buffer } from 'buffer';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	loginService,
	signUpService,
	logoutService,
	updatePasswordService,
	forgetPasswordService,
	forgetVerifyPasswordService,
	verifyEmailService,
} from 'network/services/auth.service';
import { signIn, signOut } from 'helpers/cookie.helpers';
import { ROUTE_PATHS } from 'constants/index';
import {
	resetUserState,
	setUserData,
} from 'redux-thunk/redux/slices/user.slice';
import {
	setShowLoginPopup,
	setShowSignupPopup,
} from 'redux-thunk/redux/slices/gameSetting.slice';
import { setLoginData } from 'redux-thunk/redux/slices/auth.slice';
import { emptySboUrl } from 'redux-thunk/redux/slices/games.slice';
import { resetBonus } from 'redux-thunk/redux/slices/bonus.slice';
/**
 * User Login Thunk
 */

export const userLogin = createAsyncThunk(
	'user/login',
	async ({ userNameOrEmail, password, onLoginSuccess, navigate }, thunkApi) => {
		password = Buffer.from(password).toString('base64');

		try {
			const res = await loginService({
				email: userNameOrEmail,
				password,
			});
			if (res?.user) {
				await signIn(res.accessToken);
				onLoginSuccess(res.accessToken); // Minor delay for cookie to set
				navigate(ROUTE_PATHS.HOME);
			}
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const userSignUp = createAsyncThunk(
	'user/signup',
	async (
		{ userDetails, setShowVerifyEmailPopup, setIsSignupLoading },
		thunkApi
	) => {
		try {
			const res = await signUpService(userDetails);
			thunkApi.dispatch(setShowSignupPopup(false));
			setShowVerifyEmailPopup(true);
			setIsSignupLoading(false);
			return res;
		} catch (error) {
			setIsSignupLoading(false);
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

/**
 * Operator Logout Thunk
 */
export const userLogout = createAsyncThunk(
	'user/logout',
	async ({ navigate }, thunkApi) => {
		try {
			const res = await logoutService();
			thunkApi.dispatch(emptySboUrl());
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		} finally {
			// As some time logout API is giving error.
			signOut();
			// if (ethereumClient) {
			//   ethereumClient.disconnect()
			// }
			thunkApi.dispatch(setLoginData(null));
			thunkApi.dispatch(setUserData(null));
			thunkApi.dispatch(resetBonus());
			thunkApi.dispatch(resetUserState());
			navigate(ROUTE_PATHS.HOME);
		}
	}
);

/**
 * Operator Change Password Thunk
 */
export const updatePassword = createAsyncThunk(
	'update/password',
	async ({ oldPassword, newPassword }, thunkApi) => {
		try {
			const res = await updatePasswordService({ oldPassword, newPassword });
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const forgetPassword = createAsyncThunk(
	'forget/password',
	async ({ email, setTokenSentStatus }, thunkApi) => {
		try {
			const res = await forgetPasswordService({ email });
			setTokenSentStatus(true);
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const VerifyPasswordToken = createAsyncThunk(
	'verifyforget/password',
	async ({ token, newPassword }, thunkApi) => {
		newPassword = Buffer.from(newPassword).toString('base64');

		try {
			const res = await forgetVerifyPasswordService({ token, newPassword });
			thunkApi.dispatch(setShowLoginPopup(true));
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const verifyEmail = createAsyncThunk(
	'verifyEmail/emailToken',
	async ({ param }, thunkApi) => {
		try {
			const res = await verifyEmailService(param);
			//  thunkApi.dispatch(fetchUserInformation());
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);
