import { DepositProfileIcon } from 'components/ui-kit/Icons/iconComponents/DepositProfileIcon';
import { RefferralsProfileIcon } from 'components/ui-kit/Icons/iconComponents/RefferralsProfileIcon';
import UsersProfile from 'pages/Profile/UsersProfile/index';
import SportsBetList from 'pages/Profile/SportsBetList/index';
import CasinoBetList from 'pages/Profile/CasinoBetList/index';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

// import CryptoDeposit from 'pages/Profile/CryptoDeposit/index';
import Verification from 'pages/Profile/Verification/index';
import UsersTransactions from 'pages/Profile/UsersTransactions/index';
// import Limits from 'pages/Profile/Limits/index';
import { TransactionIcon } from 'components/ui-kit/Icons/iconComponents/TransactionIcon';
import { WithdrawalsIcon } from 'components/ui-kit/Icons/iconComponents/WithdrawalsIcon';
import { SportsBookGreyIcon } from 'components/ui-kit/Icons/iconComponents/SportsBookGreyIcon';
import { CasinoTransactionIcon } from 'components/ui-kit/Icons/iconComponents/CasinoTransactionIcon';
// import { LimitsIcon } from 'components/ui-kit/Icons/iconComponents/LimitsIcon';
import { LogOutGreyIcon } from 'components/ui-kit/Icons/iconComponents/LogOutGreyIcon';
import { ProfileGreyIcon } from 'components/ui-kit/Icons/iconComponents/ProfileGreyIcon';
import Deposit from 'pages/Profile/Deposit/index';
// import DepositAmount from 'pages/Profile/DepositAmount/index';
import WithdrawAmount from 'pages/Profile/WithdrawAmount/index';
import { LoyaltyBonusIcon } from 'components/ui-kit/Icons/iconComponents/LoyaltyBonusIcon';
import MyBonuses from 'pages/Profile/MyBonuses/index';
import Referrals from 'pages/Profile/Referrals/index';
import OriginalTransactions from 'pages/Profile/OriginalTransactions/index';
import DepositAmount from 'pages/Profile/DepositAmount/index';
import { ROUTE_PATHS } from './index';

export const profileSidebarOptions = [
	{
		path: ROUTE_PATHS.PROFILE,
		name: 'profile',
		Component: UsersProfile,
		Icon: ProfileGreyIcon,
		isActive: true,
	},
	{
		path: ROUTE_PATHS.TRANSACTIONS,
		name: 'accountMenuTransactions',
		Component: UsersTransactions,
		Icon: TransactionIcon,
		isActive: true,
	},
	{
		path: ROUTE_PATHS.WITHDRAWAMOUNT,
		name: 'Withdraw',
		Component: WithdrawAmount,
		Icon: WithdrawalsIcon,
		isActive: true,
	},
	{
		path: ROUTE_PATHS.DEPOSITAMOUNT,
		name: 'Deposit',
		Component: DepositAmount,
		Icon: DepositProfileIcon,
		isActive: true,
	},
	{
		path: ROUTE_PATHS.MY_BONUSES,
		name: 'myBonuses',
		Component: MyBonuses,
		Icon: LoyaltyBonusIcon,
		isActive: true,
	},
	{
		path: ROUTE_PATHS.SPORTS_BET,
		name: 'sportsbookTransactions',
		Component: SportsBetList,
		Icon: SportsBookGreyIcon,
		isActive: true,
	},
	{
		path: ROUTE_PATHS.CASINO_BET,
		name: 'casinoTransactions',
		Component: CasinoBetList,
		Icon: CasinoTransactionIcon,
		isActive: true,
	},
	{
		path: ROUTE_PATHS.ORIGINAL_BET,
		name: 'Orignals Transactions',
		Component: OriginalTransactions,
		Icon: CasinoTransactionIcon,
		isActive: true,
	},
	{
		path: ROUTE_PATHS.DEPOSIT,
		name: 'deposit',
		Component: Deposit,
		Icon: DepositProfileIcon,
		isActive: false,
	},
	// {
	// 	path: ROUTE_PATHS.LIMITS,
	// 	name: 'limit',
	// 	Component: Limits,
	// 	Icon: LimitsIcon,
	// 	isActive: true,
	// },
	{
		path: ROUTE_PATHS.VERIFICATION,
		name: 'KYC',
		Component: Verification,
		Icon: RefferralsProfileIcon,
		isActive: true,
	},
	{
		path: ROUTE_PATHS.REFERRALS,
		name: 'Referrals',
		Component: Referrals,
		Icon: PersonAddAlt1Icon,
		isActive: true,
	},
	{
		path: 'accountMenuLogout',
		name: 'accountMenuLogout',
		Component: null,
		Icon: LogOutGreyIcon,
		isLogout: true,
		isActive: true,
	},
];

export const MY_BONUS_STATUS = [
	{
		label: 'All',
		value: 'all',
	},
	{
		label: 'Pending',
		value: 'pending',
	},
	{
		label: 'Active',
		value: 'active',
	},
	{
		label: 'Cancelled',
		value: 'cancelled',
	},
	{
		label: 'Forfitted',
		value: 'forfitted',
	},
	{
		label: 'Expired',
		value: 'expired',
	},
	{
		label: 'Claimed',
		value: 'claimed',
	},
	{
		label: 'In Process',
		value: 'in_process',
	},
	// {
	// 	label: 'Lapsed',
	// 	value: 'lapsed',
	// },
	// {
	// 	label: 'Zeroed Out',
	// 	value: 'zeroed_out',
	// },
];
