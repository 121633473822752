import { createSlice } from '@reduxjs/toolkit';
import {
	depositAmountRequest,
	generateAddressRequest,
	getCryptoNetworkFees,
	withdrawAmountRequest,
} from 'redux-thunk/thunk/user.thunk';

const initialState = {
	depositLoading: false,
	depositError: '',
	withdrawLoading: false,
	generateAddressLoading: false,
	cryptoNetworks: [],
	depositDetails: null,
	withdrawDetails: null,
	networkFeesLoading: false,
	networkFees: {},
	withdrawSuccess: false,
};
const {
	actions: {
		setDepositData,
		setShowWalletPopup,
		setCryptoNetworks,
		setDepositDetails,
		resetpaymentDetails,
		setNetworkFees,
		resetPaymentState,
	},
	reducer,
} = createSlice({
	name: 'payment',
	initialState,
	reducers: {
		setCryptoNetworks: (state, { payload }) => ({
			...state,
			cryptoNetworks: payload?.networks || [],
		}),
		setDepositDetails: (state, action) => ({
			...state,
			depositDetails: action.payload,
		}),
		resetpaymentDetails: (state) => ({
			...state,
			withdrawDetails: initialState.depositDetails,
			depositDetails: initialState.depositDetails,
		}),
		setNetworkFees: (state, action) => ({
			...state,
			networkFees: action.payload || {},
		}),
		resetPaymentState: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(depositAmountRequest.pending, (state) => ({
				...state,
				depositLoading: true,
			}))
			.addCase(depositAmountRequest.fulfilled, (state) => ({
				...state,
				depositLoading: false,
			}))
			.addCase(depositAmountRequest.rejected, (state, action) => ({
				...state,
				depositLoading: false,
				depositError: action.payload,
			}))
			.addCase(withdrawAmountRequest.pending, (state) => ({
				...state,
				withdrawLoading: true,
				withdrawSuccess: false,
			}))
			.addCase(withdrawAmountRequest.fulfilled, (state) => ({
				...state,
				withdrawLoading: false,
				withdrawSuccess: true,
			}))
			.addCase(withdrawAmountRequest.rejected, (state) => ({
				...state,
				withdrawLoading: false,
				withdrawSuccess: false,
			}))
			.addCase(generateAddressRequest.pending, (state) => ({
				...state,
				generateAddressLoading: true,
			}))
			.addCase(generateAddressRequest.fulfilled, (state) => ({
				...state,
				generateAddressLoading: false,
			}))
			.addCase(generateAddressRequest.rejected, (state) => ({
				...state,
				generateAddressLoading: false,
			}))
			.addCase(getCryptoNetworkFees.pending, (state) => ({
				...state,
				networkFeesLoading: true,
			}))
			.addCase(getCryptoNetworkFees.fulfilled, (state) => ({
				...state,
				networkFeesLoading: false,
			}))
			.addCase(getCryptoNetworkFees.rejected, (state) => ({
				...state,
				networkFeesLoading: false,
			}));
	},
});

export default reducer;
export {
	setDepositData,
	setShowWalletPopup,
	setCryptoNetworks,
	setDepositDetails,
	resetpaymentDetails,
	setNetworkFees,
	resetPaymentState,
};
