const ErrorMessage = {
	internalServerError: 'internalServerError',
	unAuthorized: 'unAuthorized',
	endPointNotFound: 'endPointNotFound',
	disabledForTime: 'disabledForTime',
	WRONG_PASSWORD: 'wrongPassword',
	USER_DOES_NOT_EXISTS: 'userDoesntExists',
	USER_INACTIVE: 'userInactive',
	EMAIL_NOT_VERIFIED: 'emailNotVerified',
	USERNAME_OR_EMAIL_ALREADY_EXISTS: 'userNameOrEmailExists',
	ALREADY_LOGGED_IN: 'alreadyLoggedIn',
	USER_NOT_LOGGED_IN: 'userNotLoggedIn',
	SESSION_EXPIRED: 'sessionExpired',
	USERNAME_IS_TAKEN: 'usernameIsTaken',
	EMAIL_IS_TAKEN: 'emailIsTaken',
	PHONE_IS_TAKEN: 'phoneIsTaken',
	LIMIT_NOT_FOUND: 'limitNotFound',
	INVALID_TIME_UNIT: 'invalidTimeUnit',
	INVALID_VALUE: 'invalidValue',
	EXCLUDED_PERMANENTLY_PLEASE_CONTACT_PROVIDER: 'excludedPermanently',
	INVALID_EVENT_ID_COMBINATION: 'invalidEventId',
	INVALID_WALLET_ID: 'invalidWalletId',
	NOT_ENOUGH_AMOUNT: 'notEnoughAmount',
	ACCESS_TOKEN_NOT_FOUND: 'accessTokenNotFound',
	BETTING_IS_DIABLED: 'bettingDisabled',
	MIN_STAKE_REQUIRED: 'minStakeRequired',
	ODDS_SHULD_BE_IN_RANGE: 'oddInRange',
	INVALID_SPORT_ID: 'invalidSportId',
	LOCATION_NOT_FOUND: 'locationNotFound',
	GAME_NOT_FOUND: 'gameNotFound',
	YOUR_COUNTRY_IS_NOT_LISTED: 'countryNotListed',
	INVALID_BONUS_ID: 'invalidBonusId',
	EXCLUDED_TEMPORARILY: 'excludedTemporarily',
	INVALID_ADDRESS_ID: 'invalidAddressId',
	INVALID_TOKEN: 'invalidToken',
	WRONG_TOKEN_TYPE: 'wrongTokenType',
	FILE_DOES_NOT_EXISTS: 'fileDoesntExist',
	INVALID_DOCUMENT_LABEL_ID: 'invalidDocumentLabelId',
	DOCUMENT_ALREADY_EXISTS: 'documentAlreadyExists',
	DOCUMENT_IS_APPROVED: 'documentApproved',
	INVALID_DOCUMENT_ID: 'invalidDocumentId',
	INVALID_BONUS: 'invalidBonus',
	BONUS_ALREADY_ACTIVE: 'bonusAlreadyActive',
	ACCESS_TOKEN_EXPIRED_OR_NOT_PASSED: 'accessTokenExpires',
	CASHOUT_NOT_ALLOWED: 'cashoutNotAllowed',
	FILE_FORMAT_NOT_SUPPORTED: 'fileFormatNotSupported',
	DAILY_BET_LIMIT_EXCEEDED: 'dailyBetLimitExceeded',
	WEEKLY_BET_LIMIT_EXCEEDED: 'weeklyBetLimitExceeded',
	MONTHLY_BET_LIMIT_EXCEEDED: 'monthlyBetLimitExceeded',
	SERVICE_UNAVAILABLE: 'serviceUnavailable',
	CURRENCY_NOT_AVAILABLE: 'currencyNotAvailable',
	BLOCKED_TRANSACTION: 'blockedTransaction',
	ADDRESS_ALREADY_EXISTS: 'addressAlreadyExists',
	TOURNAMENT_DOES_NOT_EXISTS: 'tournamentDoesNotExists',
	TOURNAMENT_NOT_ACTIVE: 'tournamentNotActive',
	TOURNAMENT_REGISTRATION_CLOSE: 'Tournament Registration Close',
	INSUFFICIENT_BALANCE: 'insufficientBalance',
	USER_ALREADY_ENROLLED_IN_TOURNAMENT: 'userAlreadyEnrolledInTournament',
	TOURNAMENT_PLAYER_LIMIT_REACHED: 'tournamentPlayerLimitReached',
	BONUS_ALREADY_CANCELLED: 'bonusAlreadyCancelled',
	TOURNAMENT_REBUY_LIMIT_REACHED: 'Tournament Re Buy Limit Reached',
};
export default ErrorMessage;
