/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import Tooltip from 'rc-tooltip';
import Divider from '@mui/material/Divider';

// import { initialize } from 'scripts/push_notification';
import {
	setShowLoginPopup,
	setShowSignupPopup,
} from 'redux-thunk/redux/slices/gameSetting.slice';
import { AuthBackgroundPng } from 'components/ui-kit/Icons/png/index';
import './login.scss';
import { ErrorMessage, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { showLoginOtpHandle } from 'redux-thunk/redux/slices/user.slice';
import {
	// forgetPassword,
	userLogin,
} from 'redux-thunk/thunk/auth.thunk';
import Loader from 'components/ui-kit/Loader/index';
import { LOADER_HANDLER_TYPES, ROUTE_PATHS } from 'constants/index.js';
// import SocialLinks from '../SocialLinks/index'
import InputField from 'components/molecules/Input/InputField';
import Button from 'components/molecules/Button/index';
// import { isValidImage } from 'utils/validate';
import { fetchUserInformation } from 'redux-thunk/thunk/user.thunk';
// import { openErrorToaster } from 'helpers/toaster.helpers';
import { userMetamaskLogin } from 'redux-thunk/thunk/metamask.thunk';
import { chatLogin, getMetamaskAccounts } from 'utils/helper';
import { openErrorToaster } from 'helpers/toaster.helpers';
import {
	getAllTournament,
	getTournamentTransactionsDetails,
} from 'redux-thunk/thunk/tournament.thunk';
import { Box, Grid, Typography } from '@mui/material/index';
import GoogleIcon from '@mui/icons-material/Google';
import TwitterIcon from '@mui/icons-material/Twitter';
import PinterestIcon from '@mui/icons-material/Pinterest';
import InstagramIcon from '@mui/icons-material/Instagram';
// import metamaskIcon from '../../components/ui-kit/Icons/svg/metamask.svg';
import { userLoginSchema } from './schema';

const Login = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { settingData } = useSelector((state) => state.general);

	const modalCloseHandler = () => {
		dispatch(setShowLoginPopup(false));
	};

	const { loginLoading } = useSelector((state) => state.auth);
	const { loginMetamaskLoading } = useSelector((state) => state.metamask);
	// const [accountValue, setAccountValue] = useState();
	const location = useLocation();
	const navigate = useNavigate();

	const onLoginSuccess = (token) => {
		dispatch(getAllTournament());
		if (location.pathname.match('/casino/play-game')) {
			navigate(ROUTE_PATHS.CASINO, { replace: true });
			dispatch(getTournamentTransactionsDetails());
		} else if (location.pathname?.match('/verify')) {
			navigate(ROUTE_PATHS.HOME, { replace: true });
		} else {
			navigate(location.pathname, { replace: true });
		}
		dispatch(setShowLoginPopup(false));
		dispatch(fetchUserInformation());
		chatLogin(token);
		// initialize();
	};

	const OnLoginError = (error) => {
		if (error && error[0] && error[0]?.description === 'otp required') {
			dispatch(setShowLoginPopup(false));
			dispatch(showLoginOtpHandle(true));
		}
	};

	const handleLoginSubmit = async ({ email, password }) => {
		dispatch(
			userLogin({
				userNameOrEmail: email,
				password,
				navigate,
				onLoginSuccess,
				OnLoginError,
			})
		);
	};

	useEffect(() => {
		const { body } = document;
		body?.classList.add('overflowHidden');

		return () => {
			body?.classList.remove('overflowHidden');
		};
	}, []);

	const handleLoginMetamask = async () => {
		if (typeof window.ethereum === 'undefined') {
			console.log(' MetaMask is not installed!');

			window.open(
				'https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en',
				'_blank'
			);
		} else {
			const accounts = await getMetamaskAccounts();
			if (!accounts?.length) {
				openErrorToaster({
					message: t('metamaskAccountNotFound'),
				});
				return null;
			}
			// const signatureValue = await signMessage(`ARC:${new Date().getTime()}`);
			dispatch(
				userMetamaskLogin({
					// signatureValue,
					accountValue: accounts[0],
					onLoginSuccess,
					OnLoginError,
				})
			);
		}
		return null;
	};
	const handleForgetPassword = () => {
		modalCloseHandler();
		navigate(ROUTE_PATHS.SEND_RESET_LINK);
	};

	const handleCreateAccount = () => {
		dispatch(setShowLoginPopup(false));
		dispatch(setShowSignupPopup(true));
	};

	return (
		<div
			className="modal fade show "
			style={{ display: 'block' }}
			id="login_modal"
		>
			<div className="modal-dialog modal-dialog-centered custom-modal-size">
				<div
					role="presentation"
					className="modal-content login-modal"
					onClick={(e) => e.stopPropagation()}
				>
					<div className="modal-body p-0">
						<div className="row px-2">
							<Tooltip
								placement="bottomRight"
								trigger={['hover']}
								overlayStyle={{ fontSize: '0.7rem', zIndex: 9999 }}
								overlay={<span>{t('close')}</span>}
								showArrow={false}
							>
								<button
									type="button"
									onClick={modalCloseHandler}
									className="btn-close shadow-none"
									data-bs-dismiss="modal"
									aria-label="Close"
								/>
							</Tooltip>
							<div className="col-lg-6 col-12 p-0 d-none d-lg-block">
								<div className="auth-img">
									<img src={AuthBackgroundPng} alt="auth" />
									<div className="auth-instruction-text">
										<p>
											{t('usersConsent')}
											{/* <NavLink to="#">{t('termsOfService')}</NavLink> */}
										</p>
									</div>
								</div>
							</div>
							<div className="auth-form-container col-lg-6 col-12">
								<div className="auth-form-wrap">
									<div className="auth-logo">
										<img
											className="brand-logo-size"
											src={
												settingData?.logo
													? settingData.logo
													: '/assets/images/logo/city99.png'
											}
											alt=""
										/>
									</div>
									<div className="register-wrap">
										<span className="Sign-InText">Sign In</span>
										<Formik
											enableReinitialize
											initialValues={{ email: '', password: '' }}
											validationSchema={userLoginSchema(t)}
											onSubmit={({ email, password }) =>
												handleLoginSubmit({ email, password })
											}
										>
											{({ values, handleChange, handleSubmit, handleBlur }) => (
												<Form autoComplete="off">
													<div className="mb-2 form-group">
														<InputField
															type="text"
															placeholder={t('emailPlaceholder')}
															className="form-control shadow-none"
															name="email"
															value={values.email}
															onChange={handleChange}
															onBlur={handleBlur}
															maxLength={50}
															haslabel="yes"
															// required
															// labeltext={t('signinUsername')}
														/>
														<ErrorMessage
															component="div"
															name="email"
															className="color-red text-danger error-msg login-input  ms-2 d-flex"
														/>
													</div>
													<div className="mb-2 form-group">
														<InputField
															type="password"
															placeholder="Password"
															className="form-control shadow-none"
															name="password"
															value={values.password}
															onChange={handleChange}
															onBlur={handleBlur}
															maxLength={50}
															minLength={8}
															// haslabel="yes"
															// required
															// labeltext={t('profilePassword')}
														/>
														<ErrorMessage
															component="div"
															name="password"
															className="color-red text-danger error-msg login-input  ms-2 d-flex"
														/>
													</div>
													<Button
														type="button"
														className="forget-password"
														onClick={handleForgetPassword}
													>
														Forget password?
													</Button>
													<div className="auth-btn-wrap ">
														<Button
															type="submit"
															disabled={loginLoading}
															className="btn btn-secondary text-uppercase w-100"
															onSubmit={handleSubmit}
															buttonlabel={
																loginLoading ? (
																	<Loader
																		variant={LOADER_HANDLER_TYPES.submit}
																	/>
																) : (
																	<span>{t('signIn')}</span>
																)
															}
														/>
													</div>
													<Grid className="Register-account">
														<Box className="create-Account-tag">
															<Typography>New to City99? </Typography>
															<Typography
																onClick={handleCreateAccount}
																className="register-button"
															>
																Create Account
															</Typography>
														</Box>
													</Grid>

													{/* <Divider
														style={{ color: 'white' }}
														orientation="vertical"
														flexItem
													/>

													<div className="auth-btn-wrap mt-4">
														<Button
															type="button"
															disabled={loginMetamaskLoading}
															className="btn btn-secondary text-uppercase w-100"
															onClick={() => {
																// getAccount();
																handleLoginMetamask();
															}}
															buttonlabel={
																loginMetamaskLoading ? (
																	<Loader
																		variant={LOADER_HANDLER_TYPES.submit}
																	/>
																) : (
																	<>
																		<img
																			style={{ width: '30px', height: '30p' }}
																			src={metamaskIcon}
																			alt="metamask"
																		/>
																		<span style={{ marginLeft: '15px' }}>
																			{t('SignInMetamask')}
																		</span>
																	</>
																)
															}
														/>
														<hr />
													</div> */}

													{/* <div className='auth-socail-wrap'> */}
													{/* <div className="auth-socail-heading">
														<h6 className="text-white">
															<span>
																{t('Forgot Your Password?')}{' '}
																<NavLink
																	onClick={() => modalCloseHandler()}
																	to="/reset-password"
																	className="reset-password"
																>
																	{t('clickHere')}
																</NavLink>{' '}
																to Recover.{' '}
															</span>
														</h6>
													</div> */}
													{/* </div> */}
												</Form>
											)}
										</Formik>
									</div>
								</div>
								{/* <div className="social-logins">
									<div className="social-elements">
										<span className="login-text">
											<span className="vertical-line"> </span>Log in directly
											with<span className="vertical-line"> </span>
										</span>
										<div className="social-icons">
											<GoogleIcon />
											<TwitterIcon />
											<PinterestIcon />
											<TwitterIcon />
											<PinterestIcon />
											<InstagramIcon />
										</div>
									</div>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Login;
