import React from 'react';
import ContentLoaderIcon from '../../../../assets/images/animation-loader-1.gif';

const MainLoader = () => (
	<div className="loader-parent">
		<div className="full-body-loader">
			{/* <ContentLoaderIcon /> */}
			<img src={ContentLoaderIcon} alt="ContentLoaderIcon" />
			<span>LOADING...</span>
		</div>
	</div>
);

export default MainLoader;
