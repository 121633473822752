import React from 'react';
import { PropTypes } from 'prop-types';
import { FavRedWhiteIcon } from 'components/ui-kit/Icons/iconComponents/FavRedWhiteIcon';
import './style.scss';

const FavouriteIcon = ({ fill }) => (
	<FavRedWhiteIcon className="fav-icon" fill={fill} />
);
FavouriteIcon.propTypes = {
	fill: PropTypes.string.isRequired,
};
export default React.memo(FavouriteIcon);
