export const EVENTS = {
    // private
    WALLET: "wallet",
    BET: "bet",
    EXCHANGE_BET: "exchange_bet",

    // public
    ORDER_BOOK: "order_book",
    IN_PLAY: "in_play",
    PRE_MATCH: "pre_match",
    LATEST_BETS: "latest_bets",
    HIGHROLLER_BETS: "highroller_bets",
}
