import React from 'react';
import { Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import InputField from 'components/molecules/Input/InputField';
import { currencyCodeList } from 'constants/currencyCodeList';
import Button from 'components/molecules/Button/index';
import { depositAmountRequest } from 'redux-thunk/thunk/user.thunk';
import { depositSchema } from './schema';

const Deposit = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { defaultWallet } = useSelector((state) => state.user);
	const currencies = useSelector((state) => state.gameSetting.allCurrencies);
	const { depositLoading } = useSelector((state) => state.payment);
	const userCurrencySymbol =
		currencyCodeList[
			currencies?.find((currency) => currency.isDefault)?.code || 'USD'
		];

	const handleDepositSubmit = ({ amount }, actions) => {
		dispatch(
			depositAmountRequest({
				payload: {
					amount,
					walletId: defaultWallet.id,
				},
				callBack: actions.resetForm,
			})
		);
	};

	const checkAmount = (val, prevAmount) => {
		if (`${val}`?.split('.')[1]?.length > 2) {
			return prevAmount;
		}
		return val;
	};

	return (
		<Grid className="profile-card-wrap">
			<Grid>
				<Typography variant="h6" mb={1}>
					{t('deposit')}
				</Typography>
			</Grid>
			<Formik
				enableReinitialize
				initialValues={{ amount: 0 }}
				validationSchema={depositSchema(t)}
				onSubmit={(formData, actions) => handleDepositSubmit(formData, actions)}
			>
				{({ values, setFieldValue, handleSubmit, handleBlur }) => (
					<Form autoComplete="off">
						<Grid className="wagerSection">
							<Grid
								container
								display="flex"
								spacing={{ xs: '0', sm: '2' }}
								alignItems="center"
								marginBottom="10px"
								className="wagerInputs"
							>
								<Grid item xs={12} sm={3}>
									<Typography>{t('amount')}</Typography>
								</Grid>
								<Grid item xs={12} sm={6} className="inputIcon" spacing={2}>
									<Typography>{userCurrencySymbol}</Typography>
									<InputField
										labeltext={`${userCurrencySymbol}`}
										name="amount"
										type="number"
										placeholder={t('enterAmount')}
										value={values.amount}
										onChange={(e) => {
											setFieldValue(
												'amount',
												checkAmount(e.target.value, values.amount)
											);
										}}
										onBlur={handleBlur}
									/>
									<ErrorMessage
										component="div"
										name="amount"
										className="color-red text-danger login-input  ms-2 d-flex"
									/>
								</Grid>
								<Grid
									xs={12}
									sm={3}
									sx={{ display: 'flex', justifyContent: 'flex-end' }}
								>
									<Button
										disabled={depositLoading}
										type="button"
										className="btn btn-secondary"
										onClick={handleSubmit}
									>
										{t('deposit')}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</Grid>
	);
};

export default React.memo(Deposit);
