import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import tracker from '../../Assets/Images/Other/Frame 21.png';

const TrackerSection = () => {
	const { t } = useTranslation();
	return (
		<Grid className="extra-market--right---panel">
			<Grid className="traker">
				<Box>
					<Typography>{t('tracker')}</Typography>
				</Box>
				<Grid className="soon">
					<img src={tracker} alt="banner" className="w-100" />
					<Typography variant="span">{t('comingSoonSB')}</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default TrackerSection;
