/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { Grid, Button, MenuItem, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DatePickerInput from 'components/molecules/DatePicker/DatePicker';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { getUserCasinoTransactions } from 'redux-thunk/thunk/user.thunk';
import {
	CASINO_PAGINATION_LIMIT,
	LEDGER_PURPOSE_VALUES,
	LOADER_HANDLER_TYPES,
	ROUTE_PATHS,
	TABLE_CELL_TYPE,
} from 'constants/index';
import { CasinoGreyIcon } from 'components/ui-kit/Icons/iconComponents/CasinoGreyIcon';
import 'react-datepicker/dist/react-datepicker.css';
import { momentAPIDate } from 'helpers/moment.helpers';
import Pagination from 'components/ui-kit/Pagination/index';
import Loader from 'components/ui-kit/Loader/index';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CustomCollapse from 'components/ui-kit/Collapse/index';

const tableColumns = [
	{
		id: uuidv4(),
		label: 'Date Time',
		value: 'createdAt',
		type: TABLE_CELL_TYPE.date,
	},
	{
		id: uuidv4(),
		label: 'Game',
		value: 'game',
		// key: 'userName',
		type: TABLE_CELL_TYPE.text,
	},
	{
		id: uuidv4(),
		label: 'Debit / Credit',
		value: 'type',
		// key: 'userName',
		type: TABLE_CELL_TYPE.text,
	},
	{
		id: uuidv4(),
		label: 'Amount',
		value: 'amount',
		// key: 'amount',
		type: TABLE_CELL_TYPE.object,
	},
	// {
	// 	id: uuidv4(),
	// 	label: 'Bet Type',
	// 	value: '',
	// 	// key: 'userName',
	// 	type: TABLE_CELL_TYPE.text,
	// },
	{
		id: uuidv4(),
		label: 'Type',
		value: 'purpose',
		key: 'amount',
		type: TABLE_CELL_TYPE.object,
	},
	{
		id: uuidv4(),
		label: 'Status',
		value: 'status',
		key: 'amount',
		type: TABLE_CELL_TYPE.object,
	},
];

const OriginalTransactions = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { isCasinoTransactionLoading } = useSelector(
		(state) => state.transactions
	);
	const dateObj = new Date();
	const initialEndDate = dateObj.setUTCHours(0, 0, 0, 0);
	const initialStartDate = dateObj.setDate(dateObj.getDate() - 30);
	const { allCurrencies } = useSelector((state) => state?.gameSetting);
	const formattedCurrencies = [{ name: 'All', id: 'all' }, ...allCurrencies];
	const [date, setDate] = useState({
		startDate: new Date(initialStartDate),
		endDate: new Date(initialEndDate),
	});
	const [page, setPage] = useState(1);
	const [open, setOpen] = useState(false);
	const [transactionType, setTransactionType] = useState('all');
	const [currencyType, setCurrencyType] = useState('all');
	const clearFilterData = () => {
		setCurrencyType('all');
		setTransactionType('all');
		setDate({
			startDate: new Date(initialStartDate),
			endDate: new Date(initialEndDate),
		});
		setPage(1);
	};
	const filterData = (type) => {
		setTransactionType(type);
	};
	const currencyFilterData = (type) => {
		setCurrencyType(type);
	};

	useEffect(() => {
		dispatch(
			getUserCasinoTransactions({
				limit: CASINO_PAGINATION_LIMIT,
				pageNo: page,
				startDate: momentAPIDate(date.startDate),
				endDate: momentAPIDate(date.endDate),
				purpose: transactionType === 'all' ? '' : transactionType,
				currencyId: currencyType === 'all' ? '' : currencyType,
				casinoType: 'originals',
			})
		);
	}, [date, page, currencyType, transactionType]);

	const { usersCasinoTransactions } = useSelector(
		(state) => state.transactions
	);
	const transactions = usersCasinoTransactions?.casinoTransaction?.rows;

	return (
		<div
			className={`tab-pane fade ${
				pathname === ROUTE_PATHS.CASINO_BET ? 'show active' : ''
			}`}
			id="v-pills-casino-bet-history"
			role="tabpanel"
			aria-labelledby="v-pills-casino-bet-history-tab"
		>
			<div className="profile-card-wrap ">
				<Grid container>
					<Grid item xs={12} md={6}>
						<div className="profile-heading mb-3">
							<h4 className="mt-0 mb-4">
								<CasinoGreyIcon /> Originals Transactions
							</h4>
						</div>
					</Grid>
					<Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
						<Button
							variant="outlined"
							onClick={() => setOpen(!open)}
							endIcon={<FilterAltOutlinedIcon />}
							className="btn btn-secondary"
						>
							Filters
						</Button>
					</Grid>
				</Grid>
				<CustomCollapse open={open}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={3} className="d-flex flex-column">
							<label htmlFor="DOB" className="form-label ">
								{t('Start Date')}
								<span className="color-red">&nbsp;*</span>
							</label>
							<DatePickerInput
								className="form-control shadow-none"
								selected={date.startDate}
								showYearDropdown
								handleDateChange={(data) => {
									setDate((prevState) => ({
										...prevState,
										startDate: data || new Date(),
									}));
									setPage(1);
								}}
								selectsStart
								maxDate={initialEndDate}
								placeholder={t('inputsStart')}
							/>
						</Grid>
						<Grid item xs={12} sm={3} className="d-flex flex-column">
							<label htmlFor="End Date" className="form-label ">
								{t('End Date')}
								<span className="color-red">&nbsp;*</span>
							</label>
							<DatePickerInput
								className="form-control shadow-none "
								selected={date.endDate}
								handleDateChange={(data) => {
									setDate((prevState) => ({
										...prevState,
										endDate: data || new Date(),
									}));
									setPage(1);
								}}
								showYearDropdown
								startDate={date.startDate}
								maxDate={initialEndDate}
								minDate={date.startDate}
								placeholder={t('End Date')}
							/>
						</Grid>

						<Grid
							item
							xs={12}
							sm={3}
							className="d-flex flex-column transaction-type"
						>
							<label className="form-label " htmlFor="types">
								Type
								<span className="color-red">&nbsp;*</span>
							</label>
							<TextField
								id="transaction-type"
								variant="outlined"
								select
								value={transactionType}
								onChange={(e) => {
									filterData(e.target.value);
									setPage(1);
								}}
								className="nav transaction-tab horizontal-theme-tabs   "
							>
								<MenuItem value="all">{t('all')} </MenuItem>
								<MenuItem value="CasinoBet">{t('Bet')} </MenuItem>
								<MenuItem value="CasinoWin">{t('Win')}</MenuItem>
								<MenuItem value="CasinoRefund">{t('Refund')}</MenuItem>
							</TextField>
						</Grid>
						<Grid
							item
							xs={12}
							sm={3}
							className="d-flex flex-column transaction-type"
						>
							<label className="form-label " htmlFor="types">
								{t('currencyTypes')}

								<span className="color-red">&nbsp;*</span>
							</label>
							<TextField
								id="transaction-type"
								variant="outlined"
								select
								value={currencyType}
								onChange={(e) => {
									currencyFilterData(e.target.value);
									setPage(1);
								}}
								className="nav transaction-tab horizontal-theme-tabs "
							>
								{formattedCurrencies.map((item) => (
									<MenuItem value={item.id}>{item.name} </MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid sm={12} display="flex" justifyContent="end">
							<Button
								onClick={() => clearFilterData()}
								className="btn btn-secondary-outline trans-btn"
							>
								{t('resetButton')}
							</Button>
						</Grid>
					</Grid>
				</CustomCollapse>
				<Grid xs={12}>
					<div className="table-responsive">
						<table className="table theme-table">
							<thead>
								<tr>
									{tableColumns.map((column) => (
										<th
											key={column?.id}
											scope="col"
											className="text-center text-nowrap"
										>
											{column.label}
										</th>
									))}
								</tr>
							</thead>
							{isCasinoTransactionLoading ? (
								<tbody>
									<td colSpan={tableColumns?.length || 4}>
										<Loader variant={LOADER_HANDLER_TYPES.content} />
									</td>
								</tbody>
							) : (
								<tbody>
									{transactions?.length > 0 ? (
										transactions?.map((data) => (
											<tr key={data?.transactionId}>
												<td className="text-center">
													<div className="text-box justify-content-start">
														<span className="icon" />
														<span className="text">
															{moment
																.utc(data?.createdAt)
																.local()
																.format('DD/MM/YYYY')}
															&nbsp;&nbsp;
															{moment
																.utc(data?.createdAt)
																.local()
																.format('h:mm A')}
														</span>
													</div>
												</td>
												<td className="text-center">
													<div className="text-box">
														<span className="icon" />
														<span className="text">
															{data?.casinoGame?.name?.EN ||
																data?.casinoGame?.name ||
																'-'}
														</span>
													</div>
												</td>
												<td>
													<span className="text ">
														{!data?.ledger?.from_wallet_id ? (
															<span className="text-green">Credit</span>
														) : (
															<span className="text-red">Debit</span>
														)}
													</span>
												</td>
												<td className="text-center">
													<div className="text-box justify-content-end">
														<span className="text ">
															{`${data?.ledger?.amount} ${data?.ledger?.currency?.code}` ||
																'-'}
														</span>
														<span className="icon" />
													</div>
												</td>
												{/* <td className="text-center">
													<div className="text-box">
														<span className="text ">
															{data?.wallet?.currency?.type || '-'}
														</span>
													</div>
												</td> */}

												<td className="text-center">
													<div className="text-box justify-content-end">
														<span className="text text-capitalize">
															{LEDGER_PURPOSE_VALUES[data?.ledger?.purpose] ||
																'-'}
														</span>
													</div>
												</td>
												<td className="text-center">
													<div className="text-box justify-content-end">
														<span className="text text-capitalize">
															{data?.status}
														</span>
													</div>
												</td>
											</tr>
										))
									) : (
										<tr>
											<td colSpan={7} className="text-center text-white">
												{t('dataNotFound')}
											</td>
										</tr>
									)}
								</tbody>
							)}
						</table>
					</div>
					{transactions?.length !== 10 && page === 1 ? null : (
						<Pagination
							page={page}
							setPage={setPage}
							limit={CASINO_PAGINATION_LIMIT}
							dataLength={transactions?.length}
							totalPages={
								usersCasinoTransactions.casinoTransaction.count /
								CASINO_PAGINATION_LIMIT
							}
						/>
					)}
				</Grid>
			</div>
		</div>
	);
};

export default OriginalTransactions;
