/* eslint-disable react/jsx-no-useless-fragment */
import { PropTypes } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ROUTE_PATHS } from 'constants/index';
// import { isMobile } from 'react-device-detect';
import Footer from '../Footer/index';
import MaintenanceMode from '../Maintenance/index';

const MainLayout = ({ showFooter, children }) => {
	const { showSideNav, isChatCollapse, showChat } = useSelector(
		(state) => state?.settings
	);
	const { settingData } = useSelector((state) => state.general);

	const location = useLocation();
	const showContentInBetween =
		location.pathname === '/casino' ||
		location.pathname === '/' ||
		location.pathname.match(`${ROUTE_PATHS.CASINO}/`);

	// const isSports = location.pathname.includes(`${ROUTE_PATHS.SPORTS}`);
	const isSportBook = location.pathname.includes(`${ROUTE_PATHS.SPORTBOOK}`);

	// const getClass = () => {
	// 	if (isSportBook) return 'sportbook';
	// 	if (isSports) return '';
	// 	return 'content-container container-lg';
	// };
	return (
		<div
			className={`page-container ${
				settingData?.maintenance && 'under-maintenance'
			} ${!showSideNav ? 'sidebar-close' : ''} ${
				isChatCollapse ? '' : 'chat-active'
			} ${showChat ? 'chat-parent' : ''}`}
		>
			<div
				className={`${showContentInBetween ? 'custom-spacer' : 'custom-spacer'} content-container container-lg`}
				style={
					!isSportBook
						? {
								minHeight: 'calc(100vh - 370px)',
							}
						: {}
				}
			>
				{!settingData?.maintenance ? children : <MaintenanceMode />}
			</div>
			{showFooter && !settingData?.maintenance ? <Footer /> : <></>}
		</div>
	);
};
MainLayout.defaultProps = {
	children: () => {},
};

MainLayout.propTypes = {
	showFooter: PropTypes.bool.isRequired,
	children: PropTypes.element,
};
export default MainLayout;
