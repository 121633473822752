import styled from 'styled-components';

export const ImageUploadContainer = styled.div`
	width: 100%;
	background: linear-gradient(
		rgba(50, 68, 194, 0.15),
		rgba(84, 103, 233, 0.15)
	);
	border: 2px dashed #384ac9;
	border-radius: 0.938rem;
	color: #384ac9;
	fill: #384ac9;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 1rem;
	outline: none;
	cursor: pointer;
	position: relative;
	&::before {
		content: '';
		display: block;
		padding-top: 60%;
	}
	.MuiBox-root {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 1rem;
		padding: 1.25rem;
		position: absolute;
		inset: 0;
	}
	svg {
		width: 2.25rem;
		height: 2.25rem;
	}
	.MuiTypography-root {
		font-size: 0.875rem;
		text-align: center;
	}
`;

export const ImagePreviewSection = styled.div`
	width: 50%;
	margin-left: 15px;
	@media screen and (max-width: 599.98px) {
		& {
			width: 100%;
		}
	}
	.doc-img-preview {
		width: 100%;
		background: #262932;
		border: 2px solid #384ac9;
		border-radius: 0.938rem;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		position: relative;
		&::before {
			content: '';
			display: block;
			padding-top: 60%;
		}
		.img-preview {
			width: calc(100% - 2rem);
			height: calc(100% - 2rem);
			object-fit: contain;
			object-position: center;
			position: absolute;
			inset: 1rem;
		}
		.img-placeholder {
			width: calc(100% - 2rem);
			height: calc(100% - 2rem);
			object-fit: contain;
			object-position: center;
			position: absolute;
			inset: 1rem;
			transform: scale(0.5);
		}
	}
	.doc-status-text-box {
		display: flex;
		align-items: center;
		gap: 0.75rem;
		margin: 1rem 0;
		.doc-status-text {
			color: #fff;
			font-size: 0.875rem;
			font-weight: 400;
		}
		.icon {
			width: 1.25rem;
			height: 1.25rem;
			display: flex;
			justify-content: center;
			align-items: center;
			svg {
				width: 1.25rem;
				height: 1.25rem;
			}
			.approve-icon {
				color: #04bd00;
				fill: #04bd00;
			}
			.reject-icon {
				color: #ff0000;
				fill: #ff0000;
			}
			.pending-icon {
				color: #ffb100;
				fill: #ffb100;
			}
		}
	}
`;

export const ImageUploadError = styled.div`
	.error-box {
		background: #262932;
		border: 2px solid #ff0000;
		border-radius: 8px;
		padding: 0.625rem 1rem;
		margin-top: 0.625rem;
		.file-size-text {
			color: #9eabff;
			font-size: 0.875rem;
			display: block;
		}
		.error-text {
			color: #ff0000;
			font-size: 0.875rem;
			display: block;
		}
	}
`;

export const NoDataContainer = styled.div`
	width: 300px;
	margin: 0px auto;
	color: white;
	padding-top: 30px;
	height: 450px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
