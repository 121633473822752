import { createAsyncThunk } from '@reduxjs/toolkit';
import { openInfoToaster, openSuccessToaster } from 'helpers/toaster.helpers';
import { orderBy } from 'lodash';
import {
	getAllBonusService,
	getUserBonusService,
	getBonusDetailsService,
	getLoyaltyLevelService,
	availBonusService,
	getMyBonusesService,
	cancelBonusService,
	getReferralCodeService,
	fetchDailyBonus,
	fetchWeeklyBonus,
	claimBonusDailyWeekly,
	fetchMonthlyBonus,
} from 'network/services/bonus.service';
import {
	getLoyaltyLevelStart,
	getBonusDetails,
	getBonusDetailsFailed,
	getReferralCodeStart,
} from 'redux-thunk/redux/slices/bonus.slice';

export const getAllBonus = createAsyncThunk(
	'fetch/all-bonus',
	async (data, thunkApi) => {
		try {
			let res = await getAllBonusService(data);
			res = { ...res, bonus: orderBy(res.bonus, 'orderId') };
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const getUserBonus = createAsyncThunk(
	'fetch/user-bonus',
	async (data, thunkApi) => {
		try {
			const res = await getUserBonusService(data);
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);
export const getLoyaltyLevel = createAsyncThunk(
	'fetch/loyalty-level',
	async (_, thunkApi) => {
		try {
			const res = await getLoyaltyLevelService();
			thunkApi.dispatch(getLoyaltyLevelStart(res));
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);
export const getBonusDetailsStart = createAsyncThunk(
	'fetch/user-bonus-details',
	async (data, thunkApi) => {
		try {
			const res = await getBonusDetailsService(data);
			thunkApi.dispatch(getBonusDetails(res));
			return res;
		} catch (error) {
			thunkApi.dispatch(getBonusDetailsFailed());
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const availBonus = createAsyncThunk(
	'avail-bonus',
	async (data, thunkApi) => {
		try {
			const payload = { ...data };
			delete payload.bonusType;
			const res = await availBonusService(payload);
			if (data.bonusType === 'deposit') {
				openInfoToaster({
					message: 'Bonus will be credited after first deposit',
				});
			}
			openSuccessToaster({ message: 'Bonus availed successfully!' });
			thunkApi.dispatch(
				getBonusDetailsStart({
					bonusId: data?.bonusId,
					bonusType: data?.bonusType,
				})
			);
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const getMyBonuses = createAsyncThunk(
	'fetch/my-bonuses',
	async (data, thunkApi) => {
		try {
			const res = await getMyBonusesService(data);
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const getDailyBonus = createAsyncThunk(
	'/bonus/daily/bonus',
	async (data, thunkApi) => {
		try {
			const res = await fetchDailyBonus(data);
			return res?.dailyBonusData || {};
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const getWeeklyBonus = createAsyncThunk(
	'/bonus/weekly/bonus',
	async (data, thunkApi) => {
		try {
			const res = await fetchWeeklyBonus(data);
			return res?.weeklyBonusData || {};
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const getMonthlyBonus = createAsyncThunk(
	'/bonus/monthly/bonus',
	async (data, thunkApi) => {
		try {
			const res = await fetchMonthlyBonus(data);
			return res?.monthlyBonusData || {};
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const claimWeeklyOrDailyBonus = createAsyncThunk(
	'/bonus/daily-weekly/claim',
	async (data, thunkApi) => {
		try {
			const res = await claimBonusDailyWeekly(data);
			switch (data?.bonusType) {
				case 'weekly':
					thunkApi.dispatch(getWeeklyBonus());
					break;
				case 'daily':
					thunkApi.dispatch(getDailyBonus());
					break;
				case 'monthly':
					thunkApi.dispatch(getMonthlyBonus());
					break;

				default:
					break;
			}
			
			openSuccessToaster({
				message: 'Bonus Claimed Successfully',
			});
			return res || {};
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const cancelBonus = createAsyncThunk(
	'fetch/my-bonuses',
	async ({ payload, successCallback }, thunkApi) => {
		try {
			const res = await cancelBonusService(payload);
			if (successCallback) successCallback();
			openSuccessToaster({ message: 'Bonus canceled successfully!' });
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);
export const getReferralCode = createAsyncThunk(
	'fetch/referralCode',
	async (data, thunkApi) => {
		try {
			const res = await getReferralCodeService(data);
			thunkApi.dispatch(getReferralCodeStart(res));
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);
