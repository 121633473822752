import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index';
import axiosInstance from 'network/apis/index';
import { microServices } from 'network/apis/microservice/index';

export const getAllTournamentService = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/tournament',
		{},
		{
			server: microServices.SERVICE_URL_1,
			params: data,
			loader: LOADER_HANDLER_TYPES.page,
		}
	);
export const getMyTournamentService = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'tournament/my-tournaments',
		{},
		{
			server: microServices.SERVICE_URL_1,
			params: data,
			loader: LOADER_HANDLER_TYPES.page,
		}
	);
export const getTournamentDetailsService = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/tournament/detail',
		{},
		{
			server: microServices.SERVICE_URL_1,
			params: data,
		}
	);
export const tournamentEnrollService = (data) =>
	axiosInstance(METHOD_TYPES.post, '/tournament/tournament-enroll', data, {
		server: microServices.SERVICE_URL_1,
		loader: LOADER_HANDLER_TYPES.submit,
	});
export const tournamentReBuyEnrollService = (data) =>
	axiosInstance(
		METHOD_TYPES.post,
		'/tournament/rebuy-tournament-points',
		data,
		{
			server: microServices.SERVICE_URL_1,
			loader: LOADER_HANDLER_TYPES.submit,
		}
	);

export const getLeaderBoardService = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/leaderBoard/detail',
		{},
		{
			server: microServices.SERVICE_URL_1,
			params: data,
		}
	);
export const leaderBoardService = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/tournament/leaderboard',
		{},
		{
			server: microServices.SERVICE_URL_1,
			params: data,
		}
	);
export const tournamentTransactionsService = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/tournament/tournament-transactions',
		{},
		{
			server: microServices.SERVICE_URL_1,
			params: data,
		}
	);
