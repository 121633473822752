import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BottomIcon from '../../Assets/Images/sports-icon/betslip-empty.svg';

const EmptyBetslip = () => {
	const { t } = useTranslation();
	return (
		<Grid
			className="empty-betslip"
			display="flex"
			justifyContent="space-between"
		>
			<Grid>
				<img src={BottomIcon} alt="BottomIcon" />
			</Grid>
			<Grid
				display="flex"
				justifyContent="center"
				alignItems="center"
				flexDirection="column"
			>
				<Typography className="heading" variant="h6">
					{t('placeYourBet')}
				</Typography>
				<Typography className="content">{t('yourSelectionArea')}</Typography>
			</Grid>
		</Grid>
	);
};

export default EmptyBetslip;
