import React from 'react';
import { PokerDIcon } from 'components/ui-kit/Icons/iconComponents/PokerDIcon';
import { LiveBIcon } from 'components/ui-kit/Icons/iconComponents/LiveBIcon';
import { SlotDIcon } from 'components/ui-kit/Icons/iconComponents/SlotDIcon';
import { SlotIcon } from 'components/ui-kit/Icons/iconComponents/SlotIcon';
import { VirtualsIcon } from 'components/ui-kit/Icons/iconComponents/VirtualsIcon';
import { VirtualGameIcon } from 'components/ui-kit/Icons/iconComponents/VirtualGameIcon';
import { TvGamesIcon } from 'components/ui-kit/Icons/iconComponents/TvGamesIcon';
import { LiveGreyIcon } from 'components/ui-kit/Icons/iconComponents/LiveGreyIcon';
import { LiveCasinoDIcon } from 'components/ui-kit/Icons/iconComponents/LiveCasinoDIcon';
import { LiveWheelIcon } from 'components/ui-kit/Icons/iconComponents/LiveWheelIcon';

export const categoryIcons = {
	1: '',
};

export const defaultCategoryIcon = <LiveWheelIcon />;

export const subCategoryIcons = {
	47: <LiveBIcon />,
	48: <SlotDIcon />,
	49: <VirtualsIcon />,
	53: <VirtualGameIcon />,
	51: <PokerDIcon />,
	52: <SlotIcon />,
	19: <LiveGreyIcon />,
	22: <LiveCasinoDIcon />,
	50: <TvGamesIcon />,
};

export const defaultSubCategoryIcon = <PokerDIcon />;
