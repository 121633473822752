import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSportsService } from 'SportsBook/BetBalancer/Network/Services/sports.service';

const getSports = createAsyncThunk('get/sports', async (payload, thunkApi) => {
	try {
		let res = await getSportsService();
		res = res?.data?.data?.filter((sport) => sport.isActive);
		return res;
	} catch (err) {
		return thunkApi.rejectWithValue(
			(err && err[0] && err[0]?.description) ||
				err?.message ||
				'Got error while getting sports data'
		);
	}
});

export { getSports };
