/* eslint-disable react/jsx-filename-extension */
import React, { Suspense } from 'react';
import ErrorBoundary from 'components/layout/ErrorBoundary/index';
// import Loader from 'components/ui-kit/Loader/index'
import MainLoader from 'components/ui-kit/Loader/MainLoader/index';
import { Provider } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from 'redux-thunk/store';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Routes from 'routes/index';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const App = () => {
	// useEffect(() => {
	//   Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve }))).then(() => {
	//     // document.querySelector('.loading-page').style.display = 'none'
	//   })
	// }, [])
	const theme = createTheme({
		typography: {
			allVariants: {
				// fontFamily: 'Rubik, sans-serif',
			},
		},
		components: {
			'& .MuiMenu-list': {
				root: {
					background: 'blue',
					'&$selected': {
						// <-- mixing the two classes
						backgroundColor: 'red',
					},
				},
			},
		},
	});

	return (
		<Suspense fallback={<MainLoader />}>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<ErrorBoundary>
						<ToastContainer
							position="top-right"
							autoClose={5000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
							theme="colored"
							className="custom-toaster-container"
						/>
						<ThemeProvider theme={theme}>
							<Routes />
						</ThemeProvider>
					</ErrorBoundary>
				</PersistGate>
			</Provider>
		</Suspense>
	);
};

export { store };

export default App;
