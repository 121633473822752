import * as React from 'react';
import { Backdrop, Grid, Box, Modal, Typography, Fade } from '@mui/material';
import PropTypes from 'prop-types';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	maxWidth: 550,
	width: '100%',
	bgcolor: 'var(--theme-dark)',
	boxShadow: 24,
	color: 'var(--white)',
	p: 4,
	borderRadius: '1rem',
	// 	"@media (max-width:'500px')":{
	// 		minWidth: 250,

	// }
};

const CustomModal = ({ open, handleClose, title, children, customClass }) => (
	<Modal
		aria-labelledby="transition-modal-title"
		aria-describedby="transition-modal-description"
		open={open}
		onClose={handleClose}
		closeAfterTransition
		slots={{ backdrop: Backdrop }}
		slotProps={{
			backdrop: {
				timeout: 500,
			},
		}}
	>
		<Fade in={open}>
			<Grid>
				<Box className={customClass} sx={style}>
					{title ? (
						<Typography
							id="transition-modal-title"
							variant="h6"
							component="h2"
							sx={{ paddingBottom: 2 }}
						>
							{title}
						</Typography>
					) : null}
					<button
							aria-label="close button"
							type="button"
							style={{ float: 'right' }}
							className="btn-close btn-close-white me-2 mt-2"
							onClick={() => handleClose()}
						/>
					<Grid>{children}</Grid>
				</Box>
			</Grid>
		</Fade>
	</Modal>
);

CustomModal.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	children: PropTypes.element.isRequired,
	customClass: PropTypes.string.isRequired,
};

export default CustomModal;
