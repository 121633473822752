import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	systemGameData: null,
	allCurrencies: [],
	allCountries: [],
	gameSettings: [],
	banner: [],
	gameSound: false,
	gameMusic: false,
	showLoginPopup: false,
	showSignupPopup: false,
	showMetamaskSignUpPopup: false,
	affilateCode: null,
	homeBanner: [],
	gameThemeSetting: null,
	encourageSettings: [],
	isDemoGame: false,
	gameIframeLink: null,
	evolutionGameIframe: null,
	tenantDetails: null,
	cmsSiteData: [],
};

const {
	actions: {
		setAllCurrency,
		setGameSettings,
		setMusicSound,
		setGameSound,
		setShowLoginPopup,
		setShowSignupPopup,
		setAffilateCode,
		setNewSeed,
		setIsDemoGame,
		setDemoWallet,
		setLanguages,
		setAllCountries,
		setRegistrationFields,
		setCmsSiteData,
		setShowMetaMaskSignUpPopup,
	},
	reducer,
} = createSlice({
	name: 'gameSetting',
	initialState,
	reducers: {
		setCmsSiteData: (state, action) => ({
			...state,
			cmsSiteData: action.payload,
		}),
		setGameSettings: (state, action) => ({
			...state,
			gameSettings: action.payload,
		}),
		setShowLoginPopup: (state, action) => ({
			...state,
			showLoginPopup: action.payload,
		}),

		setShowMetaMaskSignUpPopup: (state, action) => ({
			...state,
			showMetamaskSignUpPopup: action.payload,
			showLoginPopup: false,
		}),
		setShowSignupPopup: (state, action) => ({
			...state,
			showSignupPopup: action.payload,
		}),
		setAffilateCode: (state, action) => ({
			...state,
			affilateCode: action.payload,
		}),
		setAllCurrency: (state, action) => ({
			...state,
			allCurrencies: action.payload,
		}),
		setRegistrationFields: (state, action) => ({
			...state,
			regFields: action.payload,
		}),
		setAllCountries: (state, action) => ({
			...state,
			allCountries: action.payload,
		}),
		setLanguages: (state, action) => ({
			...state,
			languages: action.payload,
		}),
	},
	extraReducers: () => {},
});

export default reducer;
export {
	setAllCurrency,
	setGameSettings,
	setGameSound,
	setMusicSound,
	setShowLoginPopup,
	setShowMetaMaskSignUpPopup,
	setShowSignupPopup,
	setAffilateCode,
	setNewSeed,
	setIsDemoGame,
	setDemoWallet,
	setLanguages,
	setAllCountries,
	setRegistrationFields,
	setCmsSiteData,
};
