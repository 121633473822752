import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllMarketsService } from 'SportsBook/BetBalancer/Network/Services/allMarket.service';

const getAllMarkets = createAsyncThunk(
	'get/allMarkets',
	async (payload, thunkApi) => {
		try {
			let res = await getAllMarketsService(payload);
			res = res.data.data.markets;
			return res;
		} catch (err) {
			return thunkApi.rejectWithValue(
				(err && err[0] && err[0]?.description) || err?.message
			);
		}
	}
);

export { getAllMarkets };
