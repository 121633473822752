export const ODD_TO_TEAMNAME_MARKETID = {
	live: [
		'851', // Handicap
	],
	preLive: [
		'1', // 1x2
		'2',
		'219',
		'186',
		'57', // 1st half - handicap {hcp}
		'62', // 1st half - exact goals
		'63', // 1st half - odd/even
		'64', // 1st half - both teams to score
		'55', // 1st half - double chance
		'14', // Handicap
		'11', // {$competitor2} no bet
		'8', // Double chance
		'9', // Draw no bet
		'12', // Handicap {hcp}
		'13', // Winning margin
		'65', // 1st half - {$competitor1} clean sheet
		'84', // 2nd half - correct score
		'86',
		'87',
		'10', // {$competitor1} no bet
		'35',
		'47', // {$competitor2} highest scoring half
		'546',
		'78', // 2nd half - {$competitor2} total
		'63', // 1st half - odd/even
		'85',
		'540',
		'541',
		'820',
		'60', // 1st half - {$competitor1} total
	],
};

export const COMPETITOR1 = '{$competitor1}';
export const COMPETITOR2 = '{$competitor2}';

export const customOddsOrder = [
	'{$competitor1} ({-hcp})',
	'{$competitor1} ({+hcp})',
	COMPETITOR1,
	'{$competitor1} ({hcp})',
	'1',
	'draw',
	'x',
	'draw ({hcp})',
	COMPETITOR2,
	'{$competitor2} ({hcp})',
	'{$competitor2} ({+hcp})',
	'{$competitor2} ({-hcp})',
	'2',
	'over {total}',
	'o',
	'over',
	'under {total}',
	'u',
	'under',
];
