/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	render() {
		const { t } = this.props;
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div
					className="pt-5 static-root h-100 page-not-found bg-container"
					style={{ color: '#FFF' }}
				>
					<h2 className="text-center">
						<i className="pr-2 fa fa-exclamation-triangle" aria-hidden="true" />
						{t('error')}
					</h2>
					<div className="mt-3 notFound text-center">
						<p>{t('somethingWentWrong')}</p>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}

export default withTranslation('home')(ErrorBoundary);
