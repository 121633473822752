/* eslint-disable prefer-template */
import * as Yup from 'yup';

const MIN_DEPOSIT = 0.001;
const MAX_DEPOSIT = 10000;

export const depositSchema = (t) =>
	Yup.object().shape({
		amount: Yup.number()
			.min(MIN_DEPOSIT, t('amountGreaterZero'))
			.max(MAX_DEPOSIT, t('amountShouldBeLess') + MAX_DEPOSIT)
			.required(t('amountGreaterZero')),
	});
