/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { LOADER_HANDLER_TYPES } from 'constants/index';
import { ContentLoaderIcon } from '../Icons/iconComponents/ContentLoaderIcon';

const Loader = ({ variant, ...props }) => {
	switch (variant) {
		case LOADER_HANDLER_TYPES.content:
			return (
				<div
					className="d-flex justify-content-center align0items mt-2 mb-2"
					style={props.style}
				>
					<ContentLoaderIcon />
					{/* <div className='spinner-border text-light mt-2 mb-2' role='status' /> */}
				</div>
			);
		case LOADER_HANDLER_TYPES.page:
			return (
				<div
					className="d-flex justify-content-center mt-2 mb-2"
					style={props.style}
				>
					<ContentLoaderIcon />
				</div>
			);
		case LOADER_HANDLER_TYPES.table:
			return (
				<span
					className="spinner-border spinner-border-sm text-light"
					role="status"
					aria-hidden="true"
				/>
			);

		case LOADER_HANDLER_TYPES.submit:
			return (
				<span
					className="spinner-border spinner-border-sm text-light"
					role="status"
					aria-hidden="true"
				/>
			);

		default:
			return (
				<div
					className="d-flex justify-content-center spinner-border show-loader"
					role="status"
				/>
			);
	}
};

export default memo(Loader);
