import styled from 'styled-components';

export const LoaderContainer = styled.div`
	.loader-parent-SB {
		height: 100vh;
		width: 100%;
		left: 0;
		top: 0;
		display: flex;
		justify-content: center;
		align-items: center;

		.full-body-loader-SB {
			flex-direction: column;
			position: relative;
			display: flex;
			align-items: center;

			img {
				z-index: 9;
				width: 6%;
				text-shadow: var(--text-shadow);
				filter: var(--drop-shadow);
				color: var(--text-color);
			}

			span {
				font-size: 25px;
				font-weight: bold;
				color: var(--text-color);
				text-shadow: var(--text-shadow);
				position: absolute;
				bottom: -25px;
			}
		}
	}
`;
