import { lazy } from 'react';
import { ROUTE_PATHS } from 'constants/index';

const Profile = lazy(() => import('pages/Profile'));
const ForgetPassword = lazy(() => import('pages/ForgetPassword'));
const VerifyEmail = lazy(() => import('pages/VerifyEmail'));
const Home = lazy(() => import('pages/Home'));
const Casino = lazy(() => import('pages/Casino'));
const ProviderGames = lazy(() => import('pages/ProviderGames'));
const Promotions = lazy(() => import('pages/Promotions/index'));
const Tournament = lazy(() => import('pages/Tournament/index'));
const Cms = lazy(() => import('pages/Cms/index'));
const GameContainer = lazy(() => import('pages/GameContainer/index'));
const LoyaltyBonus = lazy(() => import('pages/Loyalty/index'));
const PromotionsPage = lazy(
	() => import('pages/Promotions/components/PromotionsPage')
);
const TournamentsPage = lazy(
	() => import('pages/Tournament/Components/TournamentsPage')
);
const ComingSoonPage = lazy(() => import('components/layout/ComingSoon'));
const SportBookIframe = lazy(() => import('pages/SportBookIframe'));
const routesList = [
	{
		path: ROUTE_PATHS.HOME,
		component: Home,
		isPrivate: false,
		showHeader: true,
		showFooter: true,
	},
	{
		path: ROUTE_PATHS.LOGIN,
		showHeader: true,
		showFooter: true,
		component: Home,
	},
	{
		path: ROUTE_PATHS.METAMASKSIGNUP,
		showHeader: true,
		showFooter: true,
		component: Home,
	},
	{
		path: ROUTE_PATHS.SIGNUP,
		showHeader: true,
		showFooter: true,
		component: Home,
	},
	{
		path: ROUTE_PATHS.LOYALTY_BONUS_PAGE,
		component: LoyaltyBonus,
		isPrivate: false,
		showHeader: true,
		showFooter: true,
	},

	{
		path: ROUTE_PATHS.CASINO_PLAYGAME,
		component: GameContainer,
		showHeader: true,
		showFooter: true,
		isPrivate: true,
	},
	{
		path: ROUTE_PATHS.TOURNAMENT_PLAYGAME,
		component: GameContainer,
		showHeader: true,
		showFooter: true,
	},
	{
		path: ROUTE_PATHS.SEND_RESET_LINK,
		component: ForgetPassword,
		showHeader: true,
		showFooter: true,
	},
	{
		path: ROUTE_PATHS.RESET_PASSWORD,
		component: ForgetPassword,
		showHeader: true,
		showFooter: true,
	},
	{
		path: ROUTE_PATHS.VERIFY_EMAIL,
		component: VerifyEmail,
		isPrivate: false,
		showHeader: false,
		showFooter: false,
	},

	{
		path: [ROUTE_PATHS.CASINO, ROUTE_PATHS.GAME_CATAGORY],
		component: Casino,
		module: 'casino',
		isPrivate: false,
		showHeader: true,
		showFooter: true,
	},
	{
		path: ROUTE_PATHS.PROMOTION,
		component: Promotions,
		isPrivate: false,
		showHeader: true,
		showFooter: true,
	},
	{
		path: ROUTE_PATHS.PROMOTIONS_PAGE_ID,
		component: PromotionsPage,
		isPrivate: false,
		showHeader: true,
		showFooter: true,
	},
	{
		path: ROUTE_PATHS.TOURNAMENT,
		component: Tournament,
		isPrivate: false,
		showHeader: true,
		showFooter: true,
	},
	{
		path: ROUTE_PATHS.TOURNAMENTS_PAGE_ID,
		component: TournamentsPage,
		isPrivate: false,
		showHeader: true,
		showFooter: true,
	},
	{
		path: [
			ROUTE_PATHS.PROFILE,
			ROUTE_PATHS.BALANCE,
			ROUTE_PATHS.DEPOSIT,
			ROUTE_PATHS.DEPOSITAMOUNT,
			ROUTE_PATHS.WITHDRAWAMOUNT,
			ROUTE_PATHS.WITHDRAWALS,
			ROUTE_PATHS.CASINO_BET,
			ROUTE_PATHS.ORIGINAL_BET,
			ROUTE_PATHS.SPORTS_BET,
			ROUTE_PATHS.LOYALTY,
			ROUTE_PATHS.REFERRALS,
			ROUTE_PATHS.SETTINGS,
			ROUTE_PATHS.TRANSACTIONS,
			ROUTE_PATHS.VERIFICATION,
			ROUTE_PATHS.LIMITS,
			ROUTE_PATHS.MY_BONUSES,
		],
		component: Profile,
		isPrivate: true,
		showHeader: true,
		showFooter: true,
	},
	{
		path: ROUTE_PATHS.CMS,
		component: Cms,
		isPrivate: false,
		showHeader: true,
		showFooter: true,
	},
	{
		path: ROUTE_PATHS.COMINGSOON,
		component: ComingSoonPage,
		isPrivate: false,
		showHeader: true,
		showFooter: true,
	},
	{
		path: ROUTE_PATHS.SPORTBOOK,
		component: SportBookIframe,
		module: 'sportsbook',
		isPrivate: false,
		showHeader: true,
		showFooter: false,
	},
	{
		path: ROUTE_PATHS.PROVIDER_GAMES,
		component: ProviderGames,
		module: 'casino',
		isPrivate: false,
		showHeader: true,
		showFooter: false,
	},
];

export default routesList;
