import { createSlice } from '@reduxjs/toolkit';
import { getMyBets } from '../thunk/mybets.thunk';

const initialState = {
	mybets: [],
	totalPages: 0,
	page: 0,
	loading: false,
	error: null,
};
// SB means SportsBook
const MyBets = createSlice({
	name: 'SBmyBets',
	initialState,
	reducers: {
        setMyBets: (state, action) => ({
            ...state,
            mybets: action.payload
        })
    },
	extraReducers: (builder) => {
		builder
			.addCase(getMyBets.pending, (state) => ({
				...state,
				loading: true,
			}))
			.addCase(getMyBets.fulfilled, (state, action) => {
				let updatedBets;
				if (action.payload?.isFetchMore) {
					updatedBets = [...state.mybets, ...action.payload.betslips];
				} else {
					updatedBets = action.payload.betslips;
				}

				return {
					...state,
					mybets: updatedBets,
					totalPages: action.payload.totalPages,
					page: action.payload.page,
					loading: false,
				};
			})
			.addCase(getMyBets.rejected, (state, action) => ({
				...state,
				error: action.payload,
				loading: false,
			}));
	},
});

export const {setMyBets} = MyBets.actions;

export default MyBets.reducer;
