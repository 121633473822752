import { createSlice } from '@reduxjs/toolkit';
import {
	claimWeeklyOrDailyBonus,
	getAllBonus,
	getDailyBonus,
	getMyBonuses,
	getUserBonus,
	getWeeklyBonus,
	getMonthlyBonus,
} from 'redux-thunk/thunk/bonus.thunk';

const initialState = {
	allBonus: null,
	userBonus: null,
	loyaltyLevel: null,
	bonusDetails: null,
	myBonuses: null,
	myBonusesLoading: false,
	dailyBonusLoading: false,
	dailyBonusData: {},
	weeklyBonusLoading: false,
	monthlyBonusLoading: false,
	weeklyBonusData: {},
	monthlyBonusData: {},
	claimLoading: false,
};

const {
	actions: {
		setUserBonus,
		getLoyaltyLevelStart,
		getBonusDetails,
		getBonusDetailsFailed,
		getReferralCodeStart,
		resetBonus,
	},
	reducer,
} = createSlice({
	name: 'bonus',
	initialState,
	reducers: {
		setUserBonus: (state, action) => ({
			...state,
			userBonus: action.payload,
		}),
		getBonusDetails: (state, action) => ({
			...state,
			bonusDetails: action.payload,
		}),
		getBonusDetailsFailed: (state) => ({
			...state,
			bonusDetails: null,
		}),
		getLoyaltyLevelStart: (state, action) => ({
			...state,
			loyaltyLevel: action.payload,
		}),
		getReferralCodeStart: (state, action) => ({
			...state,
			referralCodeData: action.payload,
		}),
		resetBonus: (state) => ({
			...state,
			dailyBonusData: {},
			weeklyBonusData: {},
			monthlyBonusData: {},
		}),
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllBonus.fulfilled, (state, action) => ({
				...state,
				allBonus: action.payload,
			}))
			.addCase(getUserBonus.fulfilled, (state, action) => ({
				...state,
				userBonus: action.payload,
			}))
			.addCase(getMyBonuses.pending, (state) => ({
				...state,
				myBonusesLoading: true,
			}))
			.addCase(getMyBonuses.fulfilled, (state, action) => ({
				...state,
				myBonuses: action.payload,
				myBonusesLoading: false,
			}))
			.addCase(getDailyBonus.pending, (state) => ({
				...state,
				dailyBonusLoading: true,
			}))
			.addCase(getDailyBonus.fulfilled, (state, action) => ({
				...state,
				dailyBonusData: action.payload,
				dailyBonusLoading: true,
			}))
			.addCase(getDailyBonus.rejected, (state) => ({
				...state,
				dailyBonusLoading: false,
			}))
			.addCase(getWeeklyBonus.pending, (state) => ({
				...state,
				weeklyBonusLoading: true,
			}))
			.addCase(getWeeklyBonus.fulfilled, (state, action) => ({
				...state,
				weeklyBonusData: action.payload,
				weeklyBonusLoading: true,
			}))
			.addCase(getWeeklyBonus.rejected, (state) => ({
				...state,
				weeklyBonusLoading: false,
			}))
			.addCase(getMonthlyBonus.pending, (state) => ({
				...state,
				monthlyBonusLoading: true,
			}))
			.addCase(getMonthlyBonus.fulfilled, (state, action) => ({
				...state,
				monthlyBonusData: action.payload,
				monthlyBonusLoading: true,
			}))
			.addCase(getMonthlyBonus.rejected, (state) => ({
				...state,
				monthlyBonusLoading: false,
			}))
			.addCase(claimWeeklyOrDailyBonus.pending, (state) => ({
				...state,
				claimLoading: true,
			}))
			.addCase(claimWeeklyOrDailyBonus.fulfilled, (state) => ({
				...state,
				claimLoading: true,
			}))
			.addCase(claimWeeklyOrDailyBonus.rejected, (state) => ({
				...state,
				claimLoading: false,
			}));
	},
});

export default reducer;
export {
	setUserBonus,
	getLoyaltyLevelStart,
	getBonusDetails,
	getBonusDetailsFailed,
	getReferralCodeStart,
	resetBonus,
};
