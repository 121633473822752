import { createSlice } from '@reduxjs/toolkit';
import { MATCH_VIEW_TYPE } from 'SportsBook/BetBalancer/Helper/constants';
import { getItem } from 'SportsBook/BetBalancer/Network/Axios/storageUtils';

const initialState = {
	matchView: MATCH_VIEW_TYPE.BOX,
	oddsType: getItem('oddsType') || 'decimal',
	isExchange: false,
};
// SB means SportsBook
const SBgeneralSlice = createSlice({
	name: 'SBgeneral',
	initialState,
	reducers: {
		setMatchView: (state, action) => ({
			...state,
			matchView: action.payload,
		}),
		setOddsType: (state, action) => ({
			...state,
			oddsType: action.payload,
		}),
		setIsExchange: (state, action) => ({
			...state,
			isExchange: action.payload,
		}),
	},
});

export const { setMatchView, setOddsType, setIsExchange } =
	SBgeneralSlice.actions;

export default SBgeneralSlice.reducer;
