import { styled } from '@mui/material/styles';
import { Dialog } from '@mui/material';
import StyledComponent from 'styled-components';

export const ModalStyle = styled(Dialog)(() => ({
	'& .MuiTypography-root': {
		color: 'var(--white)',
		'& button': {
			'& svg': {
				color: 'var(--white)',
			},
		},
		'& .MuiGrid-root': {
			alignItems: 'center',
		},
	},
	'& .MuiPaper-elevation': {
		backgroundColor: 'var(--boxViewBg)',
		'& .box-grid': {
			backgroundColor: 'transparent',
			padding: '0',
		},
		'& .grid-bg': {
			backgroundColor: 'var(--oddsParentBg) !important',
			color: 'var(--white)',
		},
	},
}));

export const DialogContentContainer = StyledComponent.div`
	.box-grid {
		padding: 1rem;
		border-radius: 8px;
		height: 100%;
		background: var(--casino-filter-btn);
		transition: transform 0.15s ease-out 0s;
    .font14 {
      font-size: 14px;
    }
		.grid-bg {
			background: var(--footerBorder);
			padding: 1rem;
			margin: 0.5rem 0;
			border-radius: 8px;
			display: flex;
			flex-direction: column;
			gap: 15px;
		}
		.cash-out---btn button {
			background: var(--socialLinksBg);
			border-color: transparent;
			text-transform: capitalize;
			border: none;
			// box-shadow:
			// 	rgba(255, 176, 25, 0.4) 0px 0px 10px,
			// 	rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
			// 	rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
			// 	rgb(255, 135, 25) 0px 0px 15px inset;
			color: var(--white);
			border-radius: 8px;
			padding: 0.6rem 3rem;
			font-weight: bold;
		}
	}

  .mybet-modal {
    .MuiDialog-scrollPaper {
      .MuiPaper-rounded {
        background-color: var(--boxViewBg);
      }
    }
  }
`;
