import { createAsyncThunk } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';
import {
	getAllCurrencyService,
	getGameCountriesService,
	getAllCmsSiteinfoService,
	postDefaultCurrencyServiceRequest,
} from 'network/services/gameSetting.service';
// import { openSuccessToaster } from 'helpers/toaster.helpers';
// import { getTranslation } from 'helpers/translations.helpers';
import {
	setAllCountries,
	setRegistrationFields,
	setAllCurrency,
	setCmsSiteData,
} from '../redux/slices/gameSetting.slice';

export const getAllCurrencyStart = createAsyncThunk(
	'user/getAllCurrency',
	async (_, thunkApi) => {
		try {
			const res = await getAllCurrencyService();
			thunkApi.dispatch(setAllCurrency(sortBy(res?.currencies, 'id')));
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const DefaultSelectCurrency = createAsyncThunk(
	'user/defaultCurrency',
	async ({ payload }, thunkApi) => {
		try {
			const res = await postDefaultCurrencyServiceRequest(payload);
			// openSuccessToaster({ message: getTranslation('defaultCurrency') });
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
				// openErrorToaster({ message:error && error[0] && error[0]?.description })
			);
		}
	}
);

export const getAllCmsSiteData = createAsyncThunk(
	'system/get-all-cms-site',
	async (params, thunkApi) => {
		try {
			const res = await getAllCmsSiteinfoService();
			thunkApi.dispatch(setCmsSiteData(res?.pages));
			return res;
		} catch (error) {
			params.navigate('/cms-page-not-found');
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const getAllCountriesStart = createAsyncThunk(
	'game/get-all-countries',
	async (data, thunkApi) => {
		try {
			const res = await getGameCountriesService(data);
			thunkApi.dispatch(setAllCountries(res?.countries));
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const getRegistrationFieldsStart = createAsyncThunk(
	'game/get-signup-registration-fields',
	async (data, thunkApi) => {
		try {
			// const res = await getSignupRegistrationFieldsService(data)
			const fields = {
				sms: 2,
				email: 2,
				phone: 0,
				gender: 2,
				address: 0,
				lastName: 2,
				password: 2,
				username: 2,
				firstName: 2,
				newsLetter: 0,
				countryCode: 0,
				dateOfBirth: 2,
				currencyCode: 0,
				confirmPassword: 2,
				preferredLanguage: 0,
				referralCode: 2,
			};

			thunkApi.dispatch(setRegistrationFields(fields));
			return fields;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);
