import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  skeletonStatus: {
    marginTop: "10px",
    marginBottom: "5px",
    fontSize: "1rem",
    width: "110px"
  },

  skeletonTeamWrap: {
    display: "flex",
    justifyContent: "space-between"
  },

  skeletonTeamName: {
    marginTop: "12px",
    borderRadius: "0.625rem",
    width: "180px",
    height: "21px"
  },

  skeletonTeamScore: {
    marginTop: "10px",
    borderRadius: "0.625rem",
    margin: "2px",
    width: "30px",
    height: "25px"
  },

  skeletonbtnWrap: {
    display: "flex",
    justifyContent: "space-between"
  },

  skeletonbtn: {
    borderRadius: "0.625rem",
    width: "33%",
    height: "35px"
  },
  skeletonDropbtn: {
    width: "35px",
    height: "25px"
  },
  ExtramarketName: {
    width: "30px",
    margin: "6px"
  }
}));
