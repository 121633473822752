/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

const InputField = (props) => {
	const {
		type,
		placeholder,
		value,
		haslabel = '',
		name,
		required = false,
		labeltext,
		...rest
	} = props;
	const [showPassword, setShowPassword] = useState(false);

	return (
		<>
			{haslabel && (
				<label htmlFor={name} className="form-label w-100 text-start">
					{labeltext}
					{required && <span className="color-red">&nbsp;*</span>}
				</label>
			)}
			<input
				{...props}
				{...rest}
				type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
				placeholder={placeholder}
				id={name}
				name={name}
				value={value}
				autoComplete="off"
			/>
			{type === 'password' && (
				<a
					onClick={() => setShowPassword((prevState) => !prevState)}
					className={`show-password pe-3 ${haslabel && 'label-with-password'}`}
					href={() => null}
				>
					{showPassword ? (
						<i className="fa fa-eye" />
					) : (
						<i className="fa fa-eye-slash" aria-hidden="true" />
					)}
				</a>
			)}
		</>
	);
};
InputField.defaultProps = {
	haslabel: '',
	required: false,
};
InputField.propTypes = {
	type: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	haslabel: PropTypes.string,
	name: PropTypes.string.isRequired,
	required: PropTypes.bool,
	labeltext: PropTypes.string.isRequired,
};
export default React.memo(InputField);
