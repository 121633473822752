import { createSlice } from '@reduxjs/toolkit';
import {
	getCasinoGamesList,
	getSearchedGames,
	getFavCasinoGames,
	getRecentBigWins,
} from 'redux-thunk/thunk/game.thunk';

const initialState = {
	providers: null,
	categoryId: '',
	casinoSubCategoryGroup: [],
	favoriteGames: null,
	initGameFailure: null,
	initGameData: null,
	favoriteCasinoGames: {},
	subCategoryGames: {},
	searchPopupGames: {},
	filteredGames: {},
	allGamesData: [],
	casinoBigWins: [],
	sboUrl: null,
};

const {
	actions: {
		setFilteredGames,
		setAllGames,
		setPopupGames,
		setGameCategoryId,
		updateCasinoGamesFav,
		setAllCasinoGameList,
		getGameDataSucces,
		setFavCasinoGames,
		setAllSubCategoryGames,
		resetInitGameData,
		getSboUrlSuccess,
		emptySboUrl,
	},
	reducer,
} = createSlice({
	name: 'games',
	initialState,
	reducers: {
		setFilteredGames: (state, action) => ({
			...state,
			filteredGames: action.payload,
		}),
		setAllGames: (state, action) => ({
			...state,
			allGamesData: action.payload,
		}),
		setPopupGames: (state, action) => ({
			...state,
			searchPopupGames: action.payload,
		}),
		setGameCategoryId: (state, action) => ({
			...state,
			categoryId: action?.payload,
		}),
		setAllSubCategoryGames: (state, action) => ({
			...state,
			subCategoryGames: action.payload,
		}),
		updateCasinoGamesFav: (state, { payload }) => {
			const { id } = payload;
			const newFilteredGames = {
				casinoGames: {
					rows: state.filteredGames.casinoGames.rows?.map((item) =>
						item.id === id ? { ...item, isFavorite: !item.isFavorite } : item
					),
					count: state.filteredGames.casinoGames.count,
				},
			};
			return {
				...state,
				filteredGames: { ...newFilteredGames },
			};
		},

		setAllCasinoGameList: (state, action) => ({
			...state,
			casinoGameList: action?.payload,
		}),
		getGameDataSucces: (state, action) => ({
			...state,
			initGameData: action?.payload,
		}),
		setFavCasinoGames: (state, action) => ({
			...state,
			favoriteCasinoGames: action.payload,
		}),
		resetInitGameData: (state) => ({
			...state,
			initGameData: null,
		}),
		getSboUrlSuccess: (state, action) => ({
			...state,
			sboUrl: action.payload,
		}),
		emptySboUrl: (state) => ({
			...state,
			sboUrl: null,
		}),
	},
	extraReducers: (builder) => {
		builder
			.addCase(getCasinoGamesList.fulfilled, (state, action) => ({
				...state,
				categoryWiseGamesList: action?.payload,
				allGamesData: action?.payload,
			}))
			.addCase(getFavCasinoGames.fulfilled, (state, action) => {
				let updatedList = {};
				if (action.payload?.isLoadMore) {
					updatedList = {
						count: action.payload?.count,
						rows: [
							...(state.favoriteCasinoGames?.rows || []),
							...(action.payload?.rows || []),
						],
					};
				} else {
					updatedList = action.payload;
				}
				return {
					...state,
					favoriteCasinoGames: updatedList,
				};
			})
			.addCase(getSearchedGames.fulfilled, (state, action) => {
				const filtered = action.payload;
				if (action?.payload?.popupGames) {
					return {
						...state,
						searchPopupGames: filtered,
					};
				}
				return {
					...state,
					filteredGames: filtered,
				};
			})
			.addCase(getRecentBigWins.fulfilled, (state, action) => ({
				...state,
				casinoBigWins: action.payload?.bigWins || [],
			}));
	},
});

export default reducer;
export {
	updateCasinoGamesFav,
	setFilteredGames,
	setPopupGames,
	setGameCategoryId,
	setAllCasinoGameList,
	getGameDataSucces,
	setFavCasinoGames,
	setAllSubCategoryGames,
	resetInitGameData,
	setAllGames,
	getSboUrlSuccess,
	emptySboUrl,
};
