import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import MainLoader from 'components/ui-kit/Loader/MainLoader/index';
import CustomRoutes from './routes';
import ScrollToTop from './ScrollToTop/index';
// import Loader from 'components/ui-kit/Loader/index'

const Routes = () => (
	<Router>
		<Suspense fallback={<MainLoader />}>
			<CustomRoutes />
			<ScrollToTop />
		</Suspense>
	</Router>
);

export default Routes;
