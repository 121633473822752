import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index';
import axiosInstance from 'network/apis/index';
import { microServices } from 'network/apis/microservice/index';
// import { SuccessMessage } from 'network/messages/successMessages';

/**
 * Service to update user info
//  * @param {object} data - object contains name to be updated for user
 */

export const paymentProviderServiceRequest = (data) =>
	axiosInstance(
		METHOD_TYPES.get,
		'/common/get-paymentProviders',
		{},
		{
			server: microServices.SERVICE_URL_1,
			params: data,
			loader: LOADER_HANDLER_TYPES.page,
		}
	);
