import { SuccessMessage } from 'network/messages/successMessages';
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index';
import { microServices } from 'network/apis/microservice/index';
import axiosInstanceService from 'network/apis/index';

export const loginMetamaskService = (data) =>
	axiosInstanceService(
		METHOD_TYPES.post,
		'/user/meta-mask/generate-none',
		data,
		{
			server: microServices.SERVICE_URL_1,
			handlerEnabled: false,
			loader: LOADER_HANDLER_TYPES.submit,
			// successMessage: SuccessMessage.loginMetamask,
		}
	);

export const verifyMetamaskSignatureService = (data) =>
	axiosInstanceService(
		METHOD_TYPES.post,
		'/user/meta-mask/verify-signature',
		data,
		{
			server: microServices.SERVICE_URL_1,
			handlerEnabled: false,
			loader: LOADER_HANDLER_TYPES.submit,
			successMessage: SuccessMessage.metamaskLogin,
		}
	);
