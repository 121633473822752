import React from 'react';
import { Button, List, Typography, ListItem, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SB_ROUTE_PATHS } from 'SportsBook/BetBalancer/SportsRoutes/routeConstant';
import Odds from 'SportsBook/BetBalancer/Components/Odds/index';
import { outcomeFormatter } from 'SportsBook/BetBalancer/Helper/formatterFunctions';
import useMarkets from './hooks/useMarkets';

const MainMarketsMobileView = ({ eventMarkets, match }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { filteredMainMarkets } = useMarkets(eventMarkets);

	const handleNavigate = () => {
		navigate(`${SB_ROUTE_PATHS.ALL_MARKETS}?eventId=${match?.fixtureId}`);
	};

	return (
		<Grid className="mobile-view">
			{filteredMainMarkets.slice(0, 1).map((market) => (
				<Grid>
					<Grid className="market-name--wrapper" mb={1}>
						<List>
							<ListItem>{market?.market?.name}</ListItem>
						</List>
					</Grid>

					<Grid className="market-odds-wrapper">
						<List>
							{market?.outcomes?.map((odds) => (
								<ListItem>
									<Button>
										<span>
											{outcomeFormatter(
												odds,
												1,
												2,
												filteredMainMarkets[0]?.market?.providerId
											)}
										</span>
										<h5>
											<Odds
												odds={odds}
												market={filteredMainMarkets[0]?.market}
												match={match}
											/>
										</h5>
									</Button>
								</ListItem>
							))}
						</List>
					</Grid>
				</Grid>
			))}

			<Grid className="market-odds-wrapper" sx={{ marginTop: '15px' }}>
				<List className="more-market" onClick={handleNavigate}>
					<ListItem>
						<Typography display="flex" flexDirection="column">
							<span>{t('viewMarkets')}</span>
						</Typography>
					</ListItem>
				</List>
			</Grid>
		</Grid>
	);
};

export default MainMarketsMobileView;

MainMarketsMobileView.propTypes = {
	eventMarkets: PropTypes.arrayOf(
		PropTypes.shape({
			status: PropTypes.number,
			marketId: PropTypes.string,
			market: PropTypes.shape({
				providerId: PropTypes.string,
				name: PropTypes.string,
			}),
			outcomes: PropTypes.arrayOf(
				PropTypes.shape({
					providerId: PropTypes.string,
					name: PropTypes.string,
					status: PropTypes.string,
					base_line: PropTypes.string,
					price: PropTypes.string,
				})
			),
		})
	).isRequired,
	match: PropTypes.shape({
		id: PropTypes.string,
		fixtureId: PropTypes.string,
		status: PropTypes.string,
		betStatus: PropTypes.string,
		score: PropTypes.string,
		startDate: PropTypes.string,
		league: PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
			sport: PropTypes.shape({
				id: PropTypes.string,
				name: PropTypes.string,
			}),
		}),
		eventParticipants: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
				type: PropTypes.string,
				participant: PropTypes.shape({
					id: PropTypes.string,
					name: PropTypes.string,
				}),
			})
		),
	}).isRequired,
};
