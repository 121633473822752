import { METHOD_TYPES } from 'constants/index';
import axiosInstance from 'network/apis/index';
import { microServices } from 'network/apis/microservice/index';

export const getCasinoCategoriesService = () =>
	axiosInstance(
		METHOD_TYPES.get,
		'/casino/get-categories',
		{},
		{
			server: microServices.SERVICE_URL_1,
		}
	);

export const getSiteSettingDataService = () =>
	axiosInstance(
		METHOD_TYPES.get,
		'/common/get-settings',
		{},
		{
			server: microServices.SERVICE_URL_1,
		}
	);
