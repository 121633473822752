import styled from 'styled-components';
import prev from '../../Assets/Images/banner/prev.png';
import next from '../../Assets/Images/banner/next.png';

export const BannerContainer = styled.div`
	.banner-section {
		padding: 0 1rem;
		img {
			border-radius: 12px;
		}
	}
	.slick-slider.slick-initialized {
		.slick-arrow {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			background: var(--socialLinksBg);
			border: none;
			font-size: 0;
			height: 40px;
			width: 40px;
			border-radius: 5px;
			&.slick-prev,
			&.slick-next {
				&:before {
					background-size: contain;
					height: 0.8rem;
					content: '' !important;
					display: block;
					background-repeat: no-repeat;
					background-position: center;
				}
			}
			&.slick-prev {
				left: 10px;
				z-index: 1;
				&::before {
					background-image: url(${prev});
				}
			}
			&.slick-next {
				right: 10px;
				&::before {
					background-image: url(${next});
				}
			}
		}
		.slick-dots {
			bottom: 10px;
			margin-left: 10px;
			text-align: left;
			li {
				margin: 0 5px;
				height: 7px;
				width: 5px;
				&.slick-active {
					opacity: 1;
					height: 4px;
					width: 30px;
					border-radius: 50px;
					button {
						width: 100%;
						background: var(--secondaryRadialBtn);
					}
				}
				button {
					background: #fff;
					height: 5px;
					width: 10px;
					border-radius: 50px;
					width: 100%;
					padding: 0;
					&:before {
						display: none;
					}
				}
			}
		}
	}
	.schedule-highlight-tabs {
		.card-list--view {
            .active {
                color: var(--text-color);
                text-shadow: var(--text-shadow);
            }
			color: var(--white);
			.card-view,
			.list-view {
				svg {
					margin: 0;
					width: 25px;
				}
				span {
					color: var(--white);
				}
			}
			.card-view.Mui-checked,
			.list-view.Mui-checked {
				span {
					color: var(--secondaryBtnBorder) !important;
				}
			}
		}
        .cursor-not-allowed {
            cursor: not-allowed;
        }
		padding: 1rem 1.5rem;
		display: flex;
		justify-content: space-between;
		button {
			background-color: var(--topNavigationBg);
			border: none;
			margin-right: 10px;
			color: var(--white);
			font-size: 14px;
			text-transform: uppercase;
			padding: 0px 10px;
			border-radius: 50px;
			min-height: 37px !important;
			@media screen and (max-width: 575px) {
				font-size: 11px;
				margin-right: 3px;
				padding: 0px 8px;
			}
			img,
			svg {
				margin-right: 10px;
				width: 14px;
				@media screen and (max-width: 575px) {
					margin-right: 5px;
				}
			}
			.Mui-checked {
				.MuiSwitch-thumb {
					background-color: var(--secondaryRadialBtn);
					@media screen and (max-width: 575px) {
						height: 10px;
						width: 10px;
					}
				}
			}
			.MuiButtonBase-root {
				@media screen and (max-width: 575px) {
					transform: translate(24px, 5px);
				}
			}
			.MuiSwitch-track {
				background-color: var(--theme-dark);
			}
			&.active {
				color: var(--text-color);
				text-shadow: var(--text-shadow);
				img {
					filter: var(--drop-shadow);
				}
			}
			.MuiFormControlLabel-label {
				font-size: 14px;
				@media screen and (max-width: 575px) {
					font-size: 11px;
				}
			}
			.MuiFormControlLabel-root {
				@media screen and (max-width: 575px) {
					margin-right: 0;
				}
			}
		}
	}
`;
