import {
	LANGUAGE,
	ROLLOVER,
	STRATEGY,
	USER_DISCLAIMER,
	WALLETSETTING,
} from 'constants/index';

export const setItem = (key, value) => {
	localStorage.setItem(key, JSON.stringify(value));
};

export const isValidJSONString = (str) => {
	try {
		const val = JSON.parse(str);
		return val;
	} catch (error) {
		return str;
	}
};

export const getItem = (key) => {
	const value = localStorage.getItem(key);
	return isValidJSONString(value);
};

export const setLocalLanguage = (lang) => {
	setItem(LANGUAGE, lang);
};

export const getLocalLanguage = () => getItem(LANGUAGE);

export const getLocalWalletSetting = () => getItem(WALLETSETTING);

export const setLocalWalletSetting = (wallet) => {
	setItem(WALLETSETTING, wallet);
};

export const setRollOver = (value) => {
	setItem(ROLLOVER, value);
};

export const getRollOver = () => getItem(ROLLOVER);

export const setStrategies = (value) => {
	setItem(STRATEGY, value);
};

export const getStrategies = () => getItem(STRATEGY);

export const setUserDisclaimer = (value) => {
	setItem(USER_DISCLAIMER, value);
};

export const getUserDisclaimer = () => getItem(USER_DISCLAIMER);
