/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { Grid, Button, MenuItem, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DatePickerInput from 'components/molecules/DatePicker/DatePicker';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { getUserSportsTransactions } from 'redux-thunk/thunk/user.thunk';
import {
	LEDGER_PURPOSE_VALUES,
	LOADER_HANDLER_TYPES,
	ROUTE_PATHS,
	SPORTS_BOOK_PAGINATION_LIMIT,
	TABLE_CELL_TYPE,
	TRANSACTIONS_LIMIT,
} from 'constants/index';
import { SportsBookGreyIcon } from 'components/ui-kit/Icons/iconComponents/SportsBookGreyIcon';
// import './UsersTransactions.scss'
import 'react-datepicker/dist/react-datepicker.css';
import { momentAPIDate } from 'helpers/moment.helpers';
// import ClearIcon from '@mui/icons-material/Clear';
import Pagination from 'components/ui-kit/Pagination/index';
import Loader from 'components/ui-kit/Loader/index';
import CustomCollapse from 'components/ui-kit/Collapse/index';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

const tableColumns = [
	{
		id: uuidv4(),
		label: 'Game Name',
		value: 'gameName',
		type: TABLE_CELL_TYPE.text,
	},
	{
		id: uuidv4(),
		label: 'Date Time',
		value: 'createdAt',
		type: TABLE_CELL_TYPE.date,
	},
	{
		id: uuidv4(),
		label: 'Debit / Credit',
		value: 'transactionType',
		key: 'userName',
		type: TABLE_CELL_TYPE.text,
	},
	{
		id: uuidv4(),
		label: 'Amount',
		value: 'targetWallet',
		key: 'amount',
		type: TABLE_CELL_TYPE.object,
	},
	{
		id: uuidv4(),
		label: 'Type',
		value: 'targetWallet',
		key: 'amount',
		type: TABLE_CELL_TYPE.object,
	},
	{
		id: uuidv4(),
		label: 'Status',
		value: 'status',
		type: TABLE_CELL_TYPE.text,
	},
];
const SportsBetList = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { isSportTransactionLoading, usersSportTransactions } = useSelector(
		(state) => state.transactions
	);

	const { pathname } = useLocation();
	const dateObj = new Date();
	const initialEndDate = dateObj.setUTCHours(0, 0, 0, 0);
	const initialStartDate = dateObj.setDate(dateObj.getDate() - 30);
	const { allCurrencies } = useSelector((state) => state?.gameSetting);
	const formattedCurrencies = [{ name: 'All', id: 'all' }, ...allCurrencies];
	const [currencyType, setCurrencyType] = useState('all');
	const [date, setDate] = useState({
		startDate: new Date(initialStartDate),
		endDate: new Date(initialEndDate),
	});
	const [page, setPage] = useState(1);
	// eslint-disable-next-line no-unused-vars
	const [transactionType, setTransactionType] = useState('all');
	// eslint-disable-next-line no-unused-vars
	const [numberOfRecords, setNumberOfRecords] = useState(10);
	const [open, setOpen] = useState(false);
	const clearFilterData = () => {
		setCurrencyType('all');
		setTransactionType('all');
		setDate({
			startDate: new Date(initialStartDate),
			endDate: new Date(initialEndDate),
		});
		setPage(1);
	};
	const filterData = (type) => {
		setTransactionType(type);
	};
	const currencyFilterData = (type) => {
		setCurrencyType(type);
	};

	useEffect(() => {
		dispatch(
			getUserSportsTransactions({
				startDate: momentAPIDate(date.startDate),
				endDate: momentAPIDate(date.endDate),
				pageNo: page,
				limit: numberOfRecords,
				actionType: 'SportsbookBet',
				purpose: transactionType === 'all' ? '' : transactionType,
				currencyId: currencyType === 'all' ? '' : currencyType,
			})
		);
	}, [date, page, numberOfRecords, currencyType, transactionType]);

	return (
		<div
			className={`tab-pane fade ${
				pathname === ROUTE_PATHS.SPORTS_BET ? 'show active' : ''
			}`}
			id="v-pills-sports-bet-history"
			role="tabpanel"
			aria-labelledby="v-pills-sports-bet-history-tab"
		>
			<div className="profile-card-wrap ">
				<Grid container>
					<Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
						<div className="profile-heading mb-3 ">
							<h4 className="mt-0 mb-4 ">
								<SportsBookGreyIcon /> {t('sportsbookTransactions')}
							</h4>
						</div>
					</Grid>
					<Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
						<Button
							variant="outlined"
							onClick={() => setOpen(!open)}
							endIcon={<FilterAltOutlinedIcon />}
							className="btn btn-secondary"
						>
							Filters
						</Button>
					</Grid>
				</Grid>
				<CustomCollapse open={open}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={3} className="d-flex flex-column">
							<label htmlFor="DOB" className="form-label">
								{t('Start Date')}
								<span className="color-red">&nbsp;*</span>
							</label>
							<DatePickerInput
								className="form-control shadow-none"
								selected={date.startDate}
								showYearDropdown
								handleDateChange={(data) =>
									setDate((prevState) => ({
										...prevState,
										startDate: data || new Date(),
									}))
								}
								selectsStart
								maxDate={initialEndDate}
								placeholder={t('inputsStart')}
							/>
						</Grid>
						<Grid item xs={12} sm={3} className="d-flex flex-column">
							<label htmlFor="End Date" className="form-label">
								{t('End Date')}
								<span className="color-red">&nbsp;*</span>
							</label>
							<DatePickerInput
								className="form-control shadow-none "
								selected={date.endDate}
								handleDateChange={(data) =>
									setDate((prevState) => ({
										...prevState,
										endDate: data || new Date(),
									}))
								}
								showYearDropdown
								startDate={date.startDate}
								maxDate={initialEndDate}
								minDate={date.startDate}
								placeholder={t('End Date')}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={3}
							className="d-flex flex-column transaction-type"
						>
							<label className="form-label" htmlFor="types">
								Type
								<span className="color-red">&nbsp;*</span>
							</label>
							<TextField
								id="transaction-type"
								variant="outlined"
								select
								value={transactionType}
								onChange={(e) => {
									filterData(e.target.value);
								}}
								className="nav transaction-tab horizontal-theme-tabs  "
							>
								<MenuItem value="all">{t('all')} </MenuItem>
								<MenuItem value="SportsbookBet">{t('Bet')} </MenuItem>
								<MenuItem value="SportsbookWin">{t('Win')}</MenuItem>
								<MenuItem value="SportsbookRefund">{t('Refund')}</MenuItem>
								<MenuItem value="SportsbookCashout">Cashout</MenuItem>
							</TextField>
						</Grid>
						<Grid
							item
							xs={12}
							sm={3}
							className="d-flex flex-column transaction-type"
						>
							<label className="form-label" htmlFor="types">
								{t('currencyTypes')}

								<span className="color-red">&nbsp;*</span>
							</label>
							<TextField
								id="transaction-type"
								variant="outlined"
								select
								value={currencyType}
								onChange={(e) => {
									currencyFilterData(e.target.value);
								}}
								className="nav transaction-tab horizontal-theme-tabs   "
							>
								{formattedCurrencies.map((item) => (
									<MenuItem value={item.id}>{item.name} </MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid xs={12} display="flex" justifyContent="end">
							<Button
								onClick={() => clearFilterData()}
								className="btn btn-secondary-outline trans-btn "
							>
								{t('resetButton')}
							</Button>
						</Grid>
					</Grid>
				</CustomCollapse>
				<Grid xs={12}>
					<div className="table-responsive">
						<table className="table theme-table">
							<thead>
								<tr>
									{tableColumns.map((column) => (
										<th
											key={column?.id}
											scope="col"
											className="text-center text-nowrap"
										>
											{column.label}
										</th>
									))}
								</tr>
							</thead>
							{isSportTransactionLoading ? (
								<tbody>
									<td colSpan={tableColumns?.length || 4}>
										<Loader variant={LOADER_HANDLER_TYPES.content} />
									</td>
								</tbody>
							) : (
								<tbody>
									{usersSportTransactions?.rows?.length > 0 ? (
										usersSportTransactions?.rows?.map((data) => (
											<tr key={data?.transactionId}>
												<td className="text-center">
													<div className="text-box justify-content-start">
														<span className="icon" />
														<span className="text">
															{data?.sboSubCategory?.name?.EN || ''}
														</span>
													</div>
												</td>
												<td className="text-center">
													<div className="text-box justify-content-start">
														<span className="icon" />
														<span className="text">
															{moment
																.utc(data?.createdAt)
																.local()
																.format('DD/MM/YYYY')}
															&nbsp;&nbsp;
															{moment
																.utc(data?.createdAt)
																.local()
																.format('h:mm A')}
														</span>
													</div>
												</td>
												<td>
													<span className="">
														{!data?.ledger?.from_wallet_id ? (
															<span className="text-green">Credit</span>
														) : (
															<span className="text-red">Debit</span>
														)}
													</span>
												</td>
												<td className="text-center">
													<div className="text-box">
														<span className="text">{`${data?.ledger?.amount} ${data?.ledger?.currency?.code}`}</span>
													</div>
												</td>
												<td className="text-center">
													<div className="text-box justify-content-end">
														<span className="">
															{LEDGER_PURPOSE_VALUES[data?.ledger?.purpose] ||
																''}
														</span>
														<span className="icon" />
													</div>
												</td>
												<td className="text-center">
													<div className="text-box justify-content-end">
														<span
															style={{ textTransform: 'capitalize' }}
															className=""
														>
															{data?.status || ''}
														</span>
													</div>
												</td>
											</tr>
										))
									) : (
										<tr>
											<td colSpan={7} className="text-center text-white">
												{/* {loading ? <Loader variant={LOADER_HANDLER_TYPES.table} /> : 'Data Not Found'} */}
												{t('dataNotFound')}
											</td>
										</tr>
									)}
								</tbody>
							)}
						</table>
					</div>
					{usersSportTransactions?.rows?.length !== 10 && page === 1 ? null : (
						<Pagination
							page={page}
							setPage={setPage}
							limit={TRANSACTIONS_LIMIT}
							dataLength={usersSportTransactions?.rows?.length}
							totalPages={
								(usersSportTransactions?.count || 0) /
								SPORTS_BOOK_PAGINATION_LIMIT
							}
						/>
					)}
				</Grid>
			</div>
		</div>
	);
};

export default SportsBetList;
