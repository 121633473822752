import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	Box,
	Button,
	Menu,
	MenuItem,
	Typography,
	Tooltip,
	Fade,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation, Link, NavLink } from 'react-router-dom';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
	setChat,
	setSelectedWallet,
	setUserData,
} from 'redux-thunk/redux/slices/user.slice';
import { userLogout } from 'redux-thunk/thunk/auth.thunk';
import {
	setAffilateCode,
	setShowLoginPopup,
	setShowSignupPopup,
} from 'redux-thunk/redux/slices/gameSetting.slice';
import { setLoginData } from 'redux-thunk/redux/slices/auth.slice';
// import { getAllBonus } from 'redux-thunk/thunk/bonus.thunk';
import { setUserBonus } from 'redux-thunk/redux/slices/bonus.slice';
import { fetchUserInformation } from 'redux-thunk/thunk/user.thunk';
import {
	DefaultSelectCurrency,
	getAllCurrencyStart,
} from 'redux-thunk/thunk/gameSetting';
import {
	setShowChat,
	setShowSearchPopup,
	setShowSideNav,
} from 'redux-thunk/redux/slices/settings.slice';
import WalletSetting from 'components/layout/Header/components/CurrencyDropdown/WalletSetting';
import Wallet from 'pages/Wallet';
import Login from 'pages/Login';
import Popup from 'components/ui-kit/Popup/index';

// import Metamask from 'pages/MetamaskSignUp/index';
import { HeaderSearchIcon } from 'components/ui-kit/Icons/iconComponents/HeaderSearchIcon';

import { goldICon } from 'components/ui-kit/Icons/png/index';
import { getAuthToken } from 'helpers/cookie.helpers';
import { CHAT, LIVE_DATA, ROUTE_PATHS, SHOW_SIDE_NAV } from 'constants/index';
import { getItem, setItem } from 'helpers/localstorage.helpers';
import { setLiveData } from 'redux-thunk/redux/slices/casino.slice';
import Signup from 'pages/Signup/index';
import { getSiteSettingData } from 'redux-thunk/thunk/general.thunk';
import { profileSidebarOptions } from 'constants/profile';
import { getCasinoProviderList } from 'redux-thunk/thunk/casino.thunk';
import { currencyCodeList } from 'constants/currencyCodeList';
import moment from 'moment';
import ChatIcon from '@mui/icons-material/Chat';
import useWebSocket from 'socket/hooks/useWebSocket';
import config from 'config/app.config';
import { EVENTS } from 'socket/events';
import { chatLogout } from 'utils/helper';
import { BONUS_CODE } from 'SportsBook/BetBalancer/Helper/constants';
import { ReactSelect } from 'components/molecules/ReactSelect/index';
// import { getCasinoGamesList } from 'redux-thunk/thunk/game.thunk';
import { isMobile } from 'react-device-detect';
import ProfileIcon from '../../ui-kit/Icons/svg/ProfileIcon.svg';
import SearchPopup from '../SearchPopup/index';
import SessionAlertDialog from './SessionAlertDialog';
import useStyles from './Header.styles';
import './header.scss';
import VerificationMsg from './VerificationMsg';

const Header = () => {
	const { t } = useTranslation();
	const authToken = getAuthToken();
	const token = !!authToken;
	const [showVerifyEmailPopup, setShowVerifyEmailPopup] = useState(false);
	const [showWalletSetting, setShowWalletSetting] = useState(false);
	const [showReserMailPopup, setShowResetMailPopup] = useState(false);
	const [showSessionAlertDialog, setShowSessionAlertDialog] = useState(false);
	const [toggle, setToggle] = useState(false);
	const [anchorElWallet, setAnchorElWallet] = useState(null);
	const [anchorElAccountDropDown, setAnchorElAccount] = useState(null);
	const openWalletDropDown = Boolean(anchorElWallet);
	const openAccountDropDown = Boolean(anchorElAccountDropDown);
	const navigate = useNavigate();
	const { search, pathname } = useLocation();
	const affiliateCode = new URLSearchParams(search).get('affiliateCode');
	const { showLoginPopup, showSignupPopup } = useSelector(
		(state) => state.gameSetting
	);
	const { settingData } = useSelector((state) => state.general);
	const userData = useSelector((state) => state?.user?.user?.user);
	const { allCurrencies } = useSelector((state) => state?.gameSetting);
	const [currencySymbol, setCurrencySymbol] = useState('$');
	const { showWalletPopup } = useSelector((state) => state.wallet);
	const dispatch = useDispatch();
	const { user, selectedWallet } = useSelector((state) => state.user);
	const { showSideNav, showSearchPopup, showChat } = useSelector(
		(state) => state?.settings
	);
	const [selectedCurrency, setSelectedCurrency] = useState(1);
	const userDataRef = useRef(userData);

	const handleData = (data) => {
		if (userDataRef.current) {
			try {
				const updateWallets = userDataRef?.current?.wallets?.map((wallet) => {
					// eslint-disable-next-line eqeqeq
					if (wallet.id == data.id) {
						return {
							...wallet,
							amount: data.amount,
							bonusAmount: data.bonusAmount,
						};
					}
					return wallet;
				});
				dispatch(
					setUserData({
						user: {
							...userDataRef.current,
							wallets: updateWallets,
						},
					})
				);
			} catch (err) {
				console.log('Error while receiving data from socket.', err?.message);
			}
		}
	};

	// useEffect(() => {
	// 	dispatch(
	// 		getCasinoGamesList({
	// 			data: {
	// 				offset: 0,
	// 				limit: 20,
	// 				// type: casinoCategoriesList[0]?.gameCategory,
	// 				userId: user?.id,
	// 			},
	// 		})
	// 	);
	// }, []);

	useWebSocket(
		`${config.SOCKET_URL}/private`,
		EVENTS.WALLET,
		handleData,
		authToken
	);

	const userCurrencyData = userData?.wallets?.find(
		(item) => item?.currency?.isDefault === true
	);

	// const handleClickWallet = (event) => {
	// 	event.stopPropagation();
	// 	setAnchorElWallet(event.currentTarget);
	// };
	const handleCloseWallet = (event) => {
		event.stopPropagation();
		setAnchorElWallet(null);
	};

	const handleClickAccount = (event) => {
		event.stopPropagation();
		setAnchorElAccount(event.currentTarget);
	};
	const handleCloseAccount = (event) => {
		event.stopPropagation();
		setAnchorElAccount(null);
	};

	// const handleClickNotification = (event) => {
	// 	event.stopPropagation();
	// 	// setOpenNotification(true);
	// 	dispatch(setShowChat(false));
	// };

	// const handleCloseNotification = (event) => {
	// 	event.stopPropagation();
	// 	handleRemoveCount();
	// 	// setOpenNotification(null);
	// };

	useEffect(() => {
		window.addEventListener('error', (e) => {
			if (
				e.message ===
				'ResizeObserver loop completed with undelivered notifications.'
			) {
				const resizeObserverErrDiv = document.getElementById(
					'webpack-dev-server-client-overlay-div'
				);
				const resizeObserverErr = document.getElementById(
					'webpack-dev-server-client-overlay'
				);
				if (resizeObserverErr) {
					resizeObserverErr.setAttribute('style', 'display: none');
				}
				if (resizeObserverErrDiv) {
					resizeObserverErrDiv.setAttribute('style', 'display: none');
				}
			}
		});
	}, []);

	// useEffect(() => {
	//     if (token) {
	//         connectSocket()
	// 	}
	// }, [token])

	useEffect(() => {
		dispatch(getCasinoProviderList());
	}, []);

	useEffect(() => {
		if (token) {
			dispatch(fetchUserInformation());
			dispatch(setLoginData({ accessToken: getAuthToken() }));
		}
	}, [token]);

	useEffect(() => {
		if (affiliateCode) {
			dispatch(setAffilateCode(affiliateCode));
		}
	}, [window.location.pathname, affiliateCode]);

	useEffect(() => {
		if (window.location.pathname === ROUTE_PATHS.SIGNUP) {
			dispatch(setShowSignupPopup(true));
		} else if (window.location.pathname === ROUTE_PATHS.LOGIN) {
			dispatch(setShowLoginPopup(true));
		}
	}, []);

	useEffect(() => {
		if (userData) {
			userDataRef.current = userData;
			const userCurrency = allCurrencies?.find(
				(currency) => currency.isDefault
			);
			setCurrencySymbol(currencyCodeList[userCurrency?.code || 'USD']);
		}
	}, [userData]);

	useEffect(() => {
		if (user) {
			if (!selectedWallet) {
				if (user?.userSetting?.currencyId) {
					dispatch(setSelectedWallet(user?.userSetting?.currencyId));
				} else {
					const currentWallet = user?.wallets?.find((ele) => ele.primary);
					dispatch(setSelectedWallet(currentWallet));
				}
			}
		}
	}, [user]);

	// const currencyOptions = useMemo(
	// 	() =>
	// 		userData?.wallets?.map(({ amount, id, currency }) => ({
	// 			label: (
	// 				<>
	// 					<span>{amount}</span> <span>{currency?.symbol}</span>
	// 					{/* <span>{currency?.code}</span> */}
	// 				</>
	// 			),
	// 			value: id,
	// 		})),
	// 	[userData]
	// );
	const currencyOptions = useMemo(
		() =>
			userData?.wallets?.map(({ amount, id, currency, bonusAmount }) => ({
				label: (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%',
						}}
					>
						<span>
							<span className="me-1">{currency?.symbol}</span>{' '}
							<span>{Number(amount || 0).toFixed(2)}</span>
						</span>
						<span>
							<Tooltip title="Bonus Coins">
								<img
									src={goldICon}
									alt="Coin"
									className="mx-2 option-coin-icon"
								/>
								{bonusAmount}
							</Tooltip>
						</span>
					</div>
				),
				value: id,
			})),
		[userData]
	);
	useEffect(() => {
		if (userData) {
			const defaultCurrency = userData?.wallets?.find(
				(def) => def.isDefault === true
			);

			// setSelectedCurrency({
			// 	label: (
			// 		<>
			// 			<span>{defaultCurrency?.amount}</span>{' '}
			// 			<span>{defaultCurrency?.currency?.symbol}</span>
			// 			{/* <span>{defaultCurrency?.currency?.code}</span>{' '} */}
			// 		</>
			// 	),
			// 	value: defaultCurrency?.id,
			// });
			setSelectedCurrency({
				label: (
					<>
						{' '}
						<Box style={{ display: 'flex', justifyContent: 'space-between' }}>
							<Box>
								<span className="me-1">
									{defaultCurrency?.currency?.symbol}
								</span>{' '}
								<span>{Number(defaultCurrency?.amount || 0).toFixed(2)}</span>
							</Box>
							<Box>
								<img
									src={goldICon}
									alt="Coin"
									className="mx-2 option-coin-icon"
								/>
								<span>{defaultCurrency?.bonusAmount}</span>{' '}
							</Box>
						</Box>
					</>
				),
				value: defaultCurrency?.id,
			});
		}
	}, [userData]);

	useEffect(() => {
		const sessionLimit = user?.user?.sessionLimit;
		const sessionDate = getItem('sessionLimit') || user?.user?.loggedInAt;
		let sessionTimer;
		if (sessionLimit) {
			sessionTimer = setInterval(() => {
				if (moment(sessionDate).add(sessionLimit, 'hours').isBefore(moment())) {
					setShowSessionAlertDialog(true);
					clearInterval(sessionTimer);
				}
			}, 60 * 1000);
		}
		return () => clearInterval(sessionTimer);
	}, [user, toggle]);

	useEffect(() => {
		dispatch(getAllCurrencyStart());
		// dispatch(getAllBonus({ bonusType: 'all' }));
		const liveDataStorage = getItem(LIVE_DATA);
		const chattingData = getItem(CHAT);
		if (liveDataStorage?.length > 0) {
			for (
				let elementIndex = 0;
				elementIndex <
				(liveDataStorage?.length > 20 ? 20 : liveDataStorage?.length);
				elementIndex += 1
			) {
				dispatch(setLiveData(liveDataStorage[elementIndex]));
			}
		}
		if (chattingData?.length > 0) {
			dispatch(setChat({ chatData: chattingData }));
		}
		dispatch(getSiteSettingData());
	}, []);

	const handleLogOut = () => {
		if (window?.sbIframe) window?.sbIframe?.killIframeSession();
		dispatch(userLogout({ navigate }));
		dispatch(setUserBonus([]));
		chatLogout();
	};

	const sidenavExpand = () => {
		setItem(SHOW_SIDE_NAV, !showSideNav);
		dispatch(setShowSideNav(!showSideNav));
		// setShowSportsMenu(false);
		document
			.getElementsByClassName('sidebar-wrap')[0]
			?.classList.toggle('sidebar-close');
		document
			.getElementsByClassName('page-container')[0]
			?.classList.toggle('sidebar-close');
		document
			.getElementsByClassName('header')[0]
			?.classList.toggle('sidebar-close');
	};

	const defaultWallet = userData?.wallets?.find(
		(wallet) => wallet?.currency?.isDefault
	);

	const bonusWallet = userData?.wallets?.find(
		(wallet) => wallet?.currency?.code === BONUS_CODE
	);

	const classes = useStyles();

	return (
		<>
			<Box
				className={`header ${!showSideNav ? 'sidebar-close' : ''} ${!showChat ? 'chat-close' : 'chat-open'}`}
			>
				<Box
					display="flex"
					alignItem="center"
					justifyContent="center"
					className="header-content"
				>
					<Box onClick={sidenavExpand} className="Menu-closeIcon">
						<MenuIcon />
					</Box>
					<Box className="brand-logo">
						<Link to={ROUTE_PATHS.HOME}>
							<img
								width="145px"
								// className="brand-logo-size"
								src={settingData?.logo || '/assets/images/logo/city99.png'}
								alt=""
							/>
						</Link>
					</Box>
					{token ? (
						<Box
							className="header-right auth"
							display="flex"
							alignItems="center"
						>
							<Box className="account-btn-wrap">
								{/* <Box
									onClick={handleClickWallet}
									variant="outlined"
									className={classes['balance-btn']}
								> */}
								<Box className="wallet-dropdown-container">
									<Box className="wallet-dropdown">
										{/* <Typography variant="span" className="px-2">
											{defaultWallet?.amount?.toFixed(2) || 0.0}
										</Typography> */}{' '}
										{/* <img src={goldICon} alt="Coin" className="me-2 coin-icon" /> */}
										<ReactSelect
											selectProvider__control
											my_css
											css-dvyn1m-control
											isSearchable={false}
											options={currencyOptions}
											className="selectProvider"
											defaultValue={selectedCurrency}
											// defaultMenuIsOpen
											classNamePrefix="selectProvider"
											placeholder="Wallets"
											value={selectedCurrency}
											onChange={(item) => {
												setSelectedCurrency(item);

												if (userCurrencyData?.currency?.id !== item.value) {
													dispatch(
														DefaultSelectCurrency({
															payload: {
																walletId: item.value,
															},
														})
													);
												}
											}}
										/>
										{/* <Typography
										variant="span"
										className="dropdown-icon search-btn"
									>
										<i className="fa fa-caret-down" aria-hidden="true" />
									</Typography> */}
									</Box>
								</Box>
								{/* </Box> */}

								<Menu
									anchorEl={anchorElWallet}
									open={openWalletDropDown}
									onClose={handleCloseWallet}
									onClick={handleCloseWallet}
									PaperProps={{
										elevation: 0,
									}}
									disableScrollLock
									className={classes['header-user-menu']}
									MenuListProps={{
										className: classes['header-wallet-section'],
									}}
								>
									<MenuItem className="ChooseCurrency" m={0}>
										<Box className="user-name">
											{/* <Typography m={0} variant="h4">
												{t('currency')}
											</Typography> */}
											<ReactSelect
												selectProvider__control
												css-dvyn1m-control
												options={currencyOptions}
												className="selectProvider"
												classNamePrefix="selectProvider"
												placeholder="Select currency"
												value={selectedCurrency}
												onChange={(item) => {
													setSelectedCurrency(item);

													if (userCurrencyData?.currency?.id !== item.value) {
														dispatch(
															DefaultSelectCurrency({
																payload: {
																	currencyId: item.value,
																},
															})
														);
													}
												}}
											/>
										</Box>
									</MenuItem>
									<MenuItem className="" m={0}>
										<Box className="user-name">
											<Typography m={0} variant="h4">
												{t('totalBalance')}
											</Typography>
											<Typography>
												{currencySymbol}{' '}
												{defaultWallet?.amount?.toFixed(2) || 0.0}
											</Typography>
										</Box>
									</MenuItem>
									<MenuItem className="" m={0}>
										<Box className="user-name">
											<Typography m={0} variant="h4">
												{t('bonusBalance')}
											</Typography>
											<Typography>
												{currencySymbol}{' '}
												{bonusWallet?.[1]?.amount?.toFixed(2) || 0.0}
											</Typography>
										</Box>
									</MenuItem>
									<MenuItem className="" m={0}>
										<Box className="user-name">
											<Typography m={0} variant="h4">
												{t('withdrawableBalance')}
											</Typography>
											<Typography>
												{currencySymbol}{' '}
												{Number(defaultWallet?.totalAmount?.toFixed(2) || 0) -
													Number(defaultWallet?.nonCashAmount?.toFixed(2) || 0)}
											</Typography>
										</Box>
									</MenuItem>
								</Menu>
							</Box>
							<Button
								type="button"
								aria-label="search"
								className="auth-btn default-btn"
								onClick={() => navigate('/promotions')}
							>
								<img
									width="25"
									height="25"
									src="/assets/images/homePage/gift.png"
									alt=""
								/>
							</Button>
							{!pathname.includes(ROUTE_PATHS.SPORTS) && !isMobile && (
								<Button
									type="button"
									aria-label="search"
									className="auth-btn default-btn"
									onClick={() => dispatch(setShowSearchPopup(true))}
								>
									<HeaderSearchIcon />
								</Button>
							)}
							<Box className="account-btn-wrap">
								<Box
									onClick={handleClickAccount}
									variant="outlined"
									className={classes['balance-btn']}
									display=" flex"
								>
									{/* <AccountCircleIcon /> */}
									<img src={ProfileIcon} alt="" />
									{/* <Box className="dropdown-icon">
										<MenuIcon />
									</Box> */}
								</Box>
								<Menu
									anchorEl={anchorElAccountDropDown}
									open={openAccountDropDown}
									onClose={handleCloseAccount}
									onClick={handleCloseAccount}
									// PaperProps={{ elevation: 0 }}
									disableScrollLock
									className={`${openAccountDropDown ? classes['header-user-menu'] : classes['header-user-menu-dropdown']} ${showChat && 'd-none d-md-block'}`}
									MenuListProps={{ className: classes['user-section-list'] }}
								>
									<div className="wallet-container">
										<Box className="wallet-dropdown">
											{/* <Typography variant="span" className="px-2">
												{defaultWallet?.amount?.toFixed(2) || 0.0}
											</Typography> */}{' '}
											{/* <img src={goldICon} alt="Coin" className="me-2 coin-icon" /> */}
											<ReactSelect
												selectProvider__control
												my_css
												css-dvyn1m-control
												sx={{ gap: '4rem' }}
												isSearchable={false}
												options={currencyOptions}
												className="selectProvider"
												defaultValue={selectedCurrency}
												classNamePrefix="selectProvider"
												// defaultMenuIsOpen
												placeholder="Wallets"
												value={selectedCurrency}
												onChange={(item) => {
													setSelectedCurrency(item);

													if (userCurrencyData?.currency?.id !== item.value) {
														dispatch(
															DefaultSelectCurrency({
																payload: {
																	walletId: item.value,
																},
															})
														);
													}
												}}
											/>
											{/* <Typography
											variant="span"
											className="dropdown-icon search-btn"
										>
											<i className="fa fa-caret-down" aria-hidden="true" />
										</Typography> */}
										</Box>
									</div>
									<MenuItem className="" m={0}>
										<div className="user-name">
											<h4 className="m-0">
												{t('hello')}, {userData?.username || t('user')}
											</h4>
										</div>
									</MenuItem>

									{profileSidebarOptions.map(
										({ path, Icon, name, isLogout = false, isActive }) =>
											!isLogout ? (
												isActive && (
													<MenuItem>
														<NavLink to={path} className="dropdown-item">
															<Icon /> {t(name)}
														</NavLink>
													</MenuItem>
												)
											) : (
												<MenuItem onClick={handleLogOut}>
													<NavLink to="#" activeClassName="dropdown-item">
														<Icon />
														{t(name)}
													</NavLink>
												</MenuItem>
											)
									)}
								</Menu>
							</Box>
							<Tooltip
								title={t('chat')}
								arrow
								TransitionComponent={Fade}
								TransitionProps={{ timeout: 600 }}
								placement="bottom"
							>
								<Button
									type="button"
									aria-label="chatIcon"
									className="chat-icon-button default-btn"
									onClick={() => dispatch(setShowChat(!showChat))}
								>
									<ChatIcon />
								</Button>
							</Tooltip>
						</Box>
					) : (
						<Box className="header-right">
							<Button
								color="primary"
								className="auth-btn btn-primary"
								onClick={() => dispatch(setShowLoginPopup(true))}
							>
								Sign In
							</Button>
							<Button
								className="auth-btn btn-secondary"
								onClick={() => dispatch(setShowSignupPopup(true))}
							>
								Sign Up
							</Button>
							{!pathname.includes(ROUTE_PATHS.SPORTS) && !isMobile && (
								<Button
									type="button"
									aria-label="searchIcon"
									className="auth-btn default-btn search-btn"
									onClick={() => dispatch(setShowSearchPopup(true))}
								>
									<HeaderSearchIcon />
								</Button>
							)}
							<Tooltip
								title={t('chat')}
								arrow
								TransitionComponent={Fade}
								TransitionProps={{ timeout: 600 }}
								placement="bottom"
							>
								<Button
									type="button"
									aria-label="chatIcon"
									className="chat-icon-button default-btn"
									onClick={() => dispatch(setShowChat(!showChat))}
								>
									<ChatIcon />
								</Button>
							</Tooltip>
							{/* {!showChat && (
								<Button
									type="button"
									aria-label="chatIcon"
									className="auth-btn default-btn"
									onClick={() => dispatch(setShowChat(true))}
								>
									<ChatIcon />
								</Button>
							)}
							{showChat && <Chat />} */}
						</Box>
					)}
				</Box>
			</Box>

			{showSessionAlertDialog && (
				<SessionAlertDialog
					openModal={showSessionAlertDialog}
					onCloseModal={() => setShowSessionAlertDialog(false)}
					sessionLimit={user?.user?.sessionLimit}
					handleLogout={handleLogOut}
					setToggle={setToggle}
				/>
			)}

			{![showVerifyEmailPopup] && (
				<VerificationMsg
					handleClose={() => {
						setShowVerifyEmailPopup(false);
					}}
					content={t('signupRegisterEmailPopupContent')}
				/>
			)}

			{showSignupPopup && (
				<Signup
					showSignupPopup={showSignupPopup}
					setShowVerifyEmailPopup={setShowVerifyEmailPopup}
				/>
			)}
			{/* {showMetamaskSignUpPopup && (
				<Metamask showMetamaskSignUpPopup={showMetamaskSignUpPopup} />
			)} */}

			{showLoginPopup && (
				<Login setShowVerifyEmailPopup={setShowVerifyEmailPopup} />
			)}

			{showReserMailPopup && (
				<Popup
					handleClose={() => {
						setShowResetMailPopup(false);
					}}
					content={t('signinResetMailSentSuccessContent')}
				/>
			)}

			{showWalletSetting && (
				<WalletSetting onClose={() => setShowWalletSetting(false)} />
			)}

			{showWalletPopup && <Wallet />}

			{showSearchPopup && (
				<SearchPopup setShowSearchPopup={setShowSearchPopup} />
			)}
		</>
	);
};

export default React.memo(Header);
