import { SuccessMessage } from 'network/messages/successMessages';
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index';
import { microServices } from 'network/apis/microservice/index';
import axiosInstanceService from 'network/apis/index';

/**
 * Service to do login
 * @param {object} data - object contains username and password
 */
export const loginService = (data) =>
	axiosInstanceService(METHOD_TYPES.post, '/user/login', data, {
		server: microServices.SERVICE_URL_1,
		// handlerEnabled: false,
		// loader: LOADER_HANDLER_TYPES.submit,
		successMessage: SuccessMessage.login,
	});

/**
 * Service to do logout
 */
export const logoutService = () =>
	axiosInstanceService(
		METHOD_TYPES.post,
		'/user/logout',
		{},
		{
			server: microServices.SERVICE_URL_1,
			loader: LOADER_HANDLER_TYPES.page,
			successMessage: SuccessMessage.logout,
		}
	);

export const signUpService = (data) =>
	axiosInstanceService(METHOD_TYPES.post, '/user/signup', data, {
		server: microServices.SERVICE_URL_1,
		handlerEnabled: false,
		loader: LOADER_HANDLER_TYPES.submit,
		successMessage: SuccessMessage.signup,
	});

/**
 * Service to change password
 * @param {object} data - object contains old and new password
 */
export const updatePasswordService = (data) =>
	axiosInstanceService(METHOD_TYPES.put, '/user/change-password', data, {
		server: microServices.SERVICE_URL_1,
		loader: LOADER_HANDLER_TYPES.submit,
		successMessage: SuccessMessage.changePassword,
	});

export const forgetPasswordService = (data) =>
	axiosInstanceService(METHOD_TYPES.post, '/user/forgot-password', data, {
		server: microServices.SERVICE_URL_1,
		loader: LOADER_HANDLER_TYPES.submit,
		successMessage: SuccessMessage.tokenVerify,
	});
export const forgetVerifyPasswordService = (data) =>
	axiosInstanceService(
		METHOD_TYPES.post,
		'/user/verify-forgot-password',
		data,
		{
			server: microServices.SERVICE_URL_1,
			loader: LOADER_HANDLER_TYPES.submit,
			successMessage: SuccessMessage.resetPassword,
		}
	);

export const verifyEmailService = (data) =>
	axiosInstanceService(
		METHOD_TYPES.get,
		`/user/verify-email?token=${data}`,
		{},
		{
			server: microServices.SERVICE_URL_1,
			loader: LOADER_HANDLER_TYPES.content,
			successMessage: SuccessMessage.emailVerify,
		}
	);

export const getMyBetsService = (data) =>
	axiosInstanceService(
		METHOD_TYPES.get,
		'/crash-game/get-crash-game-status',
		data,
		{
			server: microServices.SERVICE_URL_1,
			loader: LOADER_HANDLER_TYPES.submit,
			successMessage: SuccessMessage.changePassword,
		}
	);

export const checkIsEmailUnique = (data) =>
	axiosInstanceService(
		METHOD_TYPES.get,
		`/user/check-user`,
		{},
		{
			server: microServices.SERVICE_URL_1,
			loader: LOADER_HANDLER_TYPES.submit,
			params: data,
		}
	);

export const addBrowserId = (data) =>
	axiosInstanceService(METHOD_TYPES.post, '/user/insert-unique-key', data, {
		server: microServices.SERVICE_URL_1,
		loader: LOADER_HANDLER_TYPES.submit,
	});
