import { createSlice } from '@reduxjs/toolkit';
import {
	tournamentEnroll,
	getAllTournament,
	getTournamentDetails,
	getLeaderBoardDetails,
	getTournamentTransactionsDetails,
	getMyTournament,
} from 'redux-thunk/thunk/tournament.thunk';

const initialState = {
	tournamentDataLoading: false,
	tournamentError: '',
	tournamentData: null,
	myTournamentData: null,
	tournamentDetails: null,
	tournamentDataError: null,
	leaderBoardData: null,
	tournamentTransactionData: null,
};

const {
	actions: {
		setTournamentData,
		getTournamentDataSuccess,
		getTournamentDataFail,
		getTournamentDataStart,
		getMyTournamentDataSuccess,
		getMyTournamentDataFail,
		getMyTournamentDataStart,
		getTournamentDetailsStart,
		getTournamentDetailsSuccess,
		getTournamentDetailsFail,
		getLeaderBoardStart,
		getLeaderBoardSuccess,
		getLeaderBoardFail,
		getTournamentTransactionsStart,
		getTournamentTransactionsSuccess,
		getTournamentTransactionsFail,
	},
	reducer,
} = createSlice({
	name: 'tournament',
	initialState,
	reducers: {
		getTournamentDataSuccess: (state, action) => ({
			...state,
			tournamentDataLoading: false,
			tournamentData: action.payload,
		}),
		getTournamentDataStart: (state) => ({
			...state,
			tournamentDataLoading: true,
		}),
		getTournamentDataFail: (state) => ({
			...state,
			tournamentDataLoading: false,
			tournamentDataError: true,
		}),

		getMyTournamentDataSuccess: (state, action) => ({
			...state,
			tournamentDataLoading: false,
			tournamentData: action.payload,
		}),
		getMyTournamentDataStart: (state) => ({
			...state,
			tournamentDataLoading: true,
		}),
		getMyTournamentDataFail: (state) => ({
			...state,
			tournamentDataLoading: false,
			tournamentDataError: true,
		}),
		getTournamentDetailsSuccess: (state, action) => ({
			...state,
			tournamentDataLoading: false,
			tournamentDetails: action.payload,
		}),
		getTournamentDetailsStart: (state) => ({
			...state,
			tournamentDataLoading: true,
		}),
		getTournamentDetailsFail: (state) => ({
			...state,
			tournamentDataLoading: false,
			tournamentDataError: true,
		}),
		getLeaderBoardSuccess: (state, action) => ({
			...state,
			tournamentDataLoading: false,
			leaderBoardData: action.payload,
		}),
		getLeaderBoardStart: (state) => ({
			...state,
			tournamentDataLoading: true,
		}),
		getLeaderBoardFail: (state) => ({
			...state,
			tournamentDataLoading: false,
			tournamentDataError: true,
		}),
		getTournamentTransactionsSuccess: (state, action) => ({
			...state,
			tournamentDataLoading: false,
			tournamentTransactionData: action.payload,
		}),
		getTournamentTransactionsStart: (state) => ({
			...state,
			tournamentDataLoading: true,
		}),
		getTournamentTransactionsFail: (state) => ({
			...state,
			tournamentDataLoading: false,
			tournamentDataError: true,
		}),
	},
	extraReducers: (builder) => {
		builder
			.addCase(tournamentEnroll.pending, (state) => ({
				...state,
				tournamentDataLoading: true,
			}))
			.addCase(tournamentEnroll.fulfilled, (state) => ({
				...state,
				tournamentDataLoading: false,
			}))
			.addCase(tournamentEnroll.rejected, (state, action) => ({
				...state,
				tournamentDataLoading: false,
				tournamentError: action.payload,
			}))
			.addCase(getAllTournament.pending, (state) => ({
				...state,
				tournamentDataLoading: true,
			}))
			.addCase(getAllTournament.fulfilled, (state, action) => ({
				...state,
				tournamentDataLoading: false,
				tournamentData: action.payload,
			}))
			.addCase(getAllTournament.rejected, (state, action) => ({
				...state,
				tournamentDataLoading: false,
				tournamentError: action.payload,
			}))

			.addCase(getMyTournament.pending, (state) => ({
				...state,
				tournamentDataLoading: true,
			}))
			.addCase(getMyTournament.fulfilled, (state, action) => ({
				...state,
				tournamentDataLoading: false,
				tournamentData: action.payload,
			}))
			.addCase(getMyTournament.rejected, (state, action) => ({
				...state,
				tournamentDataLoading: false,
				tournamentError: action.payload,
			}))
			.addCase(getTournamentDetails.pending, (state) => ({
				...state,
				tournamentDataLoading: true,
			}))
			.addCase(getTournamentDetails.fulfilled, (state, action) => ({
				...state,
				tournamentDataLoading: false,
				tournamentDetails: action.payload,
			}))
			.addCase(getTournamentDetails.rejected, (state, action) => ({
				...state,
				tournamentDataLoading: false,
				tournamentError: action.payload,
			}))
			.addCase(getLeaderBoardDetails.pending, (state) => ({
				...state,
				tournamentDataLoading: true,
			}))
			.addCase(getLeaderBoardDetails.fulfilled, (state, action) => ({
				...state,
				tournamentDataLoading: false,
				leaderBoardData: action.payload,
			}))
			.addCase(getLeaderBoardDetails.rejected, (state, action) => ({
				...state,
				tournamentDataLoading: false,
				tournamentError: action.payload,
			}))
			.addCase(getTournamentTransactionsDetails.pending, (state) => ({
				...state,
				tournamentDataLoading: true,
			}))
			.addCase(getTournamentTransactionsDetails.fulfilled, (state, action) => ({
				...state,
				tournamentDataLoading: false,
				tournamentTransactionData: action.payload,
			}))
			.addCase(getTournamentTransactionsDetails.rejected, (state, action) => ({
				...state,
				tournamentDataLoading: false,
				tournamentError: action.payload,
			}));
	},
});

export default reducer;
export {
	getTournamentDataSuccess,
	getTournamentDataStart,
	getTournamentDataFail,
	getTournamentDetailsFail,
	getTournamentDetailsSuccess,
	getTournamentDetailsStart,
	setTournamentData,
	getLeaderBoardFail,
	getLeaderBoardSuccess,
	getLeaderBoardStart,
	getTournamentTransactionsFail,
	getTournamentTransactionsStart,
	getTournamentTransactionsSuccess,
	getMyTournamentDataStart,
	getMyTournamentDataSuccess,
	getMyTournamentDataFail,
};
