import { cloneDeep, isEmpty, sortBy } from 'lodash';
import {
	ODD_TO_TEAMNAME_MARKETID,
	customOddsOrder,
} from './formatterConstants';
import { ODDS_DATA } from './oddsTranslation';

export function replaceCompetitor(str) {
	if (!str) return '';
	let newStr = str;
	newStr = `${newStr}`;
	return newStr
		?.replaceAll('{$competitor1}', '1')
		?.replaceAll('{$competitor2}', '2')
		?.replaceAll('&amp;', '')
		?.replaceAll('amp;', '');
}

export function compareInString(args1, args2) {
	return `${args1}` === `${args2}`;
}

export const outcomeFormatter = (
	outcome,
	homeTeamName = 1,
	awayTeamName = 2,
	providerMarketId = '',
	language = 'en'
) => {
	const oddType = ODDS_DATA[outcome?.name]
		? `${ODDS_DATA[outcome.name]?.[language]}`
		: outcome?.name;
	if (!outcome || !oddType) return '';

	let outcomeType = '';
	let formattedOutComeType = outcome && replaceCompetitor(oddType);
	if (
		ODD_TO_TEAMNAME_MARKETID[outcome?.isLive ? 'live' : 'preLive']?.filter(
			(id) => compareInString(providerMarketId, id)
		)?.length
	) {
		const regex = /\b1\b|\b2\b/g;
		if (homeTeamName && awayTeamName)
			formattedOutComeType = formattedOutComeType.replace(regex, (match) =>
				compareInString(match, '1') ? homeTeamName : awayTeamName
			);
	}
	formattedOutComeType = formattedOutComeType.replace(
		/\b([ou])\b/g,
		(match, char) => {
			if (char === 'o') {
				return 'over';
			}
			if (char === 'u') {
				return 'under';
			}
			return '';
		}
	);

	const matchFromOutcomeType =
		outcome && formattedOutComeType?.match(/\{([^}]+)\}/);

	if (
		outcome &&
		outcome?.baseLine &&
		matchFromOutcomeType &&
		matchFromOutcomeType?.length > 1
	) {
		outcomeType = formattedOutComeType?.replace(
			`${matchFromOutcomeType[0]}`,
			`(${outcome?.baseLine})`
		);
	} else if (outcome?.baseLine && formattedOutComeType) {
		outcomeType = `${formattedOutComeType} (${outcome?.baseLine})`;
	} else outcomeType = formattedOutComeType;
	return outcomeType.replace('((', '(').replace('))', ')');
};

export function getName(obj, language = 'en') {
	if (!obj) return '';

	if (!(obj instanceof Array)) {
		if (obj?.[language]) return obj?.[language];
		return obj?.en || obj?.BET;
	}

	let filteredobj =
		obj && obj?.length && obj?.filter((item) => item.language === language);
	if (!filteredobj || !filteredobj?.length) {
		filteredobj = obj && obj?.filter((item) => item.language === 'BET');
	}
	if (!filteredobj || !filteredobj?.length) {
		filteredobj =
			obj && obj?.length && obj?.filter((item) => item.language === 'en');
	}
	return filteredobj[0]?.name;
}

export function getMarketName(
	homeTeamName = '',
	awayTeamName = '',
	marketData = {},
	language = 'en'
) {
	try {
		const market = {
			...marketData.market,
			outcomes: marketData?.outcomes,
		};

		if (!market?.name) return '';
		const regex12 = /\b1\b|\b2\b/g;

		let marketName = replaceCompetitor(getName({ en: market?.name }, language));

		if (homeTeamName && awayTeamName) {
			marketName = marketName.replace(regex12, (match) =>
				compareInString(match, '1') ? homeTeamName : awayTeamName
			);
		}
		const regex = /\{([^}]+)\}/;
		const match = marketName?.match(regex);
		const matchedContent = match?.length > 1 && match?.[1];

		if (
			market?.outcomes?.length &&
			market?.outcomes?.every(
				(outcome) =>
					outcome?.baseLine &&
					market?.outcomes[0]?.baseLine === outcome?.baseLine
			) &&
			matchedContent
		) {
			marketName = marketName?.replace(
				`{${matchedContent}}`,
				`(${market?.outcomes[0]?.baseLine})`
			);
		} else if (
			matchedContent &&
			market?.outcomes?.every(
				({ variant }) => variant && market?.outcomes[0]?.variant === variant
			)
		) {
			const matchedTexts = [...marketName.matchAll(/\{([^}]+)\}/g)];

			const variant = market?.outcomes[0]?.variant;
			matchedTexts.forEach((matchedText) => {
				const matchStr = matchedText?.length > 1 && matchedText?.[1];
				if (matchStr) {
					const found = `${matchStr?.replace(/[^a-zA-Z0-9]/g, '')}=`;
					const matchedContentIndexInVariant = variant?.search(found);
					if (matchedContentIndexInVariant !== -1 && found?.length) {
						const matchStrValueInVariant = variant
							?.slice(matchedContentIndexInVariant + found.length)
							?.match(/^[+-]?\d+(?::\d+)*/);

						if (matchStrValueInVariant?.length)
							marketName = marketName?.replace(
								`{${matchStr}}`,
								`${matchStrValueInVariant?.[0]}`
							);
					}
				}
			});
		} else if (matchedContent) {
			marketName = marketName?.replace(`{${matchedContent}}`, '');
		}

		return marketName;
	} catch (err) {
		return marketData?.market?.name;
	}
}

// const covertOutcome = ({
//     copyMarket,
//     isThreeOutcomeEqual,
//     isTwoOutcomeEqual
// }) => {
//     if (isThreeOutcomeEqual) {
//         const combineSliceOutcomes = [];
//         for (let i = 0; i < copyMarket?.outcomes?.length; i += 3) {
//             const slicedOutcome = copyMarket?.outcomes.slice(i, i + 3);
//             slicedOutcome?.sort((a, b) => (
//                 customOddsOrder.indexOf(`${a.name}`) -
//                 customOddsOrder.indexOf(`${b.name}`)
//             ));
//             combineSliceOutcomes.push(...slicedOutcome);
//         }
//         copyMarket.outcomes = combineSliceOutcomes;
//         return copyMarket;
//     }
//     if (isTwoOutcomeEqual) {
//         const combineSliceOutcomes = [];
//         for (let i = 0; i < copyMarket?.outcomes?.length; i += 2) {
//             const slicedOutcome = copyMarket?.outcomes.slice(i, i + 2);
//             slicedOutcome?.sort((a, b) => (
//                 customOddsOrder.indexOf(`${a.name}`) -
//                 customOddsOrder.indexOf(`${b.name}`)
//             ));
//             combineSliceOutcomes.push(...slicedOutcome);
//         }
//         copyMarket.outcomes = combineSliceOutcomes;
//         return copyMarket;
//     }
//     return copyMarket;
// };

export function getMarketsByOrder(allMarkets, onlySorting = false) {
	try {
		if (isEmpty(allMarkets)) return allMarkets;
		const markets = cloneDeep(allMarkets);
		const copyMarkets = markets?.map((market) => {
			const copyMarket = cloneDeep(market);
			if (
				`${copyMarket?.outcomes?.[0]?.baseLine}` &&
				!copyMarket?.outcomes?.every(
					(outcome) => outcome.baseLine === copyMarket?.outcomes?.[0]?.baseLine
				)
			) {
				const matchedBaseLine = copyMarket?.outcomes?.filter(
					(out) => out.baseLine === copyMarket?.outcomes?.[0]?.baseLine
				)?.length;
				const isThreeOutcomeEqual = matchedBaseLine === 3;
				const isTwoOutcomeEqual = matchedBaseLine === 2;
				if (
					copyMarket?.outcomes?.length >= 3 &&
					(isThreeOutcomeEqual || isTwoOutcomeEqual)
				) {
					copyMarket.outcomes = sortBy(copyMarket?.outcomes, ['baseLine']);
					copyMarket.groupCount = matchedBaseLine;
				}
				return copyMarket;
			}
			if (
				copyMarket?.outcomes.every((outcome) =>
					customOddsOrder.includes(outcome.name)
				)
			) {
				copyMarket?.outcomes?.sort(
					(a, b) =>
						customOddsOrder.indexOf(`${a.name}`) -
						customOddsOrder.indexOf(`${b.name}`)
				);
				return copyMarket;
			}
			copyMarket?.outcomes?.sort((a, b) => {
				if (parseInt(a.providerId, 10) > parseInt(b.providerId, 10)) return 0;
				if (parseInt(a.providerId, 10) < parseInt(b.providerId, 10)) return -1;
				return 1;
			});
			return copyMarket;
		});

		if (onlySorting) return copyMarkets;

		const marketList = [];
		if (!copyMarkets?.length) return [];
		const marketOutcomes = copyMarkets?.[0]?.outcomes;
		if (
			(marketOutcomes?.[0]?.baseLine && `${marketOutcomes?.[0]?.baseLine}`) ||
			(marketOutcomes?.[0]?.variant && `${marketOutcomes?.[0]?.variant}`) ||
			marketOutcomes?.length > 3
		) {
			const firstMarketOutcomes = marketOutcomes.slice(0, 2);
			const firstMarketRemainOutcomes = marketOutcomes.slice(
				2,
				marketOutcomes?.length
			);
			marketList.push({
				...copyMarkets?.[0],
				outcomes: firstMarketOutcomes,
			});
			if (marketOutcomes?.length > 2)
				marketList.push({
					...copyMarkets?.[0],
					outcomes: firstMarketRemainOutcomes,
				});
			marketList.push(...copyMarkets.slice(1));
			return marketList;
		}
		return copyMarkets;
	} catch (err) {
		return allMarkets;
	}
}
