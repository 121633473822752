const LOCATION_ALL = {
	id: '',
	providerId: 'all',
	name: 'All',
	isActive: true,
};

const LEAGUE_ALL = {
	id: '',
	providerId: 'all',
	name: 'All',
	sportId: '',
	locationId: '',
	isActive: true,
};

export { LOCATION_ALL, LEAGUE_ALL };
