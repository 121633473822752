import getBrowserFingerprint from 'get-browser-fingerprint';
import { addBrowserId } from 'network/services/auth.service';

export const browserFingerPrint = () => {
	const fingerprint = getBrowserFingerprint();
	const encodedValue = encodeURIComponent(`浏览器 ${fingerprint} ?`);
	if (!localStorage.getItem('browser-key')) {
		localStorage.setItem('browser-key', encodedValue);
		const data = {
			uniqueKey: encodedValue,
		};
		addBrowserId(data);
	}
	return encodedValue;
};
