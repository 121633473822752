/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { fetchUserInformation } from 'redux-thunk/thunk/user.thunk';
import {
	getLocalLanguage,
	setLocalLanguage,
} from 'helpers/localstorage.helpers';
import { LANGUAGES } from 'constants/index';
import {
	updatePassword,
	userLogin,
	userLogout,
} from 'redux-thunk/thunk/auth.thunk';

const defaultAuthState = {
	loginData: null,
	loginLoading: false,
	operatorInformationData: null,
	language: getLocalLanguage() || LANGUAGES[0].languageCode,
};

const authSlice = createSlice({
	name: 'authSlice',
	initialState: defaultAuthState,
	reducers: {
		setLoginData: (state, action) => ({
			...state,
			loginData: action?.payload,
		}),
		handleLanguageChange: (state, action) => {
			setLocalLanguage(action.payload);
			return {
				...state,
				language: action.payload,
			};
		},
		resetAuthState: () => defaultAuthState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(userLogin.fulfilled, (state, action) => ({
				...state,
				loginData: action.payload,
				language:
					action.payload?.additionalInfo?.languageCode ||
					LANGUAGES[0].languageCode,
				loginLoading: false,
			}))
			.addCase(userLogin.pending, (state, action) => {
				const { password, userNameOrEmail } = action.meta.arg;
				return {
					...state,
					loginData: { password, userNameOrEmail },
					loginLoading: true,
				};
			})
			.addCase(userLogin.rejected, (state, action) => ({
				...state,
				loginLoading: false,
			}))
			.addCase(userLogout.fulfilled, (state) => ({
				...state,
			}))
			.addCase(updatePassword.fulfilled, (state) => ({
				...state,
			}))
			.addCase(fetchUserInformation.fulfilled, (state, action) => ({
				...state,
				operatorInformationData: action.payload.userDetails,
			}));
	},
});

export const { handleLanguageChange, setLoginData, resetAuthState } =
	authSlice.actions;

export default authSlice.reducer;
