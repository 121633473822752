
const TABS_DATA = [
    { label: 'Bet Builder', number: '20', value: '2' },
    { label: '1st Half', number: '1', value: '3' },
    { label: 'Total', number: '10', value: '4' },
    { label: 'Team Stats', number: '15', value: '5' },
    { label: 'Handicap', number: '10', value: '6' },
    { label: 'Player Props', number: '5', value: '7' },
    { label: 'Fast', number: '8', value: '8' },
    { label: '2nd Half', number: '10', value: '9' },
    { label: 'Combo Markets', number: '12', value: '10' },
];

export { TABS_DATA };
