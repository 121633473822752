import { createSlice } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';
import { getSports } from '../thunk/sports.thunk';

const initialState = {
	sports: [],
	selectedPreMatchSportId: '',
	selectedLiveSportId: '',
	selectedTopMatchSportId: '',
	loading: false,
	error: null,
};
// SB means SportsBook
const SBsports = createSlice({
	name: 'SBsports',
	initialState,
	reducers: {
		selectPreMatchSport: (state, action) => ({
			...state,
			selectedPreMatchSportId: action.payload,
			// selectedLiveSportId: '',
			// selectedTopMatchSportId: '',
		}),
		selectLiveSport: (state, action) => ({
			...state,
			// selectedPreMatchSportId: '',
			selectedLiveSportId: action.payload,
			// selectedTopMatchSportId: '',
		}),
		selectTopMatchSport: (state, action) => ({
			...state,
			// selectedPreMatchSportId: '',
			// selectedLiveSportId: '',
			selectedTopMatchSportId: action.payload,
		}),
	},
	extraReducers: (builder) => {
		builder
			.addCase(getSports.pending, (state) => ({
				...state,
				loading: true,
			}))
			.addCase(getSports.fulfilled, (state, action) => ({
				...state,
				sports: sortBy(action.payload, ['id']),
				loading: false,
			}))
			.addCase(getSports.rejected, (state, action) => ({
				...state,
				error: action.payload,
				loading: false,
			}));
	},
});

export const { selectPreMatchSport, selectLiveSport, selectTopMatchSport } =
	SBsports.actions;

export default SBsports.reducer;
