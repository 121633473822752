/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { PropTypes } from 'prop-types';
import HtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Radio from '@mui/material/Radio';
import InputField from 'components/molecules/Input/InputField';
import Button from 'components/molecules/Button/index';
import ErrorMessage from 'components/layout/ErrorMessage/index';

const AuthStep = (props) => {
	const {
		values,
		handleBlur,
		handleChange,
		handleSubmit,
		setFieldValue,
		setAcceptConditions,
		acceptConditions,
		isRequired,
		touched,
		errors,
	} = props;
	// const { languageData } = useSelector(state => state?.language)
	const { regFields } = useSelector((state) => state?.gameSetting);

	const { t } = useTranslation();

	const acceptAllAction = () => {
		if (
			acceptConditions?.privacyPolicy &&
			acceptConditions?.termsAndConditions &&
			acceptConditions?.newsLetter &&
			acceptConditions.sms
		) {
			setAcceptConditions((prev) => ({
				...prev,
				privacyPolicy: false,
				termsAndConditions: false,
				newsLetter: false,
				sms: false,
			}));
			setFieldValue('newsLetter', false);
			setFieldValue('sms', false);
			return;
		}
		setAcceptConditions((prev) => ({
			...prev,
			privacyPolicy: true,
			termsAndConditions: true,
			newsLetter: isRequired(regFields?.newsLetter),
			sms: isRequired(regFields?.sms),
		}));
		setFieldValue('newsLetter', true);
		setFieldValue('sms', true);
	};

	const isAllAccepted =
		acceptConditions?.privacyPolicy &&
		acceptConditions?.termsAndConditions &&
		(acceptConditions?.newsLetter || regFields?.newsLetter === 0) &&
		(acceptConditions.sms || regFields?.sms === 0);

	// const shouldNext = !!(
	// 	values.email &&
	// 	values.password &&
	// 	values.username &&
	// 	values.confirmPassword &&
	// 	isAllAccepted &&
	// 	!errors?.username &&
	// 	!errors?.password &&
	// 	!errors?.confirmPassword &&
	// 	!errors?.email
	// );

	return (
		<div>
			{regFields && (
				<>
					<div className="row signup-form-detail" style={{ color: 'white' }}>
						{regFields?.email !== 0 && (
							<div className="form-group col-lg-6 col-sm-12">
								<InputField
									name="email"
									type="text"
									placeholder={t('emailPlaceholder')}
									id="exampleInputEmail1"
									aria-describedby="emailHelp"
									className="form-control shadow-none"
									value={values.email}
									onChange={handleChange}
									onBlur={handleBlur}
									maxLength={50}
									haslabel="yes"
									required={isRequired(regFields.email)}
									labeltext={t('profileEmail')}
								/>
								<div
									style={{
										minHeight: '14px',
										position: 'relative',
									}}
								>
									<ErrorMessage
										component="div"
										name="email"
										key="email"
										className="error-message"
									>
										{touched.email && errors?.email}
									</ErrorMessage>
								</div>
							</div>
						)}

						{regFields?.username !== 0 && (
							<div className="form-group col-lg-6 col-sm-12">
								<InputField
									name="username"
									type="text"
									id="userName"
									placeholder={t('userNamePlaceholder')}
									className="form-control shadow-none"
									value={values.username}
									onChange={handleChange}
									onBlur={handleBlur}
									maxLength={50}
									haslabel="yes"
									required={isRequired(regFields.username)}
									labeltext={t('profileUserName')}
								/>
								<div
									style={{
										minHeight: '14px',
										position: 'relative',
									}}
								>
									<ErrorMessage
										component="div"
										name="username"
										key="username"
										className="error-message"
									>
										{errors?.username}
									</ErrorMessage>
								</div>
							</div>
						)}

						{regFields?.password !== 0 && (
							<div className="form-group col-lg-6 col-sm-12">
								<InputField
									name="password"
									id="exampleInputPassword1"
									type="password"
									placeholder={t('passwordPlaceholder')}
									className="form-control shadow-none"
									value={values.password}
									onChange={handleChange}
									onBlur={handleBlur}
									maxLength={50}
									haslabel="yes"
									required={isRequired(regFields.password)}
									labeltext={t('profilePassword')}
								/>
								<div
									style={{
										minHeight: '14px',
										position: 'relative',
									}}
								>
									<ErrorMessage
										component="div"
										name="password"
										key="password"
										className="error-message"
									>
										{touched.password && errors?.password}
									</ErrorMessage>
								</div>
							</div>
						)}
						{/* {regFields?.confirmPassword !== 0 && (
							<div className="form-group col-lg-6 col-sm-12">
								<InputField
									name="confirmPassword"
									type="password"
									id="exampleInputPassword2"
									value={values.confirmPassword}
									placeholder={t('confirmPasswordPlaceholder')}
									className="form-control shadow-none"
									onChange={handleChange}
									onBlur={handleBlur}
									maxLength={50}
									haslabel="yes"
									required={isRequired(regFields.confirmPassword)}
									labeltext={`${t('signupConfirm')} ${t('signupPassword')}`}
								/>
								<div
									style={{
										minHeight: '14px',
										position: 'relative',
									}}
								>
									<div
										style={{
											minHeight: '14px',
											position: 'relative',
										}}
									>
										<ErrorMessage
											component="div"
											name="confirmPassword"
											key="confirmPassword"
											className="error-message"
										>
											{touched.confirmPassword && errors?.confirmPassword}
										</ErrorMessage>
									</div>
								</div>
							</div>
						)} */}
						{/* {regFields?.confirmPassword !== 0 && (
							<div className="form-group col-lg-6 col-sm-12">
								<InputField
									name="confirmPassword"
									type="password"
									id="exampleInputPassword2"
									value={values.confirmPassword}
									placeholder={t('confirmPasswordPlaceholder')}
									className="form-control shadow-none"
									onChange={handleChange}
									onBlur={handleBlur}
									maxLength={50}
									haslabel="yes"
									required={isRequired(regFields.confirmPassword)}
									labeltext={`${t('signupConfirm')} ${t('signupPassword')}`}
								/>
								<div
									style={{
										minHeight: '14px',
										position: 'relative',
									}}
								>
									<div
										style={{
											minHeight: '14px',
											position: 'relative',
										}}
									>
										<ErrorMessage
											component="div"
											name="confirmPassword"
											key="confirmPassword"
											className="error-message"
										>
											{touched.confirmPassword && errors?.confirmPassword}
										</ErrorMessage>
									</div>
								</div>
							</div>
						)} */}
						{regFields?.referralCode !== 0 && (
							<div className="form-group col-lg-6 col-sm-12">
								<InputField
									name="referralCode"
									type="text"
									id="referralCode"
									placeholder={t('referralCodePlaceholder')}
									className="form-control shadow-none"
									value={values.referralCode}
									onChange={handleChange}
									onBlur={handleBlur}
									maxLength={50}
									haslabel="yes"
									labeltext={t('profileReferralCode')}
								/>
								<div
									style={{
										minHeight: '14px',
										position: 'relative',
									}}
								>
									<ErrorMessage
										component="div"
										name="referralCode"
										key="referralCode"
										className="error-message"
									/>
								</div>
							</div>
						)}
					</div>

					<div className="terms-n-condition" style={{ color: 'white' }}>
						<div className="d-flex align-items-center">
							<Radio
								onClick={acceptAllAction}
								checked={isAllAccepted}
								className="radioButton"
							/>
							<p onClick={acceptAllAction}>{t('acceptAll')}</p>
						</div>

						<div className="d-flex align-items-center">
							<Radio
								checked={acceptConditions.privacyPolicy}
								onClick={() =>
									setAcceptConditions((prev) => ({
										...prev,
										privacyPolicy: !prev.privacyPolicy,
									}))
								}
								className="radioButton"
							/>
							<p
								onClick={() =>
									setAcceptConditions((prev) => ({
										...prev,
										privacyPolicy: !prev.privacyPolicy,
									}))
								}
								id="privacy-and-policy"
							>
								{HtmlParser(`${t('signupPrivacyPolicy')}`)}
								<span className="text-danger h4">*</span>
							</p>
						</div>

						<div className="d-flex align-items-center">
							<Radio
								checked={acceptConditions.termsAndConditions}
								onClick={() =>
									setAcceptConditions((prev) => ({
										...prev,
										termsAndConditions: !prev.termsAndConditions,
									}))
								}
								className="radioButton"
							/>
							<p
								onClick={() =>
									setAcceptConditions((prev) => ({
										...prev,
										termsAndConditions: !prev.termsAndConditions,
									}))
								}
								id="terms-and-conditions"
							>
								{HtmlParser(`${t('signupTermAndConditions')}`)}
								<span className="text-danger h4">*</span>
							</p>
						</div>

						{!!regFields?.newsLetter && (
							<div className="d-flex align-items-center">
								<Radio
									checked={acceptConditions.newsLetter}
									onClick={() => {
										if (acceptConditions?.newsLetter) {
											setFieldValue('newsLetter', false);
										} else {
											setFieldValue('newsLetter', true);
										}
										setAcceptConditions((prev) => ({
											...prev,
											newsLetter: !prev.newsLetter,
										}));
									}}
									className="radioButton"
								/>
								<p>
									{HtmlParser(`${t('signupNewsLetter')}`)}
									{regFields?.newsLetter === 2 && (
										<span className="text-danger h4">*</span>
									)}
								</p>
							</div>
						)}
						{!!regFields?.sms && (
							<div className="d-flex align-items-center">
								<Radio
									checked={acceptConditions.sms}
									onClick={() => {
										if (acceptConditions?.sms) {
											setFieldValue('sms', false);
										} else {
											setFieldValue('sms', true);
										}
										setAcceptConditions((prev) => ({
											...prev,
											sms: !prev.sms,
										}));
									}}
									className="radioButton"
								/>
								<p
									onClick={() => {
										if (acceptConditions?.sms) {
											setFieldValue('sms', false);
										} else {
											setFieldValue('sms', true);
										}
										setAcceptConditions((prev) => ({
											...prev,
											sms: !prev.sms,
										}));
									}}
								>
									{HtmlParser(`${t('signupSms')}`)}
									{regFields?.sms === 2 && (
										<span className="text-danger h4">*</span>
									)}
								</p>
							</div>
						)}
					</div>
				</>
			)}
			<div className="auth-btn-wrap w-100 d-flex justify-content-center align-items-center">
				<Button
					className="btn btn-primary text-uppercase w-50"
					onClick={handleSubmit}
					type="button"
					// disabled={!shouldNext}
					buttonlabel={
						// isCheckLoading ? (
						// 	<CircularProgress size="1rem" />
						// ) : (
						<span>Submit</span>
						// )
					}
				/>
			</div>
		</div>
	);
};
AuthStep.defaultProps = {};
AuthStep.propTypes = {
	values: PropTypes.shape({
		email: PropTypes.string,
		password: PropTypes.string,
		confirmPassword: PropTypes.string,
		referralCode: PropTypes.string,
		username: PropTypes.string,
	}).isRequired,
	handleBlur: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	setAcceptConditions: PropTypes.func.isRequired,
	acceptConditions: PropTypes.shape({
		privacyPolicy: PropTypes.bool,
		termsAndConditions: PropTypes.bool,
		newsLetter: PropTypes.bool,
		sms: PropTypes.bool,
	}).isRequired,
	isRequired: PropTypes.func.isRequired,
};
export default AuthStep;
