/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { Grid, Box, Button } from '@mui/material';
// import Radio from '@mui/material/Radio';
import { useDispatch, useSelector } from 'react-redux';
import { setMatchView } from 'SportsBook/BetBalancer/Store/slice/general.slice';
import { MATCH_VIEW_TYPE } from 'SportsBook/BetBalancer/Helper/constants';
import Slider from 'react-slick';
// import ListAltIcon from '@mui/icons-material/ListAlt';
import GridViewIcon from '@mui/icons-material/GridView';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useTranslation } from 'react-i18next';
import { BannerContainer } from './style';
import banner1 from '../../Assets/Images/banner/banner-2.jpg';
import banner from '../../Assets/Images/banner/banner-two.jpg';
import banner5 from '../../Assets/Images/banner/banner-3.jpg';
import hightlight from '../../Assets/Images/sports-icon/hightlight.svg';
// import loader from '../../Assets/Images/banner/arc-loader.gif';
// import schedule from '../../Assets/Images/sports-icon/schedule.svg';

const Banner = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const matchView = useSelector((state) => state.SBgeneral.matchView);

	const handleViewChange = (e, val) => {
		e.preventDefault();
		dispatch(setMatchView(val));
	};

	const sportsBookBanner = useMemo(
		() => ({
			initialSlide: 0,
			dots: true,
			infinite: true,
			arrows: true,
			speed: 600,
			autoplay: true,
			slidesToShow: 1,
			slidesToScroll: 1,
		}),
		[]
	);

	return (
		<BannerContainer>
			<Box className="schedule-highlight-tabs">
				<Grid>
					<Button className="active">
						<img src={hightlight} alt="hightlight" />
						{t('highlight')}
					</Button>
					{/* <Button className='cursor-not-allowed'>
						<img src={schedule} alt="schedule" />
						schedule
					</Button> */}
				</Grid>

				<Grid className="card-list--view">
					<Button
						onClick={(e) => handleViewChange(e, MATCH_VIEW_TYPE.BOX)}
						checked={matchView === MATCH_VIEW_TYPE.BOX}
						value={MATCH_VIEW_TYPE.BOX}
						className={`card-view ${
							matchView === MATCH_VIEW_TYPE.BOX && 'active'
						}`}
						title={t('gridView')}
					>
						<GridViewIcon />
					</Button>

					<Button
						onClick={(e) => handleViewChange(e, MATCH_VIEW_TYPE.LIST)}
						checked={matchView === MATCH_VIEW_TYPE.LIST}
						value={MATCH_VIEW_TYPE.LIST}
						className={`list-view ${
							matchView === MATCH_VIEW_TYPE.LIST && 'active'
						}`}
						title={t('listView')}
						style={{ margin: '0' }}
					>
						<FormatListBulletedIcon />
					</Button>
				</Grid>

				{/* <Grid className="card-list--view">
          <Radio
            onClick={handleViewChange}
            checked={matchView === MATCH_VIEW_TYPE.BOX}
            value={MATCH_VIEW_TYPE.BOX}
            className="card-view"
          />
          Card View
          <Radio
            onClick={handleViewChange}
            checked={matchView === MATCH_VIEW_TYPE.LIST}
            value={MATCH_VIEW_TYPE.LIST}
            className="card-view"
          />
          List View
        </Grid> */}
			</Box>
			<section className="banner-section">
				<Slider {...sportsBookBanner}>
					<Grid>
						<img src={banner5} alt="banner" className="w-100" />
					</Grid>

					<Grid>
						<img src={banner1} alt="banner" className="w-100" />
					</Grid>
					<Grid>
						<img src={banner} alt="banner" className="w-100" />
					</Grid>
				</Slider>
			</section>
		</BannerContainer>
	);
};

export default Banner;
