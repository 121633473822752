import { getRequest, postRequest } from '../Axios/axios';
import { URLS } from '../urlConfig';

const getCashoutAmountService = (params) =>
	getRequest(URLS.GET_CASHOUT_AMOUNT, params);

const acceptCashoutService = (params) =>
	postRequest(URLS.ACCEPT_CASHOUT, params);

export { getCashoutAmountService, acceptCashoutService };
