export default {
	loadMore: 'Load More',
	viewMarkets: 'View Markets',
	matchesNotAvailable: 'Matches not available!',
	noMarketsFound: 'Markets not available!',
	cashout: 'Cashout',
	cashoutDescription: 'Are you sure you want to cashout',
	cashoutNotAvailable: 'Cashout not available!',
	cashoutAmount: 'Cashout Amount',
	cashoutExpiresIn: 'Cashout will expire in ',
	cancel: 'Cancel',
	copyToClipboard: 'Copied to Clipboard!',
	highlight: 'Highlight',
	preMatchSB: 'Pre Matches',
	soccer: 'Soccer',
	international: 'International',
	at: 'At',
	oddsFormat: 'Odds Format',
	tracker: 'Tracker',
	comingSoonSB: 'Coming Soon',
	countryTopBar: 'Country top Bar',
	liabilitySE: 'Liability :',
	profitSE: 'Profit :',
	payoutSE: 'Payout :',
	betslip: 'Betslip',
	quickBet: 'Quick Bet',
	odds: 'Odds',
	possibleWin: 'Possible Win',
	placeBet: 'Place Bet',
	share: 'Share',
	trash: 'Trash',
	setting: 'Setting',
	vs: 'vs',
	acceptOddsChanges: 'Accept Odds Changes',
	placeYourBet: 'Place your bets',
	yourSelectionArea: 'Your selections will appear in this area',
	selection: 'Selection',
	stake: 'Stake',
	back: 'Back',
	lay: 'Lay',
	allMarkets: 'All Markets',
	allSports: 'All Sports',
	myBets: 'My Bets',
	warning: 'Warning',
	main: 'Main',
	listView: 'List View',
	gridView: 'Grid View',
	allBets: 'All Bets',
	totalOdds: 'Total Odds',
	possibleWinAmount: 'Possible Win Amount',
	wonAmount: 'Won Amount',
	refundAmount: 'Refund Amount',
	betslipIdSB: 'Betslip Id:',
	oneXtwo: '1X2',
	hiddenName: 'hidden name',
	goToAllMarkets: 'Go to All Markets',
	copy: 'Copy',
	noBetsFound: 'No Bets Found',
	sportsbook: 'Sports Book',
	single: 'Single',
	combo: 'Combo',
};
