// eslint-disable-next-line import/no-extraneous-dependencies
import { create, all } from 'mathjs';
import { ODD_TYPES, STAKE_PRECISION, WINNING_PRECISION } from './constants';

const math = create(all);

const oddsFormatter = (value, oddType) => {
	if (!value || value === '-') return '-';
	if (oddType === ODD_TYPES[0].value) return parseFloat(value).toFixed(2);
	if (oddType === ODD_TYPES[2].value)
		return `${parseFloat(value - 1).toFixed(2)}/1`;

	const val = value.toString();
	const dotIndex = val.indexOf('.');
	let roundedValue;
	if (dotIndex >= 0) {
		if (dotIndex === val.length - 2) {
			roundedValue = `${val}0`;
		} else {
			roundedValue = val.slice(0, dotIndex + 3);
		}
	} else {
		roundedValue = `${val}.00`;
	}

	if (oddType === ODD_TYPES[1].value) {
		roundedValue = parseFloat(roundedValue);
		if (roundedValue >= 2) {
			roundedValue = (roundedValue - 1) * 100;
		}
		if (value < 2) {
			roundedValue = -100 / (roundedValue - 1);
		}
		roundedValue = roundedValue.toFixed(0);
	}
	return roundedValue;
};

const calcBackProfitPayout = (stake = 0, odds = 0) => {
	const liability = Number(stake).toFixed(STAKE_PRECISION);
	const payout = math
		.multiply(Number(stake), Number(odds))
		.toFixed(STAKE_PRECISION);
	const profit = math.subtract(payout, Number(stake)).toFixed(STAKE_PRECISION);
	return { payout, profit, liability };
};

const calcOdds = (bets = {}) => {
	let totalOdds = 1;
	Object.values(bets).forEach((bet) => {
		totalOdds = math.multiply(Number(bet?.outcome?.price), totalOdds);
	});
	return totalOdds;
};

const calcPossibleWin = (totalOdds = 0, stake = 0) =>
	+math.multiply(Number(totalOdds), Number(stake)).toFixed(WINNING_PRECISION);

export const copyToClipboard = (text) => {
	if (window.clipboardData && window.clipboardData.setData) {
		// Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
		return window.clipboardData.setData('Text', text);
	}
	if (
		document.queryCommandSupported &&
		document.queryCommandSupported('copy')
	) {
		const textarea = document.createElement('textarea');
		textarea.textContent = text;
		textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
		document.body.appendChild(textarea);
		textarea.select();
		try {
			return document.execCommand('copy'); // Security exception may be thrown by some browsers.
		} catch (ex) {
			return false;
		} finally {
			document.body.removeChild(textarea);
		}
	}
	return true;
};

export { oddsFormatter, calcBackProfitPayout, calcOdds, calcPossibleWin };

// import np from 'number-precision'
// np.enableBoundaryChecking(false)

// export class NumberPrecision {
//   static #precision = 2

//   static round (number) {
//     return np.round(number, this.#precision)
//   }

//   static plus () {
//     return this.round(np.plus(...arguments))
//   }

//   static minus () {
//     return this.round(np.minus(...arguments))
//   }

//   static times () {
//     return this.round(np.times(...arguments))
//   }
// }

// function calculateLayProfit (liability, odd) {
// 	odd = numberPrecision.round(odd)
// 	liability = numberPrecision.round(liability)

// 	const divisor = numberPrecision.minus(odd, 1)
// 	return numberPrecision.divide(liability, divisor) || 0
//   }

//   function calculateBackProfit (liability, odd) {
// 	odd = numberPrecision.round(odd)
// 	liability = numberPrecision.round(liability)

// 	const multiplier = numberPrecision.minus(odd, 1)
// 	return numberPrecision.times(liability, multiplier) || 0
//   }

//   function calculateLayLiability (profit, odd) {
// 	odd = numberPrecision.round(odd)
// 	profit = numberPrecision.round(profit)

// 	const multiplier = numberPrecision.minus(odd, 1)
// 	return numberPrecision.times(profit, multiplier) || 0
//   }

//   function calculateBackLiability (profit, odd) {
// 	odd = numberPrecision.round(odd)
// 	profit = numberPrecision.round(profit)

// 	const divisor = numberPrecision.minus(odd, 1)
// 	return numberPrecision.divide(profit, divisor) || 0
//   }

//   function calculateStakeForLayPrice (odds, liability) {
// 	const divisor = numberPrecision.minus(odds, 1)
// 	const stake = numberPrecision.divide(liability, divisor)
// 	return stake
// //   }

//   function calculateReverseOdds (bet, profit, liability) {
// 	let quotient = 0
// 	let reverseOdds = 0

// 	profit = numberPrecision.round(profit)
// 	liability = numberPrecision.round(liability)

// 	if (bet.betType === BET_TYPE.BACK) {
// 	  quotient = numberPrecision.divide(liability, profit)
// 	  reverseOdds = numberPrecision.plus(quotient, 1)
// 	} else {
// 	  quotient = numberPrecision.divide(profit, liability)
// 	  reverseOdds = numberPrecision.plus(quotient, 1)
// 	}

// 	return reverseOdds
//   }
