/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/* eslint-disable consistent-return */
// import {
//   configureChains, createClient
// } from 'wagmi'

// import {
//   EthereumClient,
//   w3mConnectors,
//   w3mProvider
// } from '@web3modal/ethereum'
// import { arbitrum, mainnet, polygon } from 'wagmi/chains'
// import { customize, customSettings } from 'components/Game/scripts/customizations'
import CryptoConvert from 'crypto-convert';
import { DEFAULT_PRECISION } from 'constants/index';

const convert = new CryptoConvert({
	cryptoInterval: 60000, // Crypto prices update interval in ms (default 5 seconds on Node.js & 15 seconds on Browsers)
	fiatInterval: 60 * 1e3 * 60, // Fiat prices update interval (default every 1 hour)
	calculateAverage: true, // Calculate the average crypto price from exchanges
	binance: true, // Use binance rates
	bitfinex: true, // Use bitfinex rates
	coinbase: true, // Use coinbase rates
	kraken: true, // Use kraken rates
	HTTPAgent: null,
});

// const chains = [arbitrum, mainnet, polygon]

// const { provider } = configureChains(chains, [
//   w3mProvider({ projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID })
// ])

// export const wagmiClient = createClient({
//   autoConnect: true,
//   connectors: w3mConnectors({ projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID, appName: 'web3Modal', chains }),
//   provider
// })

// Web3Modal Ethereum Client
// export const ethereumClient = new EthereumClient(wagmiClient, chains)

export const blockInvalidChar = (e) =>
	['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

export const copyToClipboard = (text) => {
	if (window.clipboardData && window.clipboardData.setData) {
		// Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
		return window.clipboardData.setData('Text', text);
	}
	if (
		document.queryCommandSupported &&
		document.queryCommandSupported('copy')
	) {
		const textarea = document.createElement('textarea');
		textarea.textContent = text;
		textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
		document.body.appendChild(textarea);
		textarea.select();
		try {
			return document.execCommand('copy'); // Security exception may be thrown by some browsers.
		} catch (ex) {
			return false;
		} finally {
			document.body.removeChild(textarea);
		}
	}
};

export const toFixed = (x) => {
	if (Math.abs(x) < 1.0) {
		const e = parseInt(x.toString().split('e-')[1]);
		if (e) {
			x *= 10 ** (e - 1);
			x = `0.${new Array(e).join('0')}${x.toString().substring(2)}`;
		}
	} else {
		let e = parseInt(x.toString().split('+')[1]);
		if (e > 20) {
			e -= 20;
			x /= 10 ** e;
			x += new Array(e + 1).join('0');
		}
	}
	return x;
};

export const closeAllModals = () => {
	// get modals
	const modals = document.getElementsByClassName('modal');

	// on every modal change state like in hidden modal
	for (let i = 0; i < modals.length; i++) {
		modals[i].classList.remove('show');
		modals[i].setAttribute('aria-hidden', 'true');
		modals[i].setAttribute('style', 'display: none');
	}

	// get modal backdrops
	const modalsBackdrops = document.getElementsByClassName('modal-backdrop');

	// remove every modal backdrop
	for (let i = 0; i < modalsBackdrops.length; i++) {
		document.body.removeChild(modalsBackdrops[i]);
	}

	// remove body class modal
	document.body.classList.remove('modal-open');
};

export const closeModal = (id) => {
	// get modals
	const modals = document.getElementById(id);

	modals.classList.remove('show');
	modals.setAttribute('aria-hidden', 'true');
	modals.setAttribute('style', 'display: none');

	// get modal backdrops
	const modalsBackdrops = document.getElementsByClassName('modal-backdrop');

	// remove every modal backdrop
	for (let i = 0; i < modalsBackdrops.length; i++) {
		document.body.removeChild(modalsBackdrops[i]);
	}

	// remove body class modal
	document.body.classList.remove('modal-open');
};

const formatterToCurrency = (currency) => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency,
	});
	return formatter;
};

export const cryptoToFiat = async (from, to, amount) => {
	const testCoinToReal = { LTCT: 'LTC' };
	const fromCoin = testCoinToReal[from] || from;
	await convert.ready();
	return formatterToCurrency(to).format(convert[fromCoin][to](Number(amount)));
};

export const restrictInputToNumeric = (event) => {
	const input = event.target;
	const { value } = input;
	const newValue = value.replace(/\D/g, ''); // Remove non-numeric characters
	input.value = newValue;
};

export const getPrecision = (amount, precision = DEFAULT_PRECISION) => {
	const precisionDivide = 10 ** precision;
	const result = parseInt(amount * precisionDivide) / precisionDivide;
	return result;
};

export const preventSymbolsInNumField = (e) => {
	if (['e', 'E', '+', '-'].includes(e.key)) {
		e.preventDefault();
	}
};
