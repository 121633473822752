/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import { getAuthToken } from 'helpers/cookie.helpers';
import PrivateRoute from './HOC/PrivateRoutes/index';
import PublicRoute from './HOC/PublicRoutes/index';

const RouteValidator = ({ route }) => {
	const { component: Component, child: Child, showFooter = false } = route;
	const isAuthenticated = !!getAuthToken();
	return (
		<>
			{route?.isPrivate ? (
				<PrivateRoute
					isAuthenticated={isAuthenticated}
					Component={Component}
					child={Child}
					showFooter={showFooter}
				/>
			) : (
				<PublicRoute
					isAuthenticated={isAuthenticated}
					Component={Component}
					child={Child}
					showFooter={showFooter}
				/>
			)}
		</>
	);
};

export default RouteValidator;
