import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Stack, Typography, Grid } from '@mui/material/index';
import { useTranslation } from 'react-i18next';
import { setItem } from 'SportsBook/BetBalancer/Network/Axios/storageUtils';
import { useDispatch, useSelector } from 'react-redux';
import { setOddsType } from 'SportsBook/BetBalancer/Store/slice/general.slice';
import { OddsTypeContainer } from './style';
import { ODD_TYPES } from '../../../Helper/constants';

const OddsType = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { oddsType } = useSelector((state) => state.SBgeneral);

	const handleChange = (event) => {
		const val = event.target.value;
		dispatch(setOddsType(val));
		setItem('oddsType', val);
	};

	return (
		<OddsTypeContainer>
			<Grid className="odds-section">
				<Stack justifyContent="center" alignItems="center" flexDirection="row">
					<Typography>{t('oddsFormat')}</Typography>
					<FormControl sx={{ m: 1, minWidth: 200 }} size="small">
						<Select
							labelId="demo-select-small-label"
							id="odds-select"
							className="odds-select"
							value={oddsType}
							onChange={handleChange}
						>
							{ODD_TYPES?.map((odd) => (
								<MenuItem value={odd.value}>{odd.label}</MenuItem>
							))}
						</Select>
					</FormControl>
				</Stack>
			</Grid>
		</OddsTypeContainer>
	);
};

export default OddsType;
