import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import noData from 'SportsBook/BetBalancer/Assets/Images/home/no-data-found.png';
import { NoDataWrapper } from './style';

const NoDataFound = ({ title }) => {
	const { t } = useTranslation();
	return (
		<NoDataWrapper>
			<Grid xs={12} lg={12} xl={12} className="wrapper">
				<Grid>
					<img src={noData} alt="No data found" className="noDataImage" />
				</Grid>
				<Grid>
					<h6 className="noDataText">{t(title)}</h6>
				</Grid>
			</Grid>
		</NoDataWrapper>
	);
};
NoDataFound.propTypes = {
	title: PropTypes.string.isRequired,
};

export default NoDataFound;
