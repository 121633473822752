import styled from 'styled-components'

export const BetSlipContainer = styled.div`
	.mobileBetSlip {
		@media screen and (max-width: 1200px) {
			display: flex;
		}
		position: fixed;
		display: none;
		bottom: 5rem;
		right: 2rem;
		background-color: var(--secondaryRadialBtn);
		height: 3rem;
		width: 3rem;
		border-radius: 50%;
		justify-content: center;
		align-items: center;
		.mobBadge {
			img {
				width: 25px;
			}
			.MuiBadge-badge {
				top: -4px;
				right: -7px;
				background-color: var(--black);
				height: 1.5rem;
				width: 1.5rem;
				color: var(--secondaryRadialBtn);
				border-radius: 50%;
				font-weight: bold;
				font-size: 11px;
				animation: pulse-animation 2s infinite;
			}
		}
	}
	.betslipSection {
		width: 320px;
		.betSlipHeader {
			background: var(--secondaryRadialBtn);
			border-color: var(--secondry-border);
			padding: 0.7rem;
			border-radius: 8px 8px 0 0;
			.betSlipHeaderSection {
				img {
					width: 25px;
				}
				a {
					font-weight: bold;
					font-size: 1rem;
					color: var(--black);
					p {
						margin-left: 10px;
						img {
							margin-left: 10px;
							filter: invert(1);
						}
						span {
							font-weight: bold;
						}
					}
					.badge {
						background-color: var(--black);
						color: var(--secondaryRadialBtn);
						height: 22px;
						width: 22px;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 0;
						margin: 0;
						font-weight: normal;
					}
				}
			}
			.quickBet {
				p {
					font-size: 11px;
					font-weight: bold;
				}
			}
		}
		.betSlipBody {
			background: var(--boxViewBg);
			.betPlaceContent {
				color: var(--white);
				padding: 1rem;
				display: none;
				p {
					color: var(--white);
					font-size: 0.825rem;
					font-weight: bold;
					line-height: normal;
					margin: 0;
				}
				span {
					color: var(--white);
					font-size: 12px;
				}
			}
			.betSlipTabPanel {
				.yScrolling {
					max-height: calc(100dvh - 460px);
					overflow-y: auto;
					padding: 8px 5px;
				}

				.yScrollingExchange {
					color: var(--white);
					font-size: 14px;
					max-height: calc(100dvh - 530px);
					overflow-y: auto;
					.selectionHeading {
						background-color: var(--betSlipCloseBg);
						padding: 8px 5px;
					}
					.selection {
						font-size: 14px;
					}
					.betslipParent {
						background-color: var(--betSlipLeftBg);
						padding: 10px 5px;
						border-radius: 5px;
						margin-bottom: 5px;
					}

					.trashIcon {
						width: 30px;
						text-align: center;
						svg {
							font-size: 14px;
							opacity: 0.6;
							cursor: pointer;
							&:hover {
								opacity: 1;
							}
						}
					}
					.rightDetail {
						.odds {
							width: 80px;
							text-align: center;
						}
						.stake {
							width: 80px;
							text-align: center;
						}
					}
					.teamSelected {
						white-space: nowrap;
						overflow: hidden;
						-webkit-mask-image: var(--textMaskBg);
						text-overflow: ellipsis;
						font-size: 14px;
					}
					.selectedOdds {
						input {
							&:focus {
								outline: none;
								border-color: var(--text-color);
								box-shadow: var(--input-shadow) !important;
							}
						}
						.oddsValue {
							width: 80px;
							text-align: center;
							background-color: var(--betSlipCloseBg);
							border-radius: 5px;
							border: 1px solid transparent;
							color: var(--white);
						}
						.stakeValue {
							width: 80px;
							text-align: center;
							background-color: var(--betSlipCloseBg);
							border-radius: 5px;
							border: 1px solid transparent;
							color: var(--white);
						}
					}
					.teamName {
						display: flex;
						gap: 5px;
						width: 80%;
						white-space: nowrap;
						overflow: hidden;
						-webkit-mask-image: var(--textMaskBg);
						text-overflow: ellipsis;
						font-size: 14px;
					}
					.backlayButton {
						.backButton {
							background-color: var(--backOddsBg);
							color: var(--black);
						}
						.layButton {
							background-color: var(--layOddsBg);
							color: var(--black);
						}
						button {
							padding: 0;
							border-radius: 0;
							font-size: 12px;
						}
					}
				}

				.betSlipTabsGrp {
					min-height: auto !important;
					background-color: var(--betSlipLeftBg);
					.MuiTabs-indicator {
						background-color: var(--secondaryRadialBtn);
					}
					.MuiTabs-flexContainer {
						justify-content: space-between;
						button {
							padding: 15px 0;
							min-height: auto;
							text-transform: capitalize;
              width: 49%;
							&.Mui-selected {
								color: var(--white);
								opacity: 1;
							}
							color: var(--white);
							opacity: 0.5;
						}
						button[disabled] {
							color: var(--white) !important;
							opacity: 0.5;
							background-color: transparent !important;
							border-color: transparent !important;
							box-shadow: inherit !important;
						}
					}
				}
				.tabPanel {
					.MuiBox-root {
						padding: 5px;
					}
				}
				.oddsSelection {
					background-color: var(--betSlipCloseBg);
					border-radius: 8px;
					margin-bottom: 7px;
					.oddsStack {
						.odds {
							font-size: 14px;
						}
					}
					.closeOdds {
						color: var(--white);
						// background-color: var(--betSlipCloseBg);
						height: 100%;
						min-width: 30px;
						text-align: center;
						cursor: pointer;
						svg {
							width: 15px;
						}
					}
					.oddsSelectionDetail {
						background-color: var(--betSlipLeftBg);
						border-radius: 0 8px 8px 0;
						color: var(--white);
						width: calc(100% - 30px);
						padding: 5px 10px;
					}
					.matchType {
						font-size: 13px;
						opacity: 1;
                        text-wrap: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
						img {
							width: 15px;
							margin-right: 4px;
						}
					}
					.teamDetail {
						white-space: nowrap;
						overflow: hidden;
						opacity: 0.5;
						p {
							font-size: 13px;
						}
					}
					.StackInput {
						.stackOddsInput {
							width: 150px;
							height: 40px;
							border-radius: 8px;
							color: var(--white);
							background-color: var(--casino-input);
							border: 1px solid var(--casino-input);
							padding: 4px 10px;
							&:focus {
								outline: none;
								border-color: var(--text-color);
								box-shadow: var(--input-shadow) !important;
							}
						}
						.acceptOdds {
							background: var(--secondaryRadialBtn);
							border-color: var(--secondry-border);
							text-transform: capitalize;
							color: var(--button-text);
							min-height: auto;
							font-size: 10px;
							padding: 5px 10px;
						}
					}
				}
				.exchangeBetslipBottom {
					padding: 10px;
					ul {
						display: flex;
						gap: 10px;
						.liability {
							color: var(--liabilityColor);
							border-color: var(--liabilityColor);
						}
						.profit {
							color: var(--profitColor);
							border-color: var(--profitColor);
						}
						.payout {
							color: var(--payoutColor);
							border-color: var(--payoutColor);
						}
						li {
							display: flex;
							width: 100%;
							gap: 10px;
							justify-content: space-between;
							font-size: 12px;
							border-radius: 5px;
							padding: 5px;
							border: 1px solid var(--white);
							color: var(--white);
						}
					}
				}

				.betSlipBottomSection {
					padding: 10px;
					.combo-bet {
						position: relative;
					}
					.currency-type {
						position: absolute;
						top: 10px;
						left: 8px;
						color: var(--white);
						font-size: 12px;
					}
					.oddsValue {
						ul {
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 0;
							margin: 0;
							li {
								padding: 0;
								justify-content: flex-end;
								input {
									width: 100px;
									height: 36.5px;
									border-radius: 8px;
									color: var(--white);
									background-color: var(--casino-input);
									border: 1px solid var(--casino-input);
									padding: 4px 10px;
									padding-left: 20px;
									font-size: 12px;
									&:focus,
									&:focus-visible {
										outline: none;
										box-shadow: var(--input-shadow) !important;
										border-color: var(--text-color);
									}
								}
								button {
									color: var(--white);
									background-color: var(--oddsValueBg);
									text-align: center;
									border-radius: 5px;
									display: flex;
									justify-content: center;
									cursor: pointer;
									font-size: 14px;
									min-width: 60px;
									padding: 6px 0;
									&:hover,
									&.active {
										background: var(--secondaryRadialBtn);
										color: var(--button-text);
									}
								}
							}
						}
					}
					.totalValue {
						color: var(--white);
						opacity: 0.5;
						padding-top: 10px;
						.totalBetText {
							font-size: 13px;
						}
						.totalBetValue {
							font-size: 13px;
						}
					}
					.potentialWin {
						padding-bottom: 10px;
						color: var(--white);
						.potentialText {
							font-size: 13px;
						}
						.potentialValue {
							font-size: 13px;
						}
					}
                    .betslip-error {
						color: var(--red);
					    font-size: 13px;

					}
					.btnGrp {
						.placeBetButton {
							background: var(--secondaryRadialBtn);
							border-color: var(--secondry-border);
							text-transform: capitalize;
							color: var(--button-text);
							box-shadow: none;
							width: 100%;
						}
						.shareButton {
							background: var(--betSlipCloseBg);
							text-transform: capitalize;
							color: var(--white);
							box-shadow: none;
							width: 100%;
						}
					}
					.trashSetting {
						padding-top: 15px;
						.trash,
						.setting {
							button {
								display: flex;
								align-items: center;
								font-size: 12px;
								opacity: 0.5;
								gap: 5px;
								color: var(--white);
								text-transform: capitalize;
								&:hover {
									color: var(--white);
									opacity: 1;
								}
								svg {
									width: 14px;
								}
							}
						}
					}
				}
			}
		}
	}
	.pulse {
		animation: pulse-animation 2s infinite;
	}

	@keyframes pulse-animation {
		0% {
			box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
		}
		100% {
			box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
		}
	}
	.empty-betslip {
		background-color: var(--boxViewBg);
		padding: 10px;
		color: var(--white);
		h6 {
			font-size: 14px;
			font-weight: bold;
		}
		p {
			font-size: 12px;
		}
	}
`
