import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { oddsFormatter } from 'SportsBook/BetBalancer/Helper/utils';
import LockIcon from '@mui/icons-material/Lock';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { OUTCOME_STATUS } from 'SportsBook/BetBalancer/Helper/constants';
import { outcomeFormatter } from 'SportsBook/BetBalancer/Helper/formatterFunctions';
import soccer from '../../Assets/Images/sports-icon/soccer.svg';
import useBetslip from './hooks/useBetslip';

const ComboBets = () => {
	const { t } = useTranslation();
	const { bets, handleRemoveBet } = useBetslip();
	const { oddsType } = useSelector((state) => state.SBgeneral);

	return (
		<Grid className="yScrolling">
			{Object.entries(bets || {}).map(([, item]) => {
				const isLocked =
					item?.outcome?.status === OUTCOME_STATUS.SUSPENDED ||
					item?.outcome?.status === OUTCOME_STATUS.SETTLED ||
					item?.outcome?.price <= 1.0;
				return (
					<Grid
						className="oddsSelection"
						display="flex"
						alignItems="center"
						sx={{ width: '100%' }}
					>
						<Grid
							className="closeOdds"
							onClick={(e) => handleRemoveBet(e, item)}
						>
							<CloseIcon />
						</Grid>

						<Grid className="oddsSelectionDetail">
							<Typography className="matchType">
								<img src={soccer} alt="soccer" />
								{outcomeFormatter(
									item.outcome,
									item?.match?.eventParticipants?.[0]?.participant?.name,
									item?.match?.eventParticipants?.[1]?.participant?.name,
									item?.providerMarketId
								)}
							</Typography>

							<Grid
								className="teamDetail"
								display="flex"
								alignItems="center"
								gap={1}
								sx={{ padding: '5px 0' }}
							>
								<Typography className="teamName">
									{item?.match?.eventParticipants[0]?.participant?.name}
								</Typography>
								<Typography className="vs">{t('vs')}</Typography>
								<Typography className="teamName">
									{item?.match?.eventParticipants[1]?.participant?.name}
								</Typography>
							</Grid>

							<Grid
								className="oddsStack"
								display="flex"
								alignItems="center"
								justifyContent="space-between"
							>
								<Grid className="odds" sx={{ flexGrow: '1' }}>
									{isLocked ? (
										<LockIcon fontSize="small" />
									) : (
										oddsFormatter(item.outcome.price, oddsType)
									)}
								</Grid>

								<Grid className="StackInput">
									<Button variant="contained" className="acceptOdds" disabled>
										{t('acceptOddsChanges')}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				);
			})}
		</Grid>
	);
};

export default ComboBets;
