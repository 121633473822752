import { DUMMY_MARKET } from 'SportsBook/BetBalancer/Helper/marketConstants';
import { useEffect, useState } from 'react';

const useMarkets = (eventMarkets) => {
	const [filteredMainMarkets, setFilteredMainMarkets] = useState([]);
	const [filteredAdditionalMarkets, setFilteredAdditionalMarkets] = useState(
		[]
	);

	useEffect(() => {
		const mkts = eventMarkets.map((mkt) => {
			let mrkts = mkt;
			// eventConfig[SPORTS_PROVIDER_ID.SOCCER].mainMarkets.includes(market.marketId)
			if (mrkts?.outcomes?.length === 0) {
				mrkts = { ...mrkts, outcomes: DUMMY_MARKET[0].outcomes };
			}
			return mrkts;
		});
		if (mkts?.length === 0) {
			mkts.push(...DUMMY_MARKET);
		} else if (mkts?.length === 1) {
			mkts.push(DUMMY_MARKET[1]);
		}
		setFilteredMainMarkets(mkts);
		setFilteredAdditionalMarkets([]);
	}, [eventMarkets]);

	return {
		eventMarkets,
		filteredMainMarkets,
		filteredAdditionalMarkets,
	};
};

export default useMarkets;
