export const EVENTS = {
    // private
    WALLET: "wallet",
    BET: "bet",
    EXCHANGE_BET: "exchange_bet",

    // public
    ORDER_BOOK: "order_book",
    IN_PLAY: "in_play",
    PRE_MATCH: "pre_match",
}

export const SOCKET_URL = {
    PRIVATE: `${process.env.REACT_APP_USER_BACKEND_SOCKET_URL}/private`,
    PUBLIC: `${process.env.REACT_APP_USER_BACKEND_SOCKET_URL}/public`,
}
