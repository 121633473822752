import { styled } from '@mui/material/styles';
import { Dialog } from '@mui/material';

export const DialogWrapper = styled(Dialog)(() => ({
	'& .MuiTypography-root': {
		color: 'var(--white)',
		'& button': {
			'& svg': {
				color: 'var(--white)',
			},
		},
		'& .MuiGrid-root': {
			alignItems: 'center',
		},
	},
	'& .MuiPaper-elevation': {
		backgroundColor: 'var(--boxViewBg)',
		'& .box-grid': {
			backgroundColor: 'transparent',
			padding: '0',
		},
		'& .grid-bg': {
			backgroundColor: 'var(--oddsParentBg) !important',
			color: 'var(--white)',
		},
	},
	'& .MuiDialogContent-root': {
		color: 'var(--white)',
	},
}));
