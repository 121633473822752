import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Badge } from '@mui/material';
import { setShowBetslip } from 'SportsBook/BetBalancer/Store/slice/betslip.slice';
import betslipIcon from '../../../Assets/Images/sports-icon/betslip.svg';

const BetslipMobileBadge = () => {
    const dispatch = useDispatch();
    const { showBetslip, betCount } = useSelector((state) => state.SBbetslip);

    return (
    <Grid className="mobileBetSlip" onClick={() => dispatch(setShowBetslip(!showBetslip))}>
        <Typography>
            <Badge badgeContent={betCount} color="primary" className="mobBadge">
                <img src={betslipIcon} alt="betslipIcon" />
            </Badge>
        </Typography>
    </Grid>
)}

export default BetslipMobileBadge
