import SportBookLobby from '../SportsPages/SportBookLobby/home';
import AllMarkets from '../SportsPages/AllMarkets/index';
import MyBets from '../SportsPages/MyBets/index';
import SportEvents from '../SportsPages/SportBookLobby/sportEvents';
import { SB_ROUTE_PATHS } from './routeConstant';

export const sportsRoutes = [
    {
        path: SB_ROUTE_PATHS.SPORTS,
        component: SportBookLobby,
        // isPrivate: false,
        showHeader: true,
        showFooter: true,
    },
    {
        path: SB_ROUTE_PATHS.SPORT_EVENTS,
        component: SportEvents,
        // isPrivate: false,
        showHeader: true,
        showFooter: true,
    },
    {
        path: SB_ROUTE_PATHS.ALL_MARKETS,
        component: AllMarkets,
        // isPrivate: false,
        showHeader: true,
        showFooter: true,
    },
    {
        path: SB_ROUTE_PATHS.MY_BETS,
        component: MyBets,
        isPrivate: true,
        showHeader: true,
        showFooter: true,
    },
];
