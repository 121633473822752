/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

const Button = (props) => {
	const { buttonlabel, active = false, className = '' } = props;
	return (
		<button {...props} className={`${className} ${active && 'active'}`}>
			{buttonlabel || props.children}
		</button>
	);
};

Button.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	buttonlabel: PropTypes.shape({
		props: PropTypes.shape({
			children: PropTypes.string,
		}),
	}),
	active: PropTypes.bool,
	children: PropTypes.any,
};

export default Button;
