import React from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { selectPreMatchSport } from 'SportsBook/BetBalancer/Store/slice/sports.slice';
import { SB_ROUTE_PATHS } from 'SportsBook/BetBalancer/SportsRoutes/routeConstant';
import upcoming from '../../../Assets/Images/sports-icon/upcoming-title.svg';
import MatchesList from '../MatchesList/index';
import MatchesBox from '../MatchesBox/index';
import MatchHeading from '../MatchesContainer/MatchHeading';
import useMatches from './hook/useMatches';
import { Pagination } from '../Pagination/index';

const PreMatches = () => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const matchView = useSelector((state) => state.SBgeneral.matchView);
	const { selectedPreMatchSportId } = useSelector((state) => state.SBsports);
	const { matches, setPage, loading, totalEvents, perPage, page } =
		useMatches();

	const isSportEventPage = pathname?.includes(SB_ROUTE_PATHS.SPORT_EVENTS);

	const handlePreMatchSports = ({ providerId }) => {
		dispatch(selectPreMatchSport(providerId));
	};

	return (
		<>
			{!isSportEventPage && (
				<MatchHeading
					iconSrc={upcoming}
					title={t('preMatchSB')}
					handleSportSelect={handlePreMatchSports}
					selectedSport={selectedPreMatchSportId}
				/>
			)}
			{matchView === 'box' ? (
				<Grid className="match-box--view">
					<MatchesBox matches={matches} loading={loading} />
				</Grid>
			) : (
				<Grid className="match-list--view">
					<MatchesList matches={matches} loading={loading} />
				</Grid>
			)}
			{!loading && matches?.length ? (
				<Pagination
					setPage={setPage}
					totalEvents={totalEvents}
					perPage={perPage}
					page={page}
				/>
			) : null}
		</>
	);
};
PreMatches.defaultProps = {
	// Live_matches: '',
};

PreMatches.propTypes = {
	// Live_matches: PropTypes.string,
};

export default PreMatches;
