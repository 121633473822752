/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import './chatDrawer.scss';
import { setShowChat } from 'redux-thunk/redux/slices/settings.slice';
import { setShowLoginPopup } from 'redux-thunk/redux/slices/gameSetting.slice';
import config from 'config/app.config';
import GameLoader from 'components/ui-kit/Loader/GameLoader/GameLoader';
import {
	chatLogin,
	chatLogout,
	isValidJSONString,
	setChatTenant,
} from 'utils/helper';
import { getAuthToken } from 'helpers/cookie.helpers';
import { CHAT_ACTIONS } from 'utils/constant';
import { isMobile } from 'react-device-detect';

let authTimeout;

// Chat module: iframe
const ChatDrawer = () => {
	const dispatch = useDispatch();
	const authToken = getAuthToken();
	const [isLoading, setIsLoading] = useState(true);
	const { showChat } = useSelector((state) => state?.settings);

	useEffect(() => {
		window.addEventListener('message', (event) => {
			if (event.origin === config.REACT_APP_CHAT_URL) {
				const data = isValidJSONString(event?.data);
				if (data?.action === CHAT_ACTIONS.CLOSE_CHAT) {
					dispatch(setShowChat(false));
				}
				if (data?.action === CHAT_ACTIONS.LOGIN_PROMPT) {
					dispatch(setShowLoginPopup(true));
				}
			}
		});

		authTimeout = setTimeout(() => {
			if (authToken) chatLogin(authToken);
			else chatLogout();
		}, 1000);

		return () => {
			// eslint-disable-next-line no-unused-vars
			window.removeEventListener('message', (event) => {
				console.log('Chat listener removed');
			});
			if (authTimeout) clearTimeout(authTimeout);
		};
	}, []);

	const onChatLoad = () => {
		setIsLoading(false);
		setChatTenant();
	};

	useEffect(() => {
		// const chatDiv = document.getElementById('chat-widget-container');
		// if (chatDiv) {
		// 	if (showChat) {
		// 		chatDiv.style.transition = 'margin-right 0.3s ease';
		// 		chatDiv.style.marginRight = isMobile ? 'calc(100vw - 90px)' : '380px';
		// 	} else {
		// 		chatDiv.style.transition = 'margin-right 0.3s ease';
		// 		chatDiv.style.marginRight = '0px';
		// 	}
		// }
		// if (chatContentIframe) {
		// 	if (!iframeObserver) {
		// 		iframeObserver = new MutationObserver(() => {
		// 			if (showChat) {
		// 				dispatch(setShowChat(false));
		// 			}
		// 		});
		// 		iframeObserver.observe(chatContentIframe, {
		// 			attributes: true,
		// 			attributeFilter: ['style'],
		// 			childList: false,
		// 			subtree: false,

		// 		});
		// 	}
		// }

		window.LiveChatWidget.on('visibility_changed', (data) => {
			if (data.visibility === 'maximized' && isMobile) {
				dispatch(setShowChat(false));
			}
		});
		window.LiveChatWidget.on('ready', () => {
			const chatDiv = document.getElementById('chat-widget-container');
			if (chatDiv) {
				if (showChat) {
					if (isMobile) {
						window.LiveChatWidget.call('minimize');
					}
					chatDiv.style.transition = 'margin-right 0.3s ease';
					chatDiv.style.marginRight = isMobile ? 'calc(100vw - 90px)' : '380px';
				} else {
					chatDiv.style.transition = 'margin-right 0.3s ease';
					chatDiv.style.marginRight = '0px';
				}
			}
		});
	}, [showChat, isMobile]);

	// useEffect(() => {
	// const observer = new MutationObserver(() => {
	// 	const chatDiv = document.getElementById('chat-widget-container');
	// 	if (chatDiv) {
	// 		if (showChat) {
	// 			chatDiv.style.transition = 'margin-right 0.3s ease';
	// 			chatDiv.style.marginRight = isMobile ? 'calc(100vw - 90px)' : '380px';
	// 			observer.disconnect();
	// 		} else {
	// 			chatDiv.style.transition = 'margin-right 0.3s ease';
	// 			chatDiv.style.marginRight = '0px';
	// 			observer.disconnect();
	// 		}
	// 	}
	// });
	// observer.observe(document.body, { childList: true, subtree: true });

	// }, []);

	return (
		<Grid className={`chat-wrap ${!showChat ? 'chat-close' : ''}`}>
			{isLoading && (
				<div className="game-loader" textAlign="center">
					<GameLoader />
				</div>
			)}
			<iframe
				src={config.REACT_APP_CHAT_URL}
				id="chat-drawer"
				onLoad={onChatLoad}
			/>
		</Grid>
	);
};

export default ChatDrawer;
