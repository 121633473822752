/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setWalletSetting } from 'redux-thunk/redux/slices/user.slice';
import fiatCurrency from './data.json';

const WalletSetting = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { hideZeroCurrency, displayFiatCurrency, selectedFiat } = useSelector(
		(state) => state.user
	);
	const [hideZero, setHideZeroCurrency] = useState(hideZeroCurrency);
	const [displayFiat, setDisplayFiatCurrency] = useState(displayFiatCurrency);
	const [selectedFiatCurrency, setSelectedFiat] = useState(selectedFiat);

	return (
		<div className="modal fade show d-block" id="walletSettingsModal">
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<div className="modal-body">
						<div className="content-box">
							<button
								type="button"
								className="btn-close"
								onClick={() => props.onClose()}
							/>
							<div className="row g-0">
								<div className="col-md-12">
									<div className="side-content-box">
										<h1 className="modal-title">{t('WalletSettingTitle')}</h1>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												dispatch(
													setWalletSetting({
														hideZero,
														displayFiat,
														selectedFiatCurrency,
													})
												);
												props.onClose();
											}}
										>
											<div className="row g-2">
												<div className="col-md-12 mb-3">
													<div className="switch-text-wrap">
														<label className="switch">
															<input
																type="checkbox"
																checked={hideZero}
																onClick={() => setHideZeroCurrency(!hideZero)}
															/>
															<span className="slider" />
														</label>
														<div className="text-box">
															<h6 className="title-text">
																{t('WalletSettingHideZero')}
															</h6>
															<p className="text">
																{t('WalletSettingHideZeroInfo')}
															</p>
														</div>
													</div>
												</div>
												<div className="col-md-12 mb-3">
													<div className="switch-text-wrap">
														<label className="switch">
															<input
																type="checkbox"
																checked={displayFiat}
																onClick={() =>
																	setDisplayFiatCurrency(!displayFiat)
																}
															/>
															<span className="slider" />
														</label>
														<div className="text-box">
															<h6 className="title-text">
																{t('WalletSettingDisplayFiat')}
															</h6>
															<p className="text">
																{t('WalletSettingDisplayFiatInfo')}
															</p>
														</div>
													</div>
												</div>
												{fiatCurrency.map((info) => (
													<div
														className="col-md-3 mb-3"
														key={`fiat-${info.code}`}
													>
														<div className="form-check mb-3">
															<input
																className="form-check-input"
																checked={selectedFiatCurrency === info.code}
																onChange={() => setSelectedFiat(info.code)}
																type="radio"
																name="currencySetting"
																id="currencySetting-1"
																disabled={!displayFiat}
															/>
															<label
																className="form-check-label"
																htmlFor="currencySetting-1"
															>
																<span>{info.code}</span>
																<img
																	src="assets/images/stock-images/currency-btc.png"
																	className="img-fluid"
																	alt="Currency"
																/>
															</label>
														</div>
													</div>
												))}
												<div className="col-md-6 mb-3">
													<p className="text">
														{t('WalletSettingNoteForApprox')}
													</p>
												</div>
												<div className="col-md-6 mb-3">
													<button className="btn mainBtn w-100" type="submit">
														{t('WalletSettingSave')}
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WalletSetting;
