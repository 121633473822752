import 'moment/locale/en-gb'
import momentTimezone from 'moment-timezone'

momentTimezone.locale('en-gb')
export const moment = momentTimezone

export const momentConvertInTime = (time) => {
  if (time) {
    return moment(time).format('DD MMM HH:mm:ss')
  } 
    return ''
  
}

export const momentDate = (time) => {
  if (time) {
    return moment(time).format('MM-DD-YYYY')
  } 
    return ''
  
}
export const momentAPIDate = (time) => {
  if (time) {
    return moment(time).format('YYYY-MM-DD')
  } 
    return ''
  
}
