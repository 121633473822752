import React from "react";

import { Grid, Skeleton } from "@mui/material";
import { PropTypes } from 'prop-types';

import useStyle from "./CardSkeleton.styles";

const GamesSkeleton = ({
    skeletonRow,
    isLive,
    // isTop
}) => {
    const skeletons = Array(skeletonRow).fill(null);
    const innerSkeleton = [...Array(1).keys()];
    const classes = useStyle();

    return (
        <>
            {skeletons.map(() => (
                <Grid container spacing={2} mt={0}>
                    {innerSkeleton.map((index) => (
                        <Grid
                            key={index}
                            item
                            xs={12}
                            md={12}
                            lg={12}
                        >
                            <Skeleton sx={{ bgcolor: '#757575', fontSize: "1rem" }} variant="text" />
                            <Skeleton sx={{ bgcolor: '#757575' }} className={classes.skeletonStatus} variant="text" />
                            <div className={classes.skeletonTeamWrap}>
                                <Skeleton
                                    className={classes.skeletonTeamName}
                                    variant="rounded"
                                    sx={{ bgcolor: '#757575' }}
                                />
                                <div style={{ display: "flex" }}>
                                    {isLive && (
                                        <>
                                            <Skeleton
                                                className={classes.skeletonTeamScore}
                                                variant="rounded"
                                                sx={{ bgcolor: '#757575' }}
                                            />
                                            <Skeleton
                                                className={classes.skeletonTeamScore}
                                                variant="rounded"
                                                sx={{ bgcolor: '#757575' }}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className={classes.skeletonTeamWrap}>
                                <Skeleton
                                    className={classes.skeletonTeamName}
                                    variant="rounded"
                                    sx={{ bgcolor: '#757575' }}
                                />
                                <div style={{ display: "flex" }}>
                                    {isLive && (
                                        <>
                                            <Skeleton
                                                className={classes.skeletonTeamScore}
                                                variant="rounded"
                                                sx={{ bgcolor: '#757575' }}
                                            />
                                            <Skeleton
                                                className={classes.skeletonTeamScore}
                                                variant="rounded"
                                                sx={{ bgcolor: '#757575' }}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                            <Skeleton
                                className={classes.ExtramarketName}
                                variant="rounded"
                                sx={{ bgcolor: '#757575' }}
                            />
                            <div className={classes.skeletonbtnWrap}>
                                <Skeleton
                                    className={classes.skeletonbtn}
                                    variant="rounded"
                                    sx={{ bgcolor: '#757575' }}
                                />
                                <Skeleton
                                    className={classes.skeletonbtn}
                                    variant="rounded"
                                    sx={{ bgcolor: '#757575' }}
                                />
                                <Skeleton
                                    className={classes.skeletonbtn}
                                    variant="rounded"
                                    sx={{ bgcolor: '#757575' }}
                                />
                            </div>
                        </Grid>
                    ))}
                </Grid>
            ))}
        </>
    );
}

export default GamesSkeleton;

GamesSkeleton.defaultProps = {
    // Live_matches: '',
    skeletonRow: 8,
    isLive: false,
    // isTop: true
};

GamesSkeleton.propTypes = {
    // Live_matches: PropTypes.string,
    skeletonRow: PropTypes.number,
    isLive: PropTypes.bool,
    // isTop: PropTypes.bool
};
