import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	viewAllGames: null,
};

const {
	actions: { setViewAllPopupGames },
	reducer,
} = createSlice({
	name: 'searchPopup',
	initialState,
	reducers: {
		setViewAllPopupGames: (state, action) => ({
			...state,
			viewAllGames: action?.payload,
		}),
	},
	// extraReducers: (builder) => {

	// }
});

export default reducer;
export { setViewAllPopupGames };
