import SBgeneral from './general.slice';
import SBpreMatch from './preMatch.slice';
import SBsports from './sports.slice';
import SBbetslip from './betslip.slice';
import SBlocation from './location.slice';
import SBleague from './league.slice';
import SBallMarket from './allMarket.slice';
import SBmyBets from './mybets.slice';
import SBsettings from './settings.slice';
import SBglobalPersist from './globalPersist.slice';
import SBcashout from './cashout.slice';

const SBreducer = {
	SBgeneral,
	SBpreMatch,
	SBsports,
	SBbetslip,
	SBlocation,
	SBmyBets,
	SBleague,
	SBallMarket,
	SBsettings,
	SBglobalPersist,
	SBcashout,
};

export default SBreducer;
