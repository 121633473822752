/* eslint-disable react/prop-types */
import MainLayout from 'components/layout/MainLayout/index';
import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ Component, isAuthenticated, child, showFooter }) =>
	isAuthenticated ? (
		<MainLayout showFooter={showFooter}>
			<Component child={child} />
		</MainLayout>
	) : (
		<MainLayout showFooter={showFooter}>
			<Navigate to={{ pathname: '/' }} replace />
		</MainLayout>
	);

export default PrivateRoute;
