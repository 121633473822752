import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSuccessToaster } from 'SportsBook/BetBalancer/Helper/toaster';
import {
	getCashoutAmountService,
	acceptCashoutService,
} from 'SportsBook/BetBalancer/Network/Services/cashout.service';

const getCashoutAmount = createAsyncThunk(
	'get/cashout-amount',
	async (payload, thunkApi) => {
		try {
			let res = await getCashoutAmountService({ betslipId: payload.betslipId });
			res = res.data.data;
			if (payload.callBack) payload.callBack();
			return res;
		} catch (err) {
			return thunkApi.rejectWithValue(
				(err && err[0] && err[0]?.description) || err?.message
			);
		}
	}
);

const acceptCashout = createAsyncThunk(
	'get/accept-cashout',
	async (payload, thunkApi) => {
		try {
			let res = await acceptCashoutService({ betslipId: payload.betslipId });
			openSuccessToaster({ message: 'Cashout success!' });
			if (payload.callBack) payload.callBack();
			res = res.data.data;
			return res;
		} catch (err) {
			return thunkApi.rejectWithValue(
				(err && err[0] && err[0]?.description) || err?.message
			);
		}
	}
);

export { getCashoutAmount, acceptCashout };
