import React, { useEffect, useState } from 'react';
import { Typography, Grid, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Form, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InputField from 'components/molecules/Input/InputField';
import Button from 'components/molecules/Button/index';
import { getReferralCode } from 'redux-thunk/thunk/bonus.thunk';
import { referralsSchema } from './schema';
import './referral.scss';
import MyReferral from './RelerralTable/index';
import ReferralBanner from '../../../assets/images/rewards.png';

const Referrals = () => {
	const { t } = useTranslation();
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const dispatch = useDispatch();
	const { depositLoading } = useSelector((state) => state.payment);

	const { user } = useSelector((state) => state.user);

	useEffect(() => {
		dispatch(getReferralCode());
	}, []);

	const copyToClipboard = (text) => {
		if (navigator?.clipboard && navigator?.clipboard?.writeText) {
			navigator.clipboard
				.writeText(text)
				.then(() => {
					setSnackbarOpen(true);
					setTimeout(() => {
						setSnackbarOpen(false); // Revert button text back to 'Copy Code' after 2 seconds
					}, 2000);
				})
				.catch((err) => {
					console.error('Could not copy text:', err);
				});
		} else {
			// Fallback for browsers that do not support the Clipboard API
			const textArea = document.createElement('textarea');
			textArea.value = text;
			textArea.style.position = 'fixed';
			textArea.style.top = '0';
			textArea.style.left = '-9999px';
			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();
			try {
				document.execCommand('copy');
				setSnackbarOpen(true);
				setTimeout(() => {
					setSnackbarOpen(false); // Revert button text back to 'Copy Code' after 2 seconds
				}, 2000);
			} catch (err) {
				console.error('Fallback: Oops, unable to copy', err);
			}
			document.body.removeChild(textArea);
		}
	};

	return (
		<>
			<Grid className="profile-card-wrap referral_ref">
				<Grid className="refer_heading">
					{/* <Typography variant="h6" mb={1}>
						{t('referral')}
					</Typography> */}
					<Typography
						variant="h2"
						mb={1}
						sx={{
							color: 'rgb(255, 232, 26)',
							fontSize: '28px',
							fontWeight: 600,

							// textDecorationLine: 'line-through',
							textDecorationColor: 'red',
						}}
					>
						Refer A Friend And Get Rewarded
					</Typography>
				</Grid>
				<Grid className="refer-banner">
					<img src={ReferralBanner} alt="" />
				</Grid>
				<Formik
					enableReinitialize
					initialValues={{ code: user?.referralCode }}
					validationSchema={referralsSchema(t)}
					onSubmit={(values) => console.log(values)}
				>
					{({ values, handleBlur }) => (
						<Form autoComplete="off" style={{ marginTop: '35px' }}>
							<Grid className="wagerSection">
								<Grid
									container
									display="flex"
									spacing={{ xs: '0', sm: '2' }}
									alignItems="center"
									// marginBottom="10px"
									// marginLeft='auto'
									className="wagerInputs"
								>
									<Grid item xs={12} lg={12}>
										<Typography className="referral-code-title">
											{t('referralCode')}
										</Typography>
									</Grid>
									<Grid xs={12} sm={6} lg={12}>
										<Grid className="inputIcon" spacing={2}>
											<InputField
												name="referral"
												className="referral_input"
												type="text"
												id="textToCopy"
												placeholder={t('referralCode')}
												value={values.code}
												onBlur={handleBlur}
												disabled
											/>
											<ErrorMessage
												component="div"
												name="referral"
												className="color-red text-danger login-input  ms-2 d-flex"
											/>
											<Button
												disabled={!user?.referralCode || depositLoading}
												type="button"
												className="btn btn-secondary referral_copy_btn"
												onClick={() => copyToClipboard(values.code)}
											>
												{!snackbarOpen && <ContentCopyIcon />}
												{snackbarOpen ? 'Copied!' : ''}
											</Button>
										</Grid>

										<Snackbar
											open={snackbarOpen}
											autoHideDuration={1000}
											onClose={() => setSnackbarOpen(false)}
											message="Copied to clipboard"
										/>
									</Grid>
									<Grid item xs={12} lg={12}>
										<Button
											disabled={!user?.referralCode || depositLoading}
											type="button"
											className="copyLink-button"
											onClick={() => copyToClipboard(values.code)}
										>
											{!snackbarOpen && <ContentCopyIcon />}
											{snackbarOpen ? 'Copied!' : ''}
											Copy Code
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Form>
					)}
				</Formik>
			</Grid>
			<MyReferral />
		</>
	);
};

export default React.memo(Referrals);
