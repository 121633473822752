import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSettingsService } from 'SportsBook/BetBalancer/Network/Services/settings.service';

const getSettings = createAsyncThunk(
    'sport-book/get-settings',
    async (payload, thunkApi) => {
        try {
            let res = await getSettingsService(payload);
            res = res.data.data;
            return res;
        } catch (err) {
            return thunkApi.rejectWithValue(
                (err && err[0] && err[0]?.description) || err?.message
            );
        }
    }
);

export { getSettings };
