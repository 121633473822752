import { openErrorToaster } from 'SportsBook/BetBalancer/Helper/toaster';
import {
	calcBackProfitPayout,
	calcPossibleWin,
} from 'SportsBook/BetBalancer/Helper/utils';
import {
	removeBet,
	setComboStake,
	setSingleStake,
	setBetError,
} from 'SportsBook/BetBalancer/Store/slice/betslip.slice';
import { placeSportsbookBet } from 'SportsBook/BetBalancer/Store/thunk/betslip.thunk';
import { getSettings } from 'SportsBook/BetBalancer/Store/thunk/settings.thunk';
import { getAuthToken } from 'helpers/cookie.helpers';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useBetslip = () => {
	const dispatch = useDispatch();
	const token = !!getAuthToken();
	const [calculation, setCalculation] = useState({
		profit: 0,
		payout: 0,
		liability: 0,
		possibleWin: 0,
	});

	const { settings } = useSelector((state) => state.SBsettings);

	const { bets, singleBetStake, comboBetStake, betCount, betError, totalOdds } =
		useSelector((state) => state.SBbetslip);

	const { defaultWallet } = useSelector((state) => state.user);

	useEffect(() => {
		if (isEmpty(settings)) {
			dispatch(getSettings());
		}
	}, []);

	useEffect(() => {
		if (singleBetStake >= 0 && betCount === 1) {
			const { profit, payout, liability } = calcBackProfitPayout(
				singleBetStake,
				Object.values(bets || {})[0]?.odds
			);
			setCalculation((prev) => ({
				...prev,
				profit,
				payout,
				liability,
				possibleWin: calcPossibleWin(totalOdds, singleBetStake),
			}));
		}
	}, [singleBetStake, betCount]);

	useEffect(() => {
		if (comboBetStake >= 0 && betCount > 1) {
			setCalculation((prev) => ({
				...prev,
				possibleWin: calcPossibleWin(totalOdds, comboBetStake),
			}));
		}
	}, [comboBetStake, totalOdds, betCount]);

	const checkStake = (val, prevStake) => {
		const stake = Number(val);
		if (stake <= 0) {
			return '';
		}

		// if(stake < settings?.minStakeAmount || stake > settings?.maxStakeAmount) {
		if (stake > (settings?.maxStakeAmount || 10000)) {
			return prevStake;
		}

		if (`${stake}`?.split('.')[1]?.length > 2) {
			return prevStake;
		}

		return stake;
	};

	const handleSingleStake = (val) => {
		dispatch(setSingleStake(checkStake(val, singleBetStake)));
	};

	const handleComboStake = (val) => {
		dispatch(setComboStake(checkStake(val, comboBetStake)));
	};

	const placeSportsBet = () => {
		try {
			if (!token) {
				return openErrorToaster({ message: 'Please login to proceed.' });
			}
			const walletId = defaultWallet?.id;
			const stake = betCount > 1 ? comboBetStake : singleBetStake;

			const betValues = Object.values(bets || {});

			if (parseFloat(stake, 10) <= 0) {
				return openErrorToaster({
					message: 'Stake amount must be greater than 0.',
				});
			}

			if (isEmpty(betValues) || betError) {
				return null;
			}

			if (!walletId) {
				return openErrorToaster({ message: 'Missing wallet information!' });
			}

			for (let i = 0; i < betValues.length; i += 1) {
				for (let j = 0; j < betValues.length; j += 1) {
					if (i !== j && betValues[i].fixtureId === betValues[j].fixtureId) {
						dispatch(setBetError('Selections cannot be combined'));
						return null;
					}
				}
			}

			const betsData = Object.entries(bets || {})?.map(
				([, { fixtureId, providerMarketId, providerOutcomeId, outcome }]) => ({
					fixtureId,
					providerMarketId,
					providerOutcomeId,
					odds: outcome.price,
				})
			);

			dispatch(
				placeSportsbookBet({
					walletId,
					stake,
					betsData,
				})
			);
			return null;
		} catch (err) {
			return openErrorToaster({ message: err?.message });
		}
	};

	const handleRemoveBet = (e, bet) => {
		e.preventDefault();
		dispatch(removeBet(bet));
	};

	return {
		placeSportsBet,
		bets,
		singleBetStake,
		comboBetStake,
		handleSingleStake,
		calculation,
		handleComboStake,
		handleRemoveBet,
		token,
	};
};

export default useBetslip;
