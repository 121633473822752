/* eslint-disable jsx-a11y/label-has-associated-control */
import Button from 'components/molecules/Button/index';
import InputField from 'components/molecules/Input/InputField';
import { ReactSelect } from 'components/molecules/ReactSelect/index';
import { WalletIcon } from 'components/ui-kit/Icons/iconComponents/WalletIcon';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QRCode from 'react-qr-code';
import {
	depositAmountRequest,
	// generateAddressRequest,
	getCryptoNetworks,
} from 'redux-thunk/thunk/user.thunk';
import { openErrorToaster, openSuccessToaster } from 'helpers/toaster.helpers';
import { resetpaymentDetails } from 'redux-thunk/redux/slices/payment.slice';
import './deposit.scss';
import Loader from 'components/ui-kit/Loader/index';
import { preventSymbolsInNumField } from 'utils/helperFunctions.utils';

const currencyCodeImg = {
	USDT: '/assets/images/stock-images/tether.png',
	TETH: '/assets/images/stock-images/ETH.webp',
};

const DepositAmount = () => {
	const userData = useSelector((state) => state?.user?.user?.user);
	const dispatch = useDispatch();
	const [selectedCurrency, setSelectedCurrency] = useState(0);
	const [selectedNetwork, setSelectedNetwork] = useState(0);
	const [depositAmount, setDepositAmount] = useState('');
	// const settingData = useSelector((state) => state.general.settingData);
	const { cryptoNetworks, depositDetails, depositLoading } = useSelector(
		(state) => state.payment
	);
	// const curr = userData?.wallets?.find(
	// 	(item) => item?.currency?.code === selectedCurrency?.value
	// );
	// console.log(depositDetails, 'depositDetails');
	// const minAmount = settingData?.coinspaidDepositLimit
	// 	? settingData?.coinspaidDepositLimit?.[curr?.currency?.code] || ''
	// 	: '';
	// console.log(minAmount,settingData, 'minAmount')

	// useEffect(() => {
	// 	if (selectedCurrency !== 0) {
	// 		if (!curr?.paymentAddress) {
	// 			dispatch(
	// 				generateAddressRequest({ currencyCode: curr?.currency?.code })
	// 			);
	// 		}
	// 	}
	// }, [selectedCurrency]);

	const currencyOptions = useMemo(
		() =>
			userData?.wallets?.map(({ currency }) => ({
				label: (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%',
						}}
					>
						<span>
							<span className="me-1">{currency?.symbol}</span>
							<span>{currency.code}</span>
						</span>
						{/* <span>
							<img
								src={goldICon}
								alt="Coin"
								className="me-2 option-coin-icon"
							/>
							{bonusAmount}
						</span> */}
					</div>
				),
				value: currency.code,
			})),
		[userData]
	);

	const networkOptions = useMemo(
		() =>
			cryptoNetworks?.map((item) => ({
				label: (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%',
						}}
					>
						<span>
							{/* <span className="me-1">{currency?.symbol}</span> */}
							<span>{item.chainFullName}</span>
						</span>
						{/* <span>
							<img
								src={goldICon}
								alt="Coin"
								className="me-2 option-coin-icon"
							/>
							{bonusAmount}
						</span> */}
					</div>
				),
				value: item.chain,
			})),
		[cryptoNetworks]
	);

	const handleCopyButton = async () => {
		if (
			window.isSecureContext &&
			navigator?.clipboard &&
			depositDetails?.address
		) {
			await navigator.clipboard
				?.writeText(depositDetails?.address)
				.then(() => {
					openSuccessToaster({ message: 'Address Copied' });
				})
				.catch(() => {});
		}
	};

	const handleCurrencyBtnClick = (code) => {
		const wallet = currencyOptions.find((item) => item.value === code);
		if (wallet) {
			dispatch(getCryptoNetworks({ currencyCode: code }));
			setSelectedCurrency(wallet);
		}
	};

	const handleSubmitClick = () => {
		const amount = Number(depositAmount || 0);
		if (!amount || amount === 0) {
			openErrorToaster({ message: 'Please enter valid deposit amount' });
			return;
		}
		if (!selectedNetwork) {
			openErrorToaster({ message: 'Please select network' });
			return;
		}
		if (!selectedCurrency) {
			openErrorToaster({ message: 'Please select Currency' });
			return;
		}
		dispatch(
			depositAmountRequest({
				currencyCode: selectedCurrency?.value,
				amount: depositAmount,
				chain: selectedNetwork?.value,
			})
		);
	};

	const handleDepositUrl = () => {
		if (depositDetails?.checkoutUrl) {
			window.open(depositDetails?.checkoutUrl, '_blank').focus();
		}
	};

	const handleCurrencyChange = (item) => {
		dispatch(getCryptoNetworks({ currencyCode: item.value }));
		dispatch(resetpaymentDetails());
		setSelectedCurrency(item);
		setSelectedNetwork(0);
	};

	return (
		<div className="profile-card-wrap">
			<div className="profile-heading mb-3">
				<h4 className="m-0">
					<WalletIcon /> Deposit
				</h4>
			</div>

			<div className="d-flex col-lg-12 gap-1 mb-2 form-group">
				<div className="col-lg-12 col-12 w-2 ">
					<div className="currency-options-btn-box">
						{userData?.wallets?.map((wallet) => (
							<button
								onClick={() => handleCurrencyBtnClick(wallet?.currency?.code)}
								className={`currency-options-btn ${selectedCurrency.value === wallet?.currency?.code ? 'currency-btn-selected' : ''}`}
								type="button"
							>
								<span className="icon-box">
									<img
										src={
											currencyCodeImg?.[wallet?.currency?.code] ||
											'/assets/images/stock-images/coin.png'
										}
										alt="Currency Icon"
									/>
								</span>
								<span className="text">{wallet?.currency?.code}</span>
							</button>
						))}
					</div>
				</div>
			</div>

			<div className="d-flex col-lg-12 gap-1 mb-2 form-group">
				<div className="col-lg-6 col-6 w-2 ">
					<label htmlFor="country" className="form-label">
						Deposit Currency
					</label>
					<ReactSelect
						type="text"
						placeholder="Deposit Currency"
						classNamePrefix="selectProvider"
						options={currencyOptions}
						defaultValue={selectedCurrency}
						// defaultMenuIsOpen
						value={selectedCurrency}
						onChange={handleCurrencyChange}
					/>
				</div>
				<div className="col-lg-6 col-6 w-2 ">
					<label htmlFor="country" className="form-label">
						Choose Network
					</label>
					<ReactSelect
						type="text"
						placeholder="Network"
						classNamePrefix="selectProvider"
						options={networkOptions}
						defaultValue={selectedNetwork}
						value={selectedNetwork}
						onChange={(item) => {
							dispatch(resetpaymentDetails());
							setSelectedNetwork(item);
						}}
					/>
				</div>
			</div>
			{/* {selectedCurrency ? (
				<span className="deposit-note">{`NOTE: You Need To Deposti Minimum ${minAmount} ${curr?.currency?.code} For a Successful Payment`}</span>
			) : null} */}
			<div className="d-flex col-lg-12 gap-1 mb-2 form-group">
				<div className="col-lg-6 col-6 w-2 ">
					<label htmlFor="country" className="form-label">
						Deposit Amount
					</label>
					<InputField
						type="number"
						placeholder="Deposit Amount"
						className="form-control shadow-none"
						value={depositAmount}
						onKeyDown={preventSymbolsInNumField}
						onChange={(e) => {
							dispatch(resetpaymentDetails());
							setDepositAmount(e.target.value);
						}}
					/>
				</div>
			</div>
			{!depositDetails?.address ? (
				<div className="d-flex col-lg-12 gap-1 mb-2 form-group">
					<Button
						disabled={!selectedNetwork || depositLoading}
						type="submit"
						className="btn btn-primary submit-button"
						buttonlabel={<span>{depositLoading ? <Loader /> : 'Submit'}</span>}
						onClick={handleSubmitClick}
					/>
				</div>
			) : null}
			<div className="d-flex flex-column gap-1 form-group address-wrapper">
				{depositDetails?.address ? (
					<div className="row">
						<div className="qr-code-wrapper col-lg-4 col-md-4 col-sm-12">
							<QRCode
								size={200}
								value={depositDetails?.address}
								viewBox="0 0 256 256"
							/>
						</div>
						<div className="col-lg-8 col-md-8 col-sm-12">
							<label htmlFor="country" className="form-label">
								Deposit URL
							</label>
							<InputField
								type="text"
								placeholder="Deposit URL"
								className="form-control shadow-none deposit-url"
								value={depositDetails?.checkoutUrl || ''}
								onClick={handleDepositUrl}
								readOnly
							/>
							<label htmlFor="country" className="form-label">
								Deposit address
							</label>
							<InputField
								type="text"
								placeholder="Deposit address"
								className="form-control shadow-none deposit-address"
								value={depositDetails?.address || ''}
								readOnly
							/>

							<Button
								disabled={!selectedCurrency}
								type="submit"
								className="btn btn-primary copy-button"
								buttonlabel={<span>Copy Address</span>}
								onClick={handleCopyButton}
							/>
							{selectedCurrency ? (
								<span className="deposit-note">Crypto deposit fees: 1.5 %</span>
							) : null}
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default React.memo(DepositAmount);
