import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	getCasinoProviderGamesService,
	getCasinoProviderListService,
	getHighPayoutBets,
	getLatestBets,
} from 'network/services/casino.service';
import {
	setCasinoProviderListData,
	setHighPayoutBets,
	setLatestBets,
	setProviderGames,
} from 'redux-thunk/redux/slices/casino.slice';
import { v4 as uuidv4 } from 'uuid';

export const getCasinoProviderList = createAsyncThunk(
	'/getCasinoProviderList',
	async (data, thunkApi) => {
		try {
			const res = await getCasinoProviderListService(data);
			thunkApi.dispatch(setCasinoProviderListData(res?.providers));
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);
export const getCasinoProviderGames = createAsyncThunk(
	'casino/get-providers-games',
	async (data, thunkApi) => {
		try {
			const res = await getCasinoProviderGamesService({
				pageNo: data.pageNo,
				limit: data.limit,
				providerName: data.providerName,
			});
			thunkApi.dispatch(setProviderGames(res));
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const fetchLatestBets = createAsyncThunk(
	'casino/get-latest-bets',
	async (data, thunkApi) => {
		try {
			const res = await getLatestBets();
			const bets = (res?.recentBigWins || []).map((item) => ({
				...item,
				uniqueKey: uuidv4(),
			}));
			thunkApi.dispatch(setLatestBets({ data: bets }));
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);

export const fetchLatestHighPayout = createAsyncThunk(
	'casino/get-latest-high-payout',
	async (data, thunkApi) => {
		try {
			const res = await getHighPayoutBets();
			const bets = (res?.recentBigWins || []).map((item) => ({
				...item,
				uniqueKey: uuidv4(),
			}));
			thunkApi.dispatch(setHighPayoutBets({ data: bets }));
			return res;
		} catch (error) {
			return thunkApi.rejectWithValue(
				error && error[0] && error[0]?.description
			);
		}
	}
);
