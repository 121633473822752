/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAuthToken } from 'helpers/cookie.helpers';
import FavouriteIcon from 'components/molecules/FavouriteIcon/index';
import { GameImg } from 'components/molecules/GameImage/index';
import { LOADER_HANDLER_TYPES } from 'constants/index';
import Loader from 'components/ui-kit/Loader/index';
import { openErrorToaster } from 'helpers/toaster.helpers';
import { useTranslation } from 'react-i18next';

const CasinoGameCard = (props) => {
	const { game, handleFavouriteGame, searchPopup = false, closeModal } = props;
	const { language } = useSelector((state) => state?.auth);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const authToken = getAuthToken();
	const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(
		(state) => state.loader
	);

	const [gameId, setGameId] = useState(null);
	const onGamePlayRedirect = useCallback(
		(gme) => {
			if (authToken) {
				navigate(
					`/casino/play-game/${gme.uniqueId}/${gme.casinoProviderId}/${gme.casinoProvider.casinoAggregator.uniqueId}/${gme?.name?.[language]}/${gme.demoAvailable}`
				);
			} else {
				openErrorToaster({ message: t('loginFirstPlay') });
			}
			if (searchPopup && authToken) {
				closeModal(false);
			}
		},
		[authToken, searchPopup]
	);

	useEffect(() => {
		if (!loading) {
			setGameId(null);
		}
	}, [loading]);
	return (
		<div className="theme-card-wrap casino-game-card">
			<div className="theme-card">
				<div className="casino-img-wrap">
					<GameImg
						playerCount={game.onlinePlayerCount}
						shouldLoad={false}
						imgSrc={
							game?.iconUrl.includes('nothing')
								? game?.casinoProvider?.iconUrl
								: game?.iconUrl
						}
					/>
					{game?.isFavorite && (
						<span onClick={() => handleFavouriteGame(game)}>
							<FavouriteIcon fill={game?.isFavorite ? 'red' : 'none'} />
						</span>
					)}

					<div className="card-overlay">
						{loading && gameId === game?.id ? (
							<Loader variant={LOADER_HANDLER_TYPES.submit} />
						) : (
							<>
								<span onClick={() => onGamePlayRedirect(game)}>
									<img
										src="/assets/images/casino/play-btn.png"
										alt="Play Btn"
										className="play-now-button m-0"
									/>
									<span className="play-now-text">{t('playNow')}</span>
								</span>

								<span onClick={() => handleFavouriteGame(game)}>
									<FavouriteIcon fill={game?.isFavorite ? 'red' : 'none'} />
								</span>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default React.memo(CasinoGameCard);
