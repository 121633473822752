const URLS = {
	GET_SPORTS: '/api/v1/sport-book/get-sports',
	GET_SPORT_BOOK_EXCHANGE: '/api/v1/bet/sport-book-exchange/get-bets',
	GET_SPORT_INFO: '/api/v1/sport-book/get-sport-info',
	GET_PREMATCHES: '/api/v1/sport-book/get-events',
	PLACE_BET: '/api/v1/bet/sport-book/place-bet',
	GET_ALL_MARKETS: '/api/v1/sport-book/get-markets',
	MY_BET: '/api/v1/bet/sport-book/get-user-bets',
	SETTINGS: '/api/v1/common/get-settings',
	GET_CASHOUT_AMOUNT: '/api/v1/bet/sport-book/get-cashout-amount',
	ACCEPT_CASHOUT: '/api/v1/bet/sport-book/cashout',
};

export { URLS };
